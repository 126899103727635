import { Stack, SxProps, Theme, Box, Skeleton } from '@mui/material';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';
import { COMMON } from '../../../constants/style';

type IProps = {
  text: string;
  value: string | number;
  isBorder?: boolean;
  loading?: boolean;
  color?: string;
  sx?: SxProps<Theme>;
};

const TeleAgentTextWrapper = ({
  text,
  value,
  isBorder,
  loading,
  color = COMMON.balck,
  sx,
}: IProps) => {
  return (
    <Stack
      sx={{
        ...sx,
        pr: 1.5,
        position: 'relative',
      }}
    >
      <VegaFormInputField label={text}>
        {loading ? (
          <Skeleton />
        ) : (
          <VegaText
            text={value}
            sx={{ mt: '0.25rem !important' }}
            fontSize={'0.8125rem'}
            fontWeight={450}
            fontColor={color}
          />
        )}
      </VegaFormInputField>
      {isBorder && (
        <Box
          sx={{
            position: 'absolute',
            width: '2px',
            height: '15px',
            bgcolor: '#E1E4EB',
            right: 0,
            top: '30%',
          }}
        ></Box>
      )}
    </Stack>
  );
};
export default TeleAgentTextWrapper;
