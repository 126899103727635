import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BidService } from '../Apis/BidService';
import { Bid } from '../types/auction';
import { BIDDER_USER_ID } from '../utils/EXPENSE_CONSTANTS';

export interface BidState {
  bids: Bid[];
  page: number;
  totalItems: number;
  pageSize: number;
  loading: boolean;
  error: string | null;
}

const initialState: BidState = {
  bids: [],
  loading: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
};

export class BidActions {
  static createBid = createAsyncThunk(
    'bid/create',
    async (data: { auctionId?: string; bidAmount?: number }) => {
      const response = await BidService.createBid({
        userId: BIDDER_USER_ID,
        auctionId: data.auctionId,
        bidAmount: data.bidAmount,
      });
      return response;
    }
  );
}

const bidSlice = createSlice({
  name: 'bid',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(BidActions.createBid.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BidActions.createBid.fulfilled, state => {
        state.loading = false;
        // Optionally, you can update state after creating a bidder if needed.
        // For example, you may want to add the newly created bidder to the list.
      })
      .addCase(BidActions.createBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});

export default bidSlice;
