/* eslint-disable no-empty */
import { Collapse } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { ReceiptManagmentService } from '../../Apis/ReceiptManagmentService';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaButton from '../../components/common/VegaButton';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import {
  IErrorStateProps,
  receiptState,
  setErrorState,
  setIssueReceipt,
} from '../../features/receiptManagmentSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  AnyPaymentType,
  CashPayment,
  CashPaymentMeta,
  ChequePayment,
  DDPayment,
  ElectronicPayment,
  IssueReceiptProps,
  PaymentMode,
} from '../../types/receiptManagment';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LoanDetails from './module/LoanDetails';
import LoanDocumentUpload from './module/LoanDocumentUpload';
import PayerDetails from './module/PayerDetails';
import PaymentDetails from './module/PaymentDetails';
import { useEffect } from 'react';
import { useClientAuth } from '../../providers/ClientProvider';

const ReceiptIssue = () => {
  const { userId } = useClientAuth();
  const { issueReceipt } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loanId } = useParams();
  const { setSnackbar } = useSnackbar();
  const isPaymentMeta = (paymentMeta: AnyPaymentType) => {
    if (!Object.keys(paymentMeta).length) {
      return true;
    }
    if (paymentMeta.paymentMode === PaymentMode.CHEQUE) {
      const checkPayment = paymentMeta as ChequePayment;
      return (
        !checkPayment.bankName.length ||
        !checkPayment.branchName.length ||
        !checkPayment.chequeNumber.length ||
        !checkPayment.ifscCode.length
      );
    }
    if (paymentMeta.paymentMode === PaymentMode.DD) {
      const DDPayment = paymentMeta as DDPayment;
      return !DDPayment.ddNumber.length || !DDPayment.micr.length;
    }
    if (
      paymentMeta.paymentMode === PaymentMode.NEFT ||
      paymentMeta.paymentMode === PaymentMode.ELECTRONIC_FUND_TRANSFER
    ) {
      const electronic = paymentMeta as ElectronicPayment;
      return !electronic.refNumber.length;
    }
    if (paymentMeta.paymentMode === PaymentMode.CASH) {
      const cashPayment = paymentMeta as CashPayment;
      const isCheckCashPayment = cashPayment.cashPaymentMeta.some(
        (cash: CashPaymentMeta) => cash.count > 0
      );
      return !isCheckCashPayment;
    }
  };

  const disabledButton = (issueReceipt: IssueReceiptProps) => {
    const isPayment = isPaymentMeta(issueReceipt.paymentMeta);
    return (
      isPayment ||
      !issueReceipt.documentImage ||
      !issueReceipt.payeeName.length ||
      !issueReceipt.mobileNumber.length ||
      !issueReceipt.email.length ||
      !issueReceipt.relationshipWithCustomer.length ||
      !issueReceipt.paymentType.length ||
      !issueReceipt.remarks.length
    );
  };

  const disabled = disabledButton(issueReceipt);

  const isValidInput = (issueReceipt: IssueReceiptProps) => {
    const errorObj = {
      error: {},
      isValid: true,
    } as IErrorStateProps;
    const isValidEmail = StringUtility.validateEmail(issueReceipt.email);
    const isValidNumber = StringUtility.validatePhoneNumber(
      issueReceipt.mobileNumber
    );
    if (!isValidEmail) {
      errorObj.isValid = false;
      errorObj.error['email'] = 'Invalid email address';
    }
    if (!isValidNumber) {
      errorObj.isValid = false;
      errorObj.error['mobileNumber'] = 'Mobile number should be 10 digits';
    }
    return errorObj;
  };

  const onIssueReceipt = () => {
    const errorObj = isValidInput(issueReceipt);
    dispatch(setErrorState(errorObj));
    if (errorObj.isValid) {
      const newObj = {
        ...issueReceipt,
        loanId,
        agentId: userId,
        panCard: 'APGPS5323A',
      } as IssueReceiptProps;

      ReceiptManagmentService.createReceipt(newObj)
        .then(() => {
          setSnackbar('Issue receipt successfully!!');
          navigate(ROUTES.RECEIPT_AGENT);
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        setIssueReceipt({
          agentId: '',
          loanId: '',
          mobileNumber: '',
          email: '',
          paymentType: '',
          documentImage: null,
          payeeName: '',
          relationshipWithCustomer: '',
          totalAmount: 0,
          paymentMeta: {},
          panCard: '',
          remarks: '',
        })
      );
    };
  }, []);

  return (
    <>
      <VegaPageHeader
        sx={{
          borderBottom: 'none',
        }}
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Receipt Management', link: ROUTES.RECEIPT_AGENT },
                { label: `Issue Receipt` },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
        <VegaTitleCard title="Loan Details">
          <LoanDetails />
        </VegaTitleCard>
      </VegaPageContent>
      <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
        <VegaTitleCard title="Document Upload">
          <LoanDocumentUpload />
        </VegaTitleCard>
      </VegaPageContent>
      <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
        <VegaTitleCard title="Payer Details">
          <PayerDetails />
        </VegaTitleCard>
      </VegaPageContent>
      <Collapse in={issueReceipt.paymentMeta?.paymentMode}>
        <VegaPageContent sx={{ marginBottom: '1.5rem' }}>
          <VegaTitleCard title="Payment Details">
            <PaymentDetails />
          </VegaTitleCard>
        </VegaPageContent>
      </Collapse>

      <VegaPageContent sx={{ marginBottom: '1.5rem', textAlign: 'end' }}>
        <VegaButton
          text="Issue Receipt"
          disabled={disabled}
          onClick={onIssueReceipt}
        />
      </VegaPageContent>
    </>
  );
};

export default ReceiptIssue;
