import { FilterList, Search } from '@mui/icons-material';
import { Stack } from '@mui/material';
import LmOutlineTextField from './LmOutlineTextField';
import VegaText from './VegaText';

interface IProps {
  onFilter?: () => void;
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  search?: string;
}

const LmFilterSection = ({ onFilter, search, setSearch }: IProps) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'end'}
      gap={3}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        sx={{ cursor: 'pointer' }}
        onClick={onFilter ? onFilter : () => {}}
      >
        <FilterList sx={{ color: '#676B76', fontSize: 16 }} />
        <VegaText
          text="Filter"
          // className="font-aspekta-500"
          fontSize={'0.75rem'}
          color={'#676B76'}
        />
      </Stack>
      <LmOutlineTextField
        fixWidth={320}
        placeholder="Search"
        name="search"
        startAdornment={
          <Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />
        }
        value={search}
        onChange={e => setSearch?.(e.target.value)}
      />
    </Stack>
  );
};

export default LmFilterSection;
