import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import { PolicyRangeProps } from '../../../types/request/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  loading?: boolean;
  bumperIncentive: PolicyRangeProps;
  index: number;
}

const ViewBumperIncentive = ({ loading, bumperIncentive, index }: IProps) => {
  return (
    <Stack gap={1} key={index}>
      <VegaText
        text={`Perameter ${index + 1}`}
        fontWeight={500}
        fontSize={'0.75rem'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Collection parameter'}>
            {getTextLoadingWrapper(
              toLowerCase(bumperIncentive.parameter),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Parameter range'}>
            {getTextLoadingWrapper(
              `${bumperIncentive.parameterMin}-${bumperIncentive.parameterMax}%`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Payout Metric'}>
            {getTextLoadingWrapper(`${bumperIncentive.payoutValue}%`, loading)}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Payout capping (Max)'}>
            {getTextLoadingWrapper(
              `${bumperIncentive.payoutCapMax}/${toLowerCase(
                bumperIncentive.payoutCapMetric
              )}`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ViewBumperIncentive;

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
  }
};
