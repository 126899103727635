import { TrainOutlined } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import { LmSidebarListItemType } from '.';
import {
  HomeIcon,
  AllocationIcon,
  HolidayMasterIcon,
  ApplyLeaveIcon,
  DepositBankIcon,
  HeadphoneIcon,
  IncentivesIcon,
  CalenderIcon,
  MoneyLoanIcon,
  VisitsIcon,
  UserIcon,
  TagIcon,
} from '../components/Icons/Icons';
import UAMDetails from '../components/UAM/UAMDetails/UAMDetails';
import { isDev } from '../constants/url';
import AccountDetails from '../pages/Accounts/AccountDetails';
import Accounts from '../pages/Accounts/Accounts';
import AssignAllocation from '../pages/Allocation/AssignAllocation';
import AutoAllocationPolicy from '../pages/Allocation/AutoAllocationPolicy';
import BulkAllocation from '../pages/Allocation/BulkAllocation';
import Calender from '../pages/calender/Calender';
import DepositBank from '../pages/DepositBank';
import HomePage from '../pages/Homepage/HomePage';
import AddOnPolicy from '../pages/IncentivesGamifications/AddOnPolicy';
import IncentivesGamifications from '../pages/IncentivesGamifications/IncentivesGamifications';
import ViewPolicy from '../pages/IncentivesGamifications/ViewPolicy';
import ApplyLeave from '../pages/Leave/ApplyLeave';
import LeaveRequestActions from '../pages/Leave/LeaveRequestActions';
import LmHolidayName from '../pages/LmHolidayName';
import LmHolidayType from '../pages/LmHolidayType';
import LmUAM from '../pages/LmUAM';
import LoanAccounts from '../pages/LoanAccount/LoanAccounts';
import MinVisits from '../pages/MinVisits/MinVisits';
import Monthlyprogress from '../pages/MonthlyProgress';
import ReceiptAgent from '../pages/ReceiptManagment/ReceiptAgent';
import ReceiptApprover from '../pages/ReceiptManagment/ReceiptApprover';
import ReceiptIssue from '../pages/ReceiptManagment/ReceiptIssue';
import Targets from '../pages/Targets/Targets';
import TeleAgentAccountDetails from '../pages/TeleAgent/TeleAgentAccountDetails';
import TeleAgentAuto from '../pages/TeleAgent/TeleAgentAuto';
import TeleAgentManual from '../pages/TeleAgent/TeleAgentManual';
import TeleBulkAllocation from '../pages/TeleAllocation/TeleBulkAllocation';
import TrailPage from '../pages/Trail/TrailPage';
import BankDepositProvider from '../providers/BankDepositProvider';
import HolidayNameProvider from '../providers/HolidayNameProvider';
import HolidayTypeProvider from '../providers/HolidayTypeProvider';
import UAMProvider from '../providers/UAMProvider';
import { ROUTES, SUB_ROUTES } from './routes';

export const ALPHA_ROUTES: LmSidebarListItemType[] = [
  {
    path: ROUTES.HOME,
    label: 'Home',
    component: <HomePage />,
    icon: <HomeIcon />,
  },
  {
    path: '',
    label: 'Loans',
    component: <Outlet />,
    icon: <AllocationIcon />,
    routes: [
      ROUTES.ACCOUNT,
      ROUTES.ALLOCATION_BULK_ASSIGN,
      ROUTES.ALLOCATION_POLICY,
      ROUTES.TELE_ALLCATION_BULK,
      ROUTES.Trail_Page,
      ROUTES.Trail_Page,
      ROUTES.ACCOUNTS,
    ],
    children: [
      {
        path: ROUTES.ACCOUNT,
        label: 'Loan Accounts',
        component: <LoanAccounts />,
      },
      {
        path: ROUTES.ALLOCATION_BULK_ASSIGN,
        label: 'Bulk Allocation',
        component: <BulkAllocation />,
      },
      {
        path: ROUTES.ALLOCATION_POLICY,
        label: 'Auto Allocation Policy',
        component: <AutoAllocationPolicy />,
      },
      // {
      //   path: ROUTES.ALLOCATION_ASSIGN,
      //   label: 'Assign Allocation',
      //   component: <AssignAllocation />,
      // },
      {
        path: ROUTES.TELE_ALLCATION_BULK,
        label: 'Tele Configuration',
        component: <TeleBulkAllocation />,
      },
      {
        path: ROUTES.Trail_Page,
        label: 'Trail',
        component: <TrailPage />,
      },
      {
        path: ROUTES.ACCOUNTS,
        label: 'Accounts',
        component: <Accounts />,
      },
    ],
  },
  {
    path: '',
    label: 'Master',
    component: <Outlet />,
    icon: <HolidayMasterIcon />,
    routes: [
      SUB_ROUTES.HOLIDAY_TYPE,
      SUB_ROUTES.HOLIDAY_NAME,
      ROUTES.DEPOSIT_BANK,
      ROUTES.MIN_NO_OF_VISITS,
    ],
    children: [
      {
        path: SUB_ROUTES.HOLIDAY_TYPE,
        label: 'Holiday Type',
        component: (
          <HolidayTypeProvider>
            <LmHolidayType />
          </HolidayTypeProvider>
        ),
      },
      {
        path: SUB_ROUTES.HOLIDAY_NAME,
        label: 'Holiday Name',
        component: (
          <HolidayTypeProvider>
            <HolidayNameProvider>
              <LmHolidayName />
            </HolidayNameProvider>
          </HolidayTypeProvider>
        ),
      },
      {
        path: ROUTES.DEPOSIT_BANK,
        label: 'Bank Deposit',
        component: (
          <BankDepositProvider>
            <DepositBank />
          </BankDepositProvider>
        ),
      },
      {
        path: ROUTES.MIN_NO_OF_VISITS,
        label: 'Min. no. of Visits',
        component: <MinVisits />,
      },
    ],
  },
  {
    path: '',
    label: 'Leave Management',
    component: <Outlet />,
    icon: <ApplyLeaveIcon />,
    routes: [ROUTES.LEAVE_APPLY, ROUTES.LEAVEL_REQUEST_ACTIONS],
    children: [
      {
        path: ROUTES.LEAVE_APPLY,
        label: 'Apply Leave',
        component: <ApplyLeave />,
      },
      {
        path: ROUTES.LEAVEL_REQUEST_ACTIONS,
        label: 'Approve Leave',
        component: <LeaveRequestActions />,
      },
    ],
  },
  {
    path: '',
    label: 'Program Dashboard',
    component: <Outlet />,
    icon: <ApplyLeaveIcon />,
    routes: [ROUTES.MONTHLY_PROGRESS, ROUTES.TARGETS],
    children: [
      {
        path: ROUTES.MONTHLY_PROGRESS,
        label: 'Monthly Progress',
        component: <Monthlyprogress />,
      },
      {
        path: ROUTES.TARGETS,
        label: 'Targets',
        component: <Targets />,
      },
    ],
  },
  // {
  //   path: '',
  //   label: 'Holiday Master',
  //   component: (
  //     <HolidayTypeProvider>
  //       <LmHolidayMaster />
  //     </HolidayTypeProvider>
  //   ),
  //   icon: <HolidayMasterIcon />,
  //   children: [
  //     {
  //       path: SUB_ROUTES.HOLIDAY_TYPE,
  //       label: 'Holiday Type',
  //       component: <LmHolidayType />,
  //     },
  //     {
  //       path: SUB_ROUTES.HOLIDAY_NAME,
  //       label: 'Holiday Name',
  //       component: (
  //         <HolidayNameProvider>
  //           <LmHolidayName />
  //         </HolidayNameProvider>
  //       ),
  //     },
  //   ],
  // },
  {
    path: ROUTES.UAM,
    label: 'UAM',
    component: (
      <UAMProvider>
        <LmUAM />
      </UAMProvider>
    ),
    icon: <HomeIcon />,
  },
  // {
  //   path: ROUTES.AGENTS,
  //   label: 'Agents',
  //   component: <Agents />,
  //   icon: <HomeIcon />,
  // },
  {
    path: `${ROUTES.UAM_PARAMS}`,
    label: '',
    component: (
      <UAMProvider>
        <UAMDetails />
      </UAMProvider>
    ),
    icon: <HomeIcon />,
  },
  {
    path: `${ROUTES.UAM_PARAMS_ID}`,
    label: '',
    component: (
      <UAMProvider>
        <UAMDetails />
      </UAMProvider>
    ),
    icon: <HomeIcon />,
  },

  // {
  //   path: '',
  //   label: 'Expense Claims Agent',
  //   component: (
  //     <ExpenseModuleProvider>
  //       <Outlet />
  //     </ExpenseModuleProvider>
  //   ),
  //   icon: <HolidayMasterIcon />,
  //   children: [
  //     {
  //       path: SUB_ROUTES.EXPENSE_REPORT_DRAFT,
  //       label: 'Draft Report',
  //       component: <DraftExpenseReport />,
  //     },
  //     {
  //       path: SUB_ROUTES.EXPENSE_REPORT_SUBMITTED,
  //       label: 'Submitted Report',
  //       component: <SubmittedExpenseReport />,
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   label: 'Expense Claims Admin',
  //   component: (
  //     <ExpenseModuleProvider>
  //       <Outlet />
  //     </ExpenseModuleProvider>
  //   ),
  //   icon: <RupeeIcon strokeColor="white" />,
  //   children: [
  //     {
  //       path: SUB_ROUTES.EXPENSE_REPORT_ONGOING,
  //       label: 'Ongoing',
  //       component: <OngoingExpenseReport />,
  //     },
  //     {
  //       path: SUB_ROUTES.EXPENSE_REPORT_HISTORY,
  //       label: 'History',
  //       component: <ExpenseReportHistory />,
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   label: 'Auction',
  //   component: <Outlet />,
  //   icon: <AuctionIcon strokeColor="white" />,
  //   children: [
  //     {
  //       path: ROUTES.AUCTION_USERS,
  //       label: 'Users',
  //       component: <AuctionUsers />,
  //     },
  //     {
  //       path: ROUTES.AUCTION_PROPERTIES,
  //       label: 'Properties',
  //       component: <AuctionPossessions />,
  //     },
  //     {
  //       path: ROUTES.LIVE_AUCTIONS,
  //       label: 'Live Auctions',
  //       component: <LiveAuctions />,
  //     },
  //   ],
  // },

  // {
  //   path: '',
  //   label: 'Allocation',
  //   component: <Outlet />,
  //   icon: <AuctionIcon strokeColor="white" />,
  //   children: [
  //     {
  //       path: ROUTES.ALLOCATION_BULK_ASSIGN,
  //       label: 'Bulk Allocation',
  //       component: <BulkAllocation />,
  //     },
  //     {
  //       path: ROUTES.ALLOCATION_POLICY,
  //       label: 'Auto Allocation Policy',
  //       component: <AutoAllocationPolicy />,
  //     },
  //     {
  //       path: ROUTES.ALLOCATION_ASSIGN,
  //       label: 'Assign Allocation',
  //       component: <AssignAllocation />,
  //     },
  //   ],
  // },

  {
    path: '',
    label: 'Receipt Managment',
    component: <Outlet />,
    icon: <DepositBankIcon />,
    routes: [ROUTES.RECEIPT_APPROVER, ROUTES.RECEIPT_AGENT],
    children: [
      {
        path: ROUTES.RECEIPT_APPROVER,
        label: 'Receipt Approver',
        component: <ReceiptApprover />,
      },
      {
        path: ROUTES.RECEIPT_AGENT,
        label: 'Receipt Agent',
        component: <ReceiptAgent />,
      },
    ],
  },
  // {
  //   path: '',
  //   label: 'Tele Allocation',
  //   component: <Outlet />,
  //   icon: <AllocationIcon />,
  //   children: [
  //     {
  //       path: ROUTES.TELE_ALLCATION_BULK,
  //       label: 'Auto Allocation',
  //       component: <TeleBulkAllocation />,
  //     },
  //     {
  //       path: ROUTES.TELE_ALLCATION_MANUAL,
  //       label: 'Manual Allocation',
  //       component: <TeleManualAllocation />,
  //     },

  //     {
  //       path: ROUTES.TELE_ALLOCATION_STATUS,
  //       label: 'Allocation Status',
  //       component: <TeleAllocationStatus />,
  //     },
  //     {
  //       path: ROUTES.TELE_ALLOCATION_RULE_POLICY,
  //       label: 'Allocation Rule Policy',
  //       component: <TeleAllocationRulePolicy />,
  //     },
  //     {
  //       path: ROUTES.TELE_ALLOCATION_BULK_LOAN_UPLOAD,
  //       label: 'Bulk Loan Upload',
  //       component: <TeleAllocationBulkLoanUpload />,
  //     },
  //   ],
  // },
  {
    path: '',
    label: 'Tele Agent',
    component: <Outlet />,
    icon: <HeadphoneIcon />,
    routes: [ROUTES.TELE_AGENT_MANUAL, ROUTES.TELE_AGENT_AUTO],
    children: [
      {
        path: ROUTES.TELE_AGENT_MANUAL,
        label: 'Manual Call',
        component: <TeleAgentManual />,
      },
      {
        path: ROUTES.TELE_AGENT_AUTO,
        label: 'Auto Call',
        component: <TeleAgentAuto />,
      },
    ],
  },
  {
    path: ROUTES.INCENTIVES_GAMIFICATIONS,
    label: 'Incentives & Gamifications',
    component: <IncentivesGamifications />,
    icon: <IncentivesIcon strokeColor="white" />,
  },

  {
    path: ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT,
    label: '',
    component: <ReceiptIssue />,
  },
  {
    path: ROUTES.TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID,
    label: '',
    component: <TeleAgentAccountDetails />,
  },
  {
    path: ROUTES.INCENTIVES_GAMIFICATIONS_ADD,
    label: '',
    component: <AddOnPolicy />,
  },
  {
    path: ROUTES.INCENTIVES_GAMIFICATIONS_VIEW,
    label: '',
    component: <ViewPolicy />,
  },
  {
    path: ROUTES.CALENDER,
    label: 'Calender',
    component: <Calender />,
    icon: <CalenderIcon strokeColor="white" />,
  },
  {
    path: ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID,
    label: '',
    component: <AccountDetails />,
  },
];

export const PROD_ROUTES: LmSidebarListItemType[] = [
  {
    path: ROUTES.ACCOUNTS,
    label: 'Loan Accounts',
    icon: <HomeIcon />,
    component: <LoanAccounts />,
  },
  {
    path: ROUTES.UAM,
    label: 'UAM',
    component: (
      <UAMProvider>
        <LmUAM />
      </UAMProvider>
    ),
    icon: <VisitsIcon />,
  },
  {
    path: ROUTES.Trail_Page,
    label: 'Trail',
    component: <TrailPage />,
    icon: <TagIcon />,
  },
  {
    path: `${ROUTES.UAM_PARAMS}`,
    label: '',
    component: (
      <UAMProvider>
        <UAMDetails />
      </UAMProvider>
    ),
    icon: <HomeIcon />,
  },
  {
    path: `${ROUTES.UAM_PARAMS_ID}`,
    label: '',
    component: (
      <UAMProvider>
        <UAMDetails />
      </UAMProvider>
    ),
    icon: <HomeIcon />,
  },

  {
    path: '',
    label: 'Allocation',
    component: <Outlet />,
    icon: <AllocationIcon />,
    children: [
      {
        path: ROUTES.ALLOCATION_BULK_ASSIGN,
        label: 'Bulk Allocation',
        component: <BulkAllocation />,
      },
      {
        path: ROUTES.ALLOCATION_POLICY,
        label: 'Auto Allocation Policy',
        component: <AutoAllocationPolicy />,
      },
      {
        path: ROUTES.ALLOCATION_ASSIGN,
        label: 'Assign Allocation',
        component: <AssignAllocation />,
      },
    ],
  },

  {
    path: '',
    label: 'Tele Allocation',
    component: <Outlet />,
    icon: <AllocationIcon />,
    routes: [ROUTES.TELE_ALLCATION_BULK],
    children: [
      {
        path: ROUTES.TELE_ALLCATION_BULK,
        label: 'Tele Configuration',
        component: <TeleBulkAllocation />,
      },
    ],
  },
  {
    path: '',
    label: 'Tele Agent',
    component: <Outlet />,
    icon: <HeadphoneIcon />,
    routes: [ROUTES.TELE_AGENT_MANUAL, ROUTES.TELE_AGENT_AUTO],
    children: [
      {
        path: ROUTES.TELE_AGENT_MANUAL,
        label: 'Manual Call',
        component: <TeleAgentManual />,
      },
      {
        path: ROUTES.TELE_AGENT_AUTO,
        label: 'Auto Call',
        component: <TeleAgentAuto />,
      },
    ],
  },

  {
    path: ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT,
    label: '',
    component: <ReceiptIssue />,
  },
  {
    path: ROUTES.TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID,
    label: '',
    component: <TeleAgentAccountDetails />,
  },

  {
    path: ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID,
    label: '',
    component: <AccountDetails />,
  },
];

export const APP_ROUTES: LmSidebarListItemType[] = PROD_ROUTES;
