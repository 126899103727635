import { Grid, Skeleton } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useUAM } from '../../../../providers/UAMProvider';
import { NESTED_ROUTES } from '../../../../router/routes';
import LmTextField from '../../../common/LmTextField';
import VegaFormInputField from '../../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../../common/VegaSelect';
import VegaText from '../../../common/VegaText';
import DetailWrapper from './DetailWrapper';

interface IProps {
  isSave: boolean;
  loading: boolean;
}

const ContactDetails = ({ isSave, loading }: IProps) => {
  const { userState, handleUserDetailsChange, error } = useUAM();
  const { params } = useParams();
  return (
    <>
      <DetailWrapper title="Contact Details">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="email">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.email ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="email"
                  placeholder="Enter Email ID"
                  disabled={isSave}
                  value={userState?.email}
                  onChange={e => {
                    const value = e.target.value;

                    handleUserDetailsChange('email', value);
                  }}
                  error={error.email?.length > 0}
                  helperText={error.email}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="mobile number">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.mobile ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="number"
                  placeholder="Enter Mobile number"
                  disabled={isSave}
                  value={userState?.mobile}
                  onChange={e => {
                    const value = e.target.value;
                    handleUserDetailsChange('mobile', value);
                  }}
                  error={error.mobile?.length > 0}
                  helperText={error.mobile}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="landline number">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.landline ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="number"
                  placeholder="Enter landline mumber"
                  disabled={isSave}
                  value={userState?.landline}
                  onChange={e => {
                    const value = e.target.value;

                    handleUserDetailsChange('landline', value);
                  }}
                  error={error.landline?.length > 0}
                  helperText={error.landline}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="base branch">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.baseBranch ?? '--'}
                  />
                )
              ) : (
                <VegaSelect
                  options={branchOptions()}
                  disabled={isSave}
                  value={userState?.baseBranch}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    handleUserDetailsChange('baseBranch', value);
                  }}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="Employee code">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.employeeCode ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="text"
                  placeholder="Enter Employee code"
                  disabled={isSave}
                  value={userState?.employeeCode}
                  onChange={e => {
                    const value = e.target.value;
                    handleUserDetailsChange('employeeCode', value);
                  }}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/* <LmTextField
              label="reporting Manager"
              placeholder="Enter Reporting Manager"
              disabled={isSave}
              value={userState?.}
              onChange={(e)=> {
                const value = e.target.value
                handleUserDetailsChange('', value)
              }}
            /> */}
          </Grid>
        </Grid>
      </DetailWrapper>
    </>
  );
};

export default ContactDetails;

const branchOptions = () =>
  ['KORAMANGALA'].map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
