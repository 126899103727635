import React from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';
import VegaText from '../../common/VegaText';
import { PolicyRangeProps } from '../../../types/request/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import { RuleProps } from '../../../types/incentives';
import { toLowerCase } from '../../../constants/commonFunction';

interface IProps {
  loading?: boolean;
  rules: Partial<RuleProps>;
}

const ViewBaseIncentive = ({ loading, rules }: IProps) => {
  return (
    <Stack gap={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Incentive type'}>
            {getTextLoadingWrapper(
              toLowerCase(getPolicyValue(rules.incentiveType)),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Case type'}>
            {getTextLoadingWrapper(
              toLowerCase(getPolicyValue(rules.caseType)),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Account type'}>
            {getTextLoadingWrapper(
              toLowerCase(getPolicyValue(rules.accountType)),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'collection type'}>
            {getTextLoadingWrapper(
              toLowerCase(getPolicyValue('RESOLUTION')),
              loading
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {rules.basePayoutRange.length
        ? rules?.basePayoutRange.map(
            (baseIncentive: PolicyRangeProps, index: number) => (
              <Stack gap={1} key={index}>
                <VegaText
                  text={`Perameter ${index + 1}`}
                  fontWeight={500}
                  fontSize={'0.75rem'}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'value'}>
                      {getTextLoadingWrapper(
                        `${baseIncentive.parameterMin}-${baseIncentive.parameterMax}%`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'Incentive Amount'}>
                      {getTextLoadingWrapper(
                        `₹ ${baseIncentive.payoutValue}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <VegaFormInputField label={'Max-Payout'}>
                      {getTextLoadingWrapper(
                        `₹ ${rules.maximumPayoutAmount}/${toLowerCase(
                          rules.maximumPayoutType
                        )}`,
                        loading
                      )}
                    </VegaFormInputField>
                  </Grid>
                </Grid>
              </Stack>
            )
          )
        : ''}
    </Stack>
  );
};

export default ViewBaseIncentive;

const getPolicyValue = (policyValue: string | number) => {
  const value =
    typeof policyValue === 'number' ? policyValue.toString() : policyValue;
  if (!value) return '--';
  if (value.length) {
    return value;
  } else {
    return '--';
  }
};

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
  }
};
