/* eslint-disable no-undef */
/** @format */

import axios from 'axios';
import { isCapriEnv } from '../constants/url';

const CAPRI_API_GATEWAY = 'https://vegapay-collections-uat-api.capriglobal.in';
const ALPHA_API_GATEWAY =
  'https://7h8trf44g9.execute-api.ap-south-1.amazonaws.com/collection';

export const API_GATEWAY = `${
  isCapriEnv ? CAPRI_API_GATEWAY : ALPHA_API_GATEWAY
}`;
export const configureAxiosDefaults = () => {
  const BASE_URL = API_GATEWAY;
  axios.defaults.baseURL = BASE_URL;
};

//request interceptor
axios.interceptors.request.use(
  (config: any) => {
    config.headers['Authorization'] = `${sessionStorage.getItem('token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.status == 401) {
//       Auth.currentSession();
//     }
//     return Promise.reject(error);
//   }
// );
