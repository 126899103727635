import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { ReallocateAllocationFormData } from '../../components/Allocation/ReallocateAllocationsDrawer';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { useDrawer } from '../../hooks/useDrawer';
import {
  AllocationMetaData,
  AllocationMetaDataExcelView,
  AllocationTypes,
} from '../../types/allocations';
import { LmsVariable } from '../../types/loan';
import { EnumUtility } from '../../utils/EnumUtility';
import { StringUtility } from '../../utils/StringUtility';
import AllocateLoanAccountsDrawer, {
  AllocateLoanAccountsFormData,
} from './modules/AllocateLoanAccountsDrawer';

import { Search } from '@mui/icons-material';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { AllocationService } from '../../Apis/AllocationService';
import LmOutlineTextField from '../../components/common/LmOutlineTextField';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { GetAllocationMetaDataRequest } from '../../types/request/allocation';
import {
  getErrorMessageFromErrorObj,
  PaginatedResponse,
} from '../../utils/api';
import { DateUtility } from '../../utils/DateUtlility';
import DeallocateLoanAccountsDialog from './modules/DeallocateLoanAccountsDialog';
import ExecuteLoanAccountsDrawer, {
  ExecuteLoanAccountsFormData,
} from './modules/ExecuteLoanAccountsDrawer';
import ReallocateLoanAccountsDrawer, {
  ReallocateLoanAccountsFormData,
} from './modules/ReallocateLoanAccountsDrawer';

enum AllocationTabType {
  Allocated,
  Unallocated,
  Resolved,
  Normalized,
  Stabilized,
  Rollback,
  Cancelled,
}

function LoanAccounts() {
  const fileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`;
  const fileExtension = '.xlsx';
  const { setSnackbar } = useSnackbar();
  const [allocationsData, setAllocationsData] = useState<
    PaginatedResponse<AllocationMetaData>
  >({
    records: [],
    pageNumber: 0,
    totalPages: 0,
    totalItems: 0,
    numberOfItems: 0,
  });
  const [unAllocationsData, setUnAllocationsData] = useState<
    PaginatedResponse<AllocationMetaData>
  >({
    records: [],
    pageNumber: 0,
    totalPages: 0,
    totalItems: 0,
    numberOfItems: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchLoanId, setSearchLoanId] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(
    AllocationTabType.Allocated
  );

  const {
    open: openAllocateDrawer,
    close: closeAllocateDrawer,
    isOpen: isAllocateDrawerOpen,
  } = useDrawer();

  const {
    open: openDeAllocationDrawer,
    close: closeDeAllocatioDrawer,
    isOpen: isDeAllocatioDrawerOpen,
  } = useDrawer();

  const {
    open: openReAllocationDrawer,
    close: closeReAllocationDrawer,
    isOpen: isReAllocationDrawerOpen,
  } = useDrawer();

  const {
    open: openExecutionDrawer,
    close: closeExecutionDrawer,
    isOpen: isExecutionDrawerOpen,
  } = useDrawer();

  const handleSelectionModelChange = (selectionModel: string[]) => {
    setSelectedRows(selectionModel);
  };

  function _onTabChange(value: number): void {
    setSelectedTab(value);
  }

  function onPageChange(pageNumber: number) {
    if (selectedTab == 0) {
      setAllocationsData(prev => {
        return {
          ...prev,
          pageNumber: pageNumber,
        };
      });
    } else {
      setUnAllocationsData(prev => {
        return {
          ...prev,
          pageNumber: pageNumber,
        };
      });
    }
  }

  async function refresh() {
    try {
      setSelectedRows([]);
      if (selectedTab != 0 && selectedTab != 1) return;
      setLoading(true);
      const pageNumber = 0;
      const response = await AllocationService.getAllocationMetaData({
        status: getAllocationStatusFromTab(selectedTab),
        page: pageNumber,
      });
      if (selectedTab == 0) {
        setAllocationsData(response);
      } else if (selectedTab == 1) {
        setUnAllocationsData(response);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  async function _reallocateLoanAccounts(
    formData: Partial<ReallocateAllocationFormData>
  ) {
    const loanIds = selectedRows ?? [];
    if (loanIds.length <= 0) return;
    try {
      await AllocationService.reallocate({
        loanIds: loanIds,
        agentId: formData.agentId,
        expiresAt: formData.expiryDate,
        allocationType: formData.allocationType,
      });
      setSnackbar('Loan Accounts Re-Allocated.');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  async function _allocateLoanAccounts(
    formData: Partial<AllocateLoanAccountsFormData>
  ) {
    const loanIds = selectedRows ?? [];
    if (loanIds.length <= 0) return;
    const allocationType = formData.allocationType;
    if (!allocationType) {
      setSnackbar('Allocation Type not specified', 'error');
      return;
    }

    try {
      if (allocationType == AllocationTypes.FIELD) {
        await AllocationService.allocateField({
          loanIds: loanIds,
          agentId: formData.agentId,
          expiresAt: formData.expiryDate,
          allocationType: formData.allocationType,
        });
        setSnackbar('Loan Accounts Allocated.');
        refresh();
        return;
      } else if (allocationType == AllocationTypes.TELE) {
        await AllocationService.allocateTele({
          loanIds: loanIds,
          agentId: formData.agentId,
          expiresAt: formData.expiryDate,
          allocationType: formData.allocationType,
        });
        setSnackbar('Loan Accounts Allocated.');
        refresh();
        return;
      }
      setSnackbar('Something went wrong', 'error');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  async function _deAllocateLoanAccounts() {
    const loanIds = selectedRows ?? [];
    if (loanIds.length <= 0) return;
    try {
      await AllocationService.deallocate({
        loanIds: loanIds,
      });
      refresh();
      setSnackbar('Loan Accounts De-Allocated.');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  async function _executeLoanAccounts(
    formData: Partial<ExecuteLoanAccountsFormData>
  ) {
    const loanIds = selectedRows ?? [];
    try {
      await AllocationService.executeCriteria({
        lanId: loanIds,
        agentId: formData.agentId,
        allocationTypes: formData.allocationType,
        criteriaId: formData.ruleId,
      });
      setSnackbar('Rule Executed On Loan Accounts.');
      refresh();
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  const canEnableReallocateButton = () => {
    return selectedTab == 0 && selectedRows.length > 0;
  };

  const canEnableDeallocateButton = () => {
    return selectedTab == 0 && selectedRows.length > 0;
  };

  const canEnableAllocateButton = () => {
    return selectedTab == 1 && selectedRows.length > 0;
  };

  function onReallocateClick() {
    openReAllocationDrawer();
  }

  function onDeallocateClick() {
    openDeAllocationDrawer();
  }

  function onExecuteClick() {
    openExecutionDrawer();
  }

  function onAllocateClick() {
    openAllocateDrawer();
  }

  async function fetchData() {
    try {
      setSelectedRows([]);
      if (selectedTab != 0 && selectedTab != 1) return;
      setLoading(true);
      const pageNumber =
        selectedTab == 0
          ? allocationsData.pageNumber
          : unAllocationsData.pageNumber;
      const request = {
        status: getAllocationStatusFromTab(selectedTab),
        page: pageNumber,
      } as GetAllocationMetaDataRequest;
      if (searchLoanId.length) {
        request.loanId = searchLoanId.toLocaleUpperCase();
      }
      const response = await AllocationService.getAllocationMetaData(request);
      if (selectedTab == 0) {
        setAllocationsData(response);
      } else if (selectedTab == 1) {
        setUnAllocationsData(response);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const fetchDataForExcel = async () => {
    try {
      setLoading(true);

      const response = await AllocationService.getAllocationMetaData({
        status: getAllocationStatusFromTab(selectedTab),
        page: 0,
        size: 1000,
      });
      const accounts = response.records;
      const formatReasponse = accounts.map(
        (account: AllocationMetaData) =>
          ({
            name: account.customerDetails[0]?.customerName ?? '--',
            loanAccountNumber: account.loanId,
            allocationType:
              account?.metaDataLoanAllocationDto
                ?.allocationTypeFromModelParser ?? '-',

            pos: account.lmsVariables
              ? account.lmsVariables.outstandingAmount
                ? StringUtility.formatToINR(
                    account.lmsVariables.outstandingAmount
                  )
                : 0
              : 0,
            bucket: account.lmsVariables.bucket ?? '-',
            contactNumber: account.customerDetails[0]?.mobileNo ?? '--',
            allocationDate: DateUtility.formatStringToDDMMYYYYWithTime(
              account.loanMaster.loadDate
            ),
          } as AllocationMetaDataExcelView)
      );
      if (selectedRows.length) {
        const filteredAccounts = formatReasponse.filter((account: any) =>
          selectedRows.includes(account.loanAccountNumber)
        );
        exportToExcel(filteredAccounts);
      } else {
        exportToExcel(formatReasponse);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const exportToExcel = (data: AllocationMetaDataExcelView[]) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(blob, 'loanAllocation' + fileExtension);
  };

  const getPageNumber = () => {
    if (selectedTab == 0) return allocationsData.pageNumber;
    else if (selectedTab == 1) return unAllocationsData.pageNumber;
    return 0;
  };

  const getRecords = () => {
    if (selectedTab == 0) return allocationsData.records;
    else if (selectedTab == 1) return unAllocationsData.records;
    return [];
  };

  const getRowCount = () => {
    if (selectedTab == 0) return allocationsData.totalItems;
    else if (selectedTab == 1) return unAllocationsData.totalItems;
    return 0;
  };

  useEffect(() => {
    const getLoanData = setTimeout(fetchData, 200);
    return () => clearTimeout(getLoanData);
  }, [
    allocationsData.pageNumber,
    unAllocationsData.pageNumber,
    selectedTab,
    searchLoanId,
  ]);

  return (
    <>
      <VegaPageHeader
        renderRightView={() => {
          if (selectedTab == 0) {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text={'Execute'}
                  onClick={onExecuteClick}
                  variant="outlined"
                />
                <VegaButton
                  text={'Deallocate'}
                  onClick={onDeallocateClick}
                  variant="outlined"
                  disabled={canEnableDeallocateButton() == false}
                />
                <VegaButton
                  text={'Reallocate'}
                  onClick={onReallocateClick}
                  disabled={canEnableReallocateButton() == false}
                />
                <VegaButton text="Export" onClick={fetchDataForExcel} />
              </Stack>
            );
          } else if (selectedTab == 1) {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text={'Execute'}
                  onClick={onExecuteClick}
                  variant="outlined"
                />
                <VegaButton
                  text={'Allocate'}
                  onClick={onAllocateClick}
                  disabled={canEnableAllocateButton() == false}
                />
              </Stack>
            );
          }
        }}
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Loan Accounts'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <div
                style={{
                  flex: 1,
                }}
              >
                <VegaTabBar
                  value={selectedTab}
                  variant="scrollable"
                  scrollButtons={false}
                  onChange={(e, selected) => {
                    _onTabChange(selected);
                  }}
                >
                  <VegaTabBarItem label="Allocated" />
                  <VegaTabBarItem label="Unallocated" />
                  <VegaTabBarItem disabled label="Resolved" />
                  <VegaTabBarItem disabled label="Normalised" />
                  <VegaTabBarItem disabled label="Stablised" />
                  <VegaTabBarItem disabled label="Rollback" />
                  <VegaTabBarItem disabled label="Cancelled" />
                </VegaTabBar>
              </div>
            </Stack>
          );
        }}
      />
      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '2rem' }}
        title={EnumUtility.getEnumValueName(AllocationTabType, selectedTab)}
        renderRightView={() => {
          return (
            <LmOutlineTextField
              fixWidth={320}
              placeholder="Search By Loan account number"
              name="search"
              startAdornment={
                <Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />
              }
              value={searchLoanId}
              onChange={e => setSearchLoanId(e.target.value)}
            />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          page={getPageNumber()}
          pageSize={10}
          checkboxSelection
          rowCount={getRowCount()}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={getRecords()}
          columns={getColumnDefinition()}
          loading={loading}
          idColumn="loanId"
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </VegaPageContent>

      <AllocateLoanAccountsDrawer
        open={isAllocateDrawerOpen}
        onClose={function (): void {
          closeAllocateDrawer();
        }}
        onSubmit={function (
          formData: Partial<AllocateLoanAccountsFormData>
        ): void {
          _allocateLoanAccounts(formData);
        }}
      />

      <DeallocateLoanAccountsDialog
        open={isDeAllocatioDrawerOpen}
        onClose={function (): void {
          closeDeAllocatioDrawer();
        }}
        onSubmit={function (): void {
          _deAllocateLoanAccounts();
        }}
      />
      <ExecuteLoanAccountsDrawer
        open={isExecutionDrawerOpen}
        onClose={function (): void {
          closeExecutionDrawer();
        }}
        onSubmit={function (
          formData: Partial<ExecuteLoanAccountsFormData>
        ): void {
          _executeLoanAccounts(formData);
        }}
      />
      <ReallocateLoanAccountsDrawer
        open={isReAllocationDrawerOpen}
        onClose={function (): void {
          closeReAllocationDrawer();
        }}
        onSubmit={function (
          formData: Partial<ReallocateLoanAccountsFormData>
        ): void {
          _reallocateLoanAccounts(formData);
        }}
      />
    </>
  );
}

export default LoanAccounts;

const getColumnDefinition = () => {
  const COLUMN_DEF: GridColumns<AllocationMetaData> = [
    {
      field: 'Name',
      headerName: 'Name',
      flex: 0.7,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.customerName) {
            displayText = getCustomerName.customerName;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'LoanAccountNumber',
      headerName: 'Loan Account Number',
      flex: 1,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan.loanId ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Channel',
      headerName: 'Allocation Type',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        const isAllocated =
          loan?.metaDataLoanAllocationDto.status === 'ALLOCATED';
        return (
          <VegaText
            text={
              isAllocated
                ? loan.metaDataLoanAllocationDto.currentAllocationType
                : loan?.metaDataLoanAllocationDto
                    ?.allocationTypeFromModelParser ?? '-'
            }
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'POS',
      headerName: 'POS',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        var displayText = '-';
        if (loan.lmsVariables) {
          const outstandingDetails = loan.lmsVariables as LmsVariable;
          if (outstandingDetails.outstandingAmount) {
            displayText = StringUtility.formatToINR(
              outstandingDetails.outstandingAmount
            );
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Bucket',
      headerName: 'Bucket',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan.lmsVariables.bucket ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'ContactNumber',
      headerName: 'Contact Number',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerContactNumber = getFirstItemIfExists(
            loan.customerDetails
          );
          if (getCustomerContactNumber.mobileNo) {
            displayText = getCustomerContactNumber.mobileNo;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'AllocationDate',
      headerName: 'Allocation Date',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        return (
          <VegaText
            text={DateUtility.formatStringToDDMMYYYYWithTime(
              loan.metaDataLoanAllocationDto?.allocationDate
            )}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}

const getAllocationStatusFromTab = (tab: AllocationTabType) => {
  switch (tab) {
    case AllocationTabType.Allocated:
      return 'ALLOCATED';
    case AllocationTabType.Unallocated:
      return 'UNALLOCATED';
  }
};
