import { IconButton, Slider } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { COLOR } from '../../utils/ColorUtility';
import VegaText from '../common/VegaText';
import VegaButton from '../common/VegaButton';
import { CrossIcon } from '../Icons/Icons';

export enum ViewState {
  Inital,
  Uploading,
  Uploaded,
}
const TIMER_SECONDS = 5;
type Props = {
  fileName?: string;
  state: ViewState;
  onUploadClick: () => void;
  onCancelClick: () => void;
};
function AllocationFileUploadCard({
  fileName,
  state,
  onCancelClick,
  onUploadClick,
}: Props) {
  const [viewState, setViewState] = useState<ViewState>(ViewState.Inital);
  const [progressValue, setProgressValue] = useState(0);
  const [isIntervalRunning, setIsIntervalRunning] = useState(false);

  let intervalId;
  const getHeading = () => {
    if (viewState == ViewState.Uploading) {
      return 'Uploading....';
    } else if (viewState == ViewState.Inital) {
      return fileName;
    } else if (viewState == ViewState.Uploaded) {
      return fileName + ' Uploaded';
    }
  };

  const startInterval = () => {
    if (isIntervalRunning) {
      stopInterval();
    }
    setIsIntervalRunning(true);
    intervalId = setInterval(() => {
      const increment = (80 / TIMER_SECONDS) * 0.1;
      setProgressValue(prevValue => {
        const newValue = prevValue + increment;

        if (newValue >= 100) {
          stopInterval(); // Stop the interval
          return 100; // Set progress to 100
        }

        return newValue;
      });
    }, 80);
  };

  const stopInterval = () => {
    setIsIntervalRunning(false);
    clearInterval(intervalId);
  };

  useEffect(() => {
    return () => {
      stopInterval();
    };
  }, []);

  useEffect(() => {
    setViewState(state);
  }, [state]);

  useEffect(() => {
    if (viewState == ViewState.Uploading) {
      startInterval();
    }
  }, [viewState]);

  return (
    <div
      style={{
        borderRadius: '0.5rem',
        border: '1px solid #E1E4EB',
        background: '#FAFCFF',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
        paddingLeft: '1.88rem',
        paddingRight: '1.88rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flex={1}>
          <VegaText text={getHeading()} />
          {viewState == ViewState.Uploading && (
            <VegaText text={`${Math.floor(progressValue)}% . 30 sec left`} />
          )}
          {viewState == ViewState.Uploading && (
            <Slider
              disabled
              max={100}
              min={0}
              value={progressValue}
              sx={{
                fontFamily: 'Aspekta !important',
                color: 'rgba(116, 148, 235, 1)',
                '& .MuiSlider-thumb': {
                  display: 'none',
                },
                '& .MuiSlider-track': {
                  color: 'rgba(116, 148, 235, 1) !important',
                  height: '0.5rem',
                },
              }}
            ></Slider>
          )}
        </Stack>
        {viewState == ViewState.Inital && (
          <Stack direction={'row'} spacing="1rem">
            <IconButton
              sx={{ backgroundColor: COLOR.RED.LIGHT }}
              onClick={onCancelClick}
            >
              <CrossIcon />
            </IconButton>
            <VegaButton
              text="Upload"
              onClick={() => {
                onUploadClick();
              }}
            />
          </Stack>
        )}
      </div>
    </div>
  );
}

export default AllocationFileUploadCard;
