import { Stack } from '@mui/material';
import { useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import HistoryReceiptManagment from './module/HistoryReceiptManagment';
import PendingReceiptManagment from './module/PendingReceiptManagment';

export enum TabType {
  PENDING = 'PENDING',
  HISTORY = 'HISTORY',
}

const ReceiptApprover = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onTabChange = (value: number) => {
    setSelectedTab(value);
  };

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Receipt Management'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  onTabChange(selected);
                }}
              >
                <VegaTabBarItem label={toLowerCase(TabType.PENDING)} />
                <VegaTabBarItem label={toLowerCase(TabType.HISTORY)} />
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      {selectedTab === 1 ? (
        <HistoryReceiptManagment />
      ) : (
        <PendingReceiptManagment />
      )}
    </>
  );
};

export default ReceiptApprover;
