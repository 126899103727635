import { Divider, Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CustomerDetail, LoanData } from '../../types/loan';
import {
  ContactStatus,
  DispositionType,
  TrailNextAction,
  VisitLocationTagType,
  dispositionCodeMap,
  dispositionTypeForNonContactedCases,
  getEnumValueFromString,
} from '../../types/trail';
import { DateUtility } from '../../utils/DateUtlility';
import { AnimatedInputWrapper } from '../ExpenseClaim/CreateClaimForm';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import LmTextField from '../common/LmTextField';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../common/VegaFileSelect';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import { useAppSelector } from '../../store';
import { VisitRequestSelector } from '../../features/visitSlice';
import { useClientAuth } from '../../providers/ClientProvider';

export type CreateTrailFormData = {
  loanId: string;
  agentId: string;
  visitId: string;
  customerName: string;
  primaryContact: string;
  alternateContact: number;
  date: string;
  contactStatus: string;
  dispositionType: string;
  dispositionCode: string;
  nextActionDate: string;
  nextAction: string;
  actionComment: string;
  locationTagType: string;
  customerImageFile: VegaFileSelectOption;
  visitReason: string;
  nonPaymentReason: string;
  amountCollected: number;
  latitude: string;
  agentRemarksForNonGeoVisit: string;
  longitude: string;
};

enum InputType {
  PrimaryContact = 'primaryContact',
  AlternateContact = 'alternateContact',
  Date = 'date',
  ContactStatus = 'contactStatus',
  DispositionType = 'dispositionType',
  DispositionCode = 'dispositionCode',
  NextActionDate = 'nextActionDate',
  NextAction = 'nextAction',
  ActionComment = 'actionComment',
  VisitType = 'locationTagType',
  CustomerImage = 'customerImageFile',
  AmountCollected = 'amountCollected',
  AgentRemarksForNonGeoVisit = 'agentRemarksForNonGeoVisit',
  latitude = 'latitude',
  longitude = 'longitude',
  NonPaymentReason = 'nonPaymentReason',
}
type Props = {
  open: boolean;
  onClose: () => void;
  onUpdateTrailClick: (formData: Partial<CreateTrailFormData>) => void;
  loanDetail?: LoanData;
};

function UpdateTrailDrawer({
  onUpdateTrailClick,
  open,
  onClose,
  loanDetail,
}: Props) {
  const { userId } = useClientAuth();
  const { visit } = useAppSelector(VisitRequestSelector);
  const [formData, setFormData] = useState<Partial<CreateTrailFormData>>({});
  const [showDispositionType, setShowDispositionType] =
    useState<boolean>(false);
  const [showDispositionCode, setShowDispositionCode] =
    useState<boolean>(false);
  const [showNextActionDate, setShowNextActionDate] = useState<boolean>(false);
  const [showNextAction, setShowNextAction] = useState<boolean>(false);
  const [showActionComment, setShowActionComment] = useState<boolean>(false);
  const [showVisitType, setShowVisitType] = useState<boolean>(false);
  const [showVisitReason, setShowVisitReason] = useState<boolean>(false);
  const [showCustomerImage, setShowCustomerImage] = useState<boolean>(false);
  const [showNonPaymentReason, setShowNonPaymentReason] =
    useState<boolean>(false);
  const customerDetail = getFirstItemIfExists<CustomerDetail>(
    loanDetail?.customerDetails
  );

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  function onContactStatusChange(status) {
    updateFormData(InputType.ContactStatus, status.target.value);
    setShowDispositionType(status.target.value == ContactStatus.CONTACTED);
    setShowDispositionCode(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowNextAction(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowNextActionDate(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowActionComment(status.target.value === ContactStatus.CONTACTED);
    setShowCustomerImage(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
    setShowVisitType(
      status.target.value === ContactStatus.CONTACTED ||
        status.target.value === ContactStatus.NON_CONTACTED
    );
  }
  function updateDispositionCode(value) {
    updateFormData(InputType.DispositionCode, value);
  }
  function updateDispositionType(value) {
    updateFormData(InputType.DispositionType, value);
    setShowNonPaymentReason(value == DispositionType.NON_PAYMENT);
  }
  function updateNextActionDate(value) {
    updateFormData(InputType.NextActionDate, value);
  }
  function updateNextAction(value) {
    updateFormData(InputType.NextAction, value);
  }
  function updateActionComment(value) {
    updateFormData(InputType.ActionComment, value);
  }

  function updateVisitType(value) {
    updateFormData(InputType.VisitType, value);
    setShowVisitReason(value === VisitLocationTagType.NON_GEO_TAGGED_VISIT);
    if (value === VisitLocationTagType.GEO_TAGGED_VISIT) {
      updateFormData(InputType.latitude, '70');
      updateFormData(InputType.longitude, '71');
    }
  }
  function updateAgentRemarksForNonGeoVisit(value) {
    updateFormData(InputType.AgentRemarksForNonGeoVisit, value);
  }

  function onFileChange(file: VegaFileSelectOption) {
    updateFormData(InputType.CustomerImage, file);
  }

  function onFileAdd(file: VegaFileSelectOption) {
    onFileChange(file);
  }
  function updateNonPaymentReason(value) {
    updateFormData(InputType.NonPaymentReason, value);
  }

  function onFileDelete() {
    updateFormData(InputType.CustomerImage, '');
  }
  function onPrimaryContactDetailChange(value) {
    updateFormData(InputType.PrimaryContact, value);
  }
  function onAlternateContactDetailChange(value) {
    updateFormData(InputType.AlternateContact, value);
  }
  function OnSumbitClick() {
    close();
    const updatedData = { ...formData };
    updatedData.date = DateUtility.parseToLocalDate(formData.date);
    updatedData.nextActionDate = DateUtility.parseToLocalDate(
      formData.nextActionDate
    );
    onUpdateTrailClick(updatedData);
    setFormData({});
    setShowActionComment(false);
    setShowCustomerImage(false);
    setShowDispositionCode(false);
    setShowDispositionType(false);
    setShowNextAction(false);
    setShowNextActionDate(false);
    setShowVisitReason(false);
    setShowVisitType(false);
  }

  useEffect(() => {
    if (open == false) return;
    setFormData({
      loanId: loanDetail?.loanId,
      agentId: userId,
      visitId: visit?.id,
      customerName: customerDetail?.customerName,
      primaryContact: customerDetail?.mobileNo,
      nextActionDate: new Date().toLocaleDateString(),
    });
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Update Trail'} onClose={onClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return <LmButton text="Sumbit" onClick={OnSumbitClick} />;
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              header="Primary Contact Number"
              value={formData?.primaryContact}
              onChange={e => onPrimaryContactDetailChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              header="Alternate Contact Number"
              value={formData?.alternateContact}
              onChange={e => onAlternateContactDetailChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmDateField header="DATE" value={new Date()} onChange={e => {}} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Customer Contact Status">
              <VegaSelect
                options={contactStatusOptions()}
                value={formData?.contactStatus}
                onChange={e => {
                  onContactStatusChange(e);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <AnimatedInputWrapper show={showDispositionType} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <VegaFormInputField label="Disposition Type">
                <VegaSelect
                  value={formData?.dispositionType}
                  onChange={e => {
                    updateDispositionType(e.target.value as string);
                  }}
                  options={dispositionTypeOptions()}
                />
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper
            show={showNonPaymentReason}
            marginTop={'0.5rem'}
          >
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <VegaFormInputField label="NON Payment Reason">
                <LmTextField
                  placeholder="Enter Comment"
                  value={formData?.nonPaymentReason}
                  onChange={e => {
                    updateNonPaymentReason(e.target.value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showDispositionCode} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <VegaFormInputField label="Disposition Code">
                <VegaSelect
                  value={formData.dispositionCode}
                  onChange={e =>
                    updateDispositionCode(e.target.value as string)
                  }
                  options={dispositionCodeOptions(formData)}
                />
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showNextActionDate} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <LmDateField
                header="Next Action date"
                value={formData.nextActionDate}
                onChange={e => {
                  updateNextActionDate(DateUtility.parseDateFromDatePicker(e));
                }}
              />
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showNextAction} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <VegaFormInputField label="Next Action">
                <VegaSelect
                  value={formData?.nextAction}
                  onChange={e => updateNextAction(e.target.value)}
                  options={nextActionOptions()}
                />
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showActionComment} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <VegaFormInputField label="Action Comment">
                <LmTextField
                  placeholder="Enter Comment"
                  value={formData.actionComment}
                  onChange={e => {
                    updateActionComment(e.target.value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showCustomerImage} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
              <Stack rowGap={'0.75rem'}>
                {formData.customerImageFile && (
                  <VegaFileSelect
                    key={formData.customerImageFile?.id}
                    value={formData.customerImageFile}
                    acceptedExtension={[
                      AcceptedExtension.JPG,
                      AcceptedExtension.JPEG,
                      AcceptedExtension.PNG,
                      AcceptedExtension.PDF,
                    ]}
                    placeholder={'Upload Document'}
                    onFileSelect={file => {
                      onFileChange(file);
                    }}
                    onDeleteClick={() => {
                      onFileDelete();
                    }}
                  />
                )}

                <VegaFileSelect
                  placeholder={'Upload Customer Image'}
                  onFileSelect={file => {
                    onFileAdd(file);
                  }}
                />
              </Stack>
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showVisitType} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <Divider sx={{ borderStyle: 'dotted' }} />
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showVisitType} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <VegaFormInputField label="Visit Type">
                <VegaSelect
                  value={formData.locationTagType}
                  onChange={e => updateVisitType(e.target.value)}
                  options={visitTypeOptions()}
                />
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
          <AnimatedInputWrapper show={showVisitReason} marginTop={'0.5rem'}>
            <Grid item xs={12} style={{ marginTop: '0.75rem' }}>
              <VegaFormInputField label="Agent Remarks For Non Geo Visit">
                <LmTextField
                  placeholder="Enter Remark"
                  value={formData.visitReason}
                  onChange={e => {
                    updateAgentRemarksForNonGeoVisit(e.target.value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
          </AnimatedInputWrapper>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default UpdateTrailDrawer;
const visitTypeOptions = () =>
  [
    VisitLocationTagType.GEO_TAGGED_VISIT,
    VisitLocationTagType.NON_GEO_TAGGED_VISIT,
  ].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const contactStatusOptions = () =>
  Object.values(ContactStatus).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
const dispositionTypeOptions = () =>
  Object.keys(DispositionType).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
const dispositionCodeOptions = (formData: Partial<CreateTrailFormData>) => {
  const contactStatus = formData.contactStatus;

  if (!contactStatus) {
    return [];
  }
  if (contactStatus === ContactStatus.NON_CONTACTED) {
    return dispositionTypeForNonContactedCases().map(item => {
      const option: VegaSelectOption = {
        label: _.startCase(_.toLower(item)),
        value: item,
      };
      return option;
    });
  }
  const dispositionType = formData.dispositionType;
  if (!dispositionType) {
    return [];
  }
  const type = getEnumValueFromString(DispositionType, dispositionType);
  if (!type) {
    return [];
  }
  return dispositionCodeMap[type].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
};

const nextActionOptions = () =>
  Object.keys(TrailNextAction).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
