import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { getTargetList, getTargetState } from '../../features/targetSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import CompletedTargetList from './modules/CompletedTargetList';
import PendingTargetList from './modules/PendingTargetList';
export enum TabType {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

const TargetAdmin = () => {
  const { userId } = useClientAuth();
  const dispatch = useAppDispatch();
  const { error } = useAppSelector(getTargetState);
  const { setSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const fetchTargetList = () => {
    dispatch(getTargetList({ agentId: userId }));
  };

  useEffect(() => {
    fetchTargetList();
  }, []);

  useEffect(() => {
    if (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [error]);
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Targets'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  setSelectedTab(selected);
                }}
              >
                <VegaTabBarItem label={toLowerCase(TabType.PENDING)} />
                <VegaTabBarItem label={toLowerCase(TabType.COMPLETED)} />
              </VegaTabBar>
            </Stack>
          );
        }}
      />
      {selectedTab === 1 ? <CompletedTargetList /> : <PendingTargetList />}
    </>
  );
};

export default TargetAdmin;
