import axios from 'axios';
import {
  DepositReceiptProps,
  IssueReceiptProps,
  ReceiptsListProps,
} from '../types/receiptManagment';
import {
  GetReceiptsListRequest,
  SendPaymentLinkRequest,
  UpdateReceiptParams,
} from '../types/request/receiptManagment';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/receipting',
  LIST: '/list',
  DEPOSIT_STATUS: '/deposit/status',
  PAYMENT_LINK: '/paymentLink/link',
};

export class ReceiptManagmentService {
  static async getReceiptsList(
    request: Partial<GetReceiptsListRequest>
  ): Promise<PaginatedResponse<ReceiptsListProps>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async createSendPaymentLink(
    request: Partial<SendPaymentLinkRequest>
  ): Promise<PaginatedResponse<ReceiptsListProps>> {
    const endpoint = Endpoint.BASE + Endpoint.PAYMENT_LINK;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createReceipt(
    request: Partial<IssueReceiptProps>
  ): Promise<DepositReceiptProps> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const endpoint = Endpoint.BASE;
    const amountString = request.totalAmount.toString();
    const parsePaymentMeta = JSON.stringify(request.paymentMeta);
    const formData = new FormData();
    formData.append('agentId', request.agentId);
    formData.append('loanId', request.loanId);
    formData.append('mobileNumber', request.mobileNumber);
    formData.append('email', request.email);
    formData.append('documentImage', request.documentImage.value);
    formData.append('payeeName', request.payeeName);
    formData.append(
      'relationshipWithCustomer',
      request.relationshipWithCustomer
    );
    formData.append('totalAmount', amountString);
    formData.append('paymentMeta', parsePaymentMeta);
    formData.append('panCard', request.panCard);
    formData.append('remarks', request.remarks);
    const response = await axios.post(endpoint, formData, config);
    return response.data;
  }

  static async updateStatusForReceipt(
    request: Partial<UpdateReceiptParams>
  ): Promise<DepositReceiptProps> {
    const endpoint = Endpoint.BASE + Endpoint.DEPOSIT_STATUS;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
}
