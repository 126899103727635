/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { PRIMARY } from '../../../constants/style';
import {
  getLoanAccountList,
  receiptState,
} from '../../../features/receiptManagmentSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { ROUTES } from '../../../router/routes';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AccountAgentViewModel } from '../../../types/receiptManagment';
import { LoanListRequest } from '../../../types/request/loan';
import { DateUtility } from '../../../utils/DateUtlility';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

const ListOfAccountsAgent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loanAccounts, loading, totalItems } = useAppSelector(receiptState);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const COL_DEF: GridColumns<AccountAgentViewModel[]> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
    },

    {
      field: 'lan',
      headerName: 'Loan Account Number',
      flex: 0.7,
    },
    {
      field: 'pos',
      headerName: 'POS',
      flex: 0.5,
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.5,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact number',
      flex: 0.7,
    },
    {
      field: 'allocationDate',
      headerName: 'Allocation Date',
      flex: 0.7,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.6,
      renderCell: props => {
        const loanAccount = props.row as unknown as AccountAgentViewModel;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text="Issue Receipt"
              sx={{
                color: PRIMARY.darkBlue,
                fontSize: '0.875rem',
                bgcolor: '#E7EDFC',
                ':hover': {
                  color: PRIMARY.darkBlue,
                  bgcolor: '#E7EDFC',
                },
              }}
              onClick={() =>
                navigate(
                  ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT.replace(
                    ':loanId',
                    loanAccount.id
                  )
                )
              }
            />
          </Stack>
        );
      },
    },
  ];

  const getLoanList = async () => {
    try {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        page,
        pageSize: 10,
      } as LoanListRequest;
      dispatch(getLoanAccountList(body));
    } catch (error) {}
  };

  useEffect(() => {
    const getList = setTimeout(() => {
      getLoanList();
    }, 200);
    return () => clearTimeout(getList);
  }, [page, pageSize]);

  return (
    <>
      <VegaPageHeader
        title="Pending"
        renderRightView={() => (
          <LmFilterSection
            setSearch={() => {}}
            onFilter={() => openFilterDrawer()}
          />
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={loanAccounts}
          loading={loading}
          columns={COL_DEF}
          idColumn="id"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfAccountsAgent;
