/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import {
  CashPayment,
  CashPaymentMeta,
  IssueReceiptProps,
} from '../../../types/receiptManagment';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import LmTextField from '../../../components/common/LmTextField';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  receiptState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';

const CashPaymentDetails = () => {
  const { issueReceipt } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();

  const onReceiptChange = (
    key: keyof IssueReceiptProps,
    value: CashPayment | number
  ) => {
    const paymentDetailsObj = {
      ...issueReceipt,
      [key]: value,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(paymentDetailsObj));
  };

  const onPaymentChange = (name: number, value: number, index: number) => {
    const paymentSpread = [...issueReceipt.paymentMeta?.cashPaymentMeta];
    const paymentMeta = issueReceipt.paymentMeta?.cashPaymentMeta[index];
    const cashPaymentMetaObj = {
      ...paymentMeta,
      ['denomination']: name,
      ['count']: value,
    } as CashPaymentMeta;

    paymentSpread.splice(index, 1, cashPaymentMetaObj);
    const newObj = {
      paymentMode: issueReceipt.paymentMeta?.paymentMode,
      cashPaymentMeta: paymentSpread,
    } as CashPayment;
    onReceiptChange('paymentMeta', newObj);
  };

  return (
    <Grid container spacing={2}>
      {issueReceipt.paymentMeta?.cashPaymentMeta?.map(
        (cash: CashPaymentMeta, index: number) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <VegaFormInputField label={cash.denomination}>
              <LmTextField
                type="number"
                value={cash.count}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  onPaymentChange(cash.denomination, value, index);
                }}
              />
            </VegaFormInputField>
          </Grid>
        )
      )}
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Denomination Total'}>
          <LmTextField
            type="number"
            value={getTotalAmount(issueReceipt.paymentMeta?.cashPaymentMeta)}
            disabled
          />
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default CashPaymentDetails;

const getTotalAmount = cashPayments => {
  let totalAmount = 0;
  for (const cash of cashPayments) {
    totalAmount += cash.denomination * cash.count;
  }
  return totalAmount;
};
