import { ThemeProvider } from '@emotion/react';
import { ReactNode, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../Auth/Login';
import LmLayout from '../layout/LmLayout';
import LiveAuctionDetails from '../pages/Auction/LiveAuctionDetails';
import TrailDetailPage from '../pages/Trail/TrailDetailPage';
import ConveyancePolicy from '../pages/expense_claim/ConveyancePolicy';
import ExpenseClaims from '../pages/expense_claim/ExpenseClaims';
import ClientAuthProvider from '../providers/ClientProvider';
import SnackbarProvider from '../providers/SnackbarProvider';
import { appTheme } from '../themes/theme';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { AUTH_ROUTES, ROUTES } from './routes';
import { APP_ROUTES } from './AppRoutes';

export interface LmSidebarListItemType {
  path: string;
  label: string;
  component?: JSX.Element;
  icon?: string | ReactNode;
  activIcon?: string | ReactNode;
  children?: LmSidebarListItemType[];
  routes?: string[];
}

export const AUTHENTICATION_ROUTES: LmSidebarListItemType[] = [
  {
    path: AUTH_ROUTES.LOGIN,
    label: 'Home',
    component: <Login />,
  },
];

const AppRouter: React.FC = () => {
  return (
    <LmLayout>
      <Suspense fallback={<h1>loading....</h1>}>
        <Routes>
          <Route path={ROUTES.CLAIMS} element={<ExpenseClaims />} />
          {/* <Route path={ROUTES.AUCTION_USERS} element={<AuctionUsers />} /> */}
          <Route
            path={ROUTES.LIVE_AUCTION_DETAILS}
            element={<LiveAuctionDetails />}
          />
          <Route
            path={ROUTES.Trail_Detail_Page}
            element={<TrailDetailPage />}
          />
          <Route
            path={ROUTES.CONVEYANCE_POLICY}
            element={<ConveyancePolicy />}
          />

          {APP_ROUTES.map(
            (route: LmSidebarListItemType) =>
              route.component && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.component}
                >
                  {route.children &&
                    route.children.map(
                      childRoute =>
                        childRoute.component && (
                          <Route
                            key={childRoute.path}
                            path={childRoute.path}
                            element={childRoute.component}
                          />
                        )
                    )}
                </Route>
              )
          )}
        </Routes>
      </Suspense>
    </LmLayout>
  );
};

const AuthRouter: React.FC = () => {
  return (
    <Suspense fallback={<h1>loading....</h1>}>
      <Routes>
        <Route path={AUTH_ROUTES.LOGIN} element={<Login />} />
      </Routes>
    </Suspense>
  );
};

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <SnackbarProvider>
        <ClientAuthProvider>
          <ThemeProvider theme={appTheme}>
            <PublicRoutes>
              <AuthRouter />
            </PublicRoutes>
            <PrivateRoutes>
              <AppRouter />
            </PrivateRoutes>
          </ThemeProvider>
        </ClientAuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default Router;
