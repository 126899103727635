import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CreateLeaveRequest,
  GetLeaveListRequest,
  UpdateLeaveStatus,
} from '../types/request/leave';
import { LeaveService } from '../Apis/LeaveService';
import {
  CreateApplyLeaveFormData,
  Leave,
  LeaveViewModel,
} from '../types/leave';
import { RootState } from '../store';
interface LeaveRequestActionState {
  page: number;
  totalItems: number;
  pageSize: number;
  loading: boolean;
  leaves: LeaveViewModel[];
  error: string | null;
}

export const fetchLeaveList = createAsyncThunk(
  'apply-request/leaveDetail',
  async (request: Partial<GetLeaveListRequest>) => {
    return await LeaveService.getLeaveList(request);
  }
);

export const updateLeaveStatus = createAsyncThunk(
  'apply-request/updateLeaveStatus',
  async (
    data: {
      request: Partial<UpdateLeaveStatus>;
      fetchList: boolean;
      fetchListParams: Partial<GetLeaveListRequest>;
    },
    { dispatch }
  ) => {
    const response = await LeaveService.updatePendingLeave(data.request);
    if (data.fetchList == true) {
      dispatch(fetchLeaveList(data.fetchListParams));
    }
    return response;
  }
);

export const createLeave = createAsyncThunk(
  'leave/apply',
  async (
    data: { formData: Partial<CreateApplyLeaveFormData>; userId: string },
    { dispatch }
  ) => {
    const request: Partial<CreateLeaveRequest> = {
      userId: data.userId,
      leaveType: data.formData.typeOfLeave,
      startDate: data.formData.startDate.toISOString(),
      endDate: data.formData.endDate.toISOString(),
      startHalf: data.formData.startDateHalf,
      endHalf: data.formData.endDateHalf,
      reasonRemarks: data.formData.reason,
    };

    const response = await LeaveService.createLeave(request);
    dispatch(fetchLeaveList({ userId: data.userId }));
    return response;
  }
);

const initialState: LeaveRequestActionState = {
  loading: false,
  page: 0,
  totalItems: 0,
  pageSize: 0,
  leaves: [],
  error: null,
};

const leaveRequestSlice = createSlice({
  name: 'leaveaction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLeaveList.pending, state => {
        state.loading = true;
      })
      .addCase(fetchLeaveList.fulfilled, (state, action) => {
        state.loading = false;
        state.leaves = mapLeaveToLeaveViewModels(action.payload.records);
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchLeaveList.rejected, state => {
        state.loading = false;
      })
      .addCase(updateLeaveStatus.pending, state => {
        state.loading = true;
      })
      .addCase(updateLeaveStatus.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateLeaveStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createLeave.pending, state => {
        state.loading = true;
      })
      .addCase(createLeave.fulfilled, state => {
        state.loading = false;
      })
      .addCase(createLeave.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default leaveRequestSlice;

export const leaveRequestSelector = (state: RootState) =>
  state.leaveRequestAction;
function mapLeaveToLeaveViewModels(leaves: Leave[]): LeaveViewModel[] {
  const viewModels: LeaveViewModel[] = leaves.map(item => {
    const viewModel: LeaveViewModel = {
      id: item.id,
      agentName: item.userId,
      typesofLeave: item.leaveType,
      startDateHalf: item.startHalf,
      startDate: item.startDate,
      endDate: item.endDate,
      endDateHalf: item.endHalf,
      reason: item.reasonRemarks,
      status: item.status,
      approversName: item.approverId,
      rejectionReason: item.rejectionReason,
    };
    return viewModel;
  });

  return viewModels;
}
