import { useState, useCallback } from 'react';

interface Countdown {
  hours: number;
  minutes: number;
  seconds: number;
}

const calculateTimeLeft = (endTime: number): Countdown => {
  const now = new Date().getTime();
  const timeLeft = endTime - now;

  if (timeLeft <= 0) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { hours, minutes, seconds };
};

const useCountdown = (
  initialTime: Countdown = { hours: 0, minutes: 0, seconds: 0 }
) => {
  const [timeLeft, setTimeLeft] = useState<Countdown>(initialTime);
  const [timer, setTimer] = useState<number | null>(null);

  const startCountdown = useCallback(
    (durationInMilliseconds: number) => {
      if (timer !== null) {
        clearInterval(timer);
      }

      const endTime = new Date().getTime() + durationInMilliseconds;
      setTimer(
        window.setInterval(() => {
          const timeLeft = calculateTimeLeft(endTime);
          setTimeLeft(timeLeft);

          if (
            timeLeft.hours === 0 &&
            timeLeft.minutes === 0 &&
            timeLeft.seconds === 0
          ) {
            clearInterval(timer);
          }
        }, 1000)
      );
    },
    [timer]
  );

  const stopCountdown = () => {
    if (timer !== null) {
      clearInterval(timer);
      setTimer(null);
    }
  };

  const resetCountdown = () => {
    stopCountdown();
    setTimeLeft(initialTime);
  };

  return {
    hours: timeLeft.hours,
    minutes: timeLeft.minutes,
    seconds: timeLeft.seconds,
    startCountdown,
    stopCountdown,
    resetCountdown,
  };
};

export default useCountdown;
