import { Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';
import VegaText from '../common/VegaText';

interface MonthlyProgressCardprops {
  trendIcon?: () => ReactNode;
  trendPercentage?: string;
  achievementCount?: string;
  achievmentName?: string;
  trendDescription?: string;
  percentageColor?: string;
  renderRightView?: () => ReactNode;
}
const MonthlyProgressCard = ({
  trendIcon,
  trendPercentage,
  trendDescription,
  achievementCount,
  achievmentName,
  percentageColor,
  renderRightView,
}: MonthlyProgressCardprops) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '20rem',
        height: '8rem',
        padding: '1rem',
        alignItems: 'center',
        gap: '1rem',
        borderRadius: '0.75rem',
        border: '0.0625rem solid #e1e4eb',
        background: '#fff',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={'0.3rem'}>
            {trendIcon?.()}
            {trendPercentage && (
              <VegaText text={trendPercentage} color={percentageColor} />
            )}
            {trendDescription && (
              <VegaText text={trendDescription} color="#999DA8" />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} paddingTop="1rem">
          <VegaText text={achievementCount} variant="h5" />
        </Grid>
        <Grid item xs={12}>
          <VegaText text={achievmentName} color="#999DA8" />
        </Grid>
      </Grid>
      <div>{renderRightView?.()}</div>
    </div>
  );
};
export default MonthlyProgressCard;
