import { createAsyncThunk } from '@reduxjs/toolkit';
import { AllocationService } from '../Apis/AllocationService';
import { LoanService } from '../Apis/LoanServices';
import { GetTeleAllocationsListRequest } from '../types/request/allocation';
import { LoanListRequest } from '../types/request/loan';

export const fetchLoanAccountList = createAsyncThunk(
  'teleAgent/fetchLoanAccountList',
  async (data: Partial<LoanListRequest>) => {
    const response = await LoanService.getLoanList(data);
    return response;
  }
);

export const fetchTeleAgentAllocation = createAsyncThunk(
  'teleAgent/fetchTeleAgentAllocation',
  async (request: Partial<GetTeleAllocationsListRequest>) => {
    const response = await AllocationService.getTeleAllocations(request);
    return response;
  }
);
