/* eslint-disable no-useless-escape */
export class StringUtility {
  static concatenateStrings(...strings: (string | undefined)[]): string {
    return strings.filter(str => str !== undefined).join(' ');
  }

  static formatToINR(value?: number | string) {
    if (value === 0 || value === '0') return '₹ 0';
    if (!value) return '-';

    const numberValue =
      typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
    if (isNaN(numberValue)) {
      return 'Invalid Number';
    }

    const absoluteValue = Math.abs(numberValue);

    // Use "en-IN" locale for Indian numbering system and currency formatting
    const formattedValue = absoluteValue.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
    });
    return `${formattedValue}`;
  }

  static extractLastNDigits(n: number, value?: string): string {
    if (!value) return '';
    return value.substring(Math.max(0, value.length - n));
  }
  static validateEmail(email: string) {
    const emailRegex = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,63}){1,3})$/;
    return emailRegex.test(email);
  }
  static validatePhoneNumber(number: string) {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  }
}
