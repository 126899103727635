import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

function VegaDialogContent({ children }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ padding: '2rem' }}>{children}</div>
    </div>
  );
}

export default VegaDialogContent;
