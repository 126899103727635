/* eslint-disable no-undef */
import axios from 'axios';
import {
  Allocation,
  AllocationMetaData,
  AllocationRule,
  TeleAllocateBulk,
  TeleAllocationList,
} from '../types/allocations';
import {
  AllocateLoanAccountsRequest,
  BulkAllocateRequest,
  CreateRuleRequest,
  CreateTeleAllocateRequest,
  DeAllocateAllocationsRequest,
  DownloadBulkAllocateFileRequest,
  DownloadBulkAllocationSampleFileRequest,
  ExecuteCriteriaRequest,
  GetAllocationMetaDataRequest,
  GetAllocationsListRequest,
  GetAutoAllocationCriteriaListRequest,
  GetTeleAllocationsListRequest,
  ReallocateAllocationsRequest,
} from '../types/request/allocation';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/allocation',
  LIST_ALLOCATIONS: '/list',
  ALLOCATE_TELE: '/allocate',
  ALLOCATE_FIELD: '/allocate',
  RE_ALLOCATE: '/reallocate',
  DE_ALLOCATE: '/deallocate',
  LIST_AUTO_CRITERIA: '/auto/criteria/list',
  CREATE_RULE: '/auto/criteria',
  LIST_AUTO_ALLOCATION_ATTRIBUTES: '/auto/attributes',
  EXECUTE_CRITERIA: '/auto/execute',
  LIST_BULK_ALLOCATION: '/allocate/bulk/meta/list',
  LIST_BULK_DE_ALLOCATION: '/deallocate/bulk/meta/list',
  BULK_ALLOCATE: '/allocate/bulk',
  BULK_DEALLOCATE: '/deallocate/bulk',
  DOWNLOAD_BULK_DEALLOCATION_SAMPLE: '/deallocate/bulk/sample',
  DOWNLOAD_BULK_ALLOCATION_SAMPLE: '/allocate/bulk/sample',
  ALLOCATE_TELE_LIST: '/allocate/tele/list',
  ALLOCATE_TELE_BULK: '/allocate/tele/bulk',
  LIST_ALLOCATION_METADATA: '/metadata',
  DOWNLOAD_BULK_ERROR_FILE: '/allocate/invalidfile',
};

const BASE_URL = Endpoint.BASE;

export class AllocationService {
  static async getAllocations(
    request: Partial<GetAllocationsListRequest>
  ): Promise<Allocation[]> {
    const endpoint = BASE_URL + Endpoint.LIST_ALLOCATIONS;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getTeleAllocations(
    request: Partial<GetTeleAllocationsListRequest>
  ): Promise<PaginatedResponse<TeleAllocationList>> {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_TELE_LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async createTeleAllocations(
    request: Partial<CreateTeleAllocateRequest>
  ): Promise<TeleAllocateBulk> {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_TELE_BULK;
    const processId =
      typeof request.processId === 'number'
        ? request.processId.toString()
        : request.processId;
    const leadsetId =
      typeof request.leadsetId === 'number'
        ? request.leadsetId.toString()
        : request.leadsetId;
    const formData = new FormData();
    formData.append('file', request.file.value);
    formData.append('processId', processId);
    formData.append('teleAllocationType ', request.teleAllocationType);
    if (leadsetId) {
      formData.append('leadsetId', leadsetId);
    }
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async allocateTele(request: Partial<AllocateLoanAccountsRequest>) {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_TELE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async allocateField(request: Partial<AllocateLoanAccountsRequest>) {
    const endpoint = BASE_URL + Endpoint.ALLOCATE_FIELD;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async reallocate(request: Partial<ReallocateAllocationsRequest>) {
    const endpoint = BASE_URL + Endpoint.RE_ALLOCATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async deallocate(request: Partial<DeAllocateAllocationsRequest>) {
    const endpoint = BASE_URL + Endpoint.DE_ALLOCATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getAutoAllocationCriteria(
    request: Partial<GetAutoAllocationCriteriaListRequest>
  ): Promise<PaginatedResponse<AllocationRule>> {
    const endpoint = BASE_URL + Endpoint.LIST_AUTO_CRITERIA;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async createRule(request: Partial<CreateRuleRequest>) {
    const endpoint = BASE_URL + Endpoint.CREATE_RULE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getAutoAllocationAttributes() {
    const endpoint = BASE_URL + Endpoint.LIST_AUTO_ALLOCATION_ATTRIBUTES;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async executeCriteria(request: Partial<ExecuteCriteriaRequest>) {
    const endpoint = BASE_URL + Endpoint.EXECUTE_CRITERIA;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getBulkAllocationListMeta() {
    const endpoint = BASE_URL + Endpoint.LIST_BULK_ALLOCATION;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async bulkAllocate(request: Partial<BulkAllocateRequest>) {
    const endpoint = BASE_URL + Endpoint.BULK_ALLOCATE;
    const formData = new FormData();
    if (request.file) {
      formData.append('file', request.file);
      formData.append('allocationType', request.allocationType);
    }
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async getBulkDeAllocationListMeta() {
    const endpoint = BASE_URL + Endpoint.LIST_BULK_DE_ALLOCATION;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async bulkDeAllocate(request: Partial<BulkAllocateRequest>) {
    const endpoint = BASE_URL + Endpoint.BULK_DEALLOCATE;
    const formData = new FormData();
    if (request.file) {
      formData.append('file', request.file);
    }
    const response = await axios.post(endpoint, formData);
    return response.data;
  }

  static async downloadBulkErrorFile(
    request: Partial<DownloadBulkAllocateFileRequest>
  ) {
    const endpoint = BASE_URL + Endpoint.DOWNLOAD_BULK_ERROR_FILE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async downloadBulkAllocationSampleFile(
    request: Partial<DownloadBulkAllocationSampleFileRequest>
  ) {
    const endpoint = BASE_URL + Endpoint.DOWNLOAD_BULK_ALLOCATION_SAMPLE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async downloadBulkDeallocationSampleFile(
    request: Partial<DownloadBulkAllocationSampleFileRequest>
  ) {
    const endpoint = BASE_URL + Endpoint.DOWNLOAD_BULK_DEALLOCATION_SAMPLE;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getAllocationMetaData(
    request: Partial<GetAllocationMetaDataRequest>
  ): Promise<PaginatedResponse<AllocationMetaData>> {
    const endpoint = BASE_URL + Endpoint.LIST_ALLOCATION_METADATA;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
