import { Grid, IconButton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentiveCollectionType,
  IncentivePayoutMetrixType,
} from '../../../types/incentives';
import {
  IncentivePolicyRulesRequest,
  PolicyRangeProps,
} from '../../../types/request/incentives';
import LmTextField from '../../common/LmTextField';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import { useParams } from 'react-router-dom';
import VegaText from '../../common/VegaText';
import { DeleteIcon } from '../../Icons/Icons';

interface IBumperProps {
  bumperInsentive: PolicyRangeProps;
  index: number;
  onDelete: (index: number) => void;
}

const BumperIncentive = ({
  bumperInsentive,
  index,
  onDelete,
}: IBumperProps) => {
  const { policyRuleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId } = useParams();

  const handleBumperIncentiveChange = (
    key: keyof PolicyRangeProps,
    value: string | number
  ) => {
    const spreadBumperIncentivePolicy = [...policyRuleState.bumperPayoutRange];
    const bumperIncentiveObj = {
      ...bumperInsentive,
      [key]: value,
    };
    spreadBumperIncentivePolicy.splice(index, 1, bumperIncentiveObj);
    const basePoliciyRule = {
      ...policyRuleState,
      bumperPayoutRange: spreadBumperIncentivePolicy,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(basePoliciyRule));
  };
  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <VegaText
          text={`Parameter ${index + 1}`}
          fontWeight={500}
          fontSize={13}
          color={'#202020'}
        />
        {!policyId && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Collection parameter'}>
            <VegaSelect
              options={collectionTypeOption()}
              value={bumperInsentive.parameter}
              onChange={e => {
                const value = e.target.value as string;
                handleBumperIncentiveChange('parameter', value);
              }}
              disabled={!!policyId}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Parameter range '}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '33.33%' }}>
                <LmTextField
                  type="number"
                  placeholder="Min"
                  value={bumperInsentive.parameterMin}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleBumperIncentiveChange('parameterMin', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '33.33%' }}>
                <LmTextField
                  type="number"
                  placeholder="Max"
                  value={bumperInsentive.parameterMax}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleBumperIncentiveChange('parameterMax', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '33.33%' }}>
                <VegaSelect
                  options={metricOption()}
                  value={bumperInsentive.parameterMetric}
                  onChange={selected => {
                    handleBumperIncentiveChange(
                      'parameterMetric',
                      selected.target.value as string
                    );
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Payout Metric'}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '50%' }}>
                <LmTextField
                  type="number"
                  value={bumperInsentive.payoutValue}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleBumperIncentiveChange('payoutValue', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <VegaSelect
                  options={maxPayoutTypeOption()}
                  value={bumperInsentive.payoutMetric}
                  onChange={selected => {
                    handleBumperIncentiveChange(
                      'payoutMetric',
                      selected.target.value as string
                    );
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Payout capping (Max)'}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '50%' }}>
                <LmTextField
                  type="number"
                  disabled={!!policyId}
                  value={bumperInsentive.payoutCapMax}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleBumperIncentiveChange('payoutCapMax', value);
                  }}
                />
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <VegaSelect
                  options={maxPayoutTypeOption()}
                  value={bumperInsentive.payoutCapMetric}
                  onChange={selected => {
                    handleBumperIncentiveChange(
                      'payoutCapMetric',
                      selected.target.value as string
                    );
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Days targett'}>
            <LmTextField type="number" disabled={!!policyId} />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </>
  );
};

export default BumperIncentive;

const metricOption = () => {
  const options: VegaSelectOption[] = ['PERCENTAGE'].map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};

const collectionTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(IncentiveCollectionType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};

const maxPayoutTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(
    IncentivePayoutMetrixType
  ).map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};
