import { Grid, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TagIcon } from '../../components/Icons/Icons';
import TrailCard from '../../components/Trail/TrailCard';
import UpdateTrailDrawer, {
  CreateTrailFormData,
} from '../../components/Trail/UpdateTrailDrawer';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaButton from '../../components/common/VegaButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { COMMON } from '../../constants/style';
import { loanRequestSelector, fetchLoanList } from '../../features/loanSlice';
import {
  trailRequestSelector,
  updateTrail,
  fetchTrailList,
} from '../../features/trailSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { LoanData, CustomerDetail } from '../../types/loan';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { DateUtility } from '../../utils/DateUtlility';
import PaymentLinkDrawer from '../../components/Trail/PaymentLinkDrawer';

function TrailDetailPage() {
  const { loanAccountId } = useParams();
  const { setSnackbar } = useSnackbar();
  const {
    open: openUpdateTrailDialog,
    close: closeUpdateTrailDialog,
    isOpen: isUpdateTrailDialogOpen,
    props: updateTrailDialogProps,
  } = useDrawer(false);
  const {
    open: openPaymentLinkDialog,
    close: closePaymentLinkDialog,
    isOpen: isPaymentLinkDialogOpen,
    props: paymentLinkDialogProps,
  } = useDrawer(false);
  const dispatch = useAppDispatch();
  const { trails } = useAppSelector(trailRequestSelector);
  const { loans } = useAppSelector(loanRequestSelector);
  const loan = getFirstItemIfExists<LoanData>(loans);

  async function onUpdateTrailClick(formData: Partial<CreateTrailFormData>) {
    try {
      closeUpdateTrailDialog();
      await dispatch(updateTrail(formData)).unwrap();
      fetchTrail();
      setSnackbar('Update Trail Successfully !!!');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }
  const customerDetail = getFirstItemIfExists<CustomerDetail>(
    loan?.customerDetails
  );

  function fetchTrail() {
    dispatch(fetchTrailList({ loanId: loanAccountId }));
  }

  useEffect(() => {
    dispatch(
      fetchLoanList({
        includeCustomerDetails: true,
        includeLmsVariables: true,
        includeLoanOutstandingDetails: true,
        includeLoanPdcDetails: true,
        includeLoanTransactions: true,
        loanId: loanAccountId,
        size: 10,
      })
    );
  }, []);

  useEffect(() => {
    fetchTrail();
  }, []);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Grid container>
              <Grid item xs={12}>
                <VegaBreadCrumb
                  items={[
                    { label: 'My Accounts', link: ROUTES.Trail_Page },
                    { label: `Account Details` },
                  ]}
                ></VegaBreadCrumb>
              </Grid>
              <Grid xs={12}>
                <Stack direction="row" gap="2rem">
                  <TrailKeyValue
                    heading="Name"
                    value={customerDetail?.customerName}
                  />
                  <TrailKeyValue
                    heading="Loan Account Number"
                    // value="34123451"
                    value={loan?.loanId}
                  />
                  <TrailKeyValue
                    heading="POS"
                    value={
                      loan?.lmsVariables?.outstandingAmount?.toString() ?? '-'
                    }
                  />
                  <TrailKeyValue
                    heading="Bucket"
                    value={loan?.lmsVariables?.bucket ?? '-'}
                  />
                  <TrailKeyValue
                    heading="Contact number"
                    value={customerDetail?.mobileNo?.toString()}
                  />
                  <TrailKeyValue
                    heading="Allocation Date"
                    value={
                      loan?.metaDataLoanAllocationDto?.allocationDate ?? '-'
                    }
                  />
                  <TrailKeyValue
                    heading="Address"
                    value={customerDetail?.customerAddress}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction={'row'}
                  style={{ alignItems: 'center', marginTop: '1.70rem' }}
                  gap="1rem"
                >
                  <VegaText text={'Actions'} />
                  <VegaButton
                    text="Update Trail"
                    variant="outlined"
                    onClick={() => {
                      openUpdateTrailDialog({ loan: loan });
                    }}
                  />
                  <VegaButton
                    text="Send Payment Link"
                    variant="outlined"
                    onClick={() => {
                      openPaymentLinkDialog();
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          );
        }}
      />
      <div
        style={{
          paddingLeft: '0.94rem',
          paddingRight: '0.94rem',
          marginTop: '1rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        {trails?.map(v => {
          return (
            <TrailCard
              key={v.id}
              renderTopView={() => (
                <Grid container>
                  <Grid item xs={11}>
                    <Stack
                      direction={'row'}
                      alignItems={'center'}
                      gap={'0.25rem'}
                    >
                      <TagIcon strokeColor="black" />
                      <Typography variant="subtitle1">Trail Updated</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <VegaText
                      fontWeight={500}
                      fontSize={'0.8125rem'}
                      color={COMMON.light}
                      text={DateUtility.formatStringToDDMMYYYY(
                        v.updateTrailDate
                      )}
                    />
                  </Grid>
                </Grid>
              )}
              renderMiddleView={() => {
                return (
                  <Grid container>
                    <Grid item xs={6}>
                      <TrailCardKeyValue
                        heading={'Comment'}
                        value={v.agentRemarks ?? '-'}
                      />
                    </Grid>
                  </Grid>
                );
              }}
              renderBottomView={() => {
                return (
                  <VegaText
                    style={{
                      paddingLeft: '0.75rem',
                      paddingRight: '0.75rem',
                      marginTop: '0.4rem',
                      marginBottom: '0.4rem',
                    }}
                    text={_.startCase(_.toLower(v.dispositionCode))}
                  />
                );
              }}
            />
          );
        })}
      </div>

      <UpdateTrailDrawer
        onClose={closeUpdateTrailDialog}
        open={isUpdateTrailDialogOpen}
        onUpdateTrailClick={onUpdateTrailClick}
        loanDetail={updateTrailDialogProps.loan}
      />
      <PaymentLinkDrawer
        onClose={closePaymentLinkDialog}
        open={isPaymentLinkDialogOpen}
      />
    </div>
  );
}

export default TrailDetailPage;
type TrailKeyValueProps = {
  heading: string;
  value: string;
};

const TrailKeyValue = ({ heading, value }: TrailKeyValueProps) => {
  return (
    <Stack direction="column" marginTop="1.75rem">
      <VegaText
        text={heading}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.light}
      />
      <VegaText
        text={value}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.balck}
      />
    </Stack>
  );
};
type TrailCardKeyValueProps = {
  heading: string;
  value: string;
};
const TrailCardKeyValue = ({ heading, value }: TrailCardKeyValueProps) => {
  return (
    <Stack direction="column" marginY={'0.75rem'}>
      <VegaText
        text={heading}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.light}
      />
      <VegaText
        text={value}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.balck}
      />
    </Stack>
  );
};

// {
//   /* <TrailCard
//           renderTopView={() => (
//             <Grid container>
//               <Grid item xs={11}>
//                 <Stack direction={'row'} alignItems={'center'} gap={'0.25rem'}>
//                   <TagIcon strokeColor="black" />
//                   <Typography variant="subtitle1">Trail Updated</Typography>
//                 </Stack>
//               </Grid>
//               <Grid item xs={1}>
//                 <VegaText
//                   fontWeight={500}
//                   fontSize={'0.8125rem'}
//                   color={COMMON.light}
//                   text={'12/03/2023'}
//                 />
//               </Grid>
//             </Grid>
//           )}
//           renderMiddleView={() => {
//             return (
//               <Grid container>
//                 <Grid item xs={6}>
//                   <TrailCardKeyValue
//                     heading={'Name'}
//                     value={'visit.customerName'}
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                   <TrailCardKeyValue
//                     heading={'Name'}
//                     value={'visit.customerName'}
//                   />
//                 </Grid>
//               </Grid>
//             );
//           }}
//           renderBottomView={() => {
//             return (
//               <VegaText
//                 style={{
//                   paddingLeft: '0.75rem',
//                   paddingRight: '0.75rem',
//                   marginTop: '0.4rem',
//                   marginBottom: '0.4rem',
//                 }}
//                 text={'Hello'}
//               />
//             );
//           }}
//         />
//         <TrailCard
//           renderTopView={() => (
//             <Grid container>
//               <Grid item xs={11}>
//                 <Stack direction={'row'} alignItems={'center'} gap={'0.25rem'}>
//                   <VisitIcon strokeColor="black" />
//                   <Typography variant="subtitle1">Visit Completed</Typography>
//                 </Stack>
//               </Grid>
//               <Grid item xs={1}>
//                 <VegaText
//                   fontWeight={500}
//                   fontSize={'0.8125rem'}
//                   color={COMMON.light}
//                   text={'12/03/2023'}
//                 />
//               </Grid>
//             </Grid>
//           )}
//           renderMiddleView={() => {
//             return (
//               <Grid container>
//                 <Grid item xs={6}>
//                   <TrailCardKeyValue
//                     heading={'Name'}
//                     value={'visit.customerName'}
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                   <TrailCardKeyValue
//                     heading={'Name'}
//                     value={'visit.customerName'}
//                   />
//                 </Grid>
//               </Grid>
//             );
//           }}
//           renderBottomView={() => {
//             return (
//               <VegaText
//                 style={{
//                   paddingLeft: '0.75rem',
//                   paddingRight: '0.75rem',
//                   marginTop: '0.4rem',
//                   marginBottom: '0.4rem',
//                 }}
//                 text={'Hello'}
//               />
//             );
//           }}
//         />
//         <TrailCard
//           renderTopView={() => (
//             <Grid container>
//               <Grid item xs={11}>
//                 <Stack direction={'row'} alignItems={'center'} gap={'0.25rem'}>
//                   <VisitIcon strokeColor="black" />
//                   <Typography variant="subtitle1">Receipt Created</Typography>
//                 </Stack>
//               </Grid>
//               <Grid item xs={1}>
//                 <VegaText
//                   fontWeight={500}
//                   fontSize={'0.8125rem'}
//                   color={COMMON.light}
//                   text={'12/03/2023'}
//                 />
//               </Grid>
//             </Grid>
//           )}
//           renderMiddleView={() => {
//             return (
//               <Grid container>
//                 <Grid item xs={6}>
//                   <TrailCardKeyValue
//                     heading={'Name'}
//                     value={'visit.customerName'}
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                   <TrailCardKeyValue
//                     heading={'Name'}
//                     value={'visit.customerName'}
//                   />
//                 </Grid>
//               </Grid>
//             );
//           }}
//         />
//         <TrailCard
//           renderTopView={() => (
//             <Grid container>
//               <Grid item xs={11}>
//                 <Stack direction={'row'} alignItems={'center'} gap={'0.25rem'}>
//                   <VisitIcon strokeColor="black" />
//                   <Typography variant="subtitle1">Called</Typography>
//                 </Stack>
//               </Grid>
//               <Grid item xs={1}>
//                 <VegaText
//                   fontWeight={500}
//                   fontSize={'0.8125rem'}
//                   color={COMMON.light}
//                   text={'12/03/2023'}
//                 />
//               </Grid>
//             </Grid>
//           )}
//         /> */
// }
// {
//   /* <TrailCard
//           renderTopView={() => (
//             <Grid container>
//               <Grid item xs={11}>
//                 <Stack direction={'row'} alignItems={'center'} gap={'0.25rem'}>
//                   <VisitIcon strokeColor="black" />
//                   <Typography variant="subtitle1">SMS Sent</Typography>
//                 </Stack>
//               </Grid>
//               <Grid item xs={1}>
//                 <VegaText
//                   fontWeight={500}
//                   fontSize={'0.8125rem'}
//                   color={COMMON.light}
//                   text={'12/03/2023'}
//                 />
//               </Grid>
//             </Grid>
//           )}
//         /> */
// }
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
