import axios from 'axios';
import { Auction } from '../types/auction';
import { GetAuctionListRequest } from '../types/request/auction';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/auction',
  LIST: '/list',
  GET_BY_ID: '/{id}',
};
const BASE_URL = Endpoint.BASE;

export class AuctionService {
  static async getAuctionList(
    request: Partial<GetAuctionListRequest>
  ): Promise<PaginatedResponse<Auction>> {
    const endpoint = BASE_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getAuctionById(
    auctionId: string,
    includePossessionDetails: boolean
  ): Promise<Auction> {
    const endpoint = BASE_URL + Endpoint.GET_BY_ID.replace('{id}', auctionId);
    const response = await axios.get(endpoint, {
      params: { includePossessionDetails: includePossessionDetails },
    });
    return response.data;
  }
}
