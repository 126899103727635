import React from 'react';
import LmDialog from './LmDialog';
import { GREY, RED } from '../../constants/style';
import { InfoIcon, TrashIcon } from '../Icons/Icons';
import VegaText from './VegaText';
import { Close } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import VegaButton from './VegaButton';

interface IDeleteDialogProps {
  text: string;
  subText?: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const VegaDeleteDialog = ({
  text,
  subText,
  open,
  onClose,
  onDelete,
}: IDeleteDialogProps) => {
  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={open}
        handleClose={onClose}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <VegaButton
              fullWidth
              text="Delete"
              sx={{
                color: 'white',
                bgcolor: RED.red,
                ':hover': {
                  color: 'white',
                  bgcolor: RED.red,
                },
              }}
              startIcon={<TrashIcon strokeColor={'white'} />}
              onClick={onDelete}
            />
            <VegaButton
              text="Cancel"
              fullWidth
              variant="outlined"
              sx={{
                color: GREY.dark,
                borderColor: GREY.dark,
                bgcolor: 'white',
                ':hover': {
                  color: GREY.dark,
                  borderColor: GREY.dark,
                  bgcolor: 'white',
                },
              }}
              onClick={onClose}
              startIcon={<Close sx={{ color: GREY.dark }} />}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <InfoIcon />
            <VegaText
              text="Confirmation"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <Box p={4}>
          <VegaText
            text={text}
            fontWeight={600}
            fontSize={17}
            textAlign={'center'}
          />
          {subText && (
            <Typography
              className="font-aspekta-400"
              color={'#999DA8'}
              fontSize={'0.8125rem'}
              lineHeight={'15.6px'}
              mt={1}
            >
              {subText}
            </Typography>
          )}
        </Box>
      </LmDialog>
    </div>
  );
};

export default VegaDeleteDialog;
