import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import { PolicyRangeProps } from '../../../types/request/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  loading?: boolean;
  penaltyIncentive: PolicyRangeProps;
  index: number;
}

const ViewPenaltyIncentive = ({ loading, penaltyIncentive, index }: IProps) => {
  return (
    <Stack gap={1} key={index}>
      <VegaText
        text={`Perameter ${index + 1}`}
        fontWeight={500}
        fontSize={'0.75rem'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Collection parameter'}>
            {getTextLoadingWrapper(
              toLowerCase(penaltyIncentive.parameter),
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Parameter range'}>
            {getTextLoadingWrapper(
              `${penaltyIncentive.parameterMin}-${penaltyIncentive.parameterMax}%`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Penalty Metric'}>
            {getTextLoadingWrapper(`${penaltyIncentive.payoutValue}%`, loading)}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Penalty capping'}>
            {getTextLoadingWrapper(
              `${penaltyIncentive.payoutCapMax}/${toLowerCase(
                penaltyIncentive.payoutCapMetric
              )}`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ViewPenaltyIncentive;

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
  }
};
