import React, { useEffect } from 'react';
import VegaText from '../../common/VegaText';
import { Box, Stack } from '@mui/material';
import VegaButton from '../../common/VegaButton';
import { getOrdinalNumber } from '../../../utils/DateUtlility';
import { PlusCircleIcon } from '../../Icons/Icons';
import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { IncentivePayoutPolicyType } from '../../../types/incentives';
import {
  IncentivePolicyRulesRequest,
  PolicyRangeProps,
} from '../../../types/request/incentives';
import BumperIncentive from './BumperIncentive';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { BucketEnum } from '../../../types/targets';

const bumperPolicy = {
  parameter: '',
  parameterMin: 0,
  parameterMax: 0,
  parameterMetric: '',
  payoutMetric: '',
  payoutCapMetric: '',
  payoutCapMax: 0,
  payoutValue: 0,
  incentivePayoutPolicyType: IncentivePayoutPolicyType.BUMPER,
};

const BumperIncentiveComponent = () => {
  const { policyRuleState, selectedTab } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const isEmptyOrUndefined = (value: any) => {
    return typeof value === 'undefined' || value === null || value === '';
  };

  const isParameterValue = () => {
    const isEmptyValue =
      policyRuleState.bumperPayoutRange.length &&
      policyRuleState?.bumperPayoutRange.some(
        (range: PolicyRangeProps) =>
          isEmptyOrUndefined(range.parameter) ||
          isEmptyOrUndefined(range.parameterMin) ||
          isEmptyOrUndefined(range.parameterMax) ||
          isEmptyOrUndefined(range.parameterMetric) ||
          isEmptyOrUndefined(range.payoutMetric) ||
          isEmptyOrUndefined(range.payoutCapMax) ||
          isEmptyOrUndefined(range.payoutCapMetric) ||
          isEmptyOrUndefined(range.payoutValue)
      );
    return isEmptyValue;
  };

  const disabled = policyRuleState?.bumperPayoutRange && isParameterValue();

  const handlePolicyRule = (
    key: keyof IncentivePolicyRulesRequest,
    value: any
  ) => {
    const policyRuleStateObj = {
      ...policyRuleState,
      [key]: value,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(policyRuleStateObj));
  };

  const onAddBumberIncentive = () => {
    handlePolicyRule('bumperPayoutRange', [
      ...policyRuleState.bumperPayoutRange,
      bumperPolicy,
    ]);
  };
  const onDeleteBumperIncentive = (index: number) => {
    const spreadBumperPoicy = [...policyRuleState.bumperPayoutRange];
    spreadBumperPoicy.splice(index, 1);
    handlePolicyRule('bumperPayoutRange', spreadBumperPoicy);
  };

  const onSubmitPolicy = () => {
    if (idFromURL) {
      IncentiveServices.addRulesForPolicy({
        ...policyRuleState,
        bucket: getBucket(),
        incentiveBasePolicyId: idFromURL,
      })
        .then(response => {
          dispatch(setPolicyDetailsState(response));
          setSnackbar('Add bumper incentive successfully !!!');
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    } else {
      setSnackbar('No incentive base policy id for this bucket', 'error');
    }
  };

  const getBucket = () => {
    if (selectedTab === 1) {
      return BucketEnum.BUCKET1;
    } else if (selectedTab === 2) {
      return BucketEnum.BUCKET2;
    } else if (selectedTab === 3) {
      return BucketEnum.BUCKET2;
    } else if (selectedTab === 4) {
      return BucketEnum.NPA;
    } else {
      return BucketEnum.BUCKETX;
    }
  };

  useEffect(() => {
    if (!policyRuleState.bumperPayoutRange) {
      handlePolicyRule('bumperPayoutRange', []);
    }
  }, []);
  return (
    <>
      <Stack
        gap={2}
        sx={{
          width: '100%',
          p: '1.5rem',
        }}
      >
        <VegaText
          text={'Bumper Incentive'}
          fontWeight={600}
          fontSize={'1.0625rem'}
        />

        {policyRuleState?.bumperPayoutRange &&
          policyRuleState.bumperPayoutRange.map(
            (bumperInsentive: PolicyRangeProps, index: number) => (
              <BumperIncentive
                key={index}
                bumperInsentive={bumperInsentive}
                index={index}
                onDelete={onDeleteBumperIncentive}
              />
            )
          )}
        <Box>
          <VegaButton
            variant="text"
            text={`Add ${
              policyRuleState?.bumperPayoutRange?.length + 1
            }${getOrdinalNumber(
              policyRuleState?.bumperPayoutRange?.length + 1
            )} parameter`}
            startIcon={<PlusCircleIcon />}
            onClick={onAddBumberIncentive}
          />
        </Box>
        <Box textAlign={'end'}>
          <VegaButton
            text={`Save Bumper incentive`}
            onClick={onSubmitPolicy}
            disabled={disabled}
          />
        </Box>
      </Stack>
    </>
  );
};

export default BumperIncentiveComponent;
