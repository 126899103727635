import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  basePolicy,
  getIncentivesGamificationsState,
  setErrorPolicy,
  setPolicyDetailsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IncentivePayoutType } from '../../../types/incentives';
import {
  IncentivePolicyRulesRequest,
  PolicyRangeProps,
} from '../../../types/request/incentives';
import { BucketEnum } from '../../../types/targets';
import { getOrdinalNumber } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { PlusCircleIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';
import BaseIncentive from './BaseIncentive';

interface IProps {
  scrollTop: () => void;
}

const BaseIncentiveComponent = ({ scrollTop }: IProps) => {
  const { policyRuleState, policyEnumValues, selectedTab, loading } =
    useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const handlePolicyRule = (
    key: keyof IncentivePolicyRulesRequest,
    value: any
  ) => {
    const policyRuleStateObj = {
      ...policyRuleState,
      [key]: value,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(policyRuleStateObj));
  };

  const onAddBaseIncentive = () => {
    handlePolicyRule('basePayoutRange', [
      ...policyRuleState.basePayoutRange,
      basePolicy,
    ]);
  };

  const onDeleteBaseIncentive = (index: number) => {
    const spreadBasePoicy = [...policyRuleState.basePayoutRange];
    spreadBasePoicy.splice(index, 1);
    handlePolicyRule('basePayoutRange', spreadBasePoicy);
  };

  const isEmptyOrUndefined = (value: any) => {
    return typeof value === 'undefined' || value === null || value === '';
  };

  const isParameterValue = () => {
    const isEmptyValue =
      policyRuleState.basePayoutRange.length &&
      policyRuleState?.basePayoutRange.some(
        (range: PolicyRangeProps) =>
          isEmptyOrUndefined(range.parameterMin) ||
          isEmptyOrUndefined(range.parameterMax) ||
          isEmptyOrUndefined(range.parameterMetric) ||
          isEmptyOrUndefined(range.payoutValue)
      );
    return isEmptyValue;
  };

  const disabled =
    !policyRuleState?.incentiveType.length ||
    !policyRuleState?.accountType.length ||
    !policyRuleState?.caseType.length ||
    !policyRuleState?.maximumPayoutAmount ||
    !policyRuleState?.maximumPayoutType.length ||
    isParameterValue();

  const onSubmitPolicy = () => {
    if (idFromURL) {
      IncentiveServices.addRulesForPolicy({
        ...policyRuleState,
        bucket: getBucket(),
        incentiveBasePolicyId: idFromURL,
      })
        .then(response => {
          dispatch(setPolicyDetailsState(response));
          setSnackbar('Add base incentive successfully !!!');
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    } else {
      scrollTop();
      dispatch(setErrorPolicy('First fill policy details'));
    }
  };

  const getBucket = () => {
    if (selectedTab === 1) {
      return BucketEnum.BUCKET1;
    } else if (selectedTab === 2) {
      return BucketEnum.BUCKET2;
    } else if (selectedTab === 3) {
      return BucketEnum.BUCKET2;
    } else if (selectedTab === 4) {
      return BucketEnum.NPA;
    } else {
      return BucketEnum.BUCKETX;
    }
  };

  return (
    <Stack
      gap={2}
      sx={{
        width: '100%',
        p: '1.5rem',
      }}
    >
      <VegaText
        text={'Base Incentive'}
        fontWeight={600}
        fontSize={'1.0625rem'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Incentive type'}>
            {loading ? (
              <Skeleton />
            ) : (
              <VegaSelect
                options={payoutMetrixOption(
                  policyEnumValues?.CALCULATION_METRICS
                )}
                value={policyRuleState && policyRuleState.incentiveType}
                onChange={selected => {
                  handlePolicyRule(
                    'incentiveType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'account type'}>
            <VegaSelect
              options={accountTypeOption(policyEnumValues?.ACCOUNT_TYPES)}
              value={policyRuleState && policyRuleState?.accountType}
              onChange={selected => {
                handlePolicyRule(
                  'accountType',
                  selected.target.value as string
                );
              }}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'case type'}>
            <VegaSelect
              options={caseTypeOption(policyEnumValues?.CASE_TYPES)}
              value={policyRuleState && policyRuleState?.caseType}
              onChange={selected => {
                handlePolicyRule('caseType', selected.target.value as string);
              }}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'max-payout'}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '50%' }}>
                <LmTextField
                  type="number"
                  placeholder="Min"
                  value={
                    policyRuleState && policyRuleState?.maximumPayoutAmount
                  }
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handlePolicyRule('maximumPayoutAmount', value);
                  }}
                />
              </Stack>

              <Stack sx={{ width: '50%' }}>
                <VegaSelect
                  options={metricOption()}
                  value={policyRuleState && policyRuleState?.maximumPayoutType}
                  onChange={selected => {
                    handlePolicyRule(
                      'maximumPayoutType',
                      selected.target.value as string
                    );
                  }}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>
      </Grid>
      {policyRuleState?.basePayoutRange &&
        policyRuleState.basePayoutRange.map(
          (baseIncentive: PolicyRangeProps, index: number) => (
            <BaseIncentive
              key={index}
              index={index}
              baseIncentive={baseIncentive}
              onDelete={onDeleteBaseIncentive}
            />
          )
        )}
      <Box>
        <VegaButton
          variant="text"
          text={`Add ${
            policyRuleState?.basePayoutRange?.length + 1
          }${getOrdinalNumber(
            policyRuleState?.basePayoutRange?.length + 1
          )} parameter`}
          startIcon={<PlusCircleIcon />}
          onClick={onAddBaseIncentive}
        />
      </Box>
      <Box textAlign={'end'}>
        <VegaButton
          text={`Save Base incentive`}
          onClick={onSubmitPolicy}
          disabled={disabled}
        />
      </Box>
    </Stack>
  );
};

export default BaseIncentiveComponent;

const metricOption = () => {
  const options: VegaSelectOption[] = Object.keys(IncentivePayoutType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};

const accountTypeOption = (accountTypeEnums: string[]) => {
  if (accountTypeEnums) {
    const options: VegaSelectOption[] = accountTypeEnums.map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  }
};
const caseTypeOption = (caseTypeEnums: string[]) => {
  if (caseTypeEnums) {
    const options: VegaSelectOption[] = caseTypeEnums.map((item: string) => ({
      value: item,
      label: toLowerCase(item),
    }));
    return options;
  }
};

const payoutMetrixOption = (payoutMetrixEnums: string[]) => {
  if (payoutMetrixEnums) {
    const options: VegaSelectOption[] = payoutMetrixEnums.map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  }
};
