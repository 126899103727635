import { Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentiveCollectionType,
  IncentiveComputationBasisType,
  IncentivePayoutMetrixType,
} from '../../../types/incentives';
import {
  IncentivePolicyRulesRequest,
  MinimumEligibilityCriteriaProps,
} from '../../../types/request/incentives';
import { DeleteIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';

interface IProps {
  minimumEligibilityCriteria: Partial<MinimumEligibilityCriteriaProps>;
  onDelete: () => void;
}

const MinimumEligibilityCriteria = ({
  minimumEligibilityCriteria,
  onDelete,
}: IProps) => {
  const { policyRuleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId } = useParams();

  const handleMinimumEligibilityCriteriaChange = (
    key: keyof MinimumEligibilityCriteriaProps,
    value: string | number
  ) => {
    const minimumEligibilityCriteriaObj = {
      ...minimumEligibilityCriteria,
      [key]: value,
    };

    const minimumEligibilityCriteriaRule = {
      ...policyRuleState,
      minimumEligibilityParameters: minimumEligibilityCriteriaObj,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(minimumEligibilityCriteriaRule));
  };
  return (
    <Stack sx={{ borderBottom: BORDER_COLLECTION, pb: '1rem' }} gap={2}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
        {!policyId && (
          <IconButton onClick={() => onDelete()}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'parameter'}>
            <VegaSelect
              options={collectionTypeOption()}
              value={minimumEligibilityCriteria.parameter}
              onChange={e => {
                const value = e.target.value as string;
                handleMinimumEligibilityCriteriaChange('parameter', value);
              }}
              disabled={!!policyId}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Parameter range '}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '50%' }}>
                <LmTextField
                  type="number"
                  value={minimumEligibilityCriteria.minNo}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleMinimumEligibilityCriteriaChange('minNo', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <LmTextField
                  type="number"
                  value={minimumEligibilityCriteria.maxNo}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleMinimumEligibilityCriteriaChange('maxNo', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Metric'}>
            <VegaSelect
              options={maxPayoutTypeOption()}
              value={minimumEligibilityCriteria.metric}
              onChange={e => {
                const value = e.target.value as string;
                handleMinimumEligibilityCriteriaChange('metric', value);
              }}
              disabled={!!policyId}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Computation basis'}>
            <VegaSelect
              options={computationOption()}
              value={minimumEligibilityCriteria.computationBasis}
              onChange={e => {
                const value = e.target.value as string;
                handleMinimumEligibilityCriteriaChange(
                  'computationBasis',
                  value
                );
              }}
              disabled={!!policyId}
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MinimumEligibilityCriteria;

const collectionTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(IncentiveCollectionType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};

const maxPayoutTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(
    IncentivePayoutMetrixType
  ).map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};
const computationOption = () => {
  const options: VegaSelectOption[] = Object.keys(
    IncentiveComputationBasisType
  ).map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};
