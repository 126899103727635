import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { Stack } from '@mui/material';
import { Search } from '@mui/icons-material';
import SlashRtcView from './SlashRtcView';
import { getTeleAgent } from '../../features/teleAgentSlice';
import { useAppSelector, useAppDispatch } from '../../store';
import { useEffect, useState } from 'react';
import { fetchTeleAgentAllocation } from '../../actions/TeleAgentAction';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import { GridColumns } from '@mui/x-data-grid';
import VegaButton from '../../components/common/VegaButton';
import { ROUTES } from '../../router/routes';
import { TeleAgentManualAccountViewModel } from '../../types/teleAgent';
import { useNavigate } from 'react-router-dom';
import LmOutlineTextField from '../../components/common/LmOutlineTextField';
import VegaPageContent from '../../components/common/VegaPageContent';
import { useClientAuth } from '../../providers/ClientProvider';

const TeleAgentAuto = () => {
  const { userId } = useClientAuth();
  const { allocations, totalItems } = useAppSelector(getTeleAgent);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    dispatch(
      fetchTeleAgentAllocation({
        agentId: userId,
        page,
        pageSize: 10,
        teleAllocatonType: 'AUTO',
      })
    );
  }, [dispatch, page]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Auto Call'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmOutlineTextField
              fixWidth={320}
              placeholder="Search"
              name="search"
              startAdornment={
                <Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />
              }
            />
          );
        }}
      />

      <VegaPageContent>
        <Stack
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: '1rem',
            marginTop: '2rem',
          }}
        >
          <VegaDataGrid
            data={allocations}
            idColumn="id"
            paginationMode="server"
            onPageChange={page => setPage(page)}
            rowCount={totalItems}
            columns={getColumnDefinition({
              onViewDetails: account => {
                navigate(
                  ROUTES.TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID.replace(
                    ':loanAccountId',
                    account.lan
                  )
                );
              },
            })}
          />
          <div style={{ flexBasis: '35%', height: '70vh' }}>
            <SlashRtcView />
          </div>
        </Stack>
      </VegaPageContent>
    </div>
  );
};

export default TeleAgentAuto;

const getColumnDefinition = (data: {
  onViewDetails: (account: TeleAgentManualAccountViewModel) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'lan',
      headerName: 'Loan A/c No.',
      flex: 0.7,
    },

    {
      field: 'teleAllocatonType',
      headerName: 'Allocation Type',
      flex: 0.7,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact Number',
      flex: 0.7,
    },
    {
      field: 'allocationDate',
      headerName: 'Allocation Date',
      flex: 0.7,
    },

    {
      field: 'details',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const account = props.row as TeleAgentManualAccountViewModel;
        return (
          <VegaButton
            text={'View Details'}
            variant="text"
            onClick={() => data.onViewDetails(account)}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
