import { Collapse, Grid, Stack } from '@mui/material';
import VegaPageHeader from '../components/common/VegaPageHeader';
import {
  ArrowRightIcon,
  CrossIcon,
  TrendDownIcon,
  TrendUp,
} from '../components/Icons/Icons';
import MonthlyProgressCard from '../components/MonthlyProgress/MonthlyProgressCard';
import LmDialog from '../components/common/LmDialog';
import VegaDialogContent from '../components/common/VegaDialogContent';
import { useDrawer } from '../hooks/useDrawer';
import { GridColumns } from '@mui/x-data-grid';
import VegaButton from '../components/common/VegaButton';
import VegaPageContent from '../components/common/VegaPageContent';
import { COLOR } from '../utils/ColorUtility';
import VegaText from '../components/common/VegaText';
import VegaDataGrid from '../components/common/VegaDataGrid';
import { UPCOMING_PTPS_Table_Row } from '../types/monthlyProgress';
import { useAppDispatch, useAppSelector } from '../store';
import {
  fetchMonthlyProgress,
  monthlyProgressRequestSelector,
} from '../features/monthlyProgressSlice';
import { useEffect } from 'react';

const Monthlyprogress = () => {
  const {
    allIndiaRanking,
    loading,
    pendingVisits,
    targetPerformanceDto,
    totalVisitsCompleted,
  } = useAppSelector(monthlyProgressRequestSelector);
  const dispatch = useAppDispatch();

  const {
    open: openDetailDialog,
    close: closeDetailsDialog,
    isOpen: isDetailsDialogOpen,
  } = useDrawer(false);
  const {
    open: openUpcomingPtsDetail,
    close: closeUpcomingPtsDetail,
    isOpen: isUpcomingPtsDetailOpen,
  } = useDrawer(false);
  const updatedTargetPerformanceDto = targetPerformanceDto.map(
    (item, index) => ({
      ...item,
      id: index,
    })
  );
  useEffect(() => {
    dispatch(
      fetchMonthlyProgress({
        agentId: 'd882b6d5-4fc7-4fde-8cd1-c48ef5b51064',
        month: 10,
        year: 2023,
      })
    );
  }, []);

  return (
    <div className="monthly-progress">
      <VegaPageHeader title="Monthly Progress"></VegaPageHeader>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        pl="2rem"
        pt="2rem"
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MonthlyProgressCard
            achievmentName={'All India Ranking'}
            percentageColor={COLOR.GREEN.DARK}
            achievementCount={allIndiaRanking?.toString() ?? '-'}
            trendDescription={'on the same day last month'}
            trendPercentage="69%"
            trendIcon={() => {
              return <TrendUp />;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MonthlyProgressCard
            achievmentName={'Total Visits'}
            percentageColor={COLOR.GREEN.DARK}
            achievementCount={totalVisitsCompleted?.toString() ?? '-'}
            trendDescription={'increase over the last month'}
            trendPercentage="22.9%"
            trendIcon={() => {
              return <TrendUp />;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MonthlyProgressCard
            achievmentName={'Pending Visits'}
            achievementCount={pendingVisits?.toString() ?? '-'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} onClick={openDetailDialog}>
          <MonthlyProgressCard
            achievmentName={'Overall & Bucket '}
            achievementCount="36%"
            trendDescription={'increase over the last month'}
            percentageColor={COLOR.RED.DARK}
            trendPercentage="12%"
            trendIcon={() => {
              return <TrendDownIcon />;
            }}
            renderRightView={() => {
              return (
                <>
                  <ArrowRightIcon />
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MonthlyProgressCard
            achievmentName={'PTPs Received'}
            percentageColor={COLOR.GREEN.DARK}
            achievementCount="24"
            trendDescription={'increase over the last month'}
            trendPercentage="11.9%"
            trendIcon={() => {
              return <TrendUp />;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} onClick={openUpcomingPtsDetail}>
          <MonthlyProgressCard
            achievmentName={'Upcoming PTPs'}
            achievementCount="124"
            renderRightView={() => {
              return (
                <>
                  <ArrowRightIcon />
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      <LmDialog
        fullWidth
        maxWidth="sm"
        isCloseIcon
        open={isDetailsDialogOpen}
        handleClose={closeDetailsDialog}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Overall & Bucket Wise"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaDataGrid
            idColumn="id"
            columns={OVERALL_AND_BUCKET_COLUMN_DEF}
            data={updatedTargetPerformanceDto}
          />
        </VegaDialogContent>
      </LmDialog>
      <Collapse in={isUpcomingPtsDetailOpen} timeout={500}>
        <VegaPageHeader
          title="Upcoming PTs"
          renderRightView={() => {
            return (
              <VegaButton
                startIcon={<CrossIcon strokeColor="#1047DC" />}
                text="Cancel"
                variant="outlined"
                onClick={closeUpcomingPtsDetail}
              />
            );
          }}
          sx={{ borderBottom: 'none' }}
        ></VegaPageHeader>

        <VegaPageContent>
          <VegaDataGrid
            idColumn="id"
            columns={UPCOMING_PTPS_COLUMN_DEF}
            data={UPCOMING_PTPS_TableData}
          />
        </VegaPageContent>
      </Collapse>
    </div>
  );
};
export default Monthlyprogress;

const OVERALL_AND_BUCKET_COLUMN_DEF: GridColumns = [
  {
    field: 'Bucket',
    headerName: 'Bucket',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Bucket}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'Target',
    headerName: 'Taget(in INR cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Target}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'Achieved',
    headerName: 'Achieved (in INR Cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.Achieved}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: '%Achieved',
    headerName: '% Achieved',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.perAchieved}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
];

// const OVERALL_AND_BUCKET_TableData: OVERALL_AND_BUCKET_Table_Row[] = [
//   {
//     id: '1',
//     Bucket: 'Bucket 1',
//     Target: '100',
//     Achieved: '80',
//     perAchieved: '80',
//   },
//   {
//     id: '2',
//     Bucket: 'Bucket 2',
//     Target: '150',
//     Achieved: '120',
//     perAchieved: '80',
//   },
//   {
//     id: '3',
//     Bucket: 'Bucket 3',
//     Target: '200',
//     Achieved: '160',
//     perAchieved: '80',
//   },
//   {
//     id: '4',
//     Bucket: 'Bucket 4',
//     Target: '250',
//     Achieved: '200',
//     perAchieved: '80',
//   },
// ];

const UPCOMING_PTPS_COLUMN_DEF: GridColumns = [
  {
    field: 'UserName',
    headerName: 'User Name',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.UserName}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'LoanAccountNumber',
    headerName: 'Loan Account Number',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.LoanAccountNumber}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'POS',
    headerName: 'POS (In INR Cr)',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText text={tableData.POS} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'EMI',
    headerName: 'EMI',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText text={tableData.EMI} fontWeight={500} fontSize={'0.875rem'} />
      );
    },
  },
  {
    field: 'LastVisitDate',
    headerName: 'Last Visit Date',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.LastVisitDate}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
  {
    field: 'PTPDate',
    headerName: 'PTP Date',
    flex: 0.7,
    renderCell: props => {
      const tableData = props.row;
      return (
        <VegaText
          text={tableData.PTPDate}
          fontWeight={500}
          fontSize={'0.875rem'}
        />
      );
    },
  },
];

const UPCOMING_PTPS_TableData: UPCOMING_PTPS_Table_Row[] = [
  {
    id: '1',
    UserName: 'John Doe',
    LoanAccountNumber: 'ABC12345',
    POS: 'Store A',
    EMI: '$250',
    LastVisitDate: '2023-09-20',
    PTPDate: '2023-09-25',
  },
  {
    id: '2',
    UserName: 'Jane Smith',
    LoanAccountNumber: 'XYZ98765',
    POS: 'Store B',
    EMI: '$150',
    LastVisitDate: '2023-09-18',
    PTPDate: '2023-09-23',
  },
  {
    id: '3',
    UserName: 'Bob Johnson',
    LoanAccountNumber: 'PQR54321',
    POS: 'Store C',
    EMI: '$300',
    LastVisitDate: '2023-09-19',
    PTPDate: '2023-09-24',
  },
];
