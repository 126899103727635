/* eslint-disable no-unused-vars */
import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { ExpenseService } from '../../Apis/ExpenseService';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { useSnackbar } from '../../providers/SnackbarProvider';
import {
  ExpenseClaim,
  ExpenseClaimAction,
  ExpenseClaimAudit,
  ExpenseReportViewModel,
} from '../../types/claim';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import VegaKeyValueDisplay from '../common/VegaKeyValueDisplay';
import { AttachmentIcon } from '../Icons/Icons';

type Props = {
  show: boolean;
  onClose: () => void;
  claim?: ExpenseClaim;
  onReapplyClick: (claim: ExpenseClaim) => void;
  onAttachmentClick: (claim: ExpenseClaim) => void;
};

function ClaimDetailsDialog({
  show,
  onClose,
  claim,
  onAttachmentClick,
  onReapplyClick,
}: Props) {
  const { setSnackbar } = useSnackbar();
  const { fetchReport: fetchReportById } = useExpenseApi();
  const [loadingAudits, setLoadingAudits] = useState<boolean>(false);
  const [latestAudit, setLatestAudit] = useState<ExpenseClaimAudit | null>(
    null
  );
  const [report, setReport] = useState<ExpenseReportViewModel | null>(null);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);

  async function fetchReport(reportId: string) {
    try {
      setLoadingReport(true);
      const response = await fetchReportById({
        reportId: reportId,
        includeApprover: true,
      });
      setReport(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingReport(false);
    }
  }

  async function fetchAudits(claimId: string) {
    try {
      setLoadingAudits(true);
      const response = await ExpenseService.getClaimAudits({
        claimId: claimId,
      });
      const audits = response.records;
      setLatestAudit(audits[audits.length - 1]);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoadingAudits(false);
    }
  }

  function closeDialog() {
    setLatestAudit(null);
    setReport(null);
    onClose();
  }

  function onReapply() {
    if (!claim) return;
    setLatestAudit(null);
    onReapplyClick(claim);
  }

  const getReportApproverName = () => {
    const approver = report?.approver;
    return StringUtility.concatenateStrings(
      approver?.firstName,
      approver?.middleName,
      approver?.lastName
    );
  };

  useEffect(() => {
    if (show == true && claim != null) {
      fetchAudits(claim.id);
      fetchReport(claim.reportId);
    }
  }, [show, claim]);

  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={show}
      isCloseIcon
      handleClose={closeDialog}
      buttonComponent={() => <></>}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="View Claim Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <Box px={4} pt={3} pb={4}>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading={'Expense Type'}
              value={claim?.expenseType}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Timestamp"
              value={DateUtility.formatStringToDDMMYYYY(claim?.createdAt)}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay heading="Total Amount" value={claim?.amount} />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Expenditure Date"
              value={DateUtility.formatStringToDDMMYYYY(claim?.dateOfExpense)}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Status"
              renderValue={() => {
                const color = getColorForStatus(claim?.status);
                return (
                  <VegaText
                    text={claim?.status ?? ''}
                    fontWeight={500}
                    fontSize={'0.8125rem'}
                    color={color?.DARK}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Document"
              renderValue={() => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (!claim) return;
                      onAttachmentClick(claim);
                    }}
                  >
                    <AttachmentIcon />
                    <VegaText
                      text={'Attachment'}
                      fontWeight={500}
                      color={'#1047DC'}
                      fontSize={'0.875rem'}
                    />
                  </div>
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Approver Name"
              value={getReportApproverName()}
              loading={loadingReport}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display:
                claim?.status == ExpenseClaimAction.REJECTED
                  ? 'default'
                  : 'none',
            }}
          >
            <VegaKeyValueDisplay
              heading="Reason"
              value={latestAudit?.actionDetails}
              loading={loadingAudits}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display:
                claim?.status == ExpenseClaimAction.REJECTED
                  ? 'default'
                  : 'none',
            }}
          >
            <LmButton text={'Reapply'} onClick={onReapply} />
          </Grid>
        </Grid>
      </Box>
    </LmDialog>
  );
}

export default ClaimDetailsDialog;

const getColorForStatus = (status?: ExpenseClaimAction) => {
  if (!status) return;
  switch (status) {
    case ExpenseClaimAction.ACCEPTED:
      return COLOR.GREEN;
    case ExpenseClaimAction.PENDING:
      return COLOR.ORANGE;
    case ExpenseClaimAction.REJECTED:
      return COLOR.RED;
  }
};
