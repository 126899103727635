import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';

type Props = {
  renderBottomView?: () => ReactNode;
  renderTopView?: () => ReactNode;
  renderMiddleView?: () => ReactNode;
};

function TrailCard({
  renderMiddleView,
  renderBottomView,
  renderTopView,
}: Props) {
  return (
    <div>
      <div
        style={{
          border: 1,
          borderColor: '#E1E4EB',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'stretch',
          background: '#FFF',
          borderStyle: 'solid',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          borderBottomLeftRadius: renderBottomView ? '0px' : '0.375rem',
          borderBottomRightRadius: renderBottomView ? '0px' : '0.375rem',
        }}
      >
        <Grid
          container
          alignItems="center"
          style={{
            paddingLeft: '0.75rem',
            paddingRight: '0.75rem',
            marginTop: '0.75rem',
            marginBottom: renderBottomView ? '0px' : '0.375rem',
          }}
        >
          {renderTopView && (
            <Grid item xs={12}>
              {renderTopView()}
            </Grid>
          )}

          {renderMiddleView && (
            <Grid item xs={12}>
              {renderMiddleView()}
            </Grid>
          )}
        </Grid>
      </div>
      {renderBottomView && (
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              borderBottomLeftRadius: '0.375rem',
              borderBottomRightRadius: '0.375rem',
              backgroundColor: '#FAFAFA',
              borderStyle: 'solid',
              borderColor: '#E1E4EB',
              marginTop: '-1px',
            }}
          >
            {renderBottomView()}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default TrailCard;
