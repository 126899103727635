import { Box, Stack } from '@mui/material';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  getIncentivesGamificationsState,
  setSelectedPayoutPolicyType,
} from '../../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import VegaPageContent from '../../common/VegaPageContent';
import VegaText from '../../common/VegaText';
import BaseIncentiveComponent from '../components/BaseIncentiveComponent';
import BumperIncentiveComponent from '../components/BumperIncentiveComponent';
import MinimumEligibilityCriteriaComponent from '../components/MinimumEligibilityCriteriaComponent';
import PenaltyIncentiveComponent from '../components/PenaltyIncentiveComponent';

export enum PayoutPolicyType {
  BASE = 'Base Incentive',
  BUMPER = 'Bumper Incentive',
  PANALTY = 'Penalty Incentive',
  MIN_ELIGIBILITY = 'Minimum Eligibility Criteria',
}

interface IProps {
  scrollTop: () => void;
}

const BucketComponent = ({ scrollTop }: IProps) => {
  const { selectedPayoutPolicyType } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();

  const onSelectPayoutPolicyType = (value: string) => {
    dispatch(setSelectedPayoutPolicyType(value));
  };

  return (
    <VegaPageContent>
      <Stack
        sx={{
          borderRadius: '8px',
          border: BORDER_COLLECTION,
          bgcolor: 'white',
        }}
        direction={'row'}
      >
        <Stack
          sx={{
            maxWidth: '190px',
            borderRight: BORDER_COLLECTION,
          }}
        >
          {Object.keys(PayoutPolicyType).map((value: PayoutPolicyType) => {
            return (
              <Box
                key={value}
                sx={{
                  p: 2,
                  borderBottom:
                    selectedPayoutPolicyType === PayoutPolicyType[value]
                      ? `1px solid #1047DC`
                      : BORDER_COLLECTION,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  onSelectPayoutPolicyType(PayoutPolicyType[value])
                }
              >
                <VegaText
                  text={PayoutPolicyType[value]}
                  fontWeight={500}
                  fontSize={12}
                  color={
                    selectedPayoutPolicyType === PayoutPolicyType[value]
                      ? '#1047DC'
                      : '#676B76'
                  }
                />
              </Box>
            );
          })}
        </Stack>
        <Stack sx={{ width: '100%' }}>
          {selectedPayoutPolicyType === PayoutPolicyType.BUMPER ? (
            <BumperIncentiveComponent />
          ) : selectedPayoutPolicyType === PayoutPolicyType.PANALTY ? (
            <PenaltyIncentiveComponent />
          ) : selectedPayoutPolicyType === PayoutPolicyType.MIN_ELIGIBILITY ? (
            <MinimumEligibilityCriteriaComponent />
          ) : (
            <BaseIncentiveComponent scrollTop={scrollTop} />
          )}
        </Stack>
      </Stack>
    </VegaPageContent>
  );
};

export default BucketComponent;
