import {
  CalendarPicker,
  CalendarPickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

interface ICelanderProps extends CalendarPickerProps<Dayjs> {}

export const VegaDateCalender = ({ ...rest }: ICelanderProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CalendarPicker
        className="Celander"
        date={rest.date}
        onChange={rest.onChange}
      />
    </LocalizationProvider>
  );
};
