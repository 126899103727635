import axios from 'axios';
import { ApiService } from '../types/api';
import { IncentivePolicyProps } from '../types/incentives';
import {
  GetPolicyRequest,
  IncentivePolicyRulesRequest,
  PolicyDetailsRequest,
} from '../types/request/incentives';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/incentives',
  POLICY: '/policy',
  LIST: '/list',
  RULES: '/rules',
  POLICY_BY_ID: '/:id',
  ENUM_LIST: '/enums/list/values',
};

const INCENTIVE_BASE_URL = Endpoint.BASE + Endpoint.POLICY;

export class IncentiveServices extends ApiService {
  static async getPolicyEnum(): Promise<{ key: string[] }> {
    const endpoint = Endpoint.BASE + Endpoint.ENUM_LIST;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getPolicies(
    request: Partial<GetPolicyRequest>
  ): Promise<PaginatedResponse<IncentivePolicyProps>> {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getPolicyByPolicyId(
    policyId: string
  ): Promise<IncentivePolicyProps> {
    const endpoint =
      INCENTIVE_BASE_URL + Endpoint.POLICY_BY_ID.replace(':id', policyId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async addPolicy(
    request: Partial<PolicyDetailsRequest>
  ): Promise<IncentivePolicyProps> {
    const endpoint = INCENTIVE_BASE_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async addRulesForPolicy(
    request: Partial<IncentivePolicyRulesRequest>
  ): Promise<IncentivePolicyProps> {
    const endpoint = INCENTIVE_BASE_URL + Endpoint.RULES;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
