import { Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import { BORDER_COLLECTION } from '../../../constants/style';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentivePolicyRulesRequest,
  PolicyRangeProps,
} from '../../../types/request/incentives';
import { DeleteIcon, RupeeIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';

interface IBaseIncentiveProps {
  baseIncentive: PolicyRangeProps;
  index: number;
  onDelete?: (index: number) => void;
}

const BaseIncentive = ({
  baseIncentive,
  index,
  onDelete,
}: IBaseIncentiveProps) => {
  const { policyRuleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId } = useParams();

  const handleBaseIncentiveChange = (
    key: keyof PolicyRangeProps,
    value: string | number
  ) => {
    const spreadBaseIncentive = [...policyRuleState.basePayoutRange];
    const baseIncentiveObj = {
      ...baseIncentive,
      [key]: value,
    };
    spreadBaseIncentive.splice(index, 1, baseIncentiveObj);
    const basePoliciyRule = {
      ...policyRuleState,
      basePayoutRange: spreadBaseIncentive,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(basePoliciyRule));
  };
  return (
    <Stack sx={{ borderBottom: BORDER_COLLECTION, py: '1rem' }} gap={2}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <VegaText
          text={`Parameter ${index + 1}`}
          fontWeight={500}
          fontSize={13}
          color={'#202020'}
        />
        {!policyId && index !== 0 && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'value'}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '33.33%' }}>
                <LmTextField
                  type="number"
                  placeholder="Min"
                  value={baseIncentive.parameterMin}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleBaseIncentiveChange('parameterMin', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '33.33%' }}>
                <LmTextField
                  type="number"
                  placeholder="Max"
                  value={baseIncentive.parameterMax}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handleBaseIncentiveChange('parameterMax', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '33.33%' }}>
                <VegaSelect
                  options={metricOption()}
                  value={baseIncentive.parameterMetric}
                  onChange={selected => {
                    handleBaseIncentiveChange(
                      'parameterMetric',
                      selected.target.value as string
                    );
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Incentive Amount'}>
            <LmTextField
              type="number"
              startAdornment={<RupeeIcon />}
              value={baseIncentive.payoutValue}
              onChange={e => {
                const value = e.target.value && parseFloat(e.target.value);
                handleBaseIncentiveChange('payoutValue', value);
              }}
              disabled={!!policyId}
            />
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default BaseIncentive;

const metricOption = () => {
  const options: VegaSelectOption[] = ['PERCENTAGE'].map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};
