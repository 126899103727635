import Dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

// extending dayjs plugins
Dayjs.extend(utc);
Dayjs.extend(timezone);
Dayjs.extend(isYesterday);
Dayjs.extend(isToday);
Dayjs.extend(isTomorrow);
Dayjs.extend(isBetween);
Dayjs.extend(isSameOrBefore);
Dayjs.extend(isSameOrAfter);
Dayjs.extend(weekday);
Dayjs.extend(advancedFormat);
Dayjs.extend(customParseFormat);
Dayjs.extend(duration);
Dayjs.extend(minMax);
Dayjs.extend(relativeTime);

export const getDayjsInstance = (date: Date | Dayjs.Dayjs) =>
  Dayjs.isDayjs(date) ? date : Dayjs(date);

export const formatDate = (dateStr: string): string => {
  return Dayjs(new Date(dateStr)).format('DD-MMM-YYYY hh:mm a');
};

export const getDateString = (dateTime: any) => {
  return Dayjs(dateTime).format('DD-MM-YYYY');
};

export const getTimeString = (dateTime: any): string => {
  return Dayjs(dateTime).format('kk:mm:ss');
};

export const getDateTimeString = (
  dateTime: Date | Dayjs.Dayjs,
  format = ''
): string => {
  return getDayjsInstance(dateTime).format(format);
};

export const formatDateTime = (dateTime: Date): string => {
  return Dayjs(dateTime).format('ddd, D MMM, hh:mm a');
};

export const isCurrentMonth = (dateTime: Dayjs.Dayjs | Date): boolean => {
  const dateInstance = !Dayjs.isDayjs(dateTime) ? Dayjs(dateTime) : dateTime;

  return dateInstance.isSame(Dayjs(), 'month');
};
export { Dayjs };
