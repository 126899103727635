import { Box } from '@mui/material';
import { useEffect, useState, useRef, RefObject } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../Apis/IncentivesService';
import PolicyDetails from '../../components/IncentivesGamifications/components/PolicyDetails';
import BucketComponent, {
  PayoutPolicyType,
} from '../../components/IncentivesGamifications/modules/BucketComponent';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import {
  DEFAULT_RULE,
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyEnumValues,
  setPolicyRuleState,
  setSelectedPayoutPolicyType,
  setSelectedTab,
} from '../../features/IncentivesGamificationsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  IncentivePolicyProps,
  PayoutConfiguration,
} from '../../types/incentives';
import { IncentivePolicyRulesRequest } from '../../types/request/incentives';
import { BucketEnum } from '../../types/targets';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaText from '../../components/common/VegaText';

const AddOnPolicy = () => {
  const scrollToTopRef: RefObject<HTMLDivElement> = useRef(null);
  const { policyDetailsState, selectedTab, errorPolicy } = useAppSelector(
    getIncentivesGamificationsState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { policyId } = useParams();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const scrollToTop = () => {
    scrollToTopRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleBucketChange = (bucket: BucketEnum) => {
    const policyRule = {
      ...DEFAULT_RULE,
      bucket,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(policyRule));
  };

  const filterBucketRule = (
    rules: Partial<PayoutConfiguration>,
    bucket: BucketEnum
  ) => {
    if (Object.keys(rules).length !== 0) {
      // eslint-disable-next-line no-prototype-builtins
      if (rules.hasOwnProperty(bucket)) {
        dispatch(setPolicyRuleState(rules[bucket]));
      } else {
        dispatch(setPolicyRuleState(DEFAULT_RULE));
      }
    } else {
      dispatch(setPolicyRuleState(DEFAULT_RULE));
    }
  };

  const onSelectBucket = (selected: number) => {
    dispatch(setSelectedTab(selected));
    if (!policyId && !idFromURL) {
      if (selected === 1) {
        handleBucketChange(BucketEnum.BUCKET1);
      } else if (selected === 2) {
        handleBucketChange(BucketEnum.BUCKET2);
      } else if (selected === 3) {
        handleBucketChange(BucketEnum.BUCKET2);
      } else if (selected === 4) {
        handleBucketChange(BucketEnum.NPA);
      } else {
        handleBucketChange(BucketEnum.BUCKETX);
      }
    } else {
      if (selected === 1) {
        filterBucketRule(policyDetailsState.rules, BucketEnum.BUCKET1);
      } else if (selected === 2) {
        filterBucketRule(policyDetailsState.rules, BucketEnum.BUCKET2);
      } else if (selected === 3) {
        filterBucketRule(policyDetailsState.rules, BucketEnum.BUCKET3);
      } else if (selected === 4) {
        filterBucketRule(policyDetailsState.rules, BucketEnum.NPA);
      } else {
        filterBucketRule(policyDetailsState.rules, BucketEnum.BUCKETX);
      }
    }
  };

  const getPolicyById = (id: string) => {
    IncentiveServices.getPolicyByPolicyId(id)
      .then(res => {
        const policyDetails: Partial<IncentivePolicyProps> = {
          policyName: res.policyName,
          vertical: res.vertical,
          channelType: res.channelType,
          incentiveType: res.incentiveType,
          policyStartDate: res.policyStartDate,
          policyEndDate: res.policyEndDate,
          accountType: res.accountType,
          payoutFrequency: res.payoutFrequency,
          maxPayoutPerPerson: res.maxPayoutPerPerson,
          userType: res.userType,
          modeOfCommunication: res.modeOfCommunication,
          rules: res.rules,
        };

        dispatch(setPolicyDetailsState(policyDetails));
        filterBucketRule(policyDetails.rules, BucketEnum.BUCKETX);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  const getPolicyEnumList = () => {
    IncentiveServices.getPolicyEnum()
      .then(res => {
        dispatch(setPolicyEnumValues(res));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  useEffect(() => {
    if (policyId) {
      setLoading(true);
      getPolicyById(policyId);
    }
    if (idFromURL) {
      setLoading(true);
      getPolicyById(idFromURL);
    }
  }, [policyId, idFromURL]);
  useEffect(() => {
    getPolicyEnumList();
  }, []);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                {
                  label: 'Live Policy',
                  link: ROUTES.INCENTIVES_GAMIFICATIONS,
                },
                {
                  label: !policyId
                    ? `Add On Policy`
                    : policyDetailsState?.policyName,
                },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <Box
        height={`calc(100% - 77px)`}
        sx={{
          overflow: 'auto',
        }}
        ref={scrollToTopRef}
      >
        <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
          {errorPolicy.length ? (
            <Box
              sx={{
                borderRadius: '8px',
                border: '1px solid #BA1C1C',
                p: 2,
                bgcolor: '#F8E8E8',
                mb: '1rem',
              }}
            >
              <VegaText
                text={errorPolicy}
                fontWeight={500}
                fontSize={'0.75rem'}
                fontColor={'#BA1C1C'}
              />
            </Box>
          ) : (
            ''
          )}
          <VegaTitleCard title="Policy Details">
            <PolicyDetails loading={loading} />
          </VegaTitleCard>
        </VegaPageContent>
        <VegaPageHeader
          sx={{ paddingTop: 0, borderBottom: 'none' }}
          renderLeftView={() => {
            return (
              <VegaTabBar
                value={selectedTab}
                onChange={(e, selected) => {
                  onSelectBucket(selected);
                  dispatch(setSelectedPayoutPolicyType(PayoutPolicyType.BASE));
                }}
              >
                {Object.keys(BucketEnum).map((bucket: string) => (
                  <VegaTabBarItem
                    key={bucket}
                    label={`${getBucketLabel(bucket)}`}
                  />
                ))}
              </VegaTabBar>
            );
          }}
        />
        <BucketComponent scrollTop={scrollToTop} />
      </Box>
    </>
  );
};

export default AddOnPolicy;

const getBucketLabel = (bucket: string) => {
  switch (bucket) {
    case BucketEnum.BUCKET1:
      return 'Bucket 1-30';
    case BucketEnum.BUCKET2:
      return 'Bucket 31-60';
    case BucketEnum.BUCKET3:
      return 'Bucket 61-90';
    case BucketEnum.NPA:
      return 'Bucket NPA';

    default:
      return 'Bucket X';
  }
};
