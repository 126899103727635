import { Stack } from '@mui/system';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createRule,
  fetchAllocationRules,
} from '../../actions/AllocationActions';
import CreateRuleDrawer from '../../components/Allocation/CreateRuleDrawer';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import { useDrawer } from '../../hooks/useDrawer';
import { AppDispatch, RootState } from '../../store';
import { AllocationRuleViewModel } from '../../types/allocations';
import ExecuteAllocationRuleDrawer, {
  ExecuteAllocationFormData,
} from '../../components/Allocation/ExecuteAllocationRuleDrawer';
import ViewRuleDrawer from '../../components/Allocation/ViewRuleDrawer';
import { AllocationService } from '../../Apis/AllocationService';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../utils/api';

function AutoAllocationPolicy() {
  const dispatch = useDispatch<AppDispatch>();
  const { setSnackbar } = useSnackbar();
  const allocationRules = useSelector<RootState, AllocationRuleViewModel[]>(
    state => state.allocations.allocationRuleViewModels
  );
  const loading = useSelector<RootState, boolean>(
    state => state.allocations.loadingAllocationRules
  );
  const totalItems = useSelector<RootState, number>(
    state => state.allocations.allocationRuleTotalItems
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const {
    open: openCreateRuleDrawer,
    close: closeCreateRuleDrawer,
    isOpen: isCreateRuleDrawer,
  } = useDrawer();

  const {
    open: openViewRuleDrawer,
    close: closeViewRuleDrawer,
    isOpen: isViewRuleDrawer,
    props: viewRuleDrawerProps,
  } = useDrawer();

  const {
    open: openExecuteRulesDrawer,
    close: closeExecuteRulesDrawer,
    isOpen: isExecuteRulesDrawerOpen,
  } = useDrawer(false);
  const handleSelectionModelChange = (selectionModel: string[]) => {
    setSelectedRows(selectionModel);
  };

  async function onAllocateClick(formData: Partial<ExecuteAllocationFormData>) {
    try {
      closeExecuteRulesDrawer();
      const selectedRules = [...selectedRows];
      if (selectedRows.length == 1) {
        const rule = selectedRules[0];
        await AllocationService.executeCriteria({
          criteriaId: rule,
          agentId: formData.agentId,
          allocationTypes: formData.allocationType,
          expiresAt: formData.expiryDate,
        });
      }
      setSnackbar('Rule Executed On Loan Accounts.');
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setSelectedRows([]);
    }
  }

  useEffect(() => {
    dispatch(fetchAllocationRules({ page: currentPage }));
  }, [currentPage]);

  return (
    <div>
      <VegaPageHeader
        renderRightView={() => {
          return (
            <Stack direction={'row'} spacing="0.5rem">
              <VegaButton
                text={'Execute'}
                variant="outlined"
                disabled={selectedRows.length != 1}
                onClick={() => openExecuteRulesDrawer()}
              />
              <VegaButton
                text={'Create Rule'}
                onClick={() => openCreateRuleDrawer()}
              />
            </Stack>
          );
        }}
      />
      <VegaPageHeader
        title="Auto Allocation Policy"
        sx={{ borderBottom: 'none' }}
        renderRightView={() => {
          return <LmFilterSection />;
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={allocationRules}
          loading={loading}
          columns={getColumnDefinition({
            onViewDetails: rule => {
              openViewRuleDrawer({ rule: rule });
            },
          })}
          idColumn="id"
          rowCount={totalItems}
          page={currentPage}
          checkboxSelection
          pageSize={10}
          onPageChange={page => setCurrentPage(page)}
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </VegaPageContent>

      <ExecuteAllocationRuleDrawer
        onAllocateClick={onAllocateClick}
        onClose={closeExecuteRulesDrawer}
        open={isExecuteRulesDrawerOpen}
      />

      <CreateRuleDrawer
        open={isCreateRuleDrawer}
        onClose={function (): void {
          closeCreateRuleDrawer();
        }}
        onSubmit={formData => {
          dispatch(createRule(formData));
        }}
      />

      <ViewRuleDrawer
        open={isViewRuleDrawer}
        rule={viewRuleDrawerProps.rule}
        onClose={function (): void {
          closeViewRuleDrawer();
        }}
        onSubmit={function (): void {
          closeViewRuleDrawer();
        }}
      />
    </div>
  );
}

export default AutoAllocationPolicy;

const getColumnDefinition = (data: {
  onViewDetails: (allocation: AllocationRuleViewModel) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'ruleName',
      headerName: 'Rule Name',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        const displayText = rule.name;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'agentId',
      headerName: 'Agent ID',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        const displayText = rule.agentId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'createdBy',
    //   headerName: 'Created By',
    //   flex: 0.7,
    //   renderCell: props => {
    //     const rule = getAllocationRuleForRow(props);
    //     const displayText = rule.createdBy;
    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'criteriaCount',
      headerName: 'Criteria Count',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        const displayText = rule.criteriaList.length;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const rule = getAllocationRuleForRow(props);
        return (
          <VegaButton
            text={'View Details'}
            variant="text"
            onClick={() => data.onViewDetails(rule)}
          />
        );
      },
    },
  ];
  return COL_DEF;
};

const getAllocationRuleForRow = (props: any) =>
  props.row as AllocationRuleViewModel;
