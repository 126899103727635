/* eslint-disable no-useless-escape */
import { Clear, Done } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

const SPECIAL_CHARS = `^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~  + =`;

const getValidationItem = (
  isValue: boolean,
  text: string,
  isSpecial = false
) => {
  return (
    <>
      <Typography
        variant="caption"
        sx={{
          ...(!isValue ? { color: 'red' } : { color: 'green' }),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!isValue ? (
          <Clear color="error" fontSize="small" sx={{ mr: 1 }} />
        ) : (
          <Done color="success" fontSize="small" sx={{ mr: 1 }} />
        )}
        {text}
      </Typography>
      {isSpecial && (
        <Typography
          variant="caption"
          sx={{
            ...(!isValue ? { color: 'red' } : { color: 'green' }),
            ml: 3,
          }}
        >
          {SPECIAL_CHARS}
        </Typography>
      )}
    </>
  );
};

function usePassword() {
  const [password, setPassword] = useState('');

  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const minLengthRegExp = /.{8,}/;

  const passwordLength = password.length;
  const uppercasePassword = uppercaseRegExp.test(password);
  const lowercasePassword = lowercaseRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);

  const isValidPassword = () => {
    if (
      passwordLength &&
      uppercasePassword &&
      lowercasePassword &&
      digitsPassword &&
      specialCharPassword &&
      minLengthPassword
    ) {
      return true;
    }
    return false;
  };

  const renderValidations = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
        {getValidationItem(passwordLength !== 0, 'Password is empty')}
        {getValidationItem(minLengthPassword, '8-character minimum length')}
        {getValidationItem(
          uppercasePassword,
          'Contains at least 1 uppercase letter'
        )}
        {getValidationItem(
          lowercasePassword,
          'Contains at least 1 lowercase letter'
        )}
        {getValidationItem(digitsPassword, 'Contains at least 1 number')}
        {getValidationItem(
          specialCharPassword,
          'Contains at least 1 special character from the following set, or a non-leading, non-trailing space character',
          true
        )}
      </Box>
    );
  };

  return {
    password,
    setPassword,
    isValidPassword,
    renderValidations,
  };
}

export default usePassword;
