import { OutlinedInput, OutlinedInputProps } from '@mui/material';

interface IProps extends OutlinedInputProps {
  fixWidth?: number | string;
}

const LmOutlineTextField = ({ fixWidth, ...rest }: IProps) => {
  return (
    <OutlinedInput
      {...rest}
      sx={{
        height: '35px',
        width: fixWidth ?? 'auto',
        fontFamily: `var(--font-Aspekta-500) !important`,
        borderRadius: '6px',
        '&.MuiOutlinedInput-root': {
          bgcolor: 'white !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EBEBEB',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EBEBEB',
          borderWidth: '1px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#EBEBEB',
          borderWidth: '1px',
        },
      }}
    />
  );
};

export default LmOutlineTextField;
