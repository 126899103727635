import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserService } from '../Apis/UserService';
import { RootState } from '../store';
import { VegaUser } from '../types/claim';

export interface UserState {
  loading: boolean;
  agents: VegaUser[];
  page: number;
  totalItems: number;
  pageSize: number;
  error: string | null;
}

const initialState: UserState = {
  agents: [],
  loading: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
};

export class UserActions {
  static fetchAgents = createAsyncThunk('agent/fetch', async () => {
    const response = await UserService.getUsers({});
    return response;
  });
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(UserActions.fetchAgents.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UserActions.fetchAgents.fulfilled, (state, action) => {
        state.loading = false;
        state.agents = [...action.payload.records];
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(UserActions.fetchAgents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});

export default userSlice;

export const getUsers = (state: RootState) => state.user;
