import { Grid } from '@mui/material';
import LmTextField from '../../../components/common/LmTextField';
import VegaDatePicker from '../../../components/common/VegaDatePicker';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import {
  receiptState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { DDPayment, IssueReceiptProps } from '../../../types/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';

const DDPaymentDetails = () => {
  const { issueReceipt } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();

  const chequeDetails = issueReceipt?.paymentMeta as DDPayment;
  const onReceiptChange = (key: keyof IssueReceiptProps, value: DDPayment) => {
    const paymentDetailsObj = {
      ...issueReceipt,
      [key]: value,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(paymentDetailsObj));
  };

  const onDDPaymentChange = (key: keyof DDPayment, value: string) => {
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const DDPaymentDetailsObj = {
      ...paymentSpread,
      [key]: value,
    } as DDPayment;
    onReceiptChange('paymentMeta', DDPaymentDetailsObj);
  };

  const onDDDateChange = (key: keyof DDPayment, value: any) => {
    const dateToIsoString =
      DateUtility.parseDateFromDatePicker(value)?.toISOString();
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const DDPaymentDetailsObj = {
      ...paymentSpread,
      [key]: dateToIsoString,
    } as DDPayment;
    onReceiptChange('paymentMeta', DDPaymentDetailsObj);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'dd number'}>
          <LmTextField
            type="text"
            value={chequeDetails?.ddNumber}
            onChange={e => {
              const value = e.target.value;
              onDDPaymentChange('ddNumber', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'dd date'}>
          <VegaDatePicker
            type="text"
            value={chequeDetails?.ddDate}
            onChange={e => {
              onDDDateChange('ddDate', e);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'micr'}>
          <LmTextField
            type="text"
            value={chequeDetails?.micr}
            onChange={e => {
              onDDPaymentChange('micr', e.target.value);
            }}
          />
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default DDPaymentDetails;
