import { Stack } from '@mui/material';
import NoResults from '../../../components/common/NoResults';
import VegaButton from '../../../components/common/VegaButton';
import VegaPageContent from '../../../components/common/VegaPageContent';
import {
  getTargetState,
  setBucketDetails,
  setOpenApproveTargetDialog,
  setOpenRejectTargetDialog,
} from '../../../features/targetSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TargetListProps, TargetsStatus } from '../../../types/targets';
import { COLOR } from '../../../utils/ColorUtility';
import ApproveTargetDialog from './ApproveTargetDialog';
import RejectTargetDialog from './RejectTargetDialog';
import TargetBucketCard from './TargetBucketCard';

const PendingTargetList = () => {
  const { targets } = useAppSelector(getTargetState);
  const dispatch = useAppDispatch();

  const onOpenApproveDialog = (bucket: TargetListProps) => {
    dispatch(setOpenApproveTargetDialog(true));
    dispatch(setBucketDetails(bucket));
  };

  const onOpenRejectDialog = (bucket: TargetListProps) => {
    dispatch(setOpenRejectTargetDialog(true));
    dispatch(setBucketDetails(bucket));
  };
  return (
    <>
      <VegaPageContent sx={{ mt: '1rem' }}>
        {targets.length ? (
          <TargetBucketCard
            isBucketStatus
            bucketData={targets.filter(
              (target: TargetListProps) =>
                target.targetStatus === TargetsStatus.RAISED
            )}
            renderButtons={(bucket: TargetListProps) => {
              return (
                <Stack
                  direction={'row'}
                  gap={2}
                  justifyContent={'end'}
                  mt={'1rem'}
                >
                  <VegaButton
                    text="Approve"
                    sx={{
                      backgroundColor: COLOR.GREEN.LIGHT,
                      color: COLOR.GREEN.DARK,
                      ':hover': {
                        backgroundColor: COLOR.GREEN.LIGHT,
                        color: COLOR.GREEN.DARK,
                      },
                    }}
                    onClick={() => onOpenApproveDialog(bucket)}
                  />
                  <VegaButton
                    text="Reject"
                    sx={{
                      backgroundColor: COLOR.RED.LIGHT,
                      color: COLOR.RED.DARK,
                      ':hover': {
                        backgroundColor: COLOR.RED.LIGHT,
                        color: COLOR.RED.DARK,
                      },
                    }}
                    onClick={() => onOpenRejectDialog(bucket)}
                  />
                </Stack>
              );
            }}
          />
        ) : (
          <NoResults sx={{ height: 'calc(100% - 142px)' }} />
        )}
      </VegaPageContent>
      <ApproveTargetDialog />
      <RejectTargetDialog />
    </>
  );
};

export default PendingTargetList;
