/** @format */

import { createContext, useContext, useEffect, useState } from 'react';
import {
  createHolidayType,
  deleteHolidayType,
  fetchHolidayTypeList,
  updateHolidayType,
} from '../Apis/holiday';
import {
  ICreateHolidayTypeProps,
  IHolidayTypeListProps,
} from '../types/holiday';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSnackbar } from './SnackbarProvider';

export interface IHoliayTypeListStateProps {
  loading: boolean;
  records: IHolidayTypeListProps[];
}

export interface HolidayTypeContextType {
  search: string;
  selectedHolidayType: IHolidayTypeListProps | null;
  isAdd: boolean;
  holidayTypeListState: IHoliayTypeListStateProps;
  holidayTypeState: ICreateHolidayTypeProps | null;
  toggleAddHolidayTypeDrawer: () => void;
  getHolidayTypeList: () => void;
  onAddHolidayTypeSubmit: () => void;
  onDeleteHolidayType: () => void;
  onUpdateHolidayTypeSubmit: (
    updatedHolidayTypeState: ICreateHolidayTypeProps
  ) => void;
  handleAddHolidayTypeChange: (
    name: keyof ICreateHolidayTypeProps,
    value: string | Date
  ) => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedHolidayType: React.Dispatch<
    React.SetStateAction<IHolidayTypeListProps | null>
  >;
  setHolidayTypeState: React.Dispatch<
    React.SetStateAction<ICreateHolidayTypeProps | null>
  >;
}

export const HolidayTypeContext = createContext<HolidayTypeContextType | null>(
  null
);

const HolidayTypeProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [selectedHolidayType, setSelectedHolidayType] =
    useState<IHolidayTypeListProps | null>(null);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [holidayTypeState, setHolidayTypeState] =
    useState<ICreateHolidayTypeProps | null>(null);
  const [holidayTypeListState, setHolidayTypeListState] =
    useState<IHoliayTypeListStateProps>({
      loading: false,
      records: [],
    });

  const toggleAddHolidayTypeDrawer = () => {
    setIsAdd(!isAdd);
  };

  const onAddHolidayTypeSubmit = async () => {
    if (!holidayTypeState) return;
    try {
      await createHolidayType(holidayTypeState);
      setSnackbar('Holiday type Added');
      toggleAddHolidayTypeDrawer();
      getHolidayTypeList();
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to add Holiday type'),
        'error'
      );
    }
  };
  const onUpdateHolidayTypeSubmit = async (
    updatedHolidayTypeState: ICreateHolidayTypeProps
  ) => {
    try {
      await updateHolidayType(updatedHolidayTypeState);
      toggleAddHolidayTypeDrawer();
      setSelectedHolidayType(null);
      setSnackbar('Holiday type Updated');
      getHolidayTypeList();
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to update Holiday type'),
        'error'
      );
    }
  };

  const onDeleteHolidayType = async () => {
    try {
      if (!selectedHolidayType) return;
      await deleteHolidayType(selectedHolidayType.id);
      setSelectedHolidayType(null);
      setSnackbar('Holiday type deleted');
      getHolidayTypeList();
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to dalete Holiday type'),
        'error'
      );
    }
  };

  const handleAddHolidayTypeChange = (
    name: keyof ICreateHolidayTypeProps,
    value: string | Date
  ) => {
    setHolidayTypeState({ ...holidayTypeState, [name]: value });
  };

  const updateHolidayTypeListState = (
    key: keyof IHoliayTypeListStateProps,
    value: boolean | IHolidayTypeListProps[]
  ) => {
    setHolidayTypeListState({ ...holidayTypeListState, [key]: value });
  };

  const getHolidayTypeList = async () => {
    updateHolidayTypeListState('loading', true);
    try {
      const response = await fetchHolidayTypeList();

      updateHolidayTypeListState('loading', false);
      updateHolidayTypeListState('records', response.records);
    } catch (error) {
      updateHolidayTypeListState('loading', false);
    }
  };

  useEffect(() => {
    getHolidayTypeList();
  }, []);

  return (
    <HolidayTypeContext.Provider
      value={{
        selectedHolidayType,
        search,
        isAdd,
        holidayTypeListState,
        holidayTypeState,
        handleAddHolidayTypeChange,
        toggleAddHolidayTypeDrawer,
        getHolidayTypeList,
        onDeleteHolidayType,
        onAddHolidayTypeSubmit,
        onUpdateHolidayTypeSubmit,
        setSearch,
        setSelectedHolidayType,
        setHolidayTypeState,
      }}
    >
      {children}
    </HolidayTypeContext.Provider>
  );
};

export const useHolidayType = () =>
  useContext(HolidayTypeContext) as HolidayTypeContextType;

export default HolidayTypeProvider;
