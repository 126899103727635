import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TargetPerformanceDto } from '../types/monthlyProgress';
import { MonthlyProgressService } from '../Apis/MonthlyProgressService';
import { MonthlyProgressRequest } from '../types/request/monthlyProgress';

interface MonthlyProgressActionState {
  allIndiaRanking: number;
  totalVisitsCompleted: number;
  pendingVisits: number;
  targetPerformanceDto: TargetPerformanceDto[];
  loading: boolean;
}

export const fetchMonthlyProgress = createAsyncThunk(
  'calender/monthly',
  async (request: Partial<MonthlyProgressRequest>) => {
    const currentMonthProgress =
      await MonthlyProgressService.getMonthlyProgress(request);
    const lastMonthProgress = await MonthlyProgressService.getMonthlyProgress({
      agentId: request.agentId,
      month: request.month - 1,
      year: request.year,
    });
    const percentageChangeInVisit = calculatePercentageChange(
      currentMonthProgress.totalVisitsCompleted,
      lastMonthProgress.totalVisitsCompleted
    );
    const currentMonthOverAllAndBucketPercentage =
      calculateAverageTargetAchievedPercentage(currentMonthProgress);
    const prevMonthOverAllAndBucketPercentage =
      calculateAverageTargetAchievedPercentage(currentMonthProgress);
    const overAllAndBucketPercentage = calculateOverAllAndBucketPercentage(
      currentMonthOverAllAndBucketPercentage,
      prevMonthOverAllAndBucketPercentage
    );
    // const changeInRanking = () => {
    //   const change =
    //     currentMonthProgress.allIndiaRanking -
    //     lastMonthProgress.allIndiaRanking;
    //   return change;
    // };

    return {
      ...currentMonthProgress,
      percentageChangeInVisit,
      overAllAndBucketPercentage,
      currentMonthOverAllAndBucketPercentage,
      // changeInRanking,
    };
  }
);

const initialState: MonthlyProgressActionState = {
  loading: false,
  allIndiaRanking: 0,
  totalVisitsCompleted: 0,
  pendingVisits: 0,
  targetPerformanceDto: [],
};

const monthlyProgressSlice = createSlice({
  name: 'monthlyProgressAction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMonthlyProgress.pending, state => {
        state.loading = true;
      })
      .addCase(fetchMonthlyProgress.fulfilled, (state, action) => {
        state.allIndiaRanking = action.payload.allIndiaRanking;
        state.pendingVisits = action.payload.pendingVisits;
        state.targetPerformanceDto = action.payload.targetPerformanceDto;
        state.totalVisitsCompleted = action.payload.totalVisitsCompleted;
        state.loading = false;
      })
      .addCase(fetchMonthlyProgress.rejected, state => {
        state.loading = false;
      });
  },
});

export const monthlyProgressRequestSelector = (state: RootState) =>
  state.monthlyProgressAction;
export default monthlyProgressSlice;
function calculatePercentageChange(currentData, lastMonthData) {
  // if (!currentData || !lastMonthData) {
  //   return null;
  // }

  const current = currentData;
  const lastMonth = lastMonthData;

  const change = current - lastMonth;

  const percentageChange = (change / lastMonth) * 100;
  return percentageChange.toFixed(2);

  // If last month's value is 0, handle it to avoid division by zero
  // if (current !== 0) {
  //   return 'Infinity';
  // } else {
  //   return '0.00'; // No change
  // }
}

function calculateOverAllAndBucketPercentage(
  lastMonthPercentage,
  currentMonthPercentage
) {
  // if (typeof lastMonthPercentage !== 'number' || typeof currentMonthPercentage !== 'number') {
  //   return null; // Handle missing or invalid data
  // }
  // if (lastMonthPercentage === 0) {
  //   return 'Infinity';
  // }

  const change = currentMonthPercentage - lastMonthPercentage;
  const percentageChange = (change / Math.abs(lastMonthPercentage)) * 100;
  return percentageChange.toFixed(2);
}
function calculateAverageTargetAchievedPercentage(currentMonthProgress) {
  const targetPerformanceDto = currentMonthProgress.targetPerformanceDto;

  let totalPercentage = 0;
  let validCount = 0;

  targetPerformanceDto.forEach(item => {
    if (item && typeof item.targetAchievedPercentage === 'number') {
      totalPercentage += item.targetAchievedPercentage;
      validCount++;
    }
  });

  if (validCount === 0) {
    return null;
  }

  const averagePercentage = totalPercentage / validCount;
  return averagePercentage.toFixed(2);
}
