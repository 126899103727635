import { Box, Grid, Stack } from '@mui/material';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaKeyValueDisplay from '../../../components/common/VegaKeyValueDisplay';
import VegaText from '../../../components/common/VegaText';
import { receiptState } from '../../../features/receiptManagmentSlice';
import { useAppSelector } from '../../../store';
import { getPillColorForStatus } from '../../../types/receiptManagment';

interface IProps {
  isAgent?: boolean;
}

const ReceiptDetails = ({ isAgent }: IProps) => {
  const { receiptData } = useAppSelector(receiptState);
  return (
    <VegaDialogContent>
      <Grid container rowGap={'0.75rem'}>
        <Grid item xs={12} marginBottom="0.75rem">
          <VegaText
            text={'Details'}
            style={{
              color: '#222',
              fontSize: '0.875rem',
              fontWeight: 600,
              lineHeight: '120%',
            }}
          />
        </Grid>
        {isAgent && (
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading={'Agent Name'}
              value={receiptData.agentName}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <VegaKeyValueDisplay
            heading="Loan Account Number"
            value={receiptData.lan}
          />
        </Grid>
        <Grid item xs={6}>
          <VegaKeyValueDisplay
            heading="Receipt ID"
            value={receiptData.receiptId}
          />
        </Grid>
        <Grid item xs={6}>
          <VegaKeyValueDisplay
            heading="Receipt Date"
            value={receiptData.receiptDate}
          />
        </Grid>
        <Grid item xs={6}>
          <VegaKeyValueDisplay
            heading="Payment mode"
            value={receiptData.paymentMode}
          />
        </Grid>
        <Grid item xs={6}>
          <VegaKeyValueDisplay
            heading="Status"
            value={receiptData.status}
            renderValue={() => {
              const color = getPillColorForStatus(receiptData.status);
              return (
                <VegaText
                  text={receiptData.status}
                  fontWeight={500}
                  fontSize={'0.8125rem'}
                  color={color.DARK}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Box>
        <VegaText
          text={'Proof'}
          sx={{
            my: '2rem',
            color: '#222',
            fontSize: '0.875rem',
            fontWeight: 600,
            lineHeight: '120%',
          }}
        />
        <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
          {receiptData.proof &&
            receiptData.proof?.map((proof: string) => (
              <Box sx={{ maxWidth: '100px' }} key={proof}>
                <img
                  src={proof}
                  alt=""
                  width={'100%'}
                  style={{ borderRadius: '6px' }}
                />
              </Box>
            ))}
        </Stack>
      </Box>
    </VegaDialogContent>
  );
};

export default ReceiptDetails;
