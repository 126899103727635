import React from 'react';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import { Grid, Stack } from '@mui/material';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';

const PaymentHistoryDetails = () => {
  return (
    <TeleAgentDetailsCard text="Payment History Details" isBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Bounce on Cycle Dates" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Bounce Reason" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Charges levied for bounce" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Other Charges" value="NA" />
          </Grid>
        </Grid>
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default PaymentHistoryDetails;
