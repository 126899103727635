import { Stack, Grid } from '@mui/material';
import VegaText from '../common/VegaText';

export const AuctionCooldownTimer = (data: {
  hours: number;
  minutes: number;
  seconds: number;
}) => {
  return (
    <div
      style={{
        borderRadius: '0.5rem',
        border: '1px solid rgba(225, 228, 235, 1)',
        padding: '1.25rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        <VegaText text={'Cooldown Period'} />
        <Grid container columnGap={'0.75rem'}>
          <Grid item>
            <TimeUnitCard time={data.hours} description="Hours" />
          </Grid>
          <Grid item>
            <TimeUnitCard time={data.minutes} description="Minutes" />
          </Grid>
          <Grid item>
            <TimeUnitCard time={data.seconds} description="Seconds" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const TimeUnitCard = (data: { time: number; description: string }) => {
  return (
    <Stack spacing={'0.5rem'} alignItems="center">
      <div
        style={{
          padding: '1rem',
          borderRadius: '0.2rem',
          border: '1px solid rgba(225, 228, 235, 1)',
        }}
      >
        <VegaText variant="h3" text={data.time} />
      </div>
      <VegaText
        text={data.description}
        style={{
          fontSize: '0.75rem',
          color: '#1B1D22',
          fontWeight: 400,
          lineHeight: '130%',
        }}
      />
    </Stack>
  );
};
