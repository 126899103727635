import { Stack } from '@mui/material';
import React, { useState } from 'react';
import VegaButton from '../common/VegaButton';
import VegaPageContent from '../common/VegaPageContent';
import VegaText from '../common/VegaText';
import MoveToFutureFormDrawer from './MoveToFutureFormDrawer';
import MoveToTodayDialog from './MoveToTodayDialog';
import SendPaymentLinkFormDrawer from './SendPaymentLinkFormDrawer';
import UpdateTrailFormDrawer from './UpdateTrailFormDrawer';

export interface IActionProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountActionContainer = () => {
  const [openMoveToFutureDateDrawer, setOpenMoveToFutureDateDrawer] =
    useState<boolean>(false);
  const [openMoveToTodayDialog, setOpenMoveToTodayDialog] =
    useState<boolean>(false);
  const [openPaymentLinkDrawer, setOpenPaymentLinkDrawer] =
    useState<boolean>(false);
  const [openUpdateTrialDrawer, setOpenUpdateTrialDrawer] =
    useState<boolean>(false);

  return (
    <>
      <VegaPageContent sx={{ pt: '1.5rem' }}>
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <VegaText
            fontWeight={700}
            sx={{
              textTransform: 'uppercase',
              fontSize: '0.625rem',
              letterSpacing: '0.07em',
              color: '#676B76',
            }}
            text={'action'}
          />
          <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
            <VegaButton
              variant="outlined"
              text="Send Payment Link"
              onClick={() => setOpenPaymentLinkDrawer(true)}
            />
            <VegaButton
              variant="outlined"
              text="Move to Today"
              onClick={() => setOpenMoveToTodayDialog(true)}
            />
            <VegaButton
              variant="outlined"
              text="Move to Future Date"
              onClick={() => setOpenMoveToFutureDateDrawer(true)}
            />
            <VegaButton
              variant="outlined"
              text="Update Trail"
              onClick={() => setOpenUpdateTrialDrawer(true)}
            />
            <VegaButton variant="outlined" text="Update Contact Details" />
          </Stack>
        </Stack>
      </VegaPageContent>
      <SendPaymentLinkFormDrawer
        open={openPaymentLinkDrawer}
        setOpen={setOpenPaymentLinkDrawer}
      />
      <MoveToTodayDialog
        open={openMoveToTodayDialog}
        setOpen={setOpenMoveToTodayDialog}
      />
      <MoveToFutureFormDrawer
        open={openMoveToFutureDateDrawer}
        setOpen={setOpenMoveToFutureDateDrawer}
      />
      <UpdateTrailFormDrawer
        open={openUpdateTrialDrawer}
        setOpen={setOpenUpdateTrialDrawer}
      />
    </>
  );
};

export default AccountActionContainer;
