/* eslint-disable no-empty */
import { Box, Stack } from '@mui/material';
import { toLowerCase } from '../../../../constants/commonFunction';
import { BLUE } from '../../../../constants/style';
import { RolesProps } from '../../../../providers/UAMProvider';
import VegaText from '../../../common/VegaText';
import ArrowComponent from './ArrowComponent';
import ManagerComponent from './Manager/ManagerComponent';
import ManagerDropdownComponent from './Manager/ManagerDropdownComponent';
import RoleChip from './Role/RoleChip';
import RoleComponent from './Role/RoleComponent';

interface IProps {
  branch: RolesProps;
  index: number;
}

const DataRestrictionDropdown = ({ branch, index }: IProps) => {
  return (
    <Box position={'relative'}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          border: `1px solid #C5C8D0`,
          borderRadius: '6px',
          pl: 2,
          pr: 1.5,
          py: 0.7,
        }}
        gap={1}
      >
        <Box width={'50%'}>
          <VegaText
            text={toLowerCase(branch.entityId)}
            fontWeight={450}
            fontSize={'0.8125rem'}
          />
        </Box>
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'end'}
          gap={1}
        >
          <Stack direction={'row'} gap={1}>
            {branch.roles.name ? (
              <RoleChip index={index} role={branch.roles} />
            ) : (
              <VegaText
                text={'Assign Roles'}
                fontWeight={600}
                fontSize={'0.625rem'}
                color={BLUE.lighter}
              />
            )}
          </Stack>
          {branch.roles.name ? (
            <ManagerDropdownComponent
              index={index}
              branchManagerName={branch.managers.name}
              branchRoleId={branch.roles.id}
              branchEntityId={branch.entityId}
            />
          ) : (
            <ArrowComponent entityId={branch.entityId} />
          )}
        </Stack>
      </Stack>
      <RoleComponent
        branchEntityId={branch.entityId}
        branchRoleId={branch.roles.id}
        branchIndex={index}
      />

      <ManagerComponent branchRoleId={branch.roles.id} />
    </Box>
  );
};

export default DataRestrictionDropdown;
