import { Grid } from '@mui/material';
import LmTextField from '../../../components/common/LmTextField';
import VegaDatePicker from '../../../components/common/VegaDatePicker';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import {
  receiptState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ElectronicPayment,
  IssueReceiptProps,
} from '../../../types/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';

const ElectronicPaymentDetails = () => {
  const { issueReceipt } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();

  const chequeDetails = issueReceipt?.paymentMeta as ElectronicPayment;
  const onReceiptChange = (
    key: keyof IssueReceiptProps,
    value: ElectronicPayment
  ) => {
    const paymentDetailsObj = {
      ...issueReceipt,
      [key]: value,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(paymentDetailsObj));
  };

  const onDDPaymentChange = (key: keyof ElectronicPayment, value: string) => {
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const electronicsPaymentDetailsObj = {
      ...paymentSpread,
      [key]: value,
    } as ElectronicPayment;
    onReceiptChange('paymentMeta', electronicsPaymentDetailsObj);
  };

  const onDDDateChange = (key: keyof ElectronicPayment, value: any) => {
    const dateToIsoString =
      DateUtility.parseDateFromDatePicker(value)?.toISOString();
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const electronicsPaymentDetailsObj = {
      ...paymentSpread,
      [key]: dateToIsoString,
    } as ElectronicPayment;
    onReceiptChange('paymentMeta', electronicsPaymentDetailsObj);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'REFERENCE number'}>
          <LmTextField
            type="text"
            value={chequeDetails?.refNumber}
            onChange={e => {
              const value = e.target.value;
              onDDPaymentChange('refNumber', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'REFERENCE date'}>
          <VegaDatePicker
            type="text"
            value={chequeDetails?.refDate}
            onChange={e => {
              onDDDateChange('refDate', e);
            }}
          />
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default ElectronicPaymentDetails;
