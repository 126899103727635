import { Search } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toLowerCase } from '../../constants/commonFunction';
import { PRIMARY } from '../../constants/style';
import {
  getIncentivesGamificationsState,
  getPolicies,
} from '../../features/IncentivesGamificationsSlice';
import { getHeaderText } from '../../pages/IncentivesGamifications/IncentivesGamifications';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  IncentivePolicyReviewStatus,
  ViewIncentiesGamificationsModal,
} from '../../types/incentives';
import { GetPolicyRequest } from '../../types/request/incentives';
import { DateUtility } from '../../utils/DateUtlility';
import LmOutlineTextField from '../common/LmOutlineTextField';
import VegaButton from '../common/VegaButton';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaPageContent from '../common/VegaPageContent';
import VegaPageHeader from '../common/VegaPageHeader';
import { VegaPill } from '../common/VegaPill';
import VegaText from '../common/VegaText';

interface IProps {
  selectedPolicyTab: number;
}

const AFTER_TODAY = DateUtility.addDays(new Date(), 1);
const BEFORE_TODAY = DateUtility.previousDays(new Date(), 1);

const getStatus = (selected: number) => {
  switch (selected) {
    case 1:
      return '';
    case 2:
      return `${IncentivePolicyReviewStatus.DRAFT}`;
    case 3:
      return '';
    case 4:
      return '';

    default:
      return IncentivePolicyReviewStatus.ACTIVE;
  }
};

const ListOfPolicies = ({ selectedPolicyTab }: IProps) => {
  const { policies, loading, totalItems } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<string>('');

  const getFilteredPoliciesList = () => {
    const request: Partial<GetPolicyRequest> = {
      page,
      pageSize,
    };
    if (searchValue.length) {
      request.name = searchValue;
    }
    if (selectedPolicyTab === 0 || selectedPolicyTab === 2) {
      request.status = getStatus(selectedPolicyTab);
    } else if (selectedPolicyTab === 1) {
      request.startDateAfter = DateUtility.formatStringToYYYYMMDD(
        AFTER_TODAY.toISOString()
      );
    } else if (selectedPolicyTab === 3) {
      request.endDateBefore = DateUtility.formatStringToYYYYMMDD(
        BEFORE_TODAY.toISOString()
      );
    }
    dispatch(getPolicies(request));
  };

  useEffect(() => {
    const getPolicies = setTimeout(getFilteredPoliciesList, 300);
    return () => clearTimeout(getPolicies);
  }, [dispatch, page, pageSize, selectedPolicyTab, searchValue]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={toLowerCase(getHeaderText(selectedPolicyTab))}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return (
            <LmOutlineTextField
              fixWidth={320}
              placeholder="Search"
              name="search"
              startAdornment={
                <Search sx={{ color: '#999DA8', fontSize: 16, mr: 1 }} />
              }
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          );
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={policies}
          idColumn="id"
          paginationMode="client"
          loading={loading}
          rowCount={totalItems}
          onPageChange={page => setPage(page)}
          onPageSizeChange={pageSize => setPageSize(pageSize)}
          columns={getColumnDefinition({
            onViewDetails: (policy: ViewIncentiesGamificationsModal) => {
              navigate(
                ROUTES.INCENTIVES_GAMIFICATIONS_VIEW.replace(
                  ':policyId',
                  policy.id
                )
              );
            },
          })}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfPolicies;

const getColumnDefinition = (data: {
  onViewDetails: (policy: ViewIncentiesGamificationsModal) => void;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const COL_DEF: GridColumns = [
    {
      field: 'policyName',
      headerName: 'Policy Name',
      flex: 1,
    },
    {
      field: 'vertical',
      headerName: 'Vertical',
      flex: 0.7,
    },

    {
      field: 'channelType',
      headerName: 'Channel Type',
      flex: 0.7,
    },
    {
      field: 'userType',
      headerName: 'User Type',
      flex: 0.7,
      renderCell: props => {
        const policy = props.row as ViewIncentiesGamificationsModal;
        const userType = policy.userType;
        const users = userType.filter((value: string) => userType[0] !== value);
        return (
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <VegaPill
              text={userType[0]}
              borderColor={PRIMARY.darkBlue}
              textColor={PRIMARY.darkBlue}
              backgroundColor={'#E7EDFC'}
              isDot={false}
            />
            {userType && userType?.length > 2 && (
              <div
                style={{ position: 'relative' }}
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
              >
                <VegaText
                  text={` + ${users.length}`}
                  sx={{ cursor: 'pointer' }}
                />
                {show && (
                  <Stack
                    sx={{
                      position: 'absolute',
                      bottom: 15,
                      bgcolor: 'black',
                      color: 'white',
                      px: 1,
                      borderRadius: '6px',
                    }}
                  >
                    {users?.join(', ')}
                  </Stack>
                )}
              </div>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'payoutFrequency',
      headerName: 'Payout Frequency',
      flex: 0.7,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 0.7,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 0.7,
    },

    {
      field: 'details',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const policy = props.row as ViewIncentiesGamificationsModal;
        return (
          <VegaButton
            text={'View Details'}
            variant="text"
            onClick={() => data.onViewDetails(policy)}
          />
        );
      },
    },
  ];
  return COL_DEF;
};
