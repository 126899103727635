import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { FetchVisitListRequest } from '../types/request/visit';
import { FetchVisitsListResponse } from '../types/visits';
import { VisitService } from '../Apis/VisitService';

// interface VisitRequestActionState {}

export const fetchVisitList = createAsyncThunk(
  'visitaction/fetchVisits',
  async (request: Partial<FetchVisitListRequest>) => {
    return await VisitService.getVisitList(request);
  }
);

const initialState: Partial<FetchVisitsListResponse> = {};

const visitSlice = createSlice({
  name: 'visitaction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchVisitList.pending, state => {})
      .addCase(fetchVisitList.fulfilled, (state, action) => {
        const response = getFirstItemIfExists<FetchVisitsListResponse>(
          action?.payload
        );

        state.loanId = response?.loanId;
        state.visit = response?.visit;
        state.loans = response?.loans;
      })
      .addCase(fetchVisitList.rejected, state => {});
  },
});
export const VisitRequestSelector = (state: RootState) => state.visit;
export default visitSlice;
function getFirstItemIfExists<T>(arr: T[]) {
  if ((arr ?? []).length <= 0) return;
  return arr?.[0];
}
