import React from 'react';
import { CreateApplyLeaveFormData } from '../../types/leave';
import VegaDrawer from '../common/VegaDrawer';
import CreateLeaveForm from './CreateLeaveForm';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<CreateApplyLeaveFormData>) => void;
};
function CreateLeaveDrawer({ open, onClose, onSubmit }: Props) {
  function _onSubmit(formData: Partial<CreateApplyLeaveFormData>) {
    onSubmit(formData);
    onClose();
  }
  return (
    <VegaDrawer open={open} onClose={onClose} title="Apply Leave">
      <CreateLeaveForm
        onSubmit={function (formData: Partial<CreateApplyLeaveFormData>): void {
          _onSubmit(formData);
        }}
      />
    </VegaDrawer>
  );
}

export default CreateLeaveDrawer;
