export class EnumUtility {
  static getEnumValueName<E>(enumType: E, index: number): string | undefined {
    const enumKeys = Object.keys(enumType).filter(
      key => typeof enumType[key as keyof typeof enumType] === 'number'
    );
    if (index >= 0 && index < enumKeys.length) {
      return enumKeys[index];
    } else {
      return undefined; // Index is out of range
    }
  }
}
