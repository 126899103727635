import { Close } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import { RED, GREY } from '../../constants/style';
import { StringUtility } from '../../utils/StringUtility';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import { TrashIcon, InfoIcon } from '../Icons/Icons';

type Props = {
  show: boolean;
  claimId?: string;
  onDeleteClick: () => void;
  onCancelClick: () => void;
};
function DeleteExpenseConfirmationDialog({
  show,
  onCancelClick,
  onDeleteClick,
  claimId,
}: Props) {
  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={onCancelClick}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <LmButton
            fullWidth
            text="Delete"
            bgColor={RED.red}
            startIcon={<TrashIcon strokeColor={'white'} />}
            onClick={onDeleteClick}
          />
          <LmButton
            text="Cancel"
            fullWidth
            variant="outlined"
            bgColor=""
            textColor={GREY.dark}
            onClick={onCancelClick}
            startIcon={<Close sx={{ color: GREY.dark }} />}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#F8E8E8'}
        >
          <InfoIcon />
          <VegaText
            text="Confirmation"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={RED.red}
          />
        </Stack>
      )}
    >
      <Box p={4}>
        <VegaText
          text="Are you sure you want to delete this expense ?"
          fontWeight={600}
          fontSize={17}
        />
        <Typography
          className="font-aspekta-400"
          color={'#999DA8'}
          fontSize={'0.8125rem'}
          lineHeight={'15.6px'}
          mt={1}
        >
          {`This action cannot be undone. This will permanently delete the Claim
          ID #${StringUtility.extractLastNDigits(4, claimId)} instance.`}
        </Typography>
      </Box>
    </LmDialog>
  );
}

export default DeleteExpenseConfirmationDialog;
