import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReceiptManagmentService } from '../Apis/ReceiptManagmentService';
import { RootState } from '../store';
import { LoanData } from '../types/loan';
import {
  AccountAgentViewModel,
  IssueReceiptProps,
  ReceiptManagmentViewModel,
  ReceiptsListProps,
} from '../types/receiptManagment';
import { GetReceiptsListRequest } from '../types/request/receiptManagment';
import { DateUtility } from '../utils/DateUtlility';
import { LoanListRequest } from '../types/request/loan';
import { LoanService } from '../Apis/LoanServices';
import { StringUtility } from '../utils/StringUtility';

const issueReceiptData = {
  agentId: '',
  loanId: '',
  mobileNumber: '',
  email: '',
  paymentType: '',
  documentImage: null,
  payeeName: '',
  relationshipWithCustomer: '',
  totalAmount: 0,
  paymentMeta: {},
  panCard: '',
  remarks: '',
};

export interface IErrorProps {
  email: string;
  mobileNumber: string;
}

export interface IErrorStateProps {
  error: IErrorProps;
  isValid: boolean;
}

const errorState = {
  error: {
    mobileNumber: '',
    email: '',
  },
  isValid: false,
} as IErrorStateProps;

export interface ReceiptState {
  receipts: ReceiptManagmentViewModel[];
  loanAccounts: AccountAgentViewModel[];
  issueReceipt: IssueReceiptProps;
  receiptData: Partial<ReceiptManagmentViewModel>;
  page: number;
  errorState: IErrorStateProps;
  totalItems: number;
  pageSize: number;
  openApproval: boolean;
  openReject: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: ReceiptState = {
  receipts: [],
  loanAccounts: [],
  receiptData: {},
  errorState: errorState,
  issueReceipt: issueReceiptData,
  loading: false,
  openApproval: false,
  openReject: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
};

export const getReceiptsList = createAsyncThunk(
  'receipt/list',
  async (data: Partial<GetReceiptsListRequest>) => {
    const response = await ReceiptManagmentService.getReceiptsList(data);
    return response;
  }
);
export const getLoanAccountList = createAsyncThunk(
  'receipt/loan/list',
  async (data: LoanListRequest) => {
    const response = await LoanService.getLoanList(data);
    return response;
  }
);

const receiptManagmentSlice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    setErrorState: (state: ReceiptState, action) => {
      const { payload } = action;
      state.errorState = payload;
    },
    setReceiptData: (state: ReceiptState, action) => {
      const { payload } = action;
      state.receiptData = payload;
    },
    setIssueReceipt: (state: ReceiptState, action) => {
      const { payload } = action;
      state.issueReceipt = payload;
    },
    setOpenApproval: (state: ReceiptState, action) => {
      const { payload } = action;
      state.openApproval = payload;
    },
    setOpenReject: (state: ReceiptState, action) => {
      const { payload } = action;
      state.openReject = payload;
    },
  },

  extraReducers: builder => {
    builder

      .addCase(getReceiptsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReceiptsList.fulfilled, (state, action) => {
        const receiptData = [...action.payload.records];
        const receiptViewModal: ReceiptManagmentViewModel[] = receiptData.map(
          (receipt: ReceiptsListProps) => ({
            receiptId: receipt.id,
            agentName: receipt.payeeName,
            lan: receipt.loanId,
            bankNameCode: receipt.paymentMeta?.refNumber,
            paymentMode: receipt.paymentMeta?.paymentMode,
            id: receipt.id,
            depositId: receipt.depositId,
            status: receipt.status,
            proof: [receipt.locationImageUrl, receipt.documentImageUrl],
            receiptDate: receipt.dateOfReceipt,
          })
        );
        state.loading = false;
        state.receipts = receiptViewModal;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getReceiptsList.rejected, (state, action) => {
        state.loading = false;
        state.receipts = [];
        state.totalItems = 0;
        state.error = action.error.message || 'Failed to create bidder';
      })
      .addCase(getLoanAccountList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLoanAccountList.fulfilled, (state, action) => {
        const loanAccountList = [...action.payload.records];
        const loanViewModal: AccountAgentViewModel[] = loanAccountList.map(
          (loan: LoanData) => {
            const id = loan.loanId;
            const name = loan.customerDetails[0]?.customerName ?? '-';
            const email = loan.customerDetails[0]?.email ?? '-';
            const product = loan.loanMaster.loanProductType;
            const emi = loan.loanMaster?.emi ?? 0;
            const overDue = loan.lmsVariables
              ? loan.lmsVariables?.overdueAmount ?? 0
              : 0;
            const total = loan.lmsVariables
              ? loan.lmsVariables?.chargeDue ?? 0
              : 0;
            const allowedAmount = loan.lmsVariables
              ? loan.lmsVariables?.overdueAmount ?? 0
              : 0;
            const lan = loan.loanId;
            const pos = loan.lmsVariables
              ? loan.lmsVariables?.outstandingAmount ?? 0
              : 0;
            const ptp = 0;
            const bounceCharge = 0;
            const penaltyCharge = 0;
            const legalCharge = 0;
            const other = 0;
            const bucket = loan.lmsVariables
              ? loan.lmsVariables?.bucket ?? '-'
              : '-';
            const contactNumber = loan.customerDetails[0]?.mobileNo ?? '-';
            const allocationDate = loan.metaDataLoanAllocationDto
              ? loan.metaDataLoanAllocationDto?.allocationDate
                ? DateUtility.formatStringToDDMMYYYY(
                    loan.metaDataLoanAllocationDto?.allocationDate
                  )
                : '--'
              : '--';
            const address = loan.customerDetails[0]?.customerAddress ?? '-';
            return {
              id,
              name,
              email,
              product,
              emi,
              overDue,
              total,
              allowedAmount,
              lan,
              pos: StringUtility.formatToINR(pos),
              ptp,
              bounceCharge,
              penaltyCharge,
              legalCharge,
              other,
              bucket,
              contactNumber,
              allocationDate,
              address,
            };
          }
        );
        state.loading = false;
        state.loanAccounts = loanViewModal;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getLoanAccountList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});
export const {
  setReceiptData,
  setIssueReceipt,
  setOpenApproval,
  setOpenReject,
  setErrorState,
} = receiptManagmentSlice.actions;
export const receiptState = (state: RootState) => state.receipt;
export default receiptManagmentSlice;
