import { Typography, TypographyProps } from '@mui/material';

export interface IProps extends TypographyProps {
  text: string | number | any;
  fontColor?: string;
  fontWeight?: string | number;
}

const VegaText = ({
  text,
  fontColor = '#1B1D22',
  fontWeight = 400,
  sx,
  ...rest
}: IProps) => {
  return (
    <Typography
      className={`font-aspekta-${fontWeight}`}
      color={fontColor ?? rest.color}
      sx={sx}
      {...rest}
    >
      {text}
    </Typography>
  );
};

export default VegaText;
