import React from 'react';
import Chart from 'react-apexcharts';

interface IAreaChartProps {
  series: any;
  categories: string[];
  colors: string[];
}

const AreaChart = ({ series, categories, colors }: IAreaChartProps) => {
  const options = {
    ledend: {
      show: false,
    },
    xaxis: {
      categories: categories,

      position: 'bottom',
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: '13px',
          fontFamily: 'var(--font-Aspekta-500)',
          color: '#676B76',
        },
      },
      stroke: {
        width: 1,
      },
      title: {
        text: 'Months',
        style: {
          fontSize: '13px',
          fontFamily: 'var(--font-Aspekta-500)',
          color: '#676B76',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,

        tickAmount: 3,

        style: {
          colors: [],
          fontSize: '13px',
          fontFamily: 'var(--font-Aspekta-500)',
          color: '#676B76',
        },
      },
      stroke: {
        width: 1,
      },
      title: {
        text: 'Amount (in Lacs)',
        style: {
          fontSize: '13px',
          fontFamily: 'var(--font-Aspekta-500)',
          color: '#676B76',
        },
      },
    },

    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false, // <--- HERE
    },
    stroke: {
      width: 1,
    },

    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0,
        opacityFrom: 0.7,
        opacityTo: 0.1,
      },
      colors,
    },
  };
  return <Chart options={options} series={series} type="area" />;
};

export default AreaChart;
