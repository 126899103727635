/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { Grid, IconButton, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { fetchCriteriaAttributes } from '../../actions/AllocationActions';
import { getAllocations } from '../../features/allocationSlice';
import { UserActions, getUsers } from '../../features/userSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  AttributeDataType,
  AttributeOperationType,
  Criteria,
  RuleCriteriaAttribute,
} from '../../types/allocations';
import { VegaUser } from '../../types/claim';
import { COLOR } from '../../utils/ColorUtility';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import { CrossIcon } from '../Icons/Icons';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<CreateRuleFormData>) => void;
};

export type CreateRuleFormData = {
  name: string;
  agentId: string;
  expiry: string;
  criterias: Partial<Criteria>[];
};

enum FormField {
  Name = 'name',
  Agent = 'agentId',
  Expiry = 'expiry',
}

const TODAY = new Date();
function CreateRuleDrawer({ open, onClose, onSubmit }: Props) {
  const dispatch = useAppDispatch();
  const { criteriaAttributes, loadingCriteriaAttributes } =
    useAppSelector(getAllocations);
  const { agents, loading } = useAppSelector(getUsers);
  const [attributes, setAttributes] = useState<Partial<Criteria>[]>([]);
  const [formData, setFormData] = useState<Partial<CreateRuleFormData>>({
    expiry: TODAY.toISOString(),
  });

  function handleClose() {
    onClose();
    cleanUp();
  }

  function cleanUp() {
    setFormData({});
    setAttributes([]);
  }

  const isInputValid = () => {
    const isNameValid = (formData.name ?? '').length > 0;
    const isAgentValid = (formData.agentId ?? '').length > 0;
    const isExpirySelected = (formData.expiry ?? '').length > 0;
    const isExpiryValid =
      DateUtility.parseStringToDate(formData.expiry) != null;
    const hasRules = attributes.length > 0;
    return (
      isNameValid &&
      isAgentValid &&
      hasRules &&
      isExpiryValid &&
      isAgentValid &&
      isExpirySelected
    );
  };

  function onCreateClick() {
    const existingFormData = { ...formData };
    existingFormData.criterias = attributes;
    onSubmit(existingFormData);
    handleClose();
  }

  function onAddAttribute(value: string) {
    const attribute = getCriteriaAttributeOfType(value, criteriaAttributes);
    const criteria: Partial<Criteria> = {
      attributeName: attribute.name,
      dataType: attribute.dataType,
      entity: attribute.entity,
    };
    setAttributes(prev => [...prev, criteria]);
  }

  function updateCriteria(
    attributeName: string,
    operation: (criteria: Partial<Criteria>) => void
  ) {
    if (!attributeName) return;

    const updatedAttributes = attributes.map(item => {
      if (item.attributeName === attributeName) {
        operation(item);
      }
      return item;
    });

    setAttributes(updatedAttributes);
  }

  function updateFormData(field: FormField, value: string) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function updateExpiryDate(e: any) {
    const date = DateUtility.parseDateFromDatePicker(e);
    if (date != null) {
      updateFormData(FormField.Expiry, date.toISOString());
    }
  }

  function onValueUpdate(criteria: Partial<Criteria>, value: string) {
    const { attributeName } = criteria;
    updateCriteria(attributeName, criteriaToUpdate => {
      criteriaToUpdate.value = value;
    });
  }

  function onOperationUpdate(criteria: Partial<Criteria>, value: string) {
    const { attributeName } = criteria;
    updateCriteria(attributeName, criteriaToUpdate => {
      criteriaToUpdate.op = value;
    });
  }

  function deleteCriteria(criteria: Partial<Criteria>) {
    const updatedAttributes = attributes.filter(
      item => item.attributeName !== criteria.attributeName
    );
    setAttributes(updatedAttributes);
  }

  function cleanup() {
    setAttributes([]);
    setFormData({});
  }

  useEffect(() => {
    if (open) {
      dispatch(fetchCriteriaAttributes());
      dispatch(UserActions.fetchAgents());
    }
  }, [open]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <VegaDrawer open={open} title={'Add Rule'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Create Rule'}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Rule Name'}>
              <LmTextField
                value={formData.name}
                onChange={e => updateFormData(FormField.Name, e.target.value)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent/Agency'}>
              <VegaSelect
                options={getAgentsOptions(agents)}
                loading={loading}
                value={formData.agentId ?? ''}
                onChange={e =>
                  updateFormData(FormField.Agent, e.target.value as string)
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Expiry'}>
              <VegaDatePicker
                onChange={updateExpiryDate}
                value={formData.expiry}
                minDate={new Date()}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Filter'}>
              <VegaSelect
                // value={attributes.}
                // placeholder="Select Filter"
                options={getCriteriaOptions(criteriaAttributes, attributes)}
                loading={loadingCriteriaAttributes}
                onChange={e => onAddAttribute(e.target.value as string)}
              />
            </VegaFormInputField>
          </Grid>
          {attributes.map(item => {
            return (
              <Grid container key={item.attributeName}>
                <Grid item xs={12}>
                  <VegaFormInputField label={_.startCase(item.attributeName)}>
                    <Grid container alignItems={'center'} columnGap="0.5rem">
                      <Grid item xs={7}>
                        <LmTextField
                          value={item.value}
                          onChange={e => onValueUpdate(item, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Stack>
                          <VegaSelect
                            fullWidth
                            options={getAttributeOperationTypes(item)}
                            value={item.op ?? ''}
                            onChange={e =>
                              onOperationUpdate(item, e.target.value as string)
                            }
                          />
                        </Stack>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ backgroundColor: COLOR.RED.LIGHT }}
                          onClick={() => deleteCriteria(item)}
                        >
                          <CrossIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </VegaFormInputField>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default CreateRuleDrawer;

const getCriteriaOptions = (
  attributes: RuleCriteriaAttribute[],
  criterias: Partial<Criteria>[]
) => {
  const existingAttributes = criterias.map(item => item.attributeName);
  const options = attributes
    .map(item => {
      if (existingAttributes.includes(item.name) == false) {
        const option: VegaSelectOption = {
          value: item.name,
          label: _.startCase(item.name),
        };
        return option;
      }
      return undefined;
    })
    .filter(option => option !== undefined);

  return options;
};

const getAgentsOptions = (agents: VegaUser[]) => {
  const options: VegaSelectOption[] = agents.map(agent => {
    const option: VegaSelectOption = {
      value: agent.id,
      label: StringUtility.concatenateStrings(
        agent.firstName,
        agent.middleName,
        agent.lastName
      ),
    };
    return option;
  });
  return options;
};

const getCriteriaAttributeOfType = (
  type: string,
  from: RuleCriteriaAttribute[]
) => {
  return from.find(item => item.name === type);
};

const getAttributeOptions = (attribute: Partial<Criteria>) => {
  if (attribute.dataType == AttributeDataType.NUMBER) {
    return [
      AttributeOperationType.GreaterThen,
      AttributeOperationType.GreaterThenOrEqual,
      AttributeOperationType.Equal,
      AttributeOperationType.LessThen,
      AttributeOperationType.LessThenOrEqual,
    ];
  } else if (attribute.dataType == AttributeDataType.STRING) {
    return [AttributeOperationType.Equal, AttributeOperationType.Like];
  }
  return [];
};

const getAttributeOperationTypes = (attribute: Partial<Criteria>) => {
  const availableOptions = getAttributeOptions(attribute);
  const options: VegaSelectOption[] = availableOptions.map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: item,
    };
    return option;
  });
  return options;
};
