/* eslint-disable no-unused-vars */
import { Grid, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AuctionCooldownTimer } from '../../components/Auction/AuctionCooldownTimer';
import MakeBidDialog from '../../components/Auction/MakeBidDialog';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaText from '../../components/common/VegaText';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { VegaPill } from '../../components/common/VegaPill';

import {
  closeMakeBidDialog,
  createBid,
  fetchAuctionAndBids,
  liveAuctionDetailsState,
  onBidReceivedFromWebsocket,
} from '../../features/liveAuctionDetailsSlice';
import {
  initializeWebSocket,
  subscribeToTopic,
} from '../../features/websocket';
import { disconnectWebSocket } from '../../features/websocketSlice';
import useCountdown from '../../hooks/useCountdown';
import { ROUTES } from '../../router/routes';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { Bid, Possession } from '../../types/auction';
import { COLOR } from '../../utils/ColorUtility';

import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import AuctionMakeBidCard from './AuctionMakeBidCard';
import PossessionImagePreview from './PossessionImagePreview';

function LiveAuctionDetails() {
  const { auctionId } = useParams();
  const dispatch = useAppDispatch();
  const { loading, bids, page, latestBid, auction, isMakeBidDialogOpen } =
    useAppSelector(liveAuctionDetailsState);
  const stompClient = useSelector<RootState>(
    state => state.websocket.stompClient
  );
  const connected = useSelector<RootState, boolean>(
    state => state.websocket.isConnected
  );

  const { hours, minutes, seconds, startCountdown, stopCountdown } =
    useCountdown({
      minutes: 0,
      hours: 0,
      seconds: 0,
    });

  const handleSubscribe = (auctionId: string) => {
    if (connected == true) {
      dispatch(
        subscribeToTopic(
          stompClient,
          '/topic.auction.' + auctionId + '.bid',
          message => {
            const bid = message as Bid;
            dispatch(onBidReceivedFromWebsocket(bid));
          }
        )
      );
    }
  };

  function calculateDifferenceAndStartCountdown(latestBid: Bid) {
    const createdAtLatestBid = DateUtility.parseStringToDate(
      latestBid.createdAt
    );
    if (!createdAtLatestBid) return;
    const currentTime = new Date().getTime();

    const timeDifference = currentTime - createdAtLatestBid.getTime();

    const remainingTime = 3 * 60 * 1000 - timeDifference;

    if (remainingTime <= 0) {
      return;
    }

    stopCountdown();

    startCountdown(remainingTime);
  }

  const getPossession = () => {
    if (!auction?.metaData) return;
    if (Object.hasOwn(auction.metaData, 'possessionItem')) {
      return auction.metaData['possessionItem'] as Possession;
    }
  };

  useEffect(() => {
    handleSubscribe(auctionId);
  }, [auctionId, connected]);

  useEffect(() => {
    if (!auctionId) return;
    dispatch(fetchAuctionAndBids(auctionId));
  }, [auctionId]);

  useEffect(() => {
    dispatch(initializeWebSocket());
    return () => {
      dispatch(disconnectWebSocket());
      stopCountdown();
    };
  }, []);

  useEffect(() => {
    if (!latestBid) return;
    calculateDifferenceAndStartCountdown(latestBid);
  }, [latestBid]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Auctions', link: ROUTES.LIVE_AUCTIONS },
                { label: `Conveyance Policy` },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />

      <VegaPageContent>
        <div
          style={{ display: 'flex', flexDirection: 'row', columnGap: '2.5rem' }}
        >
          <div style={{ flex: 1, height: '100%' }}>
            <Stack>
              <Grid container marginTop={'3.25rem'} marginBottom={'2rem'}>
                <Grid item xs={8}>
                  <div>
                    <VegaText
                      text={'Property Name'}
                      style={{
                        color: '#000',
                        fontSize: '1.0625rem',
                        fontWeight: 600,
                        lineHeight: '120%',
                      }}
                    />
                    <VegaText
                      text={getPossession()?.type}
                      style={{
                        marginTop: '1rem',
                        color: '#000',
                        fontSize: '0.825rem',
                        fontWeight: 500,
                        lineHeight: '130%',
                      }}
                    />
                    <VegaText
                      text={getPossession()?.address}
                      style={{
                        marginTop: '0.5rem',
                        color: '#000',
                        fontSize: '0.825rem',
                        fontWeight: 500,
                        lineHeight: '130%',
                      }}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <VegaPill
                    text="Live"
                    textColor={COLOR.GREEN.DARK}
                    backgroundColor={COLOR.GREEN.LIGHT}
                  />
                </Grid>
              </Grid>

              <AuctionMakeBidCard auction={auction} latestBid={latestBid} />

              <VegaDataGrid
                data={bids}
                columns={getColumnDefinition()}
                idColumn={'id'}
                loading={loading}
                page={page}
              />
            </Stack>
          </div>
          <div
            style={{
              flex: 1,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <div
              style={{
                marginTop: '2rem',
                marginBottom: '2rem',
              }}
            >
              <PossessionImagePreview />
            </div>
            <AuctionCooldownTimer
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          </div>
        </div>
      </VegaPageContent>

      <MakeBidDialog
        show={isMakeBidDialogOpen}
        onClose={function (): void {
          dispatch(closeMakeBidDialog());
        }}
        onSave={function (auction, formData): void {
          dispatch(
            createBid({ auctionId: auction.id, bidAmount: formData.bidAmount })
          );
        }}
      />
    </div>
  );
}

export default LiveAuctionDetails;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'bidFrom',
      headerName: 'Bid From',
      flex: 0.7,
      renderCell: props => {
        const bid = getBidForRow(props);
        const displayText = bid.bidderId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bidPlaced',
      headerName: 'Bid Placed',
      flex: 0.7,
      renderCell: props => {
        const bid = getBidForRow(props);
        const displayText = StringUtility.formatToINR(bid.bidAmount);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'bidPlacedTime',
      headerName: 'Bid Placed Time',
      flex: 0.7,
      renderCell: props => {
        const bid = getBidForRow(props);
        const displayText = DateUtility.formatStringToDDMMYYYYWithTime(
          bid.createdAt
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
};

const getBidForRow = (props: any) => props.row as Bid;
