import { Box } from '@mui/material';
import React from 'react';
import { BLUE } from '../../../../../constants/style';
import { RolesProps, useUAM } from '../../../../../providers/UAMProvider';
import { ICreateUserProps } from '../../../../../types/UAM';
import { useDataResriction } from '../../../../../providers/DataRestrictionProvider';
import VegaText from '../../../../common/VegaText';

interface IProps {
  index: number;
  manager: ICreateUserProps;
}

const ManagerList = ({ index, manager }: IProps) => {
  const { selectedBranch, setSelectedBranch } = useUAM();
  const { setSelectedRoleId } = useDataResriction();

  const handleSelectManager = async (
    index: number,
    manager: ICreateUserProps
  ) => {
    setSelectedRoleId(null);
    const updatedBranches = [...selectedBranch];
    const selectedBranchOptions = updatedBranches[index];
    const managerObj = {
      id: manager.id,
      name: manager.firstName,
    };
    const newObj = {
      ...selectedBranchOptions,
      managers: managerObj,
    } as RolesProps;
    updatedBranches.splice(index, 1, newObj);
    setSelectedBranch(updatedBranches);
  };
  return (
    <>
      <Box
        key={index}
        sx={{
          px: 2,
          py: 0.5,
          cursor: 'pointer',
          ':hover': {
            bgcolor: BLUE.lightest,
          },
        }}
        onClick={() => handleSelectManager(index, manager)}
      >
        <VegaText text={manager.firstName} />
      </Box>
    </>
  );
};

export default ManagerList;
