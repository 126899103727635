/* eslint-disable no-empty */
import { Grid, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaText from '../../../components/common/VegaText';
import {
  getLoanAccountList,
  receiptState,
} from '../../../features/receiptManagmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { LoanListRequest } from '../../../types/request/loan';

interface ILoanDetailsProps {
  key: string;
  value: string | number;
}

const LoanDetails = () => {
  const { loanAccounts } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();
  const { loanId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const getLoanList = async () => {
    try {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        loanId,
      } as LoanListRequest;
      dispatch(getLoanAccountList(body));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const getList = setTimeout(() => {
      getLoanList();
    }, 200);
    return () => clearTimeout(getList);
  }, [loanId]);

  const loanDetails: ILoanDetailsProps[] = [
    {
      key: 'Contact Number',
      value: loanAccounts[0]?.contactNumber,
    },
    {
      key: 'Email',
      value: loanAccounts[0]?.email,
    },
    {
      key: 'Product',
      value: loanAccounts[0]?.product,
    },
    {
      key: 'PTP Amount',
      value: `₹ ${loanAccounts[0]?.ptp}`,
    },
    {
      key: 'EMI',
      value: `₹ ${loanAccounts[0]?.emi}`,
    },
    {
      key: 'Overdue',
      value: `₹ ${loanAccounts[0]?.overDue}`,
    },
    {
      key: 'Bounce Charges',
      value: `₹ ${loanAccounts[0]?.bounceCharge}`,
    },
    {
      key: 'Penalty Charges',
      value: `₹ ${loanAccounts[0]?.penaltyCharge}`,
    },
    {
      key: 'Legal Charges',
      value: `₹ ${loanAccounts[0]?.legalCharge}`,
    },
    {
      key: 'Other',
      value: `₹ ${loanAccounts[0]?.other}`,
    },
    {
      key: 'Total',
      value: `₹ ${loanAccounts[0]?.total}`,
    },
    {
      key: 'Allowed Amount',
      value: `₹ ${loanAccounts[0]?.allowedAmount}`,
    },
  ];
  return (
    <Grid container spacing={2}>
      {loanDetails.map((detail: ILoanDetailsProps) => (
        <Grid key={detail.key} item xs={6} sm={6} md={4} lg={3} xl={3}>
          <VegaFormInputField label={detail.key}>
            {loading ? <Skeleton /> : <VegaText text={detail.value} />}
          </VegaFormInputField>
        </Grid>
      ))}
    </Grid>
  );
};

export default LoanDetails;
