import { Grid, Skeleton } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useUAM } from '../../../../providers/UAMProvider';
import { NESTED_ROUTES } from '../../../../router/routes';
import LmTextField from '../../../common/LmTextField';
import VegaFormInputField from '../../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../../common/VegaSelect';
import VegaText from '../../../common/VegaText';
import DetailWrapper from './DetailWrapper';

interface IProps {
  isSave: boolean;
  loading: boolean;
}

const GeneralDetails = ({ isSave, loading }: IProps) => {
  const { params } = useParams();
  const { userState, handleUserDetailsChange } = useUAM();
  return (
    <>
      <DetailWrapper title="General Details">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="first name">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.firstName ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="text"
                  placeholder="Enter Last Name"
                  disabled={isSave}
                  value={userState?.firstName}
                  onChange={e => {
                    const value = e.target.value;
                    handleUserDetailsChange('firstName', value);
                  }}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="middle name">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.middleName ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="text"
                  placeholder="Enter Last Name"
                  disabled={isSave}
                  value={userState?.middleName}
                  onChange={e => {
                    const value = e.target.value;
                    handleUserDetailsChange('middleName', value);
                  }}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="last name">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.lastName ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="text"
                  placeholder="Enter Last Name"
                  disabled={isSave}
                  value={userState?.lastName}
                  onChange={e => {
                    const value = e.target.value;
                    handleUserDetailsChange('lastName', value);
                  }}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="gender">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.gender ?? '--'}
                  />
                )
              ) : (
                <VegaSelect
                  options={genderOptions()}
                  placeholder="Select Gender"
                  disabled={isSave}
                  value={userState?.gender}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    handleUserDetailsChange('gender', value);
                  }}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <VegaFormInputField label="designation">
              {params === NESTED_ROUTES.VIEW_DETAILS ? (
                loading ? (
                  <Skeleton />
                ) : (
                  <VegaText
                    fontWeight={500}
                    sx={{
                      fontSize: '0.875rem',
                      letterSpacing: '0.07em',
                      color: '#333333',
                    }}
                    text={userState?.designation ?? '--'}
                  />
                )
              ) : (
                <LmTextField
                  type="text"
                  placeholder="Enter Designation"
                  disabled={isSave}
                  value={userState?.designation}
                  onChange={e => {
                    const value = e.target.value;
                    handleUserDetailsChange('designation', value);
                  }}
                />
              )}
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {/* <LmTextField
              header="user id"
              placeholder="Enter User ID"
              disabled={isSave}
            /> */}
          </Grid>
        </Grid>
      </DetailWrapper>
    </>
  );
};

export default GeneralDetails;

const genderOptions = () =>
  ['MALE', 'FEMALE', 'OTHERS'].map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
