/* eslint-disable react/prop-types */
import { Close } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import { GREY, PRIMARY, RED } from '../../constants/style';
import { useBankDeposit } from '../../providers/BankDepositProvider';
import {
  ICreateDepositBankProps,
  IDepositBankListProps,
} from '../../types/depositBank';
import { VegaUserStatus } from '../../types/user';
import { EditIcon, InfoIcon, TrashIcon } from '../Icons/Icons';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaButton from '../common/VegaButton';
import VegaDataGrid from '../common/VegaDataGrid';
import { VegaSwitch } from '../common/VegaSwitch';
import VegaText from '../common/VegaText';
import ViewBankDepositDetails from './ViewBankDepositDetails';

const ListOfBankDeposit = () => {
  const {
    depositBankListState,
    setSelectedId,
    toggleAddBankDepositDrawer,
    onDeleteDepositBank,
    onUpdateDepositBankSubmit,
  } = useBankDeposit();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [depositBankName, setDepositBankName] = useState<string>('');

  const toggleDeleteModal = () => {
    setIsDelete(!isDelete);
  };
  const toggleViewDetailsModal = () => {
    setIsView(!isView);
  };

  const COLUMN_DEF: GridColumns = [
    {
      field: 'bankName',
      headerName: 'Bank Name',
      flex: 1,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },
    {
      field: 'dpositBankName',
      headerName: 'Deposit Bank Name',
      flex: 1,
      minWidth: 150,
      renderCell: props => {
        const bankDeposit = props.row as IDepositBankListProps;
        return (
          <VegaText
            text={bankDeposit.bankName}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      flex: 0.7,
      minWidth: 150,
    },
    {
      field: 'bankCode',
      headerName: 'Deposit bank code',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 150,
      renderCell: props => {
        const bankDeposit = props.row as IDepositBankListProps;
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <VegaText text={toLowerCase(bankDeposit.status)} />
            <VegaSwitch
              checked={
                bankDeposit.status === VegaUserStatus.ACTIVE ? true : false
              }
              onClick={() => updateStatusForBankDeposit(bankDeposit)}
            />
          </Stack>
        );
      },
    },

    {
      field: 'typeDetails',
      headerName: 'Bank Details',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const bankDeposit = props.row as IDepositBankListProps;
        return (
          <VegaButton
            text="View Details"
            variant="text"
            sx={{
              px: 0,
            }}
            onClick={() => viewDetails(bankDeposit.id)}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const bankDeposit = props.row as IDepositBankListProps;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text=""
              startIcon={<EditIcon />}
              sx={{
                bgcolor: '#E7EDFC',
                color: PRIMARY.darkBlue,
              }}
              onClick={() => {
                setSelectedId(bankDeposit.id);
                toggleAddBankDepositDrawer();
              }}
            />
            <VegaButton
              text=""
              startIcon={<TrashIcon />}
              sx={{
                bgcolor: '#F8E8E8',
                color: RED.red,
              }}
              onClick={() => {
                setDepositBankName(
                  bankDeposit.bankName + bankDeposit.accountNumber
                );
                setSelectedId(bankDeposit.id);
                setIsDelete(true);
              }}
            />
          </Stack>
        );
      },
    },
  ];

  const updateStatusForBankDeposit = (bankDeposit: IDepositBankListProps) => {
    const bankDepositObj = {
      ...bankDeposit,
      status:
        bankDeposit.status === VegaUserStatus.ACTIVE
          ? VegaUserStatus.INACTIVE
          : VegaUserStatus.ACTIVE,
    } as ICreateDepositBankProps;
    onUpdateDepositBankSubmit(bankDepositObj);
  };

  const viewDetails = (id: string | undefined) => {
    if (id) {
      setSelectedId(id);
      toggleViewDetailsModal();
    }
  };
  return (
    <>
      <VegaDataGrid
        data={depositBankListState.records}
        columns={COLUMN_DEF}
        idColumn="id"
        loading={depositBankListState.loading}
      />
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={isDelete}
        handleClose={toggleDeleteModal}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <LmButton
              fullWidth
              text="Delete"
              bgColor={RED.red}
              startIcon={<TrashIcon strokeColor={'white'} />}
              onClick={() => {
                onDeleteDepositBank();
                toggleDeleteModal();
              }}
            />
            <LmButton
              text="Cancel"
              fullWidth
              variant="outlined"
              bgColor=""
              textColor={GREY.dark}
              onClick={() => {
                setSelectedId(null);
                toggleDeleteModal();
              }}
              startIcon={<Close sx={{ color: GREY.dark }} />}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <InfoIcon />
            <VegaText
              text="Confirmation"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <Box p={4}>
          <VegaText
            text="Are you sure you want to delete this bank deposit?"
            fontWeight={600}
            fontSize={17}
          />
          <Typography
            className="font-aspekta-400"
            color={'#999DA8'}
            fontSize={'0.8125rem'}
            lineHeight={'15.6px'}
            mt={1}
          >
            This action cannot be undone. This will permanently delete the
            {'   '}
            <b style={{ color: '#1B1D22' }}>{depositBankName} </b> {'   '}{' '}
            instance.
          </Typography>
        </Box>
      </LmDialog>

      <LmDialog
        fullWidth
        maxWidth="sm"
        open={isView}
        isCloseIcon
        handleClose={toggleViewDetailsModal}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="View Bank Deposit Details"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <Box px={4} pt={3} pb={4}>
          <ViewBankDepositDetails />
        </Box>
      </LmDialog>
    </>
  );
};

export default ListOfBankDeposit;
