import { useEffect, useState } from 'react';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';
import { CampaignStatusListProps } from '../../../types/teleAllocation';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';

interface IProps {
  selected: string | number;
  handleChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
}

const CampaignSelector = ({
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const [campaigns, setCampaigns] = useState<VegaSelectOption[]>([]);

  const loadMoreItems = (event: any) => {};

  const getCampaignList = async () => {
    TeleAllocationService.getCampaignList().then(res => {
      const newArr = res?.map((campaign: CampaignStatusListProps) => {
        return {
          value: campaign.slashRtcCampaignId,
          label: campaign.slashRtcCampaignName,
        } as VegaSelectOption;
      });
      setCampaigns([...campaigns, ...newArr]);
    });
  };

  useEffect(() => {
    getCampaignList();
  }, []);
  return (
    <VegaSelect
      placeholder={label ?? 'Select Campaign Id'}
      size="small"
      value={selected ?? ''}
      onChange={e => handleChange(e.target.value)}
      options={campaigns}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default CampaignSelector;
