import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLoanAccountList,
  fetchTeleAgentAllocation,
} from '../actions/TeleAgentAction';
import { RootState } from '../store';
import { TeleAllocationList } from '../types/allocations';
import { LoanData } from '../types/loan';
import { TeleAgentManualAccountViewModel } from '../types/teleAgent';
import { DateUtility } from '../utils/DateUtlility';

export interface TeleAgentSliceState {
  loading: boolean;
  isOpenIframe: boolean;
  error: string;
  totalItems: number;
  allocations: TeleAgentManualAccountViewModel[];
  loanData: LoanData[];
  accountDetials: Partial<TeleAgentManualAccountViewModel>;
}

const initialState: TeleAgentSliceState = {
  loading: false,
  isOpenIframe: false,
  error: '',
  totalItems: 0,
  allocations: [],
  loanData: [],
  accountDetials: {},
};

const teleAgentSlice = createSlice({
  name: 'teleAgent',
  initialState,
  reducers: {
    setAccountDetials: (state: TeleAgentSliceState, action) => {
      const { payload } = action;
      state.accountDetials = payload;
    },
    setIsOpenIframe: (state: TeleAgentSliceState, action) => {
      const { payload } = action;
      state.isOpenIframe = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTeleAgentAllocation.pending, state => {
        state.allocations = [];
        state.totalItems = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeleAgentAllocation.fulfilled, (state, action) => {
        const response = [...action.payload.records];
        const viewModels = response.map(
          (allocation: TeleAllocationList) =>
            ({
              agentId: allocation.agentId,
              processId: allocation.processId,
              id: allocation.id,
              name: '--',
              lan: allocation.loanId,
              pos: '--',
              contactNumber: allocation.phoneNumber,
              allocationDate: allocation.createdAt
                ? DateUtility.formatStringToDDMMYYYY(allocation.createdAt)
                : '--',
              teleAllocatonType: allocation.teleAllocatonType,
              teleCallStatus: allocation.teleCallStatus,
            } as TeleAgentManualAccountViewModel)
        );
        state.loading = false;
        state.allocations = viewModels;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchTeleAgentAllocation.rejected, (state, action) => {
        state.loading = false;
        state.allocations = [];
        state.totalItems = 0;
        state.error =
          (action.payload as string) || 'Failed to fetch Allocation';
      })
      .addCase(fetchLoanAccountList.pending, state => {
        state.loanData = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoanAccountList.fulfilled, (state, action) => {
        const response = [...action.payload.records];

        state.loading = false;
        state.loanData = response;
      })
      .addCase(fetchLoanAccountList.rejected, (state, action) => {
        state.loading = false;
        state.loanData = [];
        state.error =
          (action.payload as string) || 'Failed to fetch loan details';
      });
  },
});
export const { setAccountDetials, setIsOpenIframe } = teleAgentSlice.actions;
export const getTeleAgent = (state: RootState) => state.teleAgent;
export default teleAgentSlice;
