import { Stack } from '@mui/material';
import { TargetSevices } from '../../../Apis/TargetSevices';
import { ApproveIcon } from '../../../components/Icons/Icons';
import LmDialog from '../../../components/common/LmDialog';
import VegaButton from '../../../components/common/VegaButton';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaText from '../../../components/common/VegaText';
import { GREY } from '../../../constants/style';
import {
  getTargetList,
  getTargetState,
  setOpenApproveTargetDialog,
} from '../../../features/targetSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TargetsStatus } from '../../../types/targets';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useClientAuth } from '../../../providers/ClientProvider';

const ApproveTargetDialog = () => {
  const { userId } = useClientAuth();
  const { openApproveTargetDialog, bucketDetails } =
    useAppSelector(getTargetState);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const onClose = () => {
    dispatch(setOpenApproveTargetDialog(false));
  };
  const onApproveTarget = () => {
    TargetSevices.updateTargetStatus({
      id: bucketDetails.id,
      newStatus: TargetsStatus.APPROVED,
    })
      .then(() => {
        setSnackbar('Approve Successfully !!!');
        dispatch(getTargetList({ agentId: userId }));
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={openApproveTargetDialog}
        handleClose={onClose}
        buttonComponent={() => (
          <>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="Cancel"
                fullWidth
                variant="outlined"
                sx={{
                  color: GREY.dark,
                  borderColor: GREY.dark,
                  ':hover': {
                    color: GREY.dark,
                    bgcolor: 'transparent',
                    borderColor: GREY.dark,
                  },
                }}
                onClick={onClose}
              />
              <VegaButton
                fullWidth
                text="Appove"
                startIcon={<ApproveIcon strokeColor="white" />}
                onClick={onApproveTarget}
              />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Are you sure?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaText
            text={`You are approving the Agent ID #${bucketDetails.id}`}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default ApproveTargetDialog;
