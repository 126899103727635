import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import {
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IncentivePayoutPolicyType } from '../../../types/incentives';
import { IncentivePolicyRulesRequest } from '../../../types/request/incentives';
import { BucketEnum } from '../../../types/targets';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { PlusCircleIcon } from '../../Icons/Icons';
import VegaButton from '../../common/VegaButton';
import VegaText from '../../common/VegaText';
import MinimumEligibilityCriteria from './MinimumEligibilityCriteria';

const MinimumEligibilityCriteriaRange = {
  parameter: '',
  minNo: 0,
  metric: '',
  computationBasis: '',
};

export const DEFAULT_RULE: IncentivePolicyRulesRequest = {
  incentiveBasePolicyId: '707dfc03-2169-4f2c-a8e5-6d8d07fb2cea',
  bucket: '',
  incentiveType: '',
  accountType: '',
  caseType: '',
  setMinimumEligibility: false,
  maximumPayoutAmount: 0,
  maximumPayoutType: '',
  minimumEligibilityParameters: null,
  basePayoutRange: null,
  penaltyRange: null,
  bumperPayoutRange: [
    {
      parameter: '',
      parameterMin: 0,
      parameterMax: 0,
      parameterMetric: '',
      payoutMetric: '',
      payoutValue: 0,
      payoutCapMax: 0,
      payoutCapMetric: '',
      incentivePayoutPolicyType: IncentivePayoutPolicyType.BUMPER,
    },
  ],
};

const MinimumEligibilityCriteriaComponent = () => {
  const { policyRuleState, selectedTab } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const searchParams = new URLSearchParams(window.location.search);
  const idFromURL = searchParams.get('id');

  const disabled =
    !policyRuleState?.minimumEligibilityParameters ||
    !policyRuleState?.minimumEligibilityParameters?.computationBasis?.length ||
    !policyRuleState?.minimumEligibilityParameters?.metric?.length ||
    !policyRuleState?.minimumEligibilityParameters?.minNo ||
    !policyRuleState?.minimumEligibilityParameters?.maxNo;

  const handlePolicyRule = (
    key: keyof IncentivePolicyRulesRequest,
    value: any
  ) => {
    const policyRuleStateObj = {
      ...policyRuleState,
      [key]: value,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(policyRuleStateObj));
  };

  const onAddMinimumEligibilityCriteriaIncentive = () => {
    handlePolicyRule(
      'minimumEligibilityParameters',
      MinimumEligibilityCriteriaRange
    );
  };
  const onDeleteMinimumEligibilityCriteriaIncentive = () => {
    handlePolicyRule('minimumEligibilityParameters', {});
  };

  const onSubmitPolicy = () => {
    if (idFromURL) {
      IncentiveServices.addRulesForPolicy({
        ...policyRuleState,
        bucket: getBucket(),
        incentiveBasePolicyId: idFromURL,
      })
        .then(response => {
          dispatch(setPolicyDetailsState(response));
          setSnackbar('Add penalty incentive successfully !!!');
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    } else {
      setSnackbar('No incentive base policy id for this bucket', 'error');
    }
  };

  const getBucket = () => {
    if (selectedTab === 1) {
      return BucketEnum.BUCKET1;
    } else if (selectedTab === 2) {
      return BucketEnum.BUCKET2;
    } else if (selectedTab === 3) {
      return BucketEnum.BUCKET2;
    } else if (selectedTab === 4) {
      return BucketEnum.NPA;
    } else {
      return BucketEnum.BUCKETX;
    }
  };

  useEffect(() => {
    if (!policyRuleState.minimumEligibilityParameters) {
      handlePolicyRule('minimumEligibilityParameters', {});
    }
  }, []);
  return (
    <>
      <Stack
        gap={2}
        sx={{
          width: '100%',
          p: '1.5rem',
        }}
      >
        <VegaText
          text={'Minimum Eligibility Criteria '}
          fontWeight={600}
          fontSize={'1.0625rem'}
        />

        {policyRuleState?.minimumEligibilityParameters &&
          Object.keys(policyRuleState?.minimumEligibilityParameters).length >
            0 && (
            <MinimumEligibilityCriteria
              minimumEligibilityCriteria={
                policyRuleState?.minimumEligibilityParameters
              }
              onDelete={onDeleteMinimumEligibilityCriteriaIncentive}
            />
          )}
        {policyRuleState?.minimumEligibilityParameters &&
          Object.keys(policyRuleState?.minimumEligibilityParameters)?.length ===
            0 && (
            <Box>
              <VegaButton
                variant="text"
                text={`Add  Criteria`}
                startIcon={<PlusCircleIcon />}
                onClick={onAddMinimumEligibilityCriteriaIncentive}
              />
            </Box>
          )}
        <Box textAlign={'end'}>
          <VegaButton
            text={`Save Minimum Eligibility Criteria`}
            onClick={onSubmitPolicy}
            disabled={disabled}
          />
        </Box>
      </Stack>
    </>
  );
};

export default MinimumEligibilityCriteriaComponent;
