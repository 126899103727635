import axios from 'axios';
import {
  FetchVisitListByAgentIdRequest,
  FetchVisitListRequest,
} from '../types/request/visit';
import { FetchVisitsListResponse } from '../types/visits';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/visit',
  LIST: '/list',
  GET: '/list/withLoans',
};

const BASE_URL = Endpoint.BASE;
export class VisitService {
  static async getVisitList(
    request: Partial<FetchVisitListRequest>
  ): Promise<FetchVisitsListResponse[]> {
    const endpoint = BASE_URL + Endpoint.GET;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async getVisitListByAgentId(
    request: Partial<FetchVisitListByAgentIdRequest>
  ): Promise<PaginatedResponse<any>> {
    const endpoint = BASE_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
}
