import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IncentiveServices } from '../../Apis/IncentivesService';
import PolicyDetails from '../../components/IncentivesGamifications/components/PolicyDetails';
import ViewBucketComponent from '../../components/IncentivesGamifications/modules/ViewBucketComponent';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import VegaTitleCard from '../../components/common/VegaTitleCard';
import {
  DEFAULT_POLICY_DETAILS,
  getIncentivesGamificationsState,
  setPolicyDetailsState,
} from '../../features/IncentivesGamificationsSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { IncentivePolicyProps } from '../../types/incentives';
import { BucketEnum } from '../../types/targets';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { getHeaderText } from './IncentivesGamifications';
import { toLowerCase } from '../../constants/commonFunction';

const ViewPolicy = () => {
  const { policyDetailsState, selectedPolicyTab } = useAppSelector(
    getIncentivesGamificationsState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBucket, setSelectedBucket] = useState<string>('');
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { policyId } = useParams();

  const getPolicyById = (id: string) => {
    IncentiveServices.getPolicyByPolicyId(id)
      .then(res => {
        const policyDetails: Partial<IncentivePolicyProps> = {
          policyName: res.policyName,
          vertical: res.vertical,
          channelType: res.channelType,
          incentiveType: res.incentiveType,
          policyStartDate: res.policyStartDate,
          policyEndDate: res.policyEndDate,
          accountType: res.accountType,
          policyType: res.policyType,
          caseType: res.caseType,
          payoutFrequency: res.payoutFrequency,
          maxPayoutPerPerson: res.maxPayoutPerPerson,
          userType: res.userType,
          modeOfCommunication: res.modeOfCommunication,
          rules: res.rules,
        };
        const firstKey = Object.keys(policyDetails.rules)[0];
        dispatch(setPolicyDetailsState(policyDetails));
        setSelectedBucket(firstKey);
        setLoading(false);
      })
      .catch(error => {
        dispatch(setPolicyDetailsState(DEFAULT_POLICY_DETAILS));
        setLoading(false);
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  useEffect(() => {
    if (policyId) {
      setLoading(true);
      getPolicyById(policyId);
    }
  }, [policyId]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                {
                  label: `${toLowerCase(
                    getHeaderText(selectedPolicyTab)
                  )} policy`,
                  link: ROUTES.INCENTIVES_GAMIFICATIONS,
                },
                {
                  label: !policyId
                    ? `Add On Policy`
                    : policyDetailsState?.policyName,
                },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <Box
        height={`calc(100% - 77px)`}
        sx={{
          overflow: 'auto',
        }}
      >
        <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
          <VegaTitleCard title="Policy Details">
            <PolicyDetails loading={loading} />
          </VegaTitleCard>
        </VegaPageContent>
        {policyDetailsState?.rules &&
        Object.keys(policyDetailsState?.rules).length ? (
          <VegaPageContent sx={{ marginBottom: '1.5rem', marginTop: '1.5rem' }}>
            <Stack direction={'row'} gap={2}>
              {Object.keys(policyDetailsState?.rules).map((bucket: string) => (
                <Box
                  key={bucket}
                  sx={{
                    px: 1,
                    pb: 1,
                    cursor: 'pointer',
                    borderBottom:
                      selectedBucket === bucket
                        ? `1px solid #1047DC`
                        : '1px solid transparant',
                  }}
                  onClick={() => setSelectedBucket(bucket)}
                >
                  <VegaText
                    color={selectedBucket === bucket ? '#1047DC' : '#676B76'}
                    fontSize={12}
                    fontWeight={500}
                    text={getBucketLabel(bucket)}
                  />
                </Box>
              ))}
            </Stack>
          </VegaPageContent>
        ) : (
          ''
        )}
        {policyDetailsState?.rules &&
          policyDetailsState?.rules[selectedBucket] && (
            <ViewBucketComponent
              rules={policyDetailsState?.rules[selectedBucket]}
            />
          )}
      </Box>
    </>
  );
};

export default ViewPolicy;

const getBucketLabel = (bucket: string) => {
  switch (bucket) {
    case BucketEnum.BUCKET1:
      return 'Bucket 1-30';
    case BucketEnum.BUCKET2:
      return 'Bucket 31-60';
    case BucketEnum.BUCKET3:
      return 'Bucket 61-90';
    case BucketEnum.NPA:
      return 'Bucket NPA';

    default:
      return 'Bucket X';
  }
};
