import { COLOR } from '../utils/ColorUtility';

export enum ExpenseType {
  CONVEYANCE = 'CONVEYANCE',
  MEALS = 'MEALS',
  OTHERS = 'OTHERS',
}

export enum ConveyanceVehicleType {
  SELF_TWO_WHEELER = 'SELF_TWO_WHEELER',
  SELF_FOUR_WHEELER = 'SELF_FOUR_WHEELER',
  RENTED = 'RENTED',
}

export enum ExpenseClaimAction {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface ExpenseClaim {
  id: string;
  expenseType: ExpenseType;
  conveyanceVehicleType: ConveyanceVehicleType;
  description: string;
  dateOfExpense: string;
  amount: number;
  supportingDocuments: Set<string>;
  userId: string;
  reportId: string;
  projectedPrice: number;
  status: ExpenseClaimAction;
  ratePerKm: number;
  distance: number;
  rejectionCount: number;
  createdAt: string;
  updatedAt: string;
  metaData: Record<string, any>;
}

export interface ExpenseClaimAudit {
  id: string;
  claimId: string;
  userId: string;
  action: ExpenseClaimAction;
  actionDetails: string;
  createdAt: string;
}

export interface ExpenseReport {
  id: string;
  approverId: string;
  status: ExpenseClaimAction;
  claimIds: string[];
  tripId: string;
  userId: string;
  dateOfExpense: string;
  createdAt: string;
  updatedAt: string;
  pendingClaims: number;
  rejectedClaims: number;
  acceptedClaims: number;
}

export interface VegaUser {
  id: string;
  clientId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  designation: string;
  email: string;
  mobile: string;
  landline: string;
  baseBranch: string;
  employeeCode: string;
  userType: string;
}

export interface ExpenseReportViewModel extends ExpenseReport {
  claims: ExpenseClaimViewModel[];
  claimant?: VegaUser;
  approver?: VegaUser;
}
export interface ExpenseClaimViewModel extends ExpenseClaim {
  claimant?: VegaUser;
}

export interface ExpenseVisitData {
  distance: number;
  ratePerKm: number;
  projectedPrice: number;
  tripMeatData: TripMetaData;
}

export type TripMetaData = {
  id: string;
  agentId: string;
  listOfVisitId: string[];
  visitsMade: string[];
  routeId: string;
  targetVisits: number;
  targetAmount: number;
  totalVisits: number;
  amountCollected: number;
  distanceCovered: number;
  tripDate: string;
  createdAt: string;
  updatedAt: string;
};

export interface ExpenseDocument {
  id: string;
  claimId: string;
  url: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface ConveyanceRate {
  id: string;
  userId: string;
  ratePerKm: number;
  vehicleType: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
}

export const getDisplayTextForVehicleType = (vehicleType: string) => {
  switch (vehicleType) {
    case ConveyanceVehicleType.SELF_TWO_WHEELER:
      return '2-Wheeler';
    case ConveyanceVehicleType.SELF_FOUR_WHEELER:
      return '4-Wheeler';
    case ConveyanceVehicleType.RENTED:
      return 'Rented';
  }
  return '-';
};

export const getPillColorForStatus = (status: ExpenseClaimAction) => {
  switch (status) {
    case ExpenseClaimAction.ACCEPTED:
      return COLOR.GREEN;
    case ExpenseClaimAction.PENDING:
      return COLOR.ORANGE;
    case ExpenseClaimAction.REJECTED:
      return COLOR.RED;
  }
};
