import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { MinVisitsSevices } from '../../Apis/MinVisitsServices';
import { fetchLoanAccountList } from '../../actions/TeleAgentAction';
import { getTeleAgent } from '../../features/teleAgentSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { RescheduleVisitRequest } from '../../types/request/minVisitsRequest';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import VegaButton from '../common/VegaButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import { IActionProps } from './AccountActionContainer';
import { VISIT_ID } from '../../utils/EXPENSE_CONSTANTS';

const TODAY = new Date().toISOString();

const SendPaymentLinkFormDrawer = ({ open, setOpen }: IActionProps) => {
  const { loanData } = useAppSelector(getTeleAgent);
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [scheduledDate, setScheduledDate] = useState(TODAY);

  const onClose = () => {
    setOpen(false);
    setScheduledDate(TODAY);
  };

  const handleChange = (value: string) => {
    const futureDateString = DateUtility.parseDateFromDatePicker(value);
    setScheduledDate(futureDateString.toISOString());
  };

  const onSendLink = () => {
    const rescheduleVisit = {
      visitId: VISIT_ID,
      scheduledDate,
    } as RescheduleVisitRequest;
    MinVisitsSevices.rescheduleVisit(rescheduleVisit)
      .then(() => {
        setSnackbar('Move to future date successfully !!!');
        dispatch(
          fetchLoanAccountList({
            loanId: loanData[0].loanId,
            includeCustomerDetails: true,
            includeLmsVariables: true,
            includeLoanOutstandingDetails: false,
            includeLoanTransactions: true,
            includeLoanPdcDetails: true,
          })
        );
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        onClose();
      });
  };
  return (
    <>
      <VegaDrawer open={open} title={'Move to Future Date'} onClose={onClose}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Move to Future"
                  onClick={onSendLink}
                  disabled={scheduledDate < TODAY}
                />
              </Stack>
            );
          }}
        >
          <Grid container spacing={'1.25rem'}>
            <Grid item xs={12}>
              <VegaFormInputField label={'date'}>
                <VegaDatePicker
                  value={scheduledDate}
                  onChange={date => {
                    handleChange(date);
                  }}
                  minDate={TODAY}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
    </>
  );
};

export default SendPaymentLinkFormDrawer;
