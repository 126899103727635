/** @format */

import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createAssignRole,
  createNewUser,
  createRole,
  fetchUsersList,
} from '../Apis/users';
import { ROUTES } from '../router/routes';
import { ICreateRoleProps, ICreateUserProps } from '../types/UAM';
import { StringUtility } from '../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useClientAuth } from './ClientProvider';
import { useSnackbar } from './SnackbarProvider';

export interface UAMContextType {
  userState: ICreateUserProps | null;
  selectedBranch: RolesProps[];
  uamRecords: ICreateUserProps[];
  search: string;
  isSuccess: boolean;
  isFailure: boolean;
  isCreateUser: boolean;
  isCreateRole: boolean;
  isSave: boolean;
  loading: boolean;
  page: number;
  pageSize: number;
  rowCount: number;
  error: IErrorProps;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setIsSave: React.Dispatch<React.SetStateAction<boolean>>;
  setUserState: React.Dispatch<React.SetStateAction<ICreateUserProps | null>>;
  setSelectedBranch: React.Dispatch<React.SetStateAction<RolesProps[]>>;
  handleUserDetailsChange: (
    name: keyof ICreateUserProps,
    value: string
  ) => void;
  toggleSuccessfulModal: () => void;
  toggleFailureModal: () => void;
  onCreateUserSubmit: () => void;
  onCreateOrAssignRoleSubmit: () => void;
  handleChangePage: (page: number) => void;
  handleChangePagesize: (pagesize: number) => void;
}

export interface IRoleObjProps {
  id: string;
  name: string;
}

export interface RolesProps {
  entityId: any;
  roles: IRoleObjProps;
  managers: IRoleObjProps;
}
interface IErrorProps {
  email: string;
  mobile: string;
  landline: string;
}

interface IErrorObjProps {
  error: IErrorProps;
  isValid: boolean;
}

export const UAMContext = createContext<UAMContextType | null>(null);

const UAMProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const { clientId } = useClientAuth();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFailure, setIsFailure] = useState<boolean>(false);
  const [isCreateUser, setIsCreateUser] = useState<boolean>(false);
  const [isCreateRole, setIsCreateRole] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const [userState, setUserState] = useState<ICreateUserProps | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<RolesProps[]>([]);
  const [uamRecords, setUamRecords] = useState<ICreateUserProps[]>([]);
  const [error, setError] = useState<IErrorProps>({
    email: '',
    mobile: '',
    landline: '',
  });

  const isValidInput = (userState: ICreateUserProps) => {
    const errorObj = {
      error: {},
      isValid: true,
    } as IErrorObjProps;
    const isValidEmail = StringUtility.validateEmail(userState.email);
    const isValidNumber = StringUtility.validatePhoneNumber(userState.mobile);
    if (!isValidEmail) {
      errorObj.isValid = false;
      errorObj.error['email'] = 'Invalid email address';
    }
    if (!isValidNumber) {
      errorObj.isValid = false;
      errorObj.error['mobile'] = 'Mobile number should be 10 digits';
    }
    return errorObj;
  };

  const onCreateUserSubmit = async () => {
    const isValid = isValidInput(userState);
    setError(isValid.error);
    if (isValid.isValid) {
      setIsCreateUser(true);
      try {
        const userObj = {
          ...userState,
          clientId,
          userType: 'BIDDER',
        } as ICreateUserProps;
        const response = await createNewUser(userObj);
        setUserState(response);
        setIsCreateUser(false);

        setIsSave(!isSave);
        setSnackbar('Create User Successfully !!!');
      } catch (error) {
        setSnackbar(
          getErrorMessageFromErrorObj(error, 'Failed to create user'),
          'error'
        );
        setIsCreateUser(false);
      }
    }
  };

  const onAssignRole = async () => {
    await selectedBranch.map(async (branch: RolesProps) => {
      const roleObj = {
        userId: userState?.id,
        roleId: branch.roles.id,
        managerId: branch.managers.id,
      };
      await createAssignRole(roleObj);
    });
  };
  const onCreateOrAssignRoleSubmit = async () => {
    setIsCreateRole(true);
    const branches = selectedBranch.map((branch: RolesProps) => {
      const assignRoleObj = {
        clientId,
        entityId: branch.entityId,
        name: branch.roles.name,
        ownerId: branch.managers.id,
      };
      return assignRoleObj;
    });

    try {
      await createRole(branches as ICreateRoleProps[]);
      await onAssignRole();
      setIsCreateRole(false);
      setSnackbar('Assigb User for user !!!');
      navigate(ROUTES.UAM);
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to Assign role'),
        'error'
      );
      setIsCreateRole(false);
    }
  };

  const toggleSuccessfulModal = () => {
    setIsSuccess(!isSuccess);
  };
  const toggleFailureModal = () => {
    setIsFailure(!isFailure);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };
  const handleChangePagesize = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const handleUserDetailsChange = (
    name: keyof ICreateUserProps,
    value: string
  ) => {
    setUserState({ ...userState, [name]: value });
  };

  const getUserList = async (page: number, size: number) => {
    setLoading(true);
    try {
      const response = await fetchUsersList(page, size);
      setRowCount(response.totalItems);
      setLoading(false);
      setUamRecords(response.records);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserList(page, pageSize);
  }, [page, pageSize]);

  return (
    <UAMContext.Provider
      value={{
        error,
        search,
        userState,
        isSuccess,
        isFailure,
        isCreateUser,
        isCreateRole,
        isSave,
        selectedBranch,
        loading,
        uamRecords,
        page,
        pageSize,
        rowCount,
        setSelectedBranch,
        setUserState,
        setIsSave,
        handleUserDetailsChange,
        handleChangePage,
        handleChangePagesize,
        setSearch,
        toggleFailureModal,
        toggleSuccessfulModal,
        onCreateUserSubmit,
        onCreateOrAssignRoleSubmit,
      }}
    >
      {children}
    </UAMContext.Provider>
  );
};

export const useUAM = () => useContext(UAMContext) as UAMContextType;

export default UAMProvider;
