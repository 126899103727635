import axios from 'axios';
import { ApiService } from '../types/api';
import { LoanData } from '../types/loan';
import { LoanListRequest } from '../types/request/loan';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/loan',
  LIST: '/list',
};

export class LoanService extends ApiService {
  static async getLoanList(
    request: Partial<LoanListRequest>
  ): Promise<PaginatedResponse<LoanData>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
