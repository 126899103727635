import { Box } from '@mui/material';
import React from 'react';

interface ITargetCardProps {
  children: JSX.Element;
}

const TargetCard = ({ children }: ITargetCardProps) => {
  return (
    <Box
      sx={{
        p: '1rem',
        bgcolor: 'white',
        borderRadius: '0.75rem',
        border: '1px solid #E1E4EB',
        my: '1rem',
      }}
    >
      {children}
    </Box>
  );
};

export default TargetCard;
