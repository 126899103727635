/* eslint-disable no-empty */
import {
  AddCircleOutline,
  ArrowForwardIos,
  CheckCircleOutline,
  Close,
} from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GetAssignedManagerRequest,
  fetchManagerForUser,
  fetchUserById,
} from '../../../Apis/users';
import { BLUE, GREY, PRIMARY } from '../../../constants/style';
import {
  IRoleObjProps,
  RolesProps,
  useUAM,
} from '../../../providers/UAMProvider';
import { NESTED_ROUTES, ROUTES } from '../../../router/routes';
import { IManagerAndUserRoleProps } from '../../../types/UAM';
import LmButton from '../../common/LmButton';
import LmDialog from '../../common/LmDialog';
import VegaText from '../../common/VegaText';
import ContactDetails from './components/ContactDetails';
import DataRestriction from './components/DataRestriction';
import GeneralDetails from './components/GeneralDetails';
import { DataRestrictionProvider } from '../../../providers/DataRestrictionProvider';

const UAMDetails = () => {
  const {
    isSuccess,
    isCreateUser,
    isSave,
    toggleSuccessfulModal,
    onCreateUserSubmit,
    onCreateOrAssignRoleSubmit,
  } = useUAM();
  const { params, paramsId } = useParams();
  const navigate = useNavigate();
  const { setUserState, setSelectedBranch, setIsSave, userState } = useUAM();
  const [loading, setLoading] = useState<boolean>(false);

  const isInputValid = () => {
    if (!userState) return false;
    const isFirstName =
      userState.firstName != null && userState.firstName.length > 0;
    const isMiddleName =
      userState.middleName != null && userState.middleName.length > 0;
    const isLastName =
      userState.lastName != null && userState.lastName.length > 0;
    const isGender = userState.gender != null && userState.gender.length > 0;
    const isDesignation =
      userState.designation != null && userState.designation.length > 0;
    const isEmail = userState.email != null && userState.email.length > 0;
    const isFMobile = userState.mobile != null && userState.mobile.length > 0;
    const isFLendline =
      userState.landline != null && userState.landline.length > 0;
    const isBranch =
      userState.baseBranch != null && userState.baseBranch.length > 0;
    const isEmployeeCode =
      userState.employeeCode != null && userState.employeeCode.length > 0;

    return (
      isFirstName &&
      isMiddleName &&
      isLastName &&
      isGender &&
      isDesignation &&
      isEmail &&
      isFMobile &&
      isFLendline &&
      isBranch &&
      isEmployeeCode
    );
  };

  const buttonValue = () => {
    if (isSave) {
      return {
        buttonText: 'Confirm',
        buttonClick: onCreateOrAssignRoleSubmit,
        loding: false,
        disabled: false,
      };
    } else {
      return {
        buttonText: 'Save and continue',
        buttonClick: async () => {
          await onCreateUserSubmit();
        },
        loading: isCreateUser,
        disabled: isInputValid() == false,
      };
    }
  };

  const getManager = async (request: Partial<GetAssignedManagerRequest>) => {
    try {
      const response = await fetchManagerForUser(request);
      return response;
    } catch (error) {}
  };

  const getUserDetailsById = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetchUserById(id);
      setUserState(response);
      const manager: IManagerAndUserRoleProps[] = await getManager({
        userId: id,
      });

      if (manager.length) {
        setIsSave(true);
        const managerAndRoleList = manager.map(
          (manager: IManagerAndUserRoleProps) => {
            const roleObj = {
              id: manager.userRole.id,
              name: manager.userRole.name,
            } as IRoleObjProps;
            const managerObj = {
              id: manager.managerUser.id,
              name: manager.managerUser.firstName,
            } as IRoleObjProps;
            return {
              entityId: manager.userRoleMapping.entityId,
              roles: roleObj,
              managers: managerObj,
            } as RolesProps;
          }
        );
        setSelectedBranch(managerAndRoleList);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paramsId) {
      getUserDetailsById(paramsId);
    }
  }, [paramsId]);

  return (
    <Box>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        sx={{
          py: 3,
          px: 5,
        }}
      >
        <VegaText
          text={'User Access Management'}
          color={PRIMARY.darkBlue}
          fontWeight={500}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setIsSave(false);
            setSelectedBranch([]);
            navigate(ROUTES.UAM);
          }}
        />
        <ArrowForwardIos
          sx={{
            fontSize: '0.75rem',
          }}
        />
        <VegaText text={params} fontWeight={500} />
      </Stack>
      <Stack
        sx={{
          mx: 5,
        }}
        gap={5}
      >
        <GeneralDetails isSave={isSave} loading={loading} />
        <ContactDetails isSave={isSave} loading={loading} />
        {isSave && (
          <DataRestrictionProvider>
            <DataRestriction />
          </DataRestrictionProvider>
        )}

        {params !== NESTED_ROUTES.VIEW_DETAILS && (
          <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}>
            <LmButton
              text={buttonValue().buttonText}
              onClick={buttonValue().buttonClick}
              loading={buttonValue().loading}
              disabled={buttonValue().disabled}
            />
          </Box>
        )}
      </Stack>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={isSuccess}
        handleClose={toggleSuccessfulModal}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <LmButton
              text="Cancel"
              fullWidth
              variant="outlined"
              bgColor=""
              textColor={GREY.dark}
              onClick={() => {
                //  setSelectedId(null);
                toggleSuccessfulModal();
              }}
              startIcon={<Close sx={{ color: GREY.dark }} />}
            />
            <LmButton
              fullWidth
              text="Add New User"
              startIcon={<AddCircleOutline />}
              onClick={async () => {
                //  await onDeleteDepositBank();
                toggleSuccessfulModal();
              }}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={1}
            bgcolor={BLUE.lightest}
          >
            <CheckCircleOutline sx={{ color: PRIMARY.darkBlue }} />
            <VegaText
              text="Successful"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={PRIMARY.darkBlue}
            />
          </Stack>
        )}
      >
        <Box p={4}>
          <VegaText
            text="User successfully created"
            fontWeight={600}
            fontSize={17}
            textAlign={'center'}
          />
        </Box>
      </LmDialog>
    </Box>
  );
};

export default UAMDetails;
