import { Skeleton, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { COMMON } from '../../constants/style';
import VegaText from './VegaText';

type Props = {
  heading: string;
  value?: string | number;
  renderValue?: () => ReactNode;
  loading?: boolean;
  direction?: 'vertical' | 'horizontal';
};
function VegaKeyValueDisplay({
  heading,
  value,
  renderValue,
  loading = false,
  direction = 'horizontal',
}: Props) {
  return (
    <Stack direction={direction == 'horizontal' ? 'row' : 'column'} gap="1rem">
      <VegaText
        text={heading}
        fontWeight={500}
        fontSize={'0.8125rem'}
        color={COMMON.light}
      />

      {loading == true && <Skeleton width={'100%'} />}

      {renderValue == null && (
        <VegaText
          text={value ?? '-'}
          fontWeight={500}
          fontSize={'0.8125rem'}
          color={COMMON.balck}
          width={'40%'}
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        />
      )}

      {renderValue?.()}
    </Stack>
  );
}

export default VegaKeyValueDisplay;
