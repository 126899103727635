import { Grid } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  receiptState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import LmTextField from '../../../components/common/LmTextField';
import {
  CashPayment,
  CashPaymentMeta,
  ChequePayment,
  DDPayment,
  ElectronicPayment,
  IssueReceiptProps,
  PaymentMode,
  PaymentType,
  Relationship,
} from '../../../types/receiptManagment';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import _ from 'lodash';

const cashPayment: CashPaymentMeta[] = [
  {
    denomination: 2000,
    count: 0,
  },
  {
    denomination: 500,
    count: 0,
  },
  {
    denomination: 200,
    count: 0,
  },
  {
    denomination: 100,
    count: 0,
  },
  {
    denomination: 50,
    count: 0,
  },
  {
    denomination: 20,
    count: 0,
  },
  {
    denomination: 10,
    count: 0,
  },
];

const PayerDetails = () => {
  const { issueReceipt, errorState } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();

  const onPayerDetailsChange = (
    name: keyof IssueReceiptProps,
    value: string
  ) => {
    const payerDetailsObj = {
      ...issueReceipt,
      [name]: value,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(payerDetailsObj));
  };

  const onPaymentModeChange = (
    name: keyof IssueReceiptProps,
    value: string
  ) => {
    if (value === PaymentMode.CASH) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          cashPaymentMeta: cashPayment,
        } as CashPayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
    if (value === PaymentMode.CHEQUE) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          chequeNumber: '',
          chequeDate: new Date().toISOString(),
          ifscCode: '',
          bankName: '',
          branchName: '',
        } as ChequePayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
    if (value === PaymentMode.DD) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          ddNumber: '',
          micr: '',
          ddDate: new Date().toISOString(),
        } as DDPayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
    if (
      value === PaymentMode.ELECTRONIC_FUND_TRANSFER ||
      value === PaymentMode.NEFT
    ) {
      const payerDetailsObj = {
        ...issueReceipt,
        [name]: {
          paymentMode: value,
          refNumber: '',
          refDate: new Date().toISOString(),
        } as ElectronicPayment,
      } as IssueReceiptProps;
      dispatch(setIssueReceipt(payerDetailsObj));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="payer name">
          <LmTextField
            value={issueReceipt.payeeName}
            onChange={e => onPayerDetailsChange('payeeName', e.target.value)}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Mobile number">
          <LmTextField
            type="number"
            value={issueReceipt.mobileNumber}
            onChange={e => onPayerDetailsChange('mobileNumber', e.target.value)}
            error={errorState.error.mobileNumber?.length > 0}
            helperText={errorState.error.mobileNumber}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Email">
          <LmTextField
            value={issueReceipt.email}
            onChange={e => onPayerDetailsChange('email', e.target.value)}
            error={errorState.error.email?.length > 0}
            helperText={errorState.error.email}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Relationship with customer">
          <VegaSelect
            options={reletionshipOption()}
            value={issueReceipt.relationshipWithCustomer}
            onChange={e =>
              onPayerDetailsChange('relationshipWithCustomer', e.target.value)
            }
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Payment Type">
          <VegaSelect
            options={paymentTypeOption()}
            value={issueReceipt.paymentType}
            onChange={e => onPayerDetailsChange('paymentType', e.target.value)}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <VegaFormInputField label="Mode of payment">
          <VegaSelect
            options={paymentModeOption()}
            value={issueReceipt.paymentMeta?.paymentMode}
            onChange={e => onPaymentModeChange('paymentMeta', e.target.value)}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <VegaFormInputField label="remark">
          <LmTextField
            value={issueReceipt.remarks}
            onChange={e => onPayerDetailsChange('remarks', e.target.value)}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <VegaFormInputField label="total amount">
          <LmTextField
            type="number"
            value={issueReceipt.totalAmount}
            onChange={e => onPayerDetailsChange('totalAmount', e.target.value)}
          />
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default PayerDetails;

const reletionshipOption = () =>
  Object.keys(Relationship).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
const paymentTypeOption = () =>
  Object.keys(PaymentType).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
const paymentModeOption = () =>
  Object.keys(PaymentMode).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
