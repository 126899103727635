/* eslint-disable no-empty */
import { useEffect, useState } from 'react';

import { MinVisitsSevices } from '../../../Apis/MinVisitsServices';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { getMinVisitsState } from '../../../features/minVisitsSlice';
import { useAppSelector } from '../../../store';
import { AreaListProps } from '../../../types/minVisitsType';

interface IProps {
  selected: string;
  handleChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
}

const BranchSelector = ({
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const { selectedRegion } = useAppSelector(getMinVisitsState);
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [branchList, setBranchList] = useState<VegaSelectOption[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== branchList.length) {
        setPage(page + 1);
      }
    }
  };

  const getAreaBranchList = async (region: string) => {
    try {
      const response = await MinVisitsSevices.getAreaList({
        page,
        pageSize: 10,
        region,
      });

      const branchListOption = await response.records.map(
        (area: AreaListProps) => {
          return {
            value: area.branch,
            label: area.branch,
          };
        }
      );
      if (page === 0) {
        setBranchList(branchListOption);
      } else {
        setBranchList([...branchList, ...branchListOption]);
      }
      setTotalItem(response.totalItems);
    } catch (error) {}
  };

  useEffect(() => {
    if (selectedRegion.length) {
      getAreaBranchList(selectedRegion);
    }
  }, [page, selectedRegion]);
  return (
    <VegaSelect
      placeholder={label ?? 'Select Branch'}
      options={branchList}
      onChange={e => handleChange(e.target.value)}
      value={selected}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default BranchSelector;
