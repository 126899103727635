import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import {
  fetchTrailList,
  trailRequestSelector,
} from '../../features/trailSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { LoanData } from '../../types/loan';

function TrailPage() {
  const { userId } = useClientAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, trails, totalItems } = useAppSelector(trailRequestSelector);

  const [page, setPage] = useState<number>(0);
  function onPageChange(pageNumber: number) {
    if (page == pageNumber) return;
    setPage(pageNumber);
  }

  useEffect(() => {
    dispatch(
      fetchTrailList({
        agentId: userId,
        page: page,
        size: 10,
      })
    );
  }, [page]);

  return (
    <>
      <VegaPageHeader title="Trail"></VegaPageHeader>
      <VegaPageHeader
        title="My Accounts"
        renderRightView={() => {
          return <LmFilterSection />;
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaPageContent>
        <VegaDataGrid
          page={page}
          pageSize={10}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={trails}
          columns={getColumnDefinition({
            onViewDetailsClick: loan => {
              navigate(
                ROUTES.Trail_Detail_Page.replace(':loanAccountId', loan?.loanId)
              );
            },
          })}
          loading={loading}
          idColumn="loanId"
        />
      </VegaPageContent>
    </>
  );
}

export default TrailPage;
const getColumnDefinition = (data: {
  onViewDetailsClick?: (loan: LoanData) => void;
}) => {
  const COLUMN_DEF: GridColumns<LoanData> = [
    {
      field: 'customerName',
      headerName: 'Name',
      flex: 0.7,
    },
    {
      field: 'loanId',
      headerName: 'Loan Account Number',
      flex: 1,
    },

    {
      field: 'primaryContact',
      headerName: 'Contact Number',
      flex: 0.6,
      minWidth: 129,
    },
    // {
    //   field: 'AllocationDate',
    //   headerName: 'Allocation Date',
    //   flex: 0.6,
    //   minWidth: 129,
    //   renderCell: props => {
    //     const loan = props.row as LoanData;

    //     return (
    //       <VegaText
    //         text={loan.loanMaster.loadDate ?? '-'}
    //         fontWeight={500}
    //         fontSize={'0.875rem'}
    //       />
    //     );
    //   },
    // },
    // {
    //   field: 'Address',
    //   headerName: 'Address',
    //   flex: 0.6,
    //   minWidth: 350,
    //   renderCell: props => {
    //     let displayText = '';
    //     const loan = props.row as LoanData;
    //     const getCustomerAddress = getFirstItemIfExists(loan.customerDetails);
    //     if (getCustomerAddress.customerAddress) {
    //       displayText = getCustomerAddress.customerAddress;
    //     }

    //     return (
    //       <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    {
      field: 'Details',
      headerName: 'Details',
      flex: 0.6,
      minWidth: 119,

      renderCell: props => {
        const loan = props.row as LoanData;
        return (
          <VegaButton
            text="View Details"
            onClick={() => {
              data.onViewDetailsClick(loan);
            }}
            variant="text"
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
