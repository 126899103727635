import { Grid, Skeleton, Stack } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import { MinimumEligibilityCriteriaProps } from '../../../types/request/incentives';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaText from '../../common/VegaText';

interface IProps {
  loading?: boolean;
  eligibility: Partial<MinimumEligibilityCriteriaProps>;
}
const ViewEligibilityIncentive = ({ loading, eligibility }: IProps) => {
  return (
    <Stack gap={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Collection parameter'}>
            {getTextLoadingWrapper(toLowerCase(eligibility.parameter), loading)}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Parameter range / Metric'}>
            {getTextLoadingWrapper(
              `${getEligibilityValue(eligibility.minNo)}-${getEligibilityValue(
                eligibility.maxNo
              )}/ ${toLowerCase(eligibility.metric)}`,
              loading
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <VegaFormInputField label={'Computation basis'}>
            {getTextLoadingWrapper(`${eligibility.computationBasis}`, loading)}
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ViewEligibilityIncentive;

const getEligibilityValue = (param: string | number) => {
  const value = typeof param === 'number' ? param.toString() : param;
  if (!param) return 'NA';
  return value;
};

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
  }
};
