import { createAsyncThunk } from '@reduxjs/toolkit';
import { TeleAllocationService } from '../Apis/TeleAllocationServices';

export const fetchCampaignList = createAsyncThunk(
  'teleAllocation/fetchCampaignList',
  async () => {
    const response = await TeleAllocationService.getCampaignList();
    return response;
  }
);
export const fetchProcessList = createAsyncThunk(
  'teleAllocation/fetchProcessList',
  async () => {
    const response = await TeleAllocationService.getProcessList();
    return response;
  }
);
export const fetchLeadsetList = createAsyncThunk(
  'teleAllocation/fetchLeadsetList',
  async () => {
    const response = await TeleAllocationService.getLeadsetList();
    return response;
  }
);
