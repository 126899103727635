import { ArrowDropDown, Close } from '@mui/icons-material';
import { Box, Collapse, MenuItem, Stack } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import { COMMON } from '../../constants/style';
import VegaCheckbox from './VegaCheckbox';
import { VegaSelectOption } from './VegaSelect';
import VegaText from './VegaText';

type Props = {
  searchValue?: string;
  placeHolder?: string;
  options: VegaSelectOption[];
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (selected: string) => void;
  handleDelete: (selected: number) => void;
  onScroll?: (e: any) => void;
  selected: string[];
  disabled?: boolean;
};

function VegaSelectWithCheckbox({
  searchValue,
  handleChange,
  onSelect,
  onScroll,
  options,
  selected,
  placeHolder,
  disabled,
  handleDelete,
}: Props) {
  const [show, setShow] = useState<boolean>(false);

  const onChange = (selectValue: string | VegaSelectOption) => {
    if (!disabled) {
      setShow(!show);
      if (typeof selectValue === 'string') {
        onSelect(selectValue);
      } else {
        onSelect(selectValue.value);
      }
    }
  };

  const domNode = useClickOutSide(() => {
    setShow(false);
  });

  return (
    <div ref={domNode} style={{ position: 'relative' }}>
      <Stack
        sx={{
          borderRadius: '9px',
          border: '1px solid #C5C8D0',
          pl: 2,
          pr: 1,
          transition: 'all 0.3s ease-in-out',
          height: selected?.length > 4 ? 'auto' : '35px',
          ...(!disabled && { cursor: 'pointer' }),
        }}
        onClick={disabled ? () => {} : () => setShow(!show)}
      >
        <Stack
          direction={'row'}
          minHeight={32}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack
            direction={'row'}
            gap={1}
            sx={{
              ...(selected?.length > 4 && { flexWrap: 'wrap', py: 1 }),
            }}
          >
            {selected?.length ? (
              selected.map((value: string, index: number) => {
                return (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                    key={value}
                    sx={{
                      bgcolor: '#3966E2',
                      color: 'white',
                      borderRadius: '12px',

                      px: 1.5,
                      py: 0.5,
                      fontFamily: `var(--font-Aspekta-600) !important`,
                      fontSize: '0.625rem',
                    }}
                  >
                    {toLowerCase(value)}
                    <Close
                      sx={{
                        fontSize: '0.75rem',
                        ...(!disabled && { cursor: 'pointer' }),
                      }}
                      onClick={
                        disabled
                          ? () => {}
                          : () => {
                              handleDelete(index);
                            }
                      }
                    />
                  </Stack>
                );
              })
            ) : (
              <VegaText text={placeHolder} color={'#C5C8D0'} />
            )}
          </Stack>

          <ArrowDropDown
            onClick={disabled ? () => {} : () => setShow(!show)}
            sx={{
              color: 'rgba(0, 0, 0, 0.54)',
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',

              ...(show ? { rotate: '180deg' } : ''),
            }}
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          bgcolor: 'white',
          zIndex: 9999,
          width: '100%',
        }}
      >
        <Collapse
          sx={{
            boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
            borderRadius: '6px',
          }}
          in={show}
        >
          <Box sx={{ py: 1 }}>
            {handleChange && (
              <Box
                sx={{
                  px: 1,
                }}
              >
                {' '}
                <input
                  className="VegaInput"
                  placeholder="search..."
                  type="text"
                  style={{
                    height: '35px',
                    width: '100%',
                    borderRadius: '9px',
                    border: '1px solid #C5C8D0',
                    paddingLeft: '10px',
                  }}
                  value={searchValue}
                  onChange={handleChange}
                />
              </Box>
            )}
            <div
              onScroll={onScroll}
              style={{
                height: 200,
                padding: '10px',
                overflow: 'auto',
              }}
              className=""
            >
              {options.length ? (
                options.map((option, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={typeof option === 'string' ? option : option.value}
                      onClick={() => onChange(option)}
                    >
                      <Stack
                        direction={'row'}
                        gap={2}
                        color={COMMON.gray}
                        alignItems="center"
                        className="font-aspekta-400"
                      >
                        <VegaCheckbox
                          checked={selected?.includes(option.value)}
                        />
                        {typeof option === 'string'
                          ? _.startCase(_.toLower(option))
                          : _.startCase(_.toLower(option.label))}
                      </Stack>
                    </MenuItem>
                  );
                })
              ) : (
                <VegaText text={'No Data'} />
              )}
            </div>
          </Box>
        </Collapse>
      </Box>
    </div>
  );
}

export default VegaSelectWithCheckbox;

const useClickOutSide = (handler: any) => {
  const domNode = useRef<any>();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (domNode.current && !domNode.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return domNode;
};
