import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { fetchAllocationRules } from '../../../actions/AllocationActions';
import LmButton from '../../../components/common/LmButton';
import LmDateField from '../../../components/common/LmDateField';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import { VegaFileSelectOption } from '../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { getAllocations } from '../../../features/allocationSlice';
import { getUsers, UserActions } from '../../../features/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  AllocationRuleViewModel,
  AllocationTypes,
} from '../../../types/allocations';
import { VegaUser } from '../../../types/claim';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<ExecuteLoanAccountsFormData>) => void;
};

export type ExecuteLoanAccountsFormData = {
  ruleId: string;
  agentId: string;
  allocationType: string;
  expiryDate: string;
};

enum InputType {
  RuleId = 'ruleId',
  AgentId = 'agentId',
  AllocationType = 'allocationType',
  ExpiryDate = 'expiryDate',
}

const TODAY = new Date().toISOString();
function ExecuteLoanAccountsDrawer({ open, onClose, onSubmit }: Props) {
  const [formData, setFormData] = useState<
    Partial<ExecuteLoanAccountsFormData>
  >({
    expiryDate: TODAY,
  });
  const dispatch = useAppDispatch();
  const { agents, loading } = useAppSelector(getUsers);
  const { allocationRuleViewModels, loadingAllocationRules } =
    useAppSelector(getAllocations);

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }

  const isInputValid = () => {
    const isAgentIdValid = (formData.agentId ?? '').length > 0;
    const isAllocationTypeValid = (formData.allocationType ?? '').length > 0;
    const isRuleIdValid = (formData.ruleId ?? '').length > 0;
    return isAgentIdValid && isAllocationTypeValid && isRuleIdValid;
  };

  function onSumbitClick() {
    onSubmit(formData);
    handleClose();
  }

  function handleClose() {
    onClose();
    cleanUp();
  }

  function cleanUp() {
    setFormData({});
  }

  function onAgentIdChange(value: string) {
    updateFormData(InputType.AgentId, value);
  }
  function onAllocationTypeChange(value: string) {
    updateFormData(InputType.AllocationType, value);
  }
  function updateExpiryDate(value: Date) {
    updateFormData(InputType.ExpiryDate, value);
  }

  useEffect(() => {
    if (open) {
      dispatch(UserActions.fetchAgents());
      dispatch(fetchAllocationRules({}));
    }
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Execute Rule'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Sumbit"
              onClick={onSumbitClick}
              disabled={isInputValid() == false}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'Rule'}>
              <VegaSelect
                options={getRuleOptions(allocationRuleViewModels)}
                loading={loadingAllocationRules}
                value={formData.ruleId ?? ''}
                onChange={e =>
                  updateFormData(InputType.RuleId, e.target.value as string)
                }
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label={'Agent'}>
              <VegaSelect
                options={getAgentsOptions(agents)}
                loading={loading}
                value={formData.agentId ?? ''}
                onChange={e => onAgentIdChange(e.target.value as string)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Allocation Type">
              <VegaSelect
                options={allocationTypeOptions()}
                value={formData.allocationType}
                onChange={e => {
                  onAllocationTypeChange(e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
            <LmDateField
              header="Expiry Date"
              value={formData.expiryDate}
              onChange={e => {
                updateExpiryDate(DateUtility.parseDateFromDatePicker(e));
              }}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ExecuteLoanAccountsDrawer;

const allocationTypeOptions = () =>
  Object.keys(AllocationTypes).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const getAgentsOptions = (agents: VegaUser[]) => {
  const options: VegaSelectOption[] = agents.map(agent => {
    const option: VegaSelectOption = {
      value: agent.id,
      label: StringUtility.concatenateStrings(
        agent.firstName,
        agent.middleName,
        agent.lastName
      ),
    };
    return option;
  });
  return options;
};

const getRuleOptions = (rules: AllocationRuleViewModel[]) => {
  const options: VegaSelectOption[] = rules.map(item => {
    const option: VegaSelectOption = {
      value: item.id,
      label: item.name,
    };
    return option;
  });
  return options;
};
