import React from 'react';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import { Grid, Stack } from '@mui/material';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import { useAppSelector } from '../../../store';
import { getTeleAgent } from '../../../features/teleAgentSlice';

const ContactDetails = () => {
  const { loanData, loading } = useAppSelector(getTeleAgent);
  const getCustomerDetails = () => {
    if (loanData.length && loanData[0].customerDetails.length) {
      const customerData = loanData[0].customerDetails[0];
      return {
        mobileNo: customerData.mobileNo,
        alternateNo: customerData.alternateNo,
      };
    }
    return {
      mobileNo: '--',
      alternateNo: '--',
    };
  };
  return (
    <TeleAgentDetailsCard text="Contact Details" isBorder>
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Primary Contact number"
              value={getCustomerDetails().mobileNo}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Alternate Contact number"
              value={getCustomerDetails().alternateNo}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default ContactDetails;
