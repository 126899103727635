import React from 'react';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import { Grid, Stack } from '@mui/material';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';

const AdditionDetails = () => {
  return (
    <TeleAgentDetailsCard text="Additional Details">
      <Stack gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="PTP date" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper text="Date of payment" value="NA" />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Probability to pay (Month)"
              value="NA"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2}>
            <TeleAgentTextWrapper
              text="Probability to pay (PTP Date) "
              value="NA"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <TeleAgentTextWrapper text="Details of legal actions" value="NA" />
          </Grid>
        </Grid>
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default AdditionDetails;
