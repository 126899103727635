import axios from 'axios';
import { ApiService } from '../types/api';
import { Bidder } from '../types/auction';
import {
  CreateBidderRequest,
  GetBiddersListRequest,
  UpdateBidderStatusRequest,
} from '../types/request/bidder';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/auction/bidder',
  CREATE: '/',
  GET_BY_ID: '/{id}',
  UPDATE_STATUS: '/status',
  GET_BIDDER_LIST: '/list',
};
const BASE_URL = Endpoint.BASE;
export class BidderService extends ApiService {
  static async createBidder(
    request: Partial<CreateBidderRequest>
  ): Promise<Bidder> {
    const endpoint = BASE_URL + Endpoint.CREATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getBidders(
    request: Partial<GetBiddersListRequest>
  ): Promise<PaginatedResponse<Bidder>> {
    const endpoint = BASE_URL + Endpoint.GET_BIDDER_LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }

  static async getBidder(id: string): Promise<Bidder> {
    const endpoint = BASE_URL + Endpoint.GET_BY_ID.replace('{id}', id);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async updateStatus(
    request: Partial<UpdateBidderStatusRequest>
  ): Promise<Bidder> {
    const endpoint = BASE_URL + Endpoint.UPDATE_STATUS;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
}
