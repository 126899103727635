import { createContext, useContext, useEffect, useState } from 'react';
import {
  createDepositBank,
  deleteDepositBank,
  fetchDepositBanksList,
  updateDepositBank,
} from '../Apis/depositBank';
import {
  ICreateDepositBankProps,
  IDepositBankListProps,
} from '../types/depositBank';
import { getErrorMessageFromErrorObj } from '../utils/api';
import { useSnackbar } from './SnackbarProvider';

export interface IPastUploadListProps {
  id: number | string;
  requestedId: string;
  fileName: string;
  date: string;
  totalEntries: string;
  success: string;
  failure: string;
  status: string;
}
export const dummy_PastUpload: IPastUploadListProps[] = [
  {
    id: 1,
    requestedId: '#23113344',
    fileName: 'Branches.csv',
    date: '22/03/2023',
    totalEntries: '213123124',
    success: '1234555',
    failure: '345',
    status: 'IN_PROGRESS',
  },
  {
    id: 2,
    requestedId: '#23113344',
    fileName: 'Branches.csv',
    date: '22/03/2023',
    totalEntries: '213123124',
    success: '1234555',
    failure: '0',
    status: 'SUCCESS',
  },
];

export interface IDepositBankListStateProps {
  loading: boolean;
  records: IDepositBankListProps[];
}

export interface BankDepositContextType {
  depositeBankState: ICreateDepositBankProps | null;
  search: string;
  isAdd: boolean;
  isBulkUpload: boolean;
  isPastUploadView: boolean;
  selectedId: string | null;
  depositBankListState: IDepositBankListStateProps;
  toggleAddBankDepositDrawer: () => void;
  toggleBulkUploadModal: () => void;
  togglePastUploadViewModal: () => void;
  onAddDepositBankSubmit: () => void;
  onDeleteDepositBank: () => void;
  onUpdateDepositBankSubmit: (
    updatedDepositBank: ICreateDepositBankProps
  ) => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
  setDepositeBankState: React.Dispatch<
    React.SetStateAction<ICreateDepositBankProps>
  >;
  handleDepositBankChange: (
    name: keyof ICreateDepositBankProps,
    value: string
  ) => void;
}

export const BankDepositContext = createContext<BankDepositContextType | null>(
  null
);

export const defaultDepositBankData: ICreateDepositBankProps = {
  bankName: '',
  accountNumber: '',
  bankCode: '',
  branchName: '',
  branchCode: '',
  ifsc: '',
  micr: '',
  segment: '',
};

const BankDepositProvider = ({ children }: any) => {
  const { setSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [isPastUploadView, setIsPastUploadView] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [depositeBankState, setDepositeBankState] =
    useState<ICreateDepositBankProps>({ ...defaultDepositBankData });
  const [depositBankListState, setDepositBankListState] =
    useState<IDepositBankListStateProps>({
      loading: false,
      records: [],
    });

  const toggleAddBankDepositDrawer = () => {
    setIsAdd(!isAdd);
  };
  const toggleBulkUploadModal = () => {
    setIsBulkUpload(!isBulkUpload);
  };
  const togglePastUploadViewModal = () => {
    setIsPastUploadView(!isPastUploadView);
  };

  const onAddDepositBankSubmit = async () => {
    if (!depositeBankState) return;
    try {
      await createDepositBank(depositeBankState);
      setSnackbar('Deposit bank Added');
      toggleAddBankDepositDrawer();
      getDepositBank();
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to add Deposit bank'),
        'error'
      );
    }
  };
  const onUpdateDepositBankSubmit = async (
    updatedDepositBank: ICreateDepositBankProps
  ) => {
    if (!updatedDepositBank) return;
    try {
      await updateDepositBank(updatedDepositBank);
      setSnackbar('Deposit bank updated');
      toggleAddBankDepositDrawer();
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to updated Deposit bank'),
        'error'
      );
    }
  };

  const onDeleteDepositBank = async () => {
    if (!selectedId) return;
    try {
      await deleteDepositBank(selectedId);
      getDepositBank();
      setSnackbar('Deposit bank deleted');
    } catch (error) {
      setSnackbar(
        getErrorMessageFromErrorObj(error, 'Failed to delete Deposit bank'),
        'error'
      );
    }
  };

  const handleDepositBankChange = (
    name: keyof ICreateDepositBankProps,
    value: string
  ) => {
    setDepositeBankState({ ...depositeBankState, [name]: value });
  };

  const updateDepositListState = (
    key: keyof IDepositBankListStateProps,
    value: boolean | IDepositBankListProps[]
  ) => {
    setDepositBankListState({ ...depositBankListState, [key]: value });
  };

  const getDepositBank = async () => {
    updateDepositListState('loading', true);
    try {
      const response = await fetchDepositBanksList();
      updateDepositListState('loading', false);
      updateDepositListState('records', response.records);
    } catch (error) {
      updateDepositListState('loading', false);
    }
  };

  useEffect(() => {
    getDepositBank();
  }, []);

  return (
    <BankDepositContext.Provider
      value={{
        depositeBankState,
        depositBankListState,
        search,
        isAdd,
        isBulkUpload,
        isPastUploadView,
        selectedId,
        setSelectedId,
        setDepositeBankState,
        toggleAddBankDepositDrawer,
        toggleBulkUploadModal,
        togglePastUploadViewModal,
        onAddDepositBankSubmit,
        onUpdateDepositBankSubmit,
        onDeleteDepositBank,
        setSearch,
        handleDepositBankChange,
      }}
    >
      {children}
    </BankDepositContext.Provider>
  );
};

export const useBankDeposit = () =>
  useContext(BankDepositContext) as BankDepositContextType;

export default BankDepositProvider;
