import { Grid, Stack } from '@mui/material';
import {
  getTeleAllocation,
  setOpenViewProcessDialog,
  setProcessDetials,
} from '../../../features/teleAllocationSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import LmDialog from '../../common/LmDialog';
import VegaKeyValueDisplay from '../../common/VegaKeyValueDisplay';
import VegaPageContent from '../../common/VegaPageContent';
import VegaText from '../../common/VegaText';
import { useState, useEffect } from 'react';
import { TeleAgentService } from '../../../Apis/TeleAgentServices';
import { COMMON } from '../../../constants/style';
import { DownloadIcon } from '../../Icons/Icons';
import { TeleAgentListProps } from '../../../types/teleAgent';
import { FileUtility } from '../../../utils/FileUtility';
import VegaDataGrid from '../../common/VegaDataGrid';
import { GridColumns } from '@mui/x-data-grid';
import { DateUtility } from '../../../utils/DateUtlility';

const ViewProcessDetailsDialog = () => {
  const { openViewProcessDialog, processDetials } =
    useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const [agentsInProcess, setAgentsInProcess] = useState<TeleAgentListProps[]>(
    []
  );
  const [leadCount, setLeadCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [countLoading, setCountLoading] = useState<boolean>(false);

  const onClose = () => {
    dispatch(setOpenViewProcessDialog(false));
    dispatch(setProcessDetials(null));
  };
  const fetchNoOfAgent = async (id: number) => {
    setLoading(true);
    try {
      const response = await TeleAgentService.getNoOfTeleAgent(id);
      setAgentsInProcess(response);
    } finally {
      setLoading(false);
    }
  };

  const fetchLeadCount = (id: number) => {
    setCountLoading(true);
    TeleAgentService.getLeadCount(id)
      .then(res => {
        setLeadCount(res.status);
        setCountLoading(false);
      })
      .catch(error => {
        setCountLoading(false);
      });
  };

  function downloadAgents() {
    FileUtility.downloadJSON(
      agentsInProcess,
      `agents_${new Date().toISOString()}.json`
    );
  }

  useEffect(() => {
    if (processDetials) {
      fetchNoOfAgent(processDetials?.slashRtcProcessId);
      fetchLeadCount(processDetials?.slashRtcProcessId);
    }
  }, [processDetials]);

  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="sm"
        isCloseIcon
        open={openViewProcessDialog}
        handleClose={onClose}
        buttonComponent={() => <></>}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Details"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaPageContent sx={{ pt: '2rem', pb: '1rem' }}>
          <Grid container rowGap={'0.5rem'}>
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading={'Process Id'}
                value={processDetials?.processId}
              />
            </Grid>
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading="Process Name"
                value={processDetials?.processName}
              />
            </Grid>
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading="Campaign Name"
                value={processDetials?.campaignName}
              />
            </Grid>
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading="Campaign Id"
                value={processDetials?.campaignId}
              />
            </Grid>
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading="Pending Type"
                value={processDetials?.processType}
              />
            </Grid>
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading="Pending Leads"
                loading={countLoading}
                value={leadCount}
              />
            </Grid>
            <Grid item xs={6}>
              <VegaKeyValueDisplay
                heading="No. Of Agents"
                loading={loading}
                renderValue={() => {
                  return (
                    <Stack
                      direction={'row'}
                      spacing="0.5rem"
                      alignItems={'center'}
                    >
                      <VegaText
                        text={agentsInProcess.length ?? 0}
                        fontWeight={500}
                        fontSize={'0.8125rem'}
                        color={COMMON.balck}
                        width={'40%'}
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      />
                      <div
                        onClick={() => {
                          downloadAgents();
                        }}
                      >
                        <DownloadIcon />
                      </div>
                    </Stack>
                  );
                }}
              />
            </Grid>
          </Grid>

          <div style={{ margin: '1rem' }}></div>
          <VegaDataGrid
            data={agentsInProcess}
            columns={getColumnDefinition()}
            idColumn={'id'}
          />
        </VegaPageContent>
      </LmDialog>
    </div>
  );
};

export default ViewProcessDetailsDialog;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
      renderCell: props => {
        const agent = getAgent(props);
        const displayText = agent.username;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'designation',
      headerName: 'Slash RTC User Id',
      flex: 0.7,
      renderCell: props => {
        const agent = getAgent(props);
        const displayText = agent.slashrtcUserId;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0.7,
      renderCell: props => {
        const agent = getAgent(props);
        const displayText = DateUtility.formatStringToDDMMYYYYWithTime(
          agent.createdAt
        );
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
  ];
  return COL_DEF;
};

const getAgent = (props: any) => {
  return props.row as TeleAgentListProps;
};
