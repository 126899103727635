/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '../../features/userSlice';
import { AppDispatch, RootState } from '../../store';
import { VegaUser } from '../../types/claim';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import VegaButton from '../common/VegaButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<ReallocateAllocationFormData>) => void;
};

export type ReallocateAllocationFormData = {
  agentId: string;
  expiryDate: string;
  allocationType: string;
};

enum FormField {
  Agent = 'agentId',
  ExpiryDate = 'expiryDate',
}
const TODAY = new Date();
function ReallocateAllocationsDrawer({ open, onClose, onSubmit }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const agents = useSelector<RootState, VegaUser[]>(state => state.user.agents);
  const [formData, setFormData] = useState<
    Partial<ReallocateAllocationFormData>
  >({
    expiryDate: TODAY.toISOString(),
  });

  function handleClose() {
    onClose();
    cleanup();
  }

  function updateFormData(field: FormField, value: string) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  const isInputValid = () => {
    const isAgencyValid = (formData.agentId ?? '').length > 0;
    const isDatePresent = (formData.expiryDate ?? '').length > 0;
    const isDateValid =
      DateUtility.parseStringToDate(formData.expiryDate) != null;
    return isAgencyValid && isDatePresent && isDateValid;
  };

  function onExpiryDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    setFormData(prev => {
      return {
        ...prev,
        expiryDate: parsedDate?.toISOString(),
      };
    });
  }

  function onCreateClick() {
    onSubmit(formData);
    handleClose();
  }

  function cleanup() {
    setFormData({});
  }

  useEffect(() => {
    if (open == true) {
      dispatch(UserActions.fetchAgents());
    }
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Re-Allocate'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Allocate'}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agencies'}>
              <VegaSelect
                options={getAgentsOptions(agents)}
                value={formData.agentId ?? ''}
                onChange={e => {
                  updateFormData(FormField.Agent, e.target.value as string);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Expiry Date'}>
              <VegaDatePicker
                onChange={onExpiryDateChange}
                value={formData.expiryDate}
                minDate={new Date()}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ReallocateAllocationsDrawer;

const getAgentsOptions = (agents: VegaUser[]) => {
  const options: VegaSelectOption[] = agents.map(agent => {
    const option: VegaSelectOption = {
      value: agent.id,
      label: StringUtility.concatenateStrings(
        agent.firstName,
        agent.middleName,
        agent.lastName
      ),
    };
    return option;
  });
  return options;
};
