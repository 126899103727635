/** @format */

import { createTheme } from '@mui/material';
import { PRIMARY } from '../constants/style';

const factor = 0.9;
const primaryColors = {
  50: '#E7EDFC',
  75: '#9DB4F1',
  100: '#7494EB',
  200: '#3966E2',
  300: '#1047DC',
  400: '#0B329A',
  500: '#0A2B86',
};

export const NEUTRAL_COLORS = {
  BG: {
    50: '#FFFFFF',
    100: '#FAFAFA',
    200: '#F7F7F7',
  },
  BORDER: {
    50: '#E1E4EB',
    100: '#C5C8D0',
  },
  TEXT: {
    50: '#FFFFFF',
    100: '#999DA8',
    200: '#676B76',
    300: '#1B1D22',
  },
  GOLDEN: 'rgba(200, 168, 100, 1)',
};

export const STATUS_COLORS = {
  BLACK: 'rgba(27, 29, 34, 1)',
  BRIGHT_BLUE: 'rgba(26, 137, 201, 1)',
  DARK_BLUE: primaryColors[300],
  VIOLET: 'rgba(73, 37, 199, 1)',
  ORAGNE: 'rgba(232, 112, 1, 1)',
  RED: 'rgba(169, 25, 25, 1)',
  GREEN: 'rgba(49, 132, 57, 1)',
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    // dashed: true;
    soft: true;
  }
}

export const appTheme = createTheme({
  palette: {
    primary: {
      light: primaryColors[50],
      main: primaryColors[300],
      dark: primaryColors[500],
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#FFFFFF',
      default: '#EFF2F7',
    },
    text: {
      secondary: '#FFFFFF',
    },
  },

  typography: {
    body1: {
      // fontSize: 18 * factor,
      fontWeight: 500,
      fontSize: 13,
      fontFamily: 'Aspekta',
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          fontSize: [20 * factor, '!important'],
          fontWeight: [500, '!important'],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: 20 * factor,
          paddingRight: 20,
          borderRadius: 8,
          textTransform: 'capitalize',
          // startIcon: <CircularProgress color="secondary" />,
        },
      },

      variants: [],
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            '&.Mui-focused': {
              color: `${PRIMARY.main} !important`,
            },
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: PRIMARY.main,
            },
            ':hover fieldset': { borderColor: PRIMARY.main },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '7px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#AFAFAF',
        },
      },
    },
  },
});
