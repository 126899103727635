import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CreateMinVisitRuleProps } from '../types/minVisitsType';
import { MinVisitsSevices } from '../Apis/MinVisitsServices';
import {
  CreateMinVisitRuleRequest,
  MinVisitListProps,
} from '../types/request/minVisitsRequest';

export const fetchMinVisitList = createAsyncThunk(
  'minVisits/fetchMinVisitList',
  async (request: Partial<CreateMinVisitRuleRequest>) => {
    const response = await MinVisitsSevices.getMinVisitRuleList(request);
    return response;
  }
);

export interface MinVisitsState {
  openCreateRuleDrawer: boolean;
  openMinVisitsFilterDrawer: boolean;
  openDeleteDialog: boolean;
  loading: boolean;
  error: string;
  totalItems: number;
  selectedRegion: string;
  minVisits: MinVisitListProps[];
  minVisitsRuleState: Partial<CreateMinVisitRuleProps | null>;
}

const initialState: MinVisitsState = {
  openCreateRuleDrawer: false,
  openMinVisitsFilterDrawer: false,
  openDeleteDialog: false,
  loading: false,
  minVisitsRuleState: null,
  selectedRegion: '',
  error: '',
  totalItems: 0,
  minVisits: [],
};

const minVisitsSlice = createSlice({
  name: 'minVisits',
  initialState,
  reducers: {
    setOpenCreateRuleDrawer: (state: MinVisitsState, action) => {
      const { payload } = action;
      state.openCreateRuleDrawer = payload;
    },
    setOpenMinVisitsFilterDrawer: (state: MinVisitsState, action) => {
      const { payload } = action;
      state.openMinVisitsFilterDrawer = payload;
    },
    setOpenDeleteDialog: (state: MinVisitsState, action) => {
      const { payload } = action;
      state.openDeleteDialog = payload;
    },
    setMinVisitsRuleState: (state: MinVisitsState, action) => {
      const { payload } = action;
      state.minVisitsRuleState = payload;
    },
    setSelectedRegion: (state: MinVisitsState, action) => {
      const { payload } = action;
      state.selectedRegion = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMinVisitList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMinVisitList.fulfilled, (state, action) => {
        const minVisitsData = [...action.payload.records];

        state.loading = false;
        state.minVisits = minVisitsData;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchMinVisitList.rejected, (state, action) => {
        state.loading = false;
        state.minVisits = [];
        state.totalItems = 0;
        state.error = action.error.message || 'Failed to fetch min visit rule';
      });
  },
});
export const {
  setOpenCreateRuleDrawer,
  setOpenMinVisitsFilterDrawer,
  setOpenDeleteDialog,
  setMinVisitsRuleState,
  setSelectedRegion,
} = minVisitsSlice.actions;
export const getMinVisitsState = (state: RootState) => state.minVisits;
export default minVisitsSlice;
