import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stompClient: null,
  isConnected: false,
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    connectWebSocket: (state, action) => {
      state.stompClient = action.payload;
      state.isConnected = true;
    },
    disconnectWebSocket: state => {
      if (state.stompClient) {
        state.stompClient.disconnect();
      }
      state.stompClient = null;
      state.isConnected = false;
    },
  },
});

export const { connectWebSocket, disconnectWebSocket } = websocketSlice.actions;

export default websocketSlice;
