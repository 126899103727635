import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_GATEWAY } from '../Apis/axisConfig';
import { VegaFileSelectOption } from '../components/common/VegaFileSelect';
import { Possession } from '../types/auction';
import { PropertiesViewModel } from '../types/properties';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/repossession',
  LIST: '/list',
  AUCTION: '/auction',
};

export const propertiesApi = createApi({
  reducerPath: 'propertiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_GATEWAY,
    prepareHeaders: headers => {
      const token = sessionStorage.getItem('token');
      if (token) {
        headers.set('authorization', `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Properties'],
  endpoints: builder => ({
    fetchPropertiesList: builder.query<
      PaginatedResponse<PropertiesViewModel>,
      {
        auctionStatus: string;
        page: number;
        pageSize: number;
        includeAuctionDetails: boolean;
      }
    >({
      query: ({ auctionStatus, page, pageSize, includeAuctionDetails }) => ({
        url: Endpoint.BASE + Endpoint.LIST,
        method: 'POST',
        body: { auctionStatus, page, pageSize, includeAuctionDetails },
      }),
      transformResponse: (response: PaginatedResponse<Possession>) => {
        return {
          records: response.records.length
            ? response.records.map((propertie: Possession) => ({
                id: propertie.id,
                auctionId: propertie.auctionId,
                propertyName: propertie.id,
                type: propertie.type,
                images: propertie.images,
                reservePrice: propertie.metaData
                  ? propertie.metaData.auction.reservePrice
                  : 0,
                address: propertie.address,
                totalBidder: propertie.numberOfBidders || 0,
                status: propertie.metaData
                  ? propertie.metaData.auction.status
                  : '',
                coolDownInMinutes: propertie.metaData
                  ? propertie.metaData.auction.coolDownInMinutes ?? 0
                  : 0,
                minIncrement: propertie.metaData
                  ? propertie.metaData.auction.minIncrement
                  : 0,
              }))
            : [],
          pageNumber: response.pageNumber,
          numberOfItems: response.numberOfItems,
          totalPages: response.totalPages,
          totalItems: response.totalItems,
        };
      },
      providesTags: ['Properties'],
    }),

    addNewPropertise: builder.mutation({
      query: data => {
        const formValue = new FormData();
        data.images.map((file: VegaFileSelectOption, index: number) => {
          formValue.append(`images[${index}]`, file.value);
        });
        formValue.append('address', data.address);
        formValue.append('lan', data.lan);

        formValue.append('type', data.type);
        formValue.append('possessionDateTime', data.possessionDateTime);
        return {
          url: Endpoint.BASE,
          method: 'POST',

          body: formValue,
        };
      },
      invalidatesTags: ['Properties'],
    }),
    addAuctionPropertise: builder.mutation({
      query: data => ({
        url: Endpoint.AUCTION,
        method: 'POST',

        body: data,
      }),
      invalidatesTags: ['Properties'],
    }),
    updateAuctionPropertise: builder.mutation({
      query: data => ({
        url: Endpoint.AUCTION,
        method: 'PUT',

        body: data,
      }),
      invalidatesTags: ['Properties'],
    }),
  }),
});

export const {
  useFetchPropertiesListQuery,
  useAddNewPropertiseMutation,
  useAddAuctionPropertiseMutation,
  useUpdateAuctionPropertiseMutation,
} = propertiesApi;
