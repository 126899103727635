import { ApiScope } from "./client";

export interface UserDto {
  userId: string;
  clientId: string;
  name: string;
  mobileNumber: string;
  email: string;
  address: string;
  officialEmailId: string;
  idType: string;
  idNumber: string;
  isAdmin: boolean;
  status: VegaUserStatus;
  userRoles: UserRoleDto[];
  partnerId: string;
  corporateId: string;
  branchId: string;
  platformId: string;
  platformName: UserPlatform;
  mccUser: boolean;
  team: TeamType;
  designation: string;
  createdAt: Date;
  updatedAt: Date;
  apiScope: ApiScope;
}

export enum UserPlatform {
  FOREX_HQ = "FOREX_HQ",
  FOREX_BRANCH = "FOREX_BRANCH",
  FOREX_CORPORATE = "FOREX_CORPORATE",
}

export enum VegaUserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum VegaUserRoleStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface UserRoleDto {
  id: string;
  userId: string;
  roleId: string;
  roleName: string;
  status: VegaUserRoleStatus;
  createdAt: Date;
  updatedAt: Date;
  checkerMakerId: string;
  checkerMakerFlag: boolean;
  checkerMakerStatus: string;
}

export enum TeamType {
  OPERATIONS = "OPERATIONS",
  COLLECTIONS = "COLLECTIONS",
  FINANCE = "FINANCE",
  SETTLEMENT = "SETTLEMENT",
  PRODUCT_MANAGEMENT = "PRODUCT_MANAGEMENT",
  PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
  DESIGN = "DESIGN",
  DEVELOPER = "DEVELOPER",
  CXOS = "CXOS",
  MARKETING_AND_BRANDING = "MARKETING_AND_BRANDING",
  SUPPORT = "SUPPORT",
  BUSINESS_INTELLIGENCE = "BUSINESS_INTELLIGENCE",
  BUSINESS = "BUSINESS",
  RISK = "RISK",
  AGENCY_COLLECTION = "AGENCY_COLLECTION",
  AGENCY_SUPPORT = "AGENCY_SUPPORT",
  AGENCY_OPERATIONS = "AGENCY_OPERATIONS",
}
