import { Grid, Stack } from '@mui/material';
import {
  defaultProcessState,
  getTeleAllocation,
  setCreateProcessState,
  setOpenCreateProcessDrawer,
} from '../../../features/teleAllocationSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { CreateProcess } from '../../../types/teleAllocation';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';
import CampaignSelector from '../components/CampaignSelector';
import VegaDrawer from '../../common/VegaDrawer';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { fetchProcessList } from '../../../actions/TeleAllocationAction';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';

const CreateProcessFormDrawer = () => {
  const { createProcessState, openCreateProcessDrawer } =
    useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const disabled =
    !createProcessState.name.length || !createProcessState.campaign;

  const onClose = () => {
    dispatch(setOpenCreateProcessDrawer(false));
    dispatch(setCreateProcessState(defaultProcessState));
  };

  const handleChange = (key: keyof CreateProcess, value: string) => {
    const campaign = {
      ...createProcessState,
      [key]: value,
    };
    dispatch(setCreateProcessState(campaign));
  };

  const onCreateProcess = async () => {
    await TeleAllocationService.createProcess({
      ...createProcessState,
      callingMode: 3,
    })
      .then(() => {
        setSnackbar('Create Process Successfully !!!');
        dispatch(fetchProcessList());
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  return (
    <VegaDrawer
      title="Create Process"
      open={openCreateProcessDrawer}
      onClose={onClose}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text="Create Process"
                disabled={disabled}
                onClick={onCreateProcess}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={'1.25rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'process name'}>
              <LmTextField
                placeholder="Enter Process Name"
                value={createProcessState.name}
                onChange={e => handleChange('name', e.target.value)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'campaign id'}>
              <CampaignSelector
                selected={createProcessState.campaign}
                handleChange={e => handleChange('campaign', e)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'process type'}>
              <LmTextField placeholder="Leadset Name" value={'AUTO'} disabled />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default CreateProcessFormDrawer;
