export const NESTED_ROUTES = {
  CREATE: 'create',
  VIEW_DETAILS: 'view-details',
  EDIT: 'edit',
};

export const ROUTES = {
  HOME: '/home',
  ACCOUNT: '/account',
  UAM: '/user-access-management',
  UAM_PARAMS: '/user-access-management/:params',
  UAM_PARAMS_ID: '/user-access-management/:params/:paramsId',
  DEPOSIT_BANK: '/deposit-bank',
  EXPENSE: '/expense',
  CLAIMS: '/expense/report/:reportId/claims',
  CONVEYANCE_POLICY: '/expense/conveyance-policy',
  AUCTION_USERS: '/auction/users',
  AUCTION_PROPERTIES: '/auction/properties',
  LIVE_AUCTION_DETAILS: '/auction/live/:auctionId',
  LIVE_AUCTIONS: '/auctions/live',
  ALLOCATION_POLICY: '/allocation/policy',
  ALLOCATION_BULK_ASSIGN: '/allocation/bulk-assign',
  ALLOCATION_ASSIGN: '/allocation/assign',
  LEAVE_APPLY: '/leave/apply',
  LEAVEL_REQUEST_ACTIONS: '/leave/request-actions',
  RECEIPT_APPROVER: '/receipt-approver',
  RECEIPT_AGENT: '/receipt-agent',
  RECEIPT_AGENT_ISSUE_RECEIPT: '/receipt-agent/issue-receipt/:loanId',
  MONTHLY_PROGRESS: '/monthly-progress',
  MIN_NO_OF_VISITS: '/min-visits',
  TARGETS: '/targets',
  TELE_ALLCATION_BULK: '/tele-allocation/bulk',
  TELE_ALLCATION_MANUAL: '/tele-allocation/manual',
  TELE_AGENT_MANUAL: '/tele-agent/manual',
  TELE_AGENT_MANUAL_BY_LOAN_ACCOUNT_ID: '/tele-agent/manual/:loanAccountId',
  TELE_AGENT_AUTO: '/tele-agent/auto',
  Trail_Page: '/trail',
  Trail_Detail_Page: '/trail/:loanAccountId',
  TELE_ALLOCATION_STATUS: '/tele-allocation/status',
  TELE_ALLOCATION_RULE_POLICY: '/tele-allocation/rule-policy',
  TELE_ALLOCATION_BULK_LOAN_UPLOAD: '/tele-allocation/bulk-loan-upload',
  ACCOUNTS: '/accounts',
  ACCOUNT_BY_LOAN_ACCOUNT_ID: '/account/:loanAccountId',
  AGENTS: '/agents',
  INCENTIVES_GAMIFICATIONS: '/incentives-gamifications',
  INCENTIVES_GAMIFICATIONS_ADD: '/incentives-gamifications/add-on-policy',
  INCENTIVES_GAMIFICATIONS_VIEW: '/incentives-gamifications/view/:policyId',
  CALENDER: '/calender',
};

export const SUB_ROUTES = {
  HOLIDAY_TYPE: '/holiday-type',
  HOLIDAY_NAME: '/holiday-name',
  EXPENSE_REPORT_DRAFT: '/expense-report-draft',
  EXPENSE_REPORT_ONGOING: '/expense-claim-ongoing',
  EXPENSE_REPORT_HISTORY: '/expense-claim-history',
  EXPENSE_REPORT_SUBMITTED: '/expense-report-submitted',
  EXPENSE_CLAIM_DRAFT: '/expense-claim-draft',
  EXPENSE_CLAIM_SUBMITTED: '/expense-claim-submitted',
};

export const AUTH_ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  NEW_PASSWORD: '/new-password',
};
