import axios from 'axios';
import { ApiService } from '../types/api';
import {
  TargetCreateRequest,
  TargetListRequest,
  TargetUpdateStatusRequest,
} from '../types/request/target';
import { TargetListProps } from '../types/targets';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/allocation/target',
  LIST: '/list',
  UPDATE_STATUS: '/:id/status/:newStatus',
};

export class TargetSevices extends ApiService {
  static async getTargetList(
    request: Partial<TargetListRequest>
  ): Promise<PaginatedResponse<TargetListProps>> {
    const endpoint = Endpoint.BASE + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async createTarget(
    request: Partial<TargetCreateRequest>
  ): Promise<PaginatedResponse<TargetListProps>> {
    const endpoint = Endpoint.BASE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async updateTarget(
    request: Partial<TargetCreateRequest>
  ): Promise<PaginatedResponse<TargetListProps>> {
    const endpoint = Endpoint.BASE;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
  static async updateTargetStatus({
    id,
    newStatus,
  }: Partial<TargetUpdateStatusRequest>): Promise<any> {
    const endpoint =
      Endpoint.BASE +
      Endpoint.UPDATE_STATUS.replace(':id', id).replace(
        ':newStatus',
        newStatus
      );
    const response = await axios.put(endpoint);
    return response.data;
  }
}
