import { Box, Grid } from '@mui/material';
import LmTextField from '../common/LmTextField';
import { useBankDeposit } from '../../providers/BankDepositProvider';
import { fetchDepositBankById } from '../../Apis/depositBank';
import { useEffect, useState } from 'react';

const AddNewBankDeposit = () => {
  const {
    depositeBankState,
    selectedId,
    setDepositeBankState,
    handleDepositBankChange,
  } = useBankDeposit();
  const [loading, setLoading] = useState<boolean>(false);

  const getDepositBankById = async (id: string) => {
    try {
      const response = await fetchDepositBankById(id);
      setDepositeBankState(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedId) {
      setLoading(true);
      getDepositBankById(selectedId);
    }
  }, [selectedId]);
  return (
    <>
      <Box mb={2}>
        <LmTextField
          loading={loading}
          placeholder="Enter Bank Name"
          header="bank name"
          value={depositeBankState?.bankName}
          onChange={e => {
            const value = e.target.value;
            handleDepositBankChange('bankName', value);
          }}
        />
      </Box>

      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <LmTextField
              loading={loading}
              isInfo
              placeholder="Enter Account Number"
              header="Deposit Account Number"
              value={depositeBankState?.accountNumber}
              onChange={e => {
                const value = e.target.value;
                handleDepositBankChange('accountNumber', value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LmTextField
              loading={loading}
              isInfo
              placeholder="Enter Bank Code"
              header="Deposit Bank Code"
              value={depositeBankState?.bankCode}
              onChange={e => {
                const value = e.target.value;
                handleDepositBankChange('bankCode', value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <LmTextField
              loading={loading}
              isInfo
              placeholder="Enter Branch Name"
              header="Branch Name"
              value={depositeBankState?.branchName}
              onChange={e => {
                const value = e.target.value;
                handleDepositBankChange('branchName', value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LmTextField
              loading={loading}
              isInfo
              placeholder="Enter Branch Code"
              header="Branch Code"
              value={depositeBankState?.branchCode}
              onChange={e => {
                const value = e.target.value;
                handleDepositBankChange('branchCode', value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <LmTextField
              loading={loading}
              isInfo
              placeholder="Enter IFSC"
              header="IFSC"
              value={depositeBankState?.ifsc}
              onChange={e => {
                const value = e.target.value;
                handleDepositBankChange('ifsc', value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LmTextField
              loading={loading}
              isInfo
              placeholder="Enter MICR"
              header="MICR"
              value={depositeBankState?.micr}
              onChange={e => {
                const value = e.target.value;
                handleDepositBankChange('micr', value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <LmTextField
          loading={loading}
          placeholder="Enter Segment"
          header="Segment"
          value={depositeBankState?.segment}
          onChange={e => {
            const value = e.target.value;
            handleDepositBankChange('segment', value);
          }}
        />
      </Box>
    </>
  );
};

export default AddNewBankDeposit;
