export enum TypeOfLeave {
  SICK_LEAVE = 'SICK',
  CASUAL_LEAVE = 'CASUAL',
}

export enum HalfOfDay {
  FIRST_HALF = 'FIRST_HALF',
  SECOND_HALF = 'SECOND_HALF',
}

export enum LeaveStatus {
  APPLIED = 'APPLIED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}
export enum TabHeading {
  HISTORY = 'History',
  PENDING = 'Pending',
}

export type CreateApplyLeaveFormData = {
  typeOfLeave: string;
  startDate: Date;
  startDateHalf: string;
  endDate: Date;
  endDateHalf: string;
  reason: string;
};

export type LeaveBalanceViewModel = {
  typeofLeave: string;
  labels: string[];
  chartData: number[];
};

export type LeaveRequestFormViewModel = {
  typeOfLeave: string;
  startDate: Date;
  startDateHalf: string;
  endDate: Date;
  endDateHalf: Date;
  reason: string;
};

export type Leave = {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  leaveType: string;
  startDate: string;
  startHalf: string;
  endDate: string;
  endHalf: string;
  reasonRemarks: string;
  status: string;
  approverId: string;
  rejectionReason: string;
};

export type LeaveViewModel = {
  id: string;
  agentName: string;
  typesofLeave: string;
  startDateHalf: string;
  startDate: string;
  endDate: string;
  endDateHalf: string;
  reason: string;
  status: string;
  approversName: string;
  rejectionReason: string;
};
