/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { ExpenseService } from '../../Apis/ExpenseService';
import LmButton from '../../components/common/LmButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaText from '../../components/common/VegaText';
import VegaBreadCrumb from '../../components/common/VegaBreadCrumb';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { EditIcon } from '../../components/Icons/Icons';
import { PRIMARY } from '../../constants/style';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { SUB_ROUTES } from '../../router/routes';
import {
  ConveyanceRate,
  getDisplayTextForVehicleType,
} from '../../types/claim';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';

function ConveyancePolicy() {
  const [rates, setRates] = useState<ConveyanceRate[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>();
  const { setSnackbar } = useSnackbar();

  async function fetchCurrentRates() {
    try {
      setRates([]);
      setLoading(true);
      const response = await ExpenseService.getConveyanceRates({});
      setRates(response.records);
      setPage(response.pageNumber);
      setRowCount(response.numberOfItems);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCurrentRates();
  }, [page]);

  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaBreadCrumb
              items={[
                { label: 'Expense Claim', color: '#1047DC' },
                { label: 'Ongoing', link: SUB_ROUTES.EXPENSE_REPORT_ONGOING },
                { label: `Conveyance Policy` },
              ]}
            ></VegaBreadCrumb>
          );
        }}
      />
      <Box p={3}>
        <Stack
          direction={'row'}
          alignItems="end"
          justifyContent={'space-between'}
        >
          <VegaText
            text={'Conveyance Policy'}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
          <LmFilterSection setSearch={() => {}} />
        </Stack>
        <Box pt={3}>
          <VegaDataGrid
            data={rates}
            loading={loading}
            columns={getColumnDefinition({
              onEditClick: rate => {},
            })}
            idColumn="id"
            rowCount={rowCount}
            page={page}
            pageSize={10}
          />
        </Box>
      </Box>
    </div>
  );
}

export default ConveyancePolicy;

const getColumnDefinition = (data: {
  onEditClick: (rate: ConveyanceRate) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'ownership',
      headerName: 'Ownership',
      flex: 0.5,
      renderCell: () => {
        const displayText = 'Self';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'modeOfTransport',
      headerName: 'Mode of transport',
      flex: 0.7,
      renderCell: props => {
        const rate = getRateForRow(props);
        const displayText = getDisplayTextForVehicleType(rate.vehicleType);
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'ratePerKm',
      headerName: 'Rate/km',
      flex: 0.7,
      renderCell: props => {
        const rate = getRateForRow(props);
        const displayText = `${StringUtility.formatToINR(rate.ratePerKm)}`;
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      flex: 0.7,
      renderCell: props => {
        const rate = getRateForRow(props);
        const displayText =
          DateUtility.formatStringToDDMMYYYY(rate.startDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 0.7,
      renderCell: props => {
        const rate = getRateForRow(props);
        const displayText =
          DateUtility.formatStringToDDMMYYYY(rate.endDate) ?? '-';
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    // {
    //   field: 'createdBy',
    //   headerName: 'Created By',
    //   flex: 0.7,
    //   renderCell: props => {
    //     const rate = getRateForRow(props);
    //     const displayText = 'API_PENDING';
    //     return (
    //       <LmText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },
    // {
    //   field: 'modifiedBy',
    //   headerName: 'Modified By',
    //   flex: 0.7,
    //   renderCell: props => {
    //     const rate = getRateForRow(props);
    //     const displayText = 'API_PENDING';
    //     return (
    //       <LmText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
    //     );
    //   },
    // },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      renderCell: props => {
        const rate = getRateForRow(props);
        return (
          <LmButton
            text="Edit"
            startIcon={<EditIcon />}
            bgColor="#E7EDFC"
            textColor={PRIMARY.darkBlue}
            onClick={() => {
              data.onEditClick(rate);
            }}
          />
        );
      },
    },
  ];
  return COL_DEF;
};

const getRateForRow = (props: any) => props.row as ConveyanceRate;
