import VegaDrawer from '../../../components/common/VegaDrawer';

interface IProps {
  open: boolean;
  onClose: () => void;
  children: JSX.Element;
}

const ReceiptFilterDrawer = ({ open, onClose, children }: IProps) => {
  return (
    <VegaDrawer open={open} title={'Filter By'} onClose={onClose}>
      {children}
    </VegaDrawer>
  );
};

export default ReceiptFilterDrawer;
