import { AddCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import AddNewHoliday from '../components/HolidayName/AddNewHoliday';
import ListOfHolidays from '../components/HolidayName/ListOfHolidays';
import LmButton from '../components/common/LmButton';
import LmFilterSection from '../components/common/LmFilterSection';
import VegaDrawer from '../components/common/VegaDrawer';
import VegaDrawerContent from '../components/common/VegaDrawerContent';
import VegaPageHeader from '../components/common/VegaPageHeader';
import { holidayObj, useHolidayName } from '../providers/HolidayNameProvider';
import { ICreateHolidayProps } from '../types/holiday';

const LmHolidayName = () => {
  const {
    holidayState,
    isAdd,
    selectedHoliday,
    toggleAddHolidayNameDrawer,
    onAddHolidaySubmit,
    onUpdateHolidaySubmit,
    setHolidayState,
    setSelectedHoliday,
  } = useHolidayName();

  function hasEmptyOrNullProperty(obj: ICreateHolidayProps | null): boolean {
    if (!obj) return true;
    return Object.values(obj).some(
      value => value === null || value === undefined || value === ''
    );
  }
  const isDisabled = hasEmptyOrNullProperty(holidayState);

  const checkCondition = (selectedHoliday: string | null) => {
    if (selectedHoliday) {
      return {
        title: 'Update Holiday',
        buttonText: 'Update Details',
        buttonClick: () => {
          const newHolidayTypeObj = {
            ...holidayState,
            id: selectedHoliday,
          } as ICreateHolidayProps;

          onUpdateHolidaySubmit(newHolidayTypeObj);
        },
      };
    } else {
      return {
        title: 'Add New Holiday ',
        buttonText: 'Save Details',
        buttonClick: onAddHolidaySubmit,
      };
    }
  };

  return (
    <>
      <VegaPageHeader
        title="Holidays"
        renderRightView={() => (
          <LmButton
            text="Add New Holiday"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setSelectedHoliday(null);
              setHolidayState({ ...holidayObj });
              toggleAddHolidayNameDrawer();
            }}
          />
        )}
      />
      <Box p={3}>
        <LmFilterSection setSearch={() => {}} />
        <Box pt={3}>
          <ListOfHolidays />
        </Box>
      </Box>
      <VegaDrawer
        title={checkCondition(selectedHoliday?.id).title}
        open={isAdd}
        onClose={toggleAddHolidayNameDrawer}
      >
        <VegaDrawerContent
          renderBottomView={() => (
            <LmButton
              text={checkCondition(selectedHoliday?.id).buttonText}
              fontWeight={500}
              disabled={isDisabled}
              onClick={checkCondition(selectedHoliday?.id).buttonClick}
            />
          )}
        >
          <AddNewHoliday />
        </VegaDrawerContent>
      </VegaDrawer>
    </>
  );
};

export default LmHolidayName;
