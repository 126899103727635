/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  DateRangeFilter,
  FilterOption,
} from '../../../components/ExpenseClaim/FilterInput';
import { ApproveIcon } from '../../../components/Icons/Icons';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { GREEN, PRIMARY, RED } from '../../../constants/style';
import {
  getReceiptsList,
  receiptState,
  setOpenApproval,
  setOpenReject,
  setReceiptData,
} from '../../../features/receiptManagmentSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ReceiptManagmentViewModel,
  ReceiptStatus,
} from '../../../types/receiptManagment';
import { GetReceiptsListRequest } from '../../../types/request/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';
import ReceiptApproveDialog from './ReceiptApproveDialog';
import ReciptFilterBy from './ReciptFilterBy';
import ReceiptRejectDialog from './ReceiptRejectDialog';
import ReceiptFilterDrawer from './ReceiptFilterDrawer';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

const PendingReceiptManagment = () => {
  const dispatch = useAppDispatch();
  const { receipts, loading, totalItems } = useAppSelector(receiptState);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: TODAY.toISOString(),
    endDate: TOMORROW.toISOString(),
  });
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const COL_DEF: GridColumns<ReceiptManagmentViewModel[]> = [
    {
      field: 'receiptDate',
      headerName: 'Receipt Date',
      flex: 0.5,
    },
    {
      field: 'receiptId',
      headerName: 'Receipt ID',
      flex: 0.7,
    },
    {
      field: 'agentName',
      headerName: 'Agent name',
      flex: 0.5,
    },
    {
      field: 'lan',
      headerName: 'LAN',
      flex: 0.7,
    },
    {
      field: 'bankNameCode',
      headerName: 'Bank Name & Code',
      flex: 0.7,
    },
    {
      field: 'paymentMode',
      headerName: 'Payment Mode',
      flex: 0.7,
    },
    // {
    //   field: 'proof',
    //   headerName: 'proof',
    //   flex: 0.5,
    //   renderCell: props => {
    //     const properties = props.row as unknown as ReceiptManagmentViewModel;
    //     const proof = properties.proof;
    //     return (
    //       <VegaButton
    //         text="Preview"
    //         variant="text"
    //         sx={{
    //           color: PRIMARY.darkBlue,
    //           fontSize: '0.875rem',
    //           fontWeight: 500,
    //           px: 0,
    //         }}
    //         onClick={() => {}}
    //       />
    //     );
    //   },
    // },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptManagmentViewModel;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text="Approve"
              sx={{
                color: GREEN.dark,
                fontSize: '0.875rem',
                bgcolor: '#EBF4EC',
              }}
              startIcon={<ApproveIcon strokeColor={GREEN.dark} />}
              onClick={() => onApprovalOpen(receipt)}
            />
            <VegaButton
              text="Reject"
              sx={{
                color: RED.red,
                fontSize: '0.875rem',
                bgcolor: RED.lightRed,
              }}
              startIcon={<Close sx={{ color: RED.red }} />}
              onClick={() => onRejectOpen(receipt)}
            />
          </Stack>
        );
      },
    },
  ];

  const onApprovalOpen = (receipt: ReceiptManagmentViewModel) => {
    dispatch(setOpenApproval(true));
    dispatch(setReceiptData(receipt));
  };
  const onRejectOpen = (receipt: ReceiptManagmentViewModel) => {
    dispatch(setOpenReject(true));
    dispatch(setReceiptData(receipt));
  };

  const onClearAllClick = () => {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter('');
    closeFilterDrawer();
    fetchReceiptsList();
  };

  const onSelectFilterClick = (
    statusFilter: string,
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);
    const filteredReceiptObj = {
      status: ReceiptStatus.PENDING_APPROVAL,
      page,
      pageSize,
    } as GetReceiptsListRequest;

    if (dateRangeFilter.startDate.length && dateRangeFilter.endDate.length) {
      filteredReceiptObj.toDate = dateRangeFilter.endDate;
      filteredReceiptObj.fromDate = dateRangeFilter.startDate;
    }

    dispatch(getReceiptsList(filteredReceiptObj));
  };

  const fetchReceiptsList = () => {
    dispatch(
      getReceiptsList({
        status: ReceiptStatus.PENDING_APPROVAL,
        page,
        pageSize,
      })
    );
  };

  useEffect(() => {
    fetchReceiptsList();
  }, [page, pageSize]);

  return (
    <>
      <VegaPageHeader
        title="Pending"
        renderRightView={() => (
          <LmFilterSection
            setSearch={() => {}}
            onFilter={() => openFilterDrawer()}
          />
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={receipts}
          loading={loading}
          columns={COL_DEF}
          idColumn="id"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
        />
      </VegaPageContent>
      <ReceiptFilterDrawer
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <ReciptFilterBy
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[FilterOption.DayFilter]}
        />
      </ReceiptFilterDrawer>
      <ReceiptApproveDialog onFetchList={fetchReceiptsList} />
      <ReceiptRejectDialog onFetchList={fetchReceiptsList} />
    </>
  );
};

export default PendingReceiptManagment;
