import { Stack } from '@mui/material';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import { useAppSelector } from '../../../store';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';
import VegaPageContent from '../../common/VegaPageContent';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';

const AccountDetailsForTeleAgent = () => {
  const { loanData, loading } = useAppSelector(getTeleAgent);

  const getCustomerDetails = () => {
    if (loanData.length && loanData[0].customerDetails.length) {
      const customerData = loanData[0].customerDetails[0];
      return {
        customerName: customerData.customerName,
        loanId: customerData.loanId,
        mobileNo: customerData.mobileNo,
        customerAddress: customerData.customerAddress,
      };
    }
    return {
      customerName: '--',
      loanId: '--',
      mobileNo: '--',
      customerAddress: '--',
    };
  };
  const getLmsVariableData = () => {
    if (loanData.length && loanData[0].lmsVariables) {
      const lmsVariableData = loanData[0]?.lmsVariables;
      return {
        outstandingAmount: StringUtility.formatToINR(
          lmsVariableData?.outstandingAmount
        ),
        bucket: lmsVariableData.bucket,
      };
    }
    return {
      outstandingAmount: '--',
      bucket: '--',
    };
  };
  const getMetaDataLoanAllocationDto = () => {
    if (loanData.length && loanData[0].metaDataLoanAllocationDto) {
      const metaDataLoanAllocationDto = loanData[0]?.metaDataLoanAllocationDto;
      return {
        allocationDate: DateUtility.formatStringToDDMMYYYY(
          metaDataLoanAllocationDto?.allocationDate
        ),
      };
    }
    return {
      allocationDate: '--',
    };
  };

  return (
    <>
      <VegaPageContent>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
          <TeleAgentTextWrapper
            text="name"
            value={getCustomerDetails().customerName}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={loading}
          />
          <TeleAgentTextWrapper
            text="Loan Account Number"
            value={getCustomerDetails().loanId}
            isBorder
            sx={{
              minWidth: '140px',
            }}
            loading={loading}
          />
          <TeleAgentTextWrapper
            text="POS"
            value={getLmsVariableData().outstandingAmount}
            isBorder
            sx={{
              minWidth: '42px',
            }}
            loading={loading}
          />
          <TeleAgentTextWrapper
            text="Bucket"
            value={getLmsVariableData().bucket}
            isBorder
            sx={{
              minWidth: '57px',
            }}
            loading={loading}
          />
          <TeleAgentTextWrapper
            text="Contact number"
            value={getCustomerDetails().mobileNo}
            isBorder
            sx={{
              minWidth: '106px',
            }}
            loading={loading}
          />
          <TeleAgentTextWrapper
            text="Allocation Date"
            value={getMetaDataLoanAllocationDto().allocationDate}
            isBorder
            sx={{
              minWidth: '65px',
            }}
            loading={loading}
          />
          <TeleAgentTextWrapper
            text="Address"
            value={getCustomerDetails().customerAddress}
            sx={{
              minWidth: '425px',
            }}
            loading={loading}
          />
        </Stack>
      </VegaPageContent>
    </>
  );
};

export default AccountDetailsForTeleAgent;
