import { Close } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { GREY } from '../../constants/style';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { ExpenseClaim, ExpenseClaimViewModel } from '../../types/claim';
import { StringUtility } from '../../utils/StringUtility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';

type Props = {
  show: boolean;
  claim?: ExpenseClaim;
  onApproveClick: (claim: ExpenseClaim) => void;
  onCancelClick: () => void;
};

function ApproveConfirmationDialog({
  show,
  onCancelClick,
  onApproveClick,
  claim,
}: Props) {
  const { fetchExpenseClaim } = useExpenseApi();
  const { setSnackbar } = useSnackbar();

  const [claimViewModel, setClaimViewModel] =
    useState<ExpenseClaimViewModel | null>(null);

  async function fetchClaim(claimId: string) {
    try {
      const response = await fetchExpenseClaim(
        {
          includeDocumentMetaData: false,
          id: claimId,
        },
        { includeClaimant: true }
      );
      setClaimViewModel(response);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }

  const getAgentName = () => {
    const claimant = claimViewModel?.claimant;
    return StringUtility.concatenateStrings(
      claimant?.firstName,
      claimant?.middleName,
      claimant?.lastName
    );
  };

  useEffect(() => {
    if (show == true && claim?.id != null) {
      fetchClaim(claim.id);
    }
  }, [show]);

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={onCancelClick}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <LmButton
            text="Cancel"
            fullWidth
            variant="outlined"
            bgColor=""
            textColor={GREY.dark}
            onClick={onCancelClick}
            startIcon={<Close sx={{ color: GREY.dark }} />}
          />
          <LmButton
            fullWidth
            text="Approve"
            bgColor={'#1047DC'}
            onClick={() => {
              if (!claimViewModel) return;
              onApproveClick(claimViewModel);
            }}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E7EDFC'}
        >
          <VegaText text="Are you sure?" fontWeight={600} fontSize={17} />
        </Stack>
      )}
    >
      <Box p={4}>
        <Typography
          className="font-aspekta-400"
          color={'#999DA8'}
          fontSize={'0.8125rem'}
          lineHeight={'15.6px'}
          mt={1}
        >
          {` You are approving the Claim ID #${StringUtility.extractLastNDigits(
            4,
            claim?.id
          )} of Agent ${getAgentName()}.`}
        </Typography>
      </Box>
    </LmDialog>
  );
}

export default ApproveConfirmationDialog;
