import axios from 'axios';
import { Bid } from '../types/auction';
import { CreateBidRequest, GetBidListRequest } from '../types/request/auction';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/auction/bid',
  CREATE: '/',
  LIST: '/list',
};

const BASE_URL = Endpoint.BASE;

export class BidService {
  static async createBid(request: Partial<CreateBidRequest>): Promise<Bid> {
    const endpoint = BASE_URL + Endpoint.CREATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getBidList(
    request: Partial<GetBidListRequest>
  ): Promise<PaginatedResponse<Bid>> {
    const endpoint = BASE_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
}
