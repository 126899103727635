import { Box, Stack } from '@mui/material';
import { BORDER_COLLECTION, COMMON } from '../../../constants/style';
import VegaText from '../../common/VegaText';
import { CalenderTimerIcon, TagIcon, WalletIcon } from '../../Icons/Icons';

const notifications = [
  {
    header: 'Expense Approval Pending',
    icon: <WalletIcon />,
    day: 'TODAY',
    notification: 'Agent Rahul has submitted expense report for 09/10/2023.',
  },
  {
    header: 'Trails Update',
    icon: <TagIcon strokeColor="black" />,
    day: 'TODAY',
    notification: 'Today’s PTP: XX accounts worth Rs. YY Crore POS',
  },
  {
    header: 'Leave Request',
    icon: <CalenderTimerIcon />,
    day: 'YESTERDAY',
    notification:
      'XX agent applied Leave for 12/03/2023 First Half to 12/02/2023 Seconds Half',
  },
];

const AdminNotifications = () => {
  return (
    <>
      <Stack
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '0.75rem',
          bgcolor: 'white',
          gap: 1,
          height: '100%',
        }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: BORDER_COLLECTION,
          }}
        >
          <VegaText
            text={'Notifications'}
            fontWeight={500}
            fontSize={14}
            color={COMMON.balck}
          />
        </Box>
        <Stack
          gap={2}
          sx={{
            p: 2,
          }}
        >
          {notifications.map(notify => (
            <Stack key={notify.header} gap={0.5}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack direction={'row'} gap={1}>
                  <Box>{notify.icon}</Box>
                  <VegaText
                    text={notify.header}
                    fontWeight={500}
                    fontSize={14}
                    color={COMMON.balck}
                  />
                </Stack>
                <VegaText
                  text={notify.day}
                  fontWeight={400}
                  fontSize={10}
                  color={'#676B76'}
                />
              </Stack>
              <VegaText
                text={notify.notification}
                fontWeight={400}
                fontSize={12}
                color={'#676B76'}
              />
              <VegaText
                sx={{
                  cursor: 'pointer',
                }}
                text={'View Details'}
                fontWeight={500}
                fontSize={9}
                color={'#1047DC'}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  );
};

export default AdminNotifications;
