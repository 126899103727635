import { Box, Grid, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { ReceiptManagmentService } from '../../../Apis/ReceiptManagmentService';
import { VisitService } from '../../../Apis/VisitService';
import { fetchAllocationsMetaData } from '../../../actions/AllocationActions';
import ExpenseCard from '../../../components/Calender/ExpenseCard';
import ListOfTopCollectionAccount from '../../../components/Home/AgentHome/ListOfTopCollectionAccount';
import PolicyCard from '../../../components/Home/AgentHome/PolicyCard';
import HomeSummariesCard from '../../../components/Home/components/HomeSummariesCard';
import VegaOutlineDatePicker from '../../../components/common/VegaOutlineDatePicker';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { getAllocations } from '../../../features/allocationSlice';
import { useClientAuth } from '../../../providers/ClientProvider';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { DateUtility } from '../../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

const AgentHomePage = () => {
  const { totalItemsOfMetaData, loading } = useAppSelector(getAllocations);
  const dispatch = useAppDispatch();
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [totalVisits, setTotalVisits] = useState<number>(0);
  const [loadingVisits, setLoadingVisits] = useState<boolean>(false);
  const [totalReceipts, setTotalReceipts] = useState<number>(0);
  const [loadingReceipts, setLoadingReceipts] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<string>(
    DateUtility.formatStringToYYYYMMDD(new Date().toDateString())
  );
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const DUMMY = [
    {
      label: 'Incentive',
      value: '24',
      sameDayValue: '65',
      growth: 'UP',
      loading: false,
    },
    {
      label: 'accounts',
      value: totalItemsOfMetaData,
      sameDayValue: '65',
      growth: 'UP',
      loading: loading,
    },
    {
      label: 'Visits',
      value: totalVisits,
      sameDayValue: '65',
      growth: 'UP',
      loading: loadingVisits,
    },
    {
      label: 'Receipts',
      value: totalReceipts,
      sameDayValue: '65',
      growth: 'DOWN',
      loading: loadingReceipts,
    },
  ];

  const handleChangeDate = (e: any) => {
    const date = DateUtility.formatStringToYYYYMMDD(e);
    setDateValue(date);
  };
  const getVisits = () => {
    setLoadingVisits(true);
    VisitService.getVisitListByAgentId({ agentId: userId })
      .then(res => {
        setTotalVisits(res.totalItems);
        setLoadingVisits(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error'),
          setLoadingVisits(false);
      });
  };
  const getReceipts = () => {
    setLoadingReceipts(true);
    ReceiptManagmentService.getReceiptsList({ agentId: userId })
      .then(res => {
        setTotalReceipts(res.totalItems);
        setLoadingReceipts(false);
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error'),
          setLoadingReceipts(false);
      });
  };
  useEffect(() => {
    dispatch(
      fetchAllocationsMetaData({ status: 'ALLOCATED', page, size: pageSize })
    );
  }, [page, pageSize]);

  useEffect(() => {
    getVisits();
    getReceipts();
  }, []);
  return (
    <>
      <Box height={'100%'}>
        <VegaPageHeader
          renderLeftView={() => {
            return (
              <Stack spacing={'2rem'}>
                <VegaText
                  text={'Hi, Akash'}
                  fontWeight={600}
                  fontSize={'1.0625rem'}
                  color={'black'}
                />
              </Stack>
            );
          }}
          renderRightView={() => {
            return (
              <Stack direction={'row'} gap={1}>
                <VegaOutlineDatePicker
                  onChange={handleChangeDate}
                  value={dateValue}
                  maxDate={new Date()}
                />
              </Stack>
            );
          }}
        />
        <Box height={'calc(100% - 95px)'} sx={{ overflow: 'scroll' }}>
          <VegaPageContent mt={'1rem'}>
            <Grid container spacing={2}>
              {DUMMY.map((card, index) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <HomeSummariesCard
                    label={card.label}
                    value={card.value}
                    sameDayValue={card.sameDayValue}
                    growth={card.growth}
                    loading={card.loading}
                  />
                </Grid>
              ))}
            </Grid>
          </VegaPageContent>
          <VegaPageContent mt={'1rem'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <ListOfTopCollectionAccount
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <ExpenseCard date={dayjs(dateValue)} />
                <PolicyCard />
              </Grid>
            </Grid>
          </VegaPageContent>
        </Box>
      </Box>
    </>
  );
};

export default AgentHomePage;
