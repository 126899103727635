import { GridColumns } from '@mui/x-data-grid';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import {
  fetchCalender,
  fetchExpenseReport,
  getCalenderState,
} from '../../features/calenderSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { ICalenderRequest } from '../../types/request/CalenderRequest';
import { DateUtility } from '../../utils/DateUtlility';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaText from '../common/VegaText';
import { Stack } from '@mui/material';
import { useClientAuth } from '../../providers/ClientProvider';

interface IProps {
  date: Dayjs | null;
}

const ListOfAccountCalender = ({ date }: IProps) => {
  const { accounts, loading } = useAppSelector(getCalenderState);
  const dispatch = useAppDispatch();
  const { userId } = useClientAuth();

  useEffect(() => {
    if (date) {
      const request = {
        agentId: userId,
        date: DateUtility.formatStringToYYYYMMDD(dayjs(date).toISOString()),
      } as ICalenderRequest;
      dispatch(fetchCalender(request));
      dispatch(fetchExpenseReport({ dateOfExpense: date.toISOString() }));
    }
  }, [date]);

  return (
    <Stack sx={{ width: '100%' }}>
      <VegaText
        text={'Account'}
        fontWeight={600}
        fontSize={'1.0625rem'}
        color={'black'}
        sx={{ mb: '0.5rem' }}
      />
      <VegaDataGrid
        data={accounts}
        idColumn="id"
        loading={loading}
        columns={getColumnDefinition()}
      />
    </Stack>
  );
};

export default ListOfAccountCalender;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'lan',
      headerName: 'Loan A/c No.',
      flex: 0.7,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.7,
    },
    {
      field: 'dispositionCode',
      headerName: 'Deposition Code',
      flex: 0.7,
    },
  ];
  return COL_DEF;
};
