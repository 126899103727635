import { MinimumEligibilityCriteriaProps } from './request/incentives';

export enum IncentiveAccountType {
  ALL = 'ALL',
  NORMAL = 'NORMAL',
}

export enum IncentiveType {
  POINT_BASED = 'POINT_BASED',
  METRIC_BASED = 'METRIC_BASED',
}
export enum IncentivePolicyType {
  ADD_ON_POLICY = 'ADD_ON_POLICY',
  NEW_POLICY = 'NEW_POLICY',
}
export enum IncentiveChannelType {
  FIELD = 'FIELD',
}
export enum IncentivePayoutFrequencyType {
  FORTNIGHT = 'FORTNIGHT',
  MONTHLY = 'MONTHLY',
  QUATERLY = 'QUATERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY',
}

export enum IncentivePayoutType {
  PER_CASE = 'PER_CASE',
  PER_PERSON = 'PER_PERSON',
}

export enum IncentiveCollectionType {
  RESOLUTION = 'RESOLUTION',
  NORMALIZATION = 'NORMALIZATION',
  ROLL_BACK = 'ROLL_BACK',
  PENALTY_COLLECTION = 'PENALTY_COLLECTION',
  NORMALIZATION_AND_ROLLBACK = 'NORMALIZATION_AND_ROLLBACK',
  NO_OF_CASES = 'NO_OF_CASES',
  AMOUNT_COLLECTED = 'AMOUNT_COLLECTED',
}
export enum IncentiveComputationBasisType {
  POS = 'POS',
  OVERDUE_AMOUNT = 'OVERDUE_AMOUNT',
  NO_OF_CASES_ALLOCATED = 'NO_OF_CASES_ALLOCATED',
  CHARGES_DUE = 'CHARGES_DUE',
}

export enum IncentiveCalulationMetrixType {
  PER_EMI = 'PER_EMI',
  PER_CASE = 'PER_CASE',
  PERCENTAGE_AMOUNT_COLLECTED = 'PERCENTAGE_AMOUNT_COLLECTED',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE_INCENTIVE_EARNED = 'PERCENTAGE_INCENTIVE_EARNED',
}

export enum IncentivePayoutMetrixType {
  PERCENTAGE = 'PERCENTAGE',
  PER_CASE = 'PER_CASE',
  PER_EMI = 'PER_EMI',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
}

export enum IncentivePayoutPolicyType {
  BASE = 'BASE',
  BUMPER = 'BUMPER',
  PENALTY = 'PENALTY',
}

export enum IncentivePolicyReviewStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  IN_REVIEW = 'IN_REVIEW',
  DELETED = 'DELETED',
}
export enum IncentiveModeOfCommunicationType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
export enum IncentiveVerticalType {
  CGCL = 'CGCL',
}

export interface ViewIncentiesGamificationsModal {
  id: string;
  policyName: string;
  vertical: string;
  channelType: string;
  userType: string[];
  payoutFrequency: string;
  startDate: string;
  endDate: string;
  status: string;
}

export interface RangeProps {
  id: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  parameter: string;
  parameterMin: number;
  parameterMax: number | null;
  parameterMetric: string;
  payoutValue: number;
  payoutMetric: string;
  payoutCapMin: number | null;
  payoutCapMax: number | null;
  payoutCapMetric: string | null;
  from: string | null;
  to: string | null;
  isSubjectToAchievingTarget: boolean | null;
  targetValue: number | null;
  incentivePayoutPolicyType: string;
  createdBy: string | null;
}

export interface RuleProps {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  bucket: string;
  collectionType: string | null;
  incentiveType: string | null;
  accountType: string;
  caseType: string;
  setMinimumEligibility: boolean;
  maximumPayoutAmount: number;
  maximumPayoutType: string;
  minimumEligibilityParameters: Partial<MinimumEligibilityCriteriaProps> | null;
  basePayoutRange: RangeProps[] | null;
  bumperPayoutRange: RangeProps[] | null;
  penaltyRange: RangeProps[] | null;
}

export interface PayoutConfiguration {
  [key: string]: Partial<RuleProps>;
}

export interface IncentivePolicyProps {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  policyName: string;
  vertical: string;
  channelType: string;
  policyType?: string;
  caseType?: string;
  incentiveType: string;
  accountType: string;
  payoutFrequency: string;
  userType: string[];
  modeOfCommunication: string[];
  createdBy?: string | null;
  approvedBy?: string | null;
  maxPayoutPerPerson: number;
  policyStartDate: string;
  policyEndDate: string;
  rules?: Partial<PayoutConfiguration>;
  status?: string;
}
