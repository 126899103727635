import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  TextFieldProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { toLowerCase } from '../../constants/commonFunction';
import VegaText from './VegaText';

export interface VegaSelectOption {
  value: any;
  label: string;
  icon?: JSX.Element;
  checked?: boolean;
}

interface LosSelectProps {
  placeholder?: string;
  hideDropDownIcon?: boolean;
  dropDownIcon?: ReactNode;
  options?: VegaSelectOption[];
  renderOptions?: () => ReactNode;
  onScroll?: (event: any) => void;
  renderSelectedValue?: (selected: string) => ReactNode;
  hidePlaceholder?: boolean;
  loading?: boolean;
}

function VegaSelect({
  options,
  placeholder,
  renderOptions,
  renderSelectedValue,
  hideDropDownIcon,
  hidePlaceholder = false,
  loading,
  onScroll,
  ...rest
}: LosSelectProps & SelectProps & TextFieldProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getLabelForSelectedValue = (selected: string) => {
    const selectedOption =
      options?.filter(item => item.value == selected) ?? [];
    if (selectedOption.length > 0) {
      return selectedOption[0].label;
    }
    return selected;
  };

  return (
    <FormControl>
      {rest.label && (
        <InputLabel
          size="small"
          sx={{ color: '#AFAFAF' }}
          htmlFor={rest.labelId}
        >
          {rest.label}
        </InputLabel>
      )}
      <Select
        {...rest}
        sx={{
          borderRadius: '.5rem',
          maxHeight: '40px',

          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: theme.palette.grey[400],
          },
          ...(isMobile == false && {
            backgroundColor: 'white',
          }),
          ...rest.sx,
        }}
        MenuProps={{
          PaperProps: {
            onScroll: onScroll ? onScroll : () => {},
          },
          style: { maxHeight: onScroll ? 300 : 'auto' },
        }}
        displayEmpty
        IconComponent={hideDropDownIcon ? () => null : undefined}
        renderValue={(selected: any) => {
          if (
            hidePlaceholder == false &&
            (!selected || selected?.length <= 0)
          ) {
            return <VegaText text={placeholder ?? ''} color={'#C5C8D0'} />;
          }
          if (renderSelectedValue) {
            return renderSelectedValue(selected);
          }
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <VegaText
                text={toLowerCase(getLabelForSelectedValue(selected))}
                style={{ fontSize: '13px', lineHeight: '120%' }}
              />
            </div>
          );
        }}
      >
        {renderOptions && renderOptions()}
        {options?.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={option.value}
              style={{ fontFamily: 'Aspekta !important' }}
            >
              <Stack direction={'row'} gap={2} alignItems="center">
                {option.icon && option.icon}
                <VegaText
                  text={toLowerCase(option.label)}
                  style={{ fontSize: '14px' }}
                ></VegaText>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default VegaSelect;
