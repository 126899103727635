/* eslint-disable no-useless-catch */
import axios from 'axios';

import { ICreateRoleProps, ICreateUserProps } from '../types/UAM';
import { IRoleProps } from '../components/UAM/UAMDetails/components/DataRestriction';

const COLLECTIONS = {
  BASE: '/master',
  USER: '/user',
  USER_BY_ID: '/user/userId',
  LIST_ROLE_FOR_USER: '/user/byUserId/{userId}',
  LIST_MANAGER_FOR_USER: '/user/assigned-manager',
  USER_LIST: '/user/list',
  ASSIGN_ROLE: '/user/assign-role',
  MANAGER_BY_ROLE_ID: '/user/manager/role',
  ROLES: '/roles',
  ROLES_BULK: '/roles/bulk',
  ROLES_BY_ENTITY_ID_FOR_ENTITY: '/roles/entity/{entityId}',
};

export const fetchUsersList = async (page: number, size: number) => {
  try {
    const endpoint = COLLECTIONS.USER_LIST;
    const response = await axios.post(endpoint, { page: page, size: size });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserById = async (userId: string) => {
  try {
    const response = await axios.get(
      COLLECTIONS.USER_BY_ID.replace('userId', userId)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchRoleListForUser = async (userId: string) => {
  try {
    const response = await axios.get(
      COLLECTIONS.LIST_ROLE_FOR_USER.replace('{userId}', userId)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export type GetAssignedManagerRequest = {
  userId: string;
  entityId: string;
  roleId: string;
};
export const fetchManagerForUser = async (
  request: Partial<GetAssignedManagerRequest>
) => {
  try {
    const response = await axios.post(
      COLLECTIONS.LIST_MANAGER_FOR_USER,
      request
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRolesByClientId = async (clientId: string) => {
  const endpoint = '/roles/client/' + clientId;
  const response = await axios.get(endpoint);
  return response.data as IRoleProps[];
};

export type FetchManagerRequest = {
  roleId: string;
  entityIds: string[];
};

export const fetchManagerByRoleId = async (request: FetchManagerRequest) => {
  try {
    const response = await axios.post(COLLECTIONS.MANAGER_BY_ROLE_ID, request);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAssignRole = async (userData: Object) => {
  try {
    const response = await axios.post(COLLECTIONS.ASSIGN_ROLE, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const createNewUser = async (userData: ICreateUserProps) => {
  try {
    const response = await axios.post(`${COLLECTIONS.USER}`, userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const createRole = async (role: ICreateRoleProps[]) => {
  try {
    const response = await axios.post(COLLECTIONS.ROLES_BULK, role);
    return response.data;
  } catch (error) {
    throw error;
  }
};
