/* eslint-disable no-unused-vars */
import { ArrowBackIos } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import LmTextField from '../components/common/LmTextField';
import VegaButton from '../components/common/VegaButton';
import { useSnackbar } from '../providers/SnackbarProvider';

interface IProps {
  forgotPasswordHandler: (state: string) => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const ForgotPassword = ({ forgotPasswordHandler, email, setEmail }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isDisabled = () => {
    if (isValidEmail) {
      if (!email.length) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const resendCode = () => {
    if (email) {
      Auth.forgotPassword(email)
        .then(() => {
          forgotPasswordHandler('NEW_PASSWORD');
          setSnackbar(`Code send to ${email}`);
        })
        .catch(error => {
          setSnackbar(`${error.message}`, 'error');
          forgotPasswordHandler('LOGIN');
        });
    } else {
      forgotPasswordHandler('LOGIN');
    }
  };

  useEffect(() => {
    if (!email.length) {
      setIsValidEmail(false);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton onClick={() => forgotPasswordHandler('LOGIN')}>
            <ArrowBackIos sx={{ color: 'black', fontSize: 14 }} />
          </IconButton>
          <Typography fontSize={14} className="font-aspekta-600">
            Back
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          maxWidth: isMobile ? '330px' : '400px',
          mx: 'auto',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography
            fontSize={17}
            className="font-aspekta-600"
            color={'black'}
          >
            Forgot your password
          </Typography>
          <Box
            my={3}
            component={'form'}
            onSubmit={e => {
              e.preventDefault();
              resendCode();
              //   forgotPasswordHandler('NEW_PASSWORD');
            }}
          >
            <Box mb={2}>
              <LmTextField
                header="email id"
                type="email"
                error={!isValidEmail}
                value={email}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >
                ): void => {
                  const inputValue = event.target.value;
                  setEmail(inputValue);

                  setIsValidEmail(validateEmail(inputValue));
                }}
              />
            </Box>

            <Stack spacing={2}>
              <VegaButton
                type="submit"
                text="Continue"
                disabled={isDisabled()}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
