import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DateUtility } from '../../utils/DateUtlility';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import { VegaFileSelectOption } from './BulkAllocationFileSelect';
import { AllocationTypes } from '../../types/allocations';
import { getUsers, UserActions } from '../../features/userSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { VegaUser } from '../../types/claim';
import { StringUtility } from '../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onAllocateClick: (formData: Partial<ExecuteAllocationFormData>) => void;
};

export type ExecuteAllocationFormData = {
  agentId: string;
  allocationType: string;
  expiryDate: string;
};

enum InputType {
  AgentId = 'agentId',
  AllocationType = 'allocationType',
  ExpiryDate = 'expiryDate',
}

const TODAY = new Date();
function ExecuteAllocationRuleDrawer({
  onAllocateClick,
  open,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const { agents, loading } = useAppSelector(getUsers);
  const [formData, setFormData] = useState<Partial<ExecuteAllocationFormData>>({
    expiryDate: TODAY.toISOString(),
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }
  function onSumbitClick() {
    onAllocateClick(formData);
    setFormData({
      expiryDate: '',
    });
  }
  function onAgentIdChange(value) {
    updateFormData(InputType.AgentId, value);
  }
  function onAllocationTypeChange(value) {
    updateFormData(InputType.AllocationType, value);
  }
  function updateExpiryDate(value) {
    updateFormData(InputType.ExpiryDate, value);
  }

  useEffect(() => {
    const isAllocationTypeValid = !!formData.allocationType;
    const isExpiryDateValid = !!formData.expiryDate;
    setIsSubmitDisabled(!(isAllocationTypeValid && isExpiryDateValid));
  }, [formData]);

  useEffect(() => {
    if (open) {
      dispatch(UserActions.fetchAgents());
    }
  }, [open]);

  return (
    <VegaDrawer open={open} title={'Execute Rule'} onClose={onClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Sumbit"
              onClick={onSumbitClick}
              disabled={isSubmitDisabled}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent'}>
              <VegaSelect
                options={getAgentsOptions(agents)}
                loading={loading}
                value={formData.agentId ?? ''}
                onChange={e => onAgentIdChange(e.target.value as string)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Allocation Type">
              <VegaSelect
                options={allocationTypeOptions()}
                value={formData.allocationType}
                onChange={e => {
                  onAllocationTypeChange(e.target.value);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
            <LmDateField
              header="Expiry Date"
              value={formData.expiryDate}
              onChange={e => {
                updateExpiryDate(DateUtility.parseDateFromDatePicker(e));
              }}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ExecuteAllocationRuleDrawer;

const allocationTypeOptions = () =>
  Object.keys(AllocationTypes).map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const getAgentsOptions = (agents: VegaUser[]) => {
  const options: VegaSelectOption[] = agents.map(agent => {
    const option: VegaSelectOption = {
      value: agent.id,
      label: StringUtility.concatenateStrings(
        agent.firstName,
        agent.middleName,
        agent.lastName
      ),
    };
    return option;
  });
  return options;
};
