export enum AccessType {
  RESTRICTED = 'RESTRICTED',
  NON_RESTRICTED = 'NON_RESTRICTED',
}
export interface IHolidayTypeListProps {
  createdAt: string | Date;
  updatedAt: string | Date;
  id: string;
  name: string;
  description: string | null;
  receiptIssuance: string;
  receiptDeposition: string;
  receiptAuthorization: string;
}

export interface ICreateHolidayTypeProps {
  id?: string;
  name?: string;
  description?: string;
  receiptIssuance?: AccessType;
  receiptIssuanceDate?: string;
  receiptDeposition?: AccessType;
  receiptDepositionDate?: string;
  receiptAuthorization?: AccessType;
  receiptAuthorizationDate?: string;
  createdAt: string;
  updatedAt: string;
}
export interface ICreateHolidayProps {
  id?: string | undefined;
  name: string;
  startDateTime: string;
  endDateTime: string;
  holidayType: string;
  holidayTimeline?: string;
}
