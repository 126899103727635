import axios from 'axios';
import { VegaUser } from '../types/claim';
import { GetUserListApiRequest } from '../types/request/user';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/user',
  GET_USER_BY_ID: '/{id}',
  GET_AGENTS: '/agents',
  LITS_USERS: '/list',
};

const BASE_URL = Endpoint.BASE;
export class UserService {
  static async getUserById(userId: string): Promise<VegaUser> {
    const endpoint = BASE_URL + Endpoint.GET_USER_BY_ID.replace('{id}', userId);
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getAgents(): Promise<VegaUser[]> {
    const endpoint = BASE_URL + Endpoint.GET_AGENTS;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async getUsers(
    request: Partial<GetUserListApiRequest>
  ): Promise<PaginatedResponse<VegaUser>> {
    const endpoint = BASE_URL + Endpoint.LITS_USERS;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
