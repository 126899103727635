/* eslint-disable react/prop-types */
import { GridColumns } from '@mui/x-data-grid';
import { PRIMARY } from '../../constants/style';
import {
  IPastUploadListProps,
  dummy_PastUpload,
} from '../../providers/BankDepositProvider';
import LmButton from '../common/LmButton';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaText from '../common/VegaText';

const ListOfPastUploads = () => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      minWidth: 150,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: props => {
        const pastUpload = props.row as IPastUploadListProps;
        return (
          <VegaText
            text={pastUpload.fileName}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'requestedId',
      headerName: 'Request Id',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const pastUpload = props.row as IPastUploadListProps;
        return (
          <VegaText
            text={pastUpload.requestedId}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'date',
      headerName: 'date',
      flex: 0.7,
      minWidth: 100,
      renderCell: props => {
        const pastUpload = props.row as IPastUploadListProps;
        return (
          <VegaText
            text={pastUpload.date}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'totalEntries',
      headerName: 'Total Entries',
      flex: 0.7,
      minWidth: 120,
      renderCell: props => {
        const pastUpload = props.row as IPastUploadListProps;
        return (
          <VegaText
            text={pastUpload.totalEntries}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'success',
      headerName: 'Success',
      flex: 0.5,
      minWidth: 100,
      renderCell: props => {
        const pastUpload = props.row as IPastUploadListProps;
        return (
          <VegaText
            text={pastUpload.success}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'failure',
      headerName: 'Failure',
      flex: 0.5,
      minWidth: 100,
      renderCell: props => {
        const pastUpload = props.row as IPastUploadListProps;
        return (
          <VegaText
            text={pastUpload.failure}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },

    {
      field: 'status',
      headerName: 'status',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        const pastUpload = props.row as IPastUploadListProps;
        return pastUpload.status === 'SUCCESS' ? (
          <LmButton
            text="Download"
            textColor={PRIMARY.darkBlue}
            bgColor="#E7EDFC"
            // onClick={() => viewDetails(bankDeposit.id)}
          />
        ) : (
          <VegaText text={'In Progress'} fontWeight={500} color={'#E87001'} />
        );
      },
    },
  ];

  return (
    <>
      <VegaDataGrid
        data={dummy_PastUpload}
        columns={COLUMN_DEF}
        idColumn="id"
        hideFooter
      />
    </>
  );
};

export default ListOfPastUploads;
