import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IncentiveServices } from '../Apis/IncentivesService';
import { PayoutPolicyType } from '../components/IncentivesGamifications/modules/BucketComponent';
import { RootState } from '../store';
import {
  IncentiveCollectionType,
  IncentivePayoutPolicyType,
  IncentivePolicyProps,
  ViewIncentiesGamificationsModal,
} from '../types/incentives';
import {
  GetPolicyRequest,
  IncentivePolicyRulesRequest,
  PolicyRangeProps,
} from '../types/request/incentives';
import { DateUtility } from '../utils/DateUtlility';
import { toLowerCase } from '../constants/commonFunction';
import dayjs from 'dayjs';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);
const getLocalPolicyTab = JSON.parse(localStorage.getItem('policyTab'));
export const PolicyRange: Partial<PolicyRangeProps> = {
  parameter: '',
  parameterMin: 0,
  parameterMax: 0,
  payoutCapMin: 0,
  payoutCapMax: 0,
  payoutCapMetric: '',
  parameterMetric: '',
  payoutValue: 0,
  payoutMetric: '',
  incentivePayoutPolicyType: '',
};
export const MinimumEligibilityCriteriaRange = {
  parameter: '',
  minNo: 0,
  metric: '',
  computationBasis: '',
};

export const basePolicy = {
  parameter: IncentiveCollectionType.RESOLUTION,
  parameterMin: 0,
  parameterMax: 0,
  parameterMetric: '',
  payoutValue: 0,
  incentivePayoutPolicyType: IncentivePayoutPolicyType.BASE,
};

export const DEFAULT_RULE: IncentivePolicyRulesRequest = {
  incentiveBasePolicyId: '',
  bucket: '',
  incentiveType: '',
  accountType: '',
  caseType: '',
  setMinimumEligibility: false,
  maximumPayoutAmount: 0,
  maximumPayoutType: '',
  minimumEligibilityParameters: null,
  basePayoutRange: [basePolicy],
  penaltyRange: null,
  bumperPayoutRange: null,
};

export const DEFAULT_POLICY_DETAILS: IncentivePolicyProps = {
  policyName: '',
  policyType: '',
  vertical: '',
  caseType: '',
  channelType: '',
  incentiveType: '',
  policyStartDate: DateUtility.formatStringToYYYYMMDD(TODAY.toDateString()),
  policyEndDate: DateUtility.formatStringToYYYYMMDD(TOMORROW.toDateString()),
  accountType: '',
  payoutFrequency: '',
  maxPayoutPerPerson: 0,
  userType: [],
  modeOfCommunication: [],
  rules: {},
};

export interface IncentivePolicySliceProps {
  policies: ViewIncentiesGamificationsModal[];
  totalItems: number;
  selectedTab: number;
  selectedPolicyTab: number;
  loading: boolean;
  error: string | null;
  selectedPayoutPolicyType: string;
  errorPolicy: string;
  policyRuleState: IncentivePolicyRulesRequest | null;
  policyDetailsState: IncentivePolicyProps;
  policyEnumValues: { [key: string]: string[] } | null;
}

const initialState: IncentivePolicySliceProps = {
  policies: [],
  loading: false,
  error: null,
  totalItems: 0,
  selectedTab: 0,
  selectedPolicyTab: getLocalPolicyTab ? getLocalPolicyTab : 0,
  policyRuleState: DEFAULT_RULE,
  policyDetailsState: DEFAULT_POLICY_DETAILS,
  policyEnumValues: null,
  selectedPayoutPolicyType: PayoutPolicyType.BASE,
  errorPolicy: '',
};

export const getPolicies = createAsyncThunk(
  'incentiesGamifications/list',
  async (request: Partial<GetPolicyRequest>) => {
    const response = await IncentiveServices.getPolicies(request);
    return response;
  }
);

const IncentivesGamificationsSlice = createSlice({
  name: 'incentiesGamifications',
  initialState,
  reducers: {
    setSelectedPolicyTab: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.selectedPolicyTab = payload;

      localStorage.setItem('policyTab', payload);
    },
    setSelectedTab: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.selectedTab = payload;
    },
    setErrorPolicy: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.errorPolicy = payload;
    },
    setPolicyRuleState: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.policyRuleState = payload;
    },
    setPolicyDetailsState: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.policyDetailsState = payload;
    },
    setPolicyEnumValues: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.policyEnumValues = payload;
    },
    setSelectedPayoutPolicyType: (state: IncentivePolicySliceProps, action) => {
      const { payload } = action;
      state.selectedPayoutPolicyType = payload;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getPolicies.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPolicies.fulfilled, (state, action) => {
        const policies = [...action.payload.records];
        const policiesViewModal = policies.map(
          (policy: IncentivePolicyProps) =>
            ({
              id: policy.id,
              policyName: policy.policyName,
              vertical: policy.vertical,
              channelType: toLowerCase(policy.channelType),
              userType: policy.userType,
              payoutFrequency: toLowerCase(policy.payoutFrequency),
              startDate: dayjs(policy.policyStartDate).format('D MMMM YYYY'),
              endDate: dayjs(policy.policyEndDate).format('D MMMM YYYY'),
              status: policy.status,
            } as ViewIncentiesGamificationsModal)
        );
        state.loading = false;
        state.policies = policiesViewModal;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getPolicies.rejected, (state, action) => {
        state.loading = false;
        state.policies = [];
        state.totalItems = 0;
        state.error = action.error.message;
      });
  },
});
export const {
  setPolicyRuleState,
  setPolicyDetailsState,
  setPolicyEnumValues,
  setSelectedPayoutPolicyType,
  setSelectedTab,
  setErrorPolicy,
  setSelectedPolicyTab,
} = IncentivesGamificationsSlice.actions;
export const getIncentivesGamificationsState = (state: RootState) =>
  state.incentiesGamifications;
export default IncentivesGamificationsSlice;
