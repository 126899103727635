import React from 'react';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaButton from '../../components/common/VegaButton';
import LmFilterSection from '../../components/common/LmFilterSection';
import ListOfRules from './modules/ListOfRules';
import VegaPageContent from '../../components/common/VegaPageContent';
import { useAppDispatch } from '../../store';
import {
  setOpenCreateRuleDrawer,
  setOpenMinVisitsFilterDrawer,
} from '../../features/minVisitsSlice';
import MinVisitsFilterDrawer from './modules/MinVisitsFilterDrawer';

const MinVisits = () => {
  const dispatch = useAppDispatch();
  return (
    <>
      <VegaPageHeader
        title="Min. no. of Visits"
        renderRightView={() => (
          <VegaButton
            text="Create Rule"
            onClick={() => dispatch(setOpenCreateRuleDrawer(true))}
          />
        )}
      />
      <VegaPageHeader
        title="Rules"
        renderRightView={() => {
          return (
            <LmFilterSection
              onFilter={() => dispatch(setOpenMinVisitsFilterDrawer(true))}
            />
          );
        }}
        sx={{ borderBottom: 'none' }}
      />
      <VegaPageContent>
        <ListOfRules />
      </VegaPageContent>
      <MinVisitsFilterDrawer />
    </>
  );
};

export default MinVisits;
