import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subscriptions: {}, // Store subscriptions as an object with topic as keys and subscription objects as values
};

const websocketSubscriptionSlice = createSlice({
  name: 'websocket/subscriptions',
  initialState,
  reducers: {
    storeSubscription: (state, action) => {
      const { topic, subscription } = action.payload;
      state.subscriptions[topic] = subscription;
    },
    removeSubscription: (state, action) => {
      const { topic } = action.payload;
      if (state.subscriptions[topic]) {
        state.subscriptions[topic].unsubscribe();
        delete state.subscriptions[topic];
      }
    },
  },
});

export const { storeSubscription, removeSubscription } =
  websocketSubscriptionSlice.actions;

export default websocketSubscriptionSlice;
