/** @format */

export const COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_BQjAePmed',
  APP_CLIENT_ID: '7j9br921aff0cspse6pjhc2vp7',
};

export const DEV_COGNITO = {
  REGION: 'ap-south-1',
  USER_POOL_ID: 'ap-south-1_8afkpIMGf',
  APP_CLIENT_ID: '7qi6r2cf60ah35at75mc0tklrs',
};
