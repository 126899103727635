import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useRef } from 'react';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';
import { fetchProcessList } from '../../../actions/TeleAllocationAction';
import { PRIMARY } from '../../../constants/style';
import {
  getTeleAllocation,
  setOpenViewProcessDialog,
  setProcessDetials,
} from '../../../features/teleAllocationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TeleAllocationProcessViewModal } from '../../../types/teleAllocation';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LmFilterSection from '../../common/LmFilterSection';
import VegaButton from '../../common/VegaButton';
import VegaDataGrid from '../../common/VegaDataGrid';
import {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../common/VegaFileSelect';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';
import ViewProcessDetailsDialog from './ViewProcessDetailsDialog';

const ListOfProcessCreation = () => {
  const { processes, processDetials } = useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);

  const handleFile = event => {
    const selectedFile = event.target.files[0];
    const fileObj: VegaFileSelectOption = {
      value: selectedFile,
      label: selectedFile.name,
      id: selectedFile.name,
    };
    TeleAllocationService.createBulkProcess({
      processId: processDetials.slashRtcProcessId,
      file: fileObj,
    })
      .then(response => {
        const result = response.result;
        const statusCode = result.statusCode;
        const message = result.msg;
        if (result && statusCode && message) {
          setSnackbar(message, 'error');
        } else {
          setSnackbar('Allocate Successfully !!!');
          dispatch(fetchProcessList());
        }
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  useEffect(() => {
    dispatch(fetchProcessList());
  }, [dispatch]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Process '}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return <LmFilterSection />;
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={processes}
          idColumn="processId"
          paginationMode="client"
          columns={getColumnDefinition({
            onViewDetails: (process: TeleAllocationProcessViewModal) => {
              dispatch(setProcessDetials(process));
              dispatch(setOpenViewProcessDialog(true));
            },
            onMapAgent: (process: TeleAllocationProcessViewModal) => {
              fileInputRef.current.click();
              dispatch(setProcessDetials(process));
            },
            handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => {
              handleFile(event);
            },
            fileInputRef,
          })}
        />
      </VegaPageContent>
      <ViewProcessDetailsDialog />
    </>
  );
};

export default ListOfProcessCreation;

const getColumnDefinition = (data: {
  onViewDetails: (process: TeleAllocationProcessViewModal) => void;
  onMapAgent: (process: TeleAllocationProcessViewModal) => void;
  handleFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: any;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'slashRtcProcessId',
      headerName: 'Process Id',
      flex: 0.5,
    },
    {
      field: 'processName',
      headerName: 'Process Name',
      flex: 0.7,
    },

    {
      field: 'campaignId',
      headerName: 'Campaign ID',
      flex: 0.7,
    },
    {
      field: 'processType',
      headerName: 'Process Type',
      flex: 0.7,
    },

    {
      field: 'details',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const process = props.row as TeleAllocationProcessViewModal;
        return (
          <VegaButton
            text={'View Details'}
            variant="text"
            onClick={() => data.onViewDetails(process)}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Map Agent',
      flex: 0.7,
      renderCell: props => {
        const process = props.row as TeleAllocationProcessViewModal;
        return (
          <>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={data.fileInputRef}
              onChange={e => data.handleFileSelect(e)}
              accept={AcceptedExtension.XLSX}
            />
            <VegaButton
              text={'Upload'}
              sx={{
                bgcolor: '#E7EDFC',
                color: PRIMARY.darkBlue,
                ':hover': {
                  bgcolor: '#E7EDFC',
                  color: PRIMARY.darkBlue,
                },
              }}
              onClick={() => data.onMapAgent(process)}
            />
          </>
        );
      },
    },
  ];
  return COL_DEF;
};
