import axios from 'axios';
import {
  CreateLeaveRequest,
  GetLeaveListRequest,
  UpdateLeaveStatus,
} from '../types/request/leave';
import { PaginatedResponse } from '../utils/api';
import { Leave } from '../types/leave';

const Endpoint = {
  BASE: '/leave',
  CREATE: '/',
  UPDATE: '/',
  GET: '/list',
};

const BASE_URL = Endpoint.BASE;
export class LeaveService {
  static async createLeave(request: Partial<CreateLeaveRequest>) {
    const endpoint = BASE_URL + Endpoint.CREATE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getLeaveList(
    request: Partial<GetLeaveListRequest>
  ): Promise<PaginatedResponse<Leave>> {
    const endpoint = BASE_URL + Endpoint.GET;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updatePendingLeave(
    request: Partial<UpdateLeaveStatus>
  ): Promise<PaginatedResponse<Leave>> {
    const endpoint = BASE_URL + Endpoint.UPDATE;
    const response = await axios.put(endpoint, request);
    return response.data;
  }
}
