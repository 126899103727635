import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TeleAgentService } from '../../Apis/TeleAgentServices';
import VegaText from '../../components/common/VegaText';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { TeleAgentListProps } from '../../types/teleAgent';
import { getErrorMessageFromErrorObj } from '../../utils/api';

function SlashRtcView() {
  const { userId } = useClientAuth();
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [agentDetails, setAgentDetails] = useState<TeleAgentListProps | null>(
    null
  );

  async function getTeleAgentByuserId() {
    try {
      setAgentDetails(null);
      setLoading(true);
      const res = await TeleAgentService.getTeleAgentList({
        userId: userId,
      });
      const agent = res.records[0];
      setAgentDetails(agent);
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTeleAgentByuserId();
  }, []);

  return (
    <div
      style={{
        height: '100%',
        padding: '1rem',
        flex: 1,
      }}
    >
      {loading && <CircularProgress />}
      {loading == false && agentDetails == null && (
        <div
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <VegaText text={'No Agent Found'}></VegaText>
        </div>
      )}
      {loading == false && agentDetails != null && (
        <iframe
          src={`https://capriglobalcollection.slashrtc.in/index.php/ssoLogin?1=1&crmUniqueId=${agentDetails.encryptedCrmId}&usernameId=${agentDetails.username}`}
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
}

export default SlashRtcView;
