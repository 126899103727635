import { createContext, useContext, useState } from 'react';
import { ICreateUserProps } from '../types/UAM';
import { IRoleProps } from '../components/UAM/UAMDetails/components/DataRestriction';

type DataRestrictionContextType = {
  selectedRoleId: string | null;
  selectedEntityId: string | null;
  managerList: ICreateUserProps[];
  roleList: IRoleProps[];
  setSelectedRoleId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedEntityId: React.Dispatch<React.SetStateAction<string | null>>;
  setManagertList: React.Dispatch<React.SetStateAction<ICreateUserProps[]>>;
  setRoleList: React.Dispatch<React.SetStateAction<IRoleProps[]>>;
};

const DataRestrictionContext = createContext<DataRestrictionContextType | null>(
  null
);

export const useDataResriction = () =>
  useContext(DataRestrictionContext) as DataRestrictionContextType;

export const DataRestrictionProvider = ({ children }: any) => {
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [selectedEntityId, setSelectedEntityId] = useState<string | null>(null);
  const [managerList, setManagertList] = useState<ICreateUserProps[]>([]);
  const [roleList, setRoleList] = useState<IRoleProps[]>([]);

  return (
    <DataRestrictionContext.Provider
      value={{
        managerList,
        roleList,
        selectedRoleId,
        selectedEntityId,
        setManagertList,
        setRoleList,
        setSelectedEntityId,
        setSelectedRoleId,
      }}
    >
      {children}
    </DataRestrictionContext.Provider>
  );
};
