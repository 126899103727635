export class DateUtility {
  static parseStringToDate(dateString?: string): Date | undefined {
    if (!dateString) return;
    return new Date(dateString);
  }

  static formatDateToDDMMYYYY(date?: Date): string | undefined {
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());

    return `${day}/${month}/${year}`;
  }

  static formatStringToDDMMYYYY(dateString?: string): string | undefined {
    if (!dateString) return;
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    return `${day}/${month}/${year}`;
  }
  static formatStringToYYYYMMDD(dateString?: string): string | undefined {
    if (!dateString) return;
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  }

  static formatStringToDDMMYYYYWithTime(
    dateString?: string
  ): string | undefined {
    if (!dateString) return;
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) return;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0'); // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }

  static setTimeToStartOfDay(date?: Date): Date | undefined {
    if (!date) return;
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  static setTimeToEndOfDay(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(23, 59, 59, 999);
    return newDate;
  }

  static getTimeFromDate(date?: Date): string | undefined {
    if (!date) return;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  static getDateOnly(date: Date): Date {
    return new Date(date.toDateString());
  }

  static isAfter(date1: Date, date2: Date): boolean {
    return date1 > date2;
  }

  static isBefore(date1: Date, date2: Date): boolean {
    return date1 < date2;
  }

  static isEqual(date1: Date, date2: Date): boolean {
    return date1.getTime() === date2.getTime();
  }

  static parseDateFromDatePicker(e: any): Date | undefined {
    if (!e) {
      return;
    }
    const date = e.$d;
    const isInValidDate = isNaN(date.getTime());
    if (isInValidDate == true) {
      return;
    }
    return DateUtility.parseStringToDate(date);
  }

  static addDays(date: Date, daysToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + daysToAdd);
    return newDate;
  }
  static previousDays(date: Date, daysToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - daysToAdd);
    return newDate;
  }

  static subtractDays(date: Date, daysToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - daysToSubtract);
    return newDate;
  }

  static addHours(date: Date, hoursToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setHours(date.getHours() + hoursToAdd);
    return newDate;
  }

  static subtractHours(date: Date, hoursToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setHours(date.getHours() - hoursToSubtract);
    return newDate;
  }

  static addMinutes(date: Date, minutesToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() + minutesToAdd);
    return newDate;
  }

  static subtractMinutes(date: Date, minutesToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() - minutesToSubtract);
    return newDate;
  }

  static addMonths(date: Date, monthsToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + monthsToAdd);
    return newDate;
  }

  static subtractMonths(date: Date, monthsToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() - monthsToSubtract);
    return newDate;
  }

  static addYears(date: Date, yearsToAdd: number): Date {
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() + yearsToAdd);
    return newDate;
  }

  static subtractYears(date: Date, yearsToSubtract: number): Date {
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() - yearsToSubtract);
    return newDate;
  }

  static getDateWithMonthName(date: any): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    return `${day}${getOrdinalNumber(day)} ${month}, ${year}`;
  }

  static parseToLocalDate(dateString?: string): string | undefined {
    if (!dateString) {
      return;
    }
    const date = DateUtility.parseStringToDate(dateString);
    if (!date) {
      return;
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Note: Month is zero-based
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  }
}
export const getOrdinalNumber = (day: number): string => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
