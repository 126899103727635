// websocket.js
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { connectWebSocket } from './websocketSlice';
import {
  removeSubscription,
  storeSubscription,
} from './websocketSubscriptionSlice';

export const initializeWebSocket = () => async dispatch => {
  let stompClient = null;

  const socket = new SockJS(
    'https://1f1uyt1v38.execute-api.ap-south-1.amazonaws.com/collection_websocket'
  );
  stompClient = Stomp.over(socket);

  try {
    await new Promise<void>((resolve, reject) => {
      stompClient.connect(
        {},
        () => {
          dispatch(connectWebSocket(stompClient));
          resolve();
        },
        error => {
          console.error('WebSocket connection error:', error);
          reject(error);
        }
      );
    });
  } catch (error) {
    console.error('WebSocket connection error:', error);
  }
};

export const subscribeToTopic =
  (stompClient, topic, onMessageCallback) => dispatch => {
    const subscription = stompClient.subscribe(topic, message => {
      onMessageCallback(JSON.parse(message.body));
    });

    // Dispatch the storeSubscription action to store the subscription
    dispatch(storeSubscription({ topic, subscription }));

    return subscription;
  };

export const unsubscribeFromTopic = topic => (dispatch, getState) => {
  const { websocket } = getState();
  const { stompClient } = websocket;
  if (stompClient && stompClient.connected) {
    const subscription = websocket.subscriptions[topic];
    if (subscription) {
      subscription.unsubscribe();
      // Dispatch the removeSubscription action to remove the subscription
      dispatch(removeSubscription({ topic }));
    }
  }
};
