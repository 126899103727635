import { Grid, Stack } from '@mui/material';
import {
  defaultCampaignState,
  getTeleAllocation,
  setCreateCampaignState,
  setOpenCreateCampaignDrawer,
} from '../../../features/teleAllocationSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';
import { CreateCampaign } from '../../../types/teleAllocation';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { fetchCampaignList } from '../../../actions/TeleAllocationAction';
import VegaDrawer from '../../common/VegaDrawer';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';

const CreateCampaignFormDrawer = () => {
  const { createCampaignState, openCreateCampaignDrawer } =
    useAppSelector(getTeleAllocation);
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setOpenCreateCampaignDrawer(false));
    dispatch(setCreateCampaignState(defaultCampaignState));
  };

  const disabled =
    !createCampaignState.name.length || !createCampaignState.description.length;

  const handleChange = (key: keyof CreateCampaign, value: string) => {
    const campaign = {
      ...createCampaignState,
      [key]: value,
    };
    dispatch(setCreateCampaignState(campaign));
  };

  const onCreateCampaign = async () => {
    await TeleAllocationService.createCampaign(createCampaignState)
      .then(() => {
        setSnackbar('Create Campaign Successfully !!!');
        dispatch(fetchCampaignList());
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  return (
    <>
      <VegaDrawer
        open={openCreateCampaignDrawer}
        title={'Create Campaign'}
        onClose={onClose}
      >
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Create Campaign"
                  disabled={disabled}
                  onClick={onCreateCampaign}
                />
              </Stack>
            );
          }}
        >
          <Grid container spacing={'1.25rem'}>
            <Grid item xs={12}>
              <VegaFormInputField label={'campaign name'}>
                <LmTextField
                  placeholder="Enter Campaign Name"
                  value={createCampaignState.name}
                  onChange={e => handleChange('name', e.target.value)}
                />
              </VegaFormInputField>
            </Grid>

            <Grid item xs={12}>
              <VegaFormInputField label={'Campaign Description'}>
                <LmTextField
                  placeholder="Enter Campaign Description"
                  value={createCampaignState.description}
                  onChange={e => handleChange('description', e.target.value)}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
    </>
  );
};

export default CreateCampaignFormDrawer;
