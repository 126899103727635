import { IDrawerProps } from '../../../utils/typeUtility';
import VegaDrawer from '../../common/VegaDrawer';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';
import PolicySelector from './PolicySelector';
import UserTypeSelector from './UserTypeSelector';
import { useState } from 'react';
import { Grid } from '@mui/material';

const NewPolicyFormDrawer = ({ open, setOpen }: IDrawerProps) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const onClose = () => {
    setOpen(!open);
  };

  const onUserSelect = (user: string) => {
    if (selectedUsers.includes(user)) {
      const filteredUser = selectedUsers.filter(
        (selectedUser: string) => selectedUser !== user
      );
      setSelectedUsers(filteredUser);
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };
  const onUserDelete = (index: number) => {
    const users = [...selectedUsers];
    users.splice(index);
    setSelectedUsers(users);
  };
  return (
    <div>
      <VegaDrawer open={open} title={'New Policy'} onClose={onClose}>
        <VegaDrawerContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <VegaFormInputField label={'user type'}>
                <UserTypeSelector
                  selected={selectedUsers}
                  onSelecte={selected => onUserSelect(selected)}
                  onDelete={selectedIndex => onUserDelete(selectedIndex)}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <VegaFormInputField label={'policy'}>
                <PolicySelector
                  selected={selectedUsers}
                  onSelecte={selected => onUserSelect(selected)}
                  onDelete={selectedIndex => onUserDelete(selectedIndex)}
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
    </div>
  );
};

export default NewPolicyFormDrawer;
