import { Close } from '@mui/icons-material';
import { Box, IconButton, Slide, Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import React, { ReactNode, createContext, useContext } from 'react';
import VegaText from '../components/common/VegaText';
import { VEGA_BOX_SHADOW } from '../constants/style';
import { getErrorMessageFromErrorObj } from '../utils/api';

const SUCCESS_GREEN = '#318439';
const SUCCESS_GREEN_BG = '#EBF4EC';
const ERROR_RED = '#BA1C1C';
const ERROR_RED_BG = '#F8E8E8';

type Props = {
  children: ReactNode;
};

export type SnackbarContextType = {
  onClose?: () => void;
  setSnackbar: (content: string, type?: AlertColor) => void;
  setSnackbarError: (error: any) => void;
};

type SnackbarStateInterface = {
  isOpen: boolean;
  type: AlertColor;
  content: string;
};

const SnackbarContext = createContext<SnackbarContextType | null>(null);
export const useSnackbar = () => {
  return useContext(SnackbarContext) as SnackbarContextType;
};

const SnackbarProvider = ({ children }: Props) => {
  const [snackbarState, setSnackbarState] =
    React.useState<SnackbarStateInterface>({
      isOpen: false,
      type: 'success',
      content: '',
    });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
    // type: AlertColor
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarState({ isOpen: false, content: '', type: type });
  };

  const setSnackbar = (snackbarContent: string, snackbarType?: AlertColor) => {
    setSnackbarState({
      isOpen: true,
      type: snackbarType || 'success',
      content: snackbarContent,
    });
  };

  //  Need to make VegaError Type
  const _setSnackbarError = (error: any) => {
    setSnackbarState({
      isOpen: true,
      type: 'error',
      content: getErrorMessageFromErrorObj(error),
    });
  };

  const { isOpen, type, content } = snackbarState;
  const isSuccess = type == 'success';
  const typeColor = isSuccess ? SUCCESS_GREEN : ERROR_RED;
  const typeBgColor = isSuccess ? SUCCESS_GREEN_BG : ERROR_RED_BG;
  return (
    <>
      <SnackbarContext.Provider
        value={{ setSnackbar, setSnackbarError: _setSnackbarError }}
      >
        {children}
      </SnackbarContext.Provider>

      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={Slide}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            minWidth: 350,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: '1.5rem',
            py: '0.75rem',
            boxShadow: VEGA_BOX_SHADOW,
            borderRadius: '9px',
            bgcolor: typeBgColor,
            border: `1px solid ${typeColor}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <VegaText
                text={content}
                fontWeight={500}
                fontSize={'0.75rem'}
                fontColor={typeColor}
              />
            </Box>
          </Box>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <Close fontSize="small" sx={{ color: typeColor }} />
          </IconButton>
        </Box>
      </Snackbar>
    </>
  );
};

export default SnackbarProvider;
