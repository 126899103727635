// biddersSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BidderService } from '../Apis/BidderService';
import { CreateBidderFormData } from '../components/Bidder/CreateBidderDrawer';
import { Bidder } from '../types/auction';
import { GetBiddersListRequest } from '../types/request/bidder';
import { PaginatedResponse } from '../utils/api';
import { RootState } from '../store';

export interface BidderState {
  bidders: Bidder[];
  page: number;
  totalItems: number;
  pageSize: number;
  loading: boolean;
  error: string | null;
}

const initialState: BidderState = {
  bidders: [],
  loading: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
};

export const fetchBidders = createAsyncThunk(
  'bidders/fetchBidders',
  async (
    request: Partial<GetBiddersListRequest>
  ): Promise<PaginatedResponse<Bidder>> => {
    const response = await BidderService.getBidders(request);
    return response;
  }
);

export const createBidder = createAsyncThunk(
  'bidders/createBidder',
  async (formData: Partial<CreateBidderFormData>) => {
    const response = await BidderService.createBidder({
      userName: formData.userName,
      email: formData.email,
      startDate: formData.startDate,
      endDate: formData.endDate,
    });
    return response;
  }
);

const biddersSlice = createSlice({
  name: 'bidders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBidders.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBidders.fulfilled, (state, action) => {
        state.loading = false;
        state.bidders = [...action.payload.records];
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchBidders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch bidders';
      })
      .addCase(createBidder.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBidder.fulfilled, state => {
        state.loading = false;
        // Optionally, you can update state after creating a bidder if needed.
        // For example, you may want to add the newly created bidder to the list.
      })
      .addCase(createBidder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});

export const getBidders = (state: RootState) => state.bidders;
export default biddersSlice;
