import { Collapse, FormControl, FormControlLabel } from '@mui/material';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { DateUtility } from '../../utils/DateUtlility';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import VegaText from '../common/VegaText';
import VegaCheckbox from '../common/VegaCheckbox';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaRadioGroup, { VegaRadioOption } from '../common/VegaRadioGroup';

export enum FilterOption {
  DayFilter,
  StatusFilter,
}

enum DayFilter {
  PAST_7_DAYS = 'PAST_7_DAYS',
  PAST_14_DAYS = 'PAST_14_DAYS',
  CUSTOM = 'CUSTOM',
}

enum StatusFilter {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type DateRangeFilter = {
  startDate: string;
  endDate: string;
};

const getDayFilterOptions = () =>
  Object.values(DayFilter).map(item => {
    const option: VegaRadioOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const getStatusFilterOptions = () =>
  Object.values(StatusFilter).map(item => {
    var displayText = '-';
    if (item == StatusFilter.ACCEPTED) {
      displayText = 'Approved';
    } else {
      displayText = _.startCase(_.toLower(item));
    }
    const option: VegaRadioOption = {
      label: displayText,
      value: item,
    };
    return option;
  });

type Props = {
  onClearAllClick: () => void;
  allowedFilters: FilterOption[];
  onSelectFilterClick: (
    statusFilter: string[],
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => void;
  value: {
    dayFilter?: string;
    statusFilter: string[];
    dateRangeFilter: DateRangeFilter;
  };
};

const dayFilters = getDayFilterOptions();
const statusFilters = getStatusFilterOptions();

function FilterInput({
  onClearAllClick,
  onSelectFilterClick,
  value,
  allowedFilters,
}: Props) {
  const [selectedDayFilter, setSelectedDayFilter] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateRangeFilter>({
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
  });
  function handleStatusChange(status: string, checked: boolean) {
    let updatedFilters;
    if (checked) {
      if (!selectedStatus.includes(status)) {
        updatedFilters = [...selectedStatus, status];
      } else {
        updatedFilters = selectedStatus;
      }
    } else {
      updatedFilters = selectedStatus.filter(item => item !== status);
    }
    setSelectedStatus(updatedFilters);
  }

  function onStartDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    if (!parsedDate) return;
    setDateRange(prev => {
      const endDate = DateUtility.parseStringToDate(prev.endDate);
      if (endDate) {
        const isStartDateAfterEndDate = DateUtility.isAfter(
          parsedDate,
          endDate
        );
        if (isStartDateAfterEndDate) {
          return {
            ...prev,
            startDate: parsedDate.toISOString(),
            endDate: parsedDate.toISOString(),
          };
        }
      }
      return { ...prev, startDate: parsedDate.toISOString() };
    });
  }

  function onEndDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    if (!parsedDate) return;
    setDateRange(prev => {
      return { ...prev, endDate: parsedDate.toISOString() };
    });
  }

  function onSelectClick() {
    let dateRangeValue: DateRangeFilter;
    const today = new Date();
    if (selectedDayFilter == DayFilter.PAST_7_DAYS) {
      const sevenDaysBefore = DateUtility.subtractDays(today, 7);
      dateRangeValue = {
        startDate: sevenDaysBefore.toISOString(),
        endDate: today.toISOString(),
      };
    } else if (selectedDayFilter == DayFilter.PAST_14_DAYS) {
      const fourteenDaysBefore = DateUtility.subtractDays(today, 14);
      dateRangeValue = {
        startDate: fourteenDaysBefore.toISOString(),
        endDate: today.toISOString(),
      };
    } else {
      dateRangeValue = dateRange;
    }
    onSelectFilterClick(selectedStatus, dateRangeValue, selectedDayFilter);
  }

  function onClearClick() {
    onClearAllClick();
  }

  useEffect(() => {
    setDateRange(value.dateRangeFilter);
    setSelectedDayFilter(value.dayFilter);
    setSelectedStatus(value.statusFilter);
  }, [value]);

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <LmButton
              text="Clear All"
              onClick={onClearClick}
              bgColor=""
              variant="text"
              textColor="#1047DC"
            />
            <LmButton text="Apply Filter" onClick={onSelectClick} />
          </Stack>
        );
      }}
    >
      <Stack
        spacing={'1.5rem'}
        style={{
          display: allowedFilters.includes(FilterOption.DayFilter)
            ? ''
            : 'none',
        }}
      >
        <VegaRadioGroup
          heading="Day"
          options={dayFilters}
          value={selectedDayFilter}
          onChange={e => {
            setSelectedDayFilter(e.target.value);
          }}
        />
        <Collapse in={selectedDayFilter == DayFilter.CUSTOM} timeout={300}>
          <Stack direction={'row'} columnGap="1rem">
            <LmDateField
              header="Start Date"
              onChange={onStartDateChange}
              value={dateRange.startDate}
              disabled
            />
            <LmDateField
              header="End Date"
              onChange={onEndDateChange}
              minDate={dateRange.startDate}
              value={dateRange.endDate}
              disabled
            />
          </Stack>
        </Collapse>
        <FormControl
          style={{
            display: allowedFilters.includes(FilterOption.StatusFilter)
              ? ''
              : 'none',
          }}
        >
          <VegaText
            text={'Status'}
            marginBottom="1.25rem"
            style={{
              fontSize: '0.625rem',
              fontWeight: 700,
              lineHeight: '124%',
              letterSpacing: '0.04375rem',
              color: '#999DA8',
              textTransform: 'uppercase',
            }}
          />
          {statusFilters.map(item => {
            return (
              <FormControlLabel
                key={item.value}
                control={
                  <VegaCheckbox
                    checked={selectedStatus.includes(item.value)}
                    onChange={(e, checked) => {
                      handleStatusChange(item.value, checked);
                    }}
                  />
                }
                label={<VegaText text={item.label} />}
              />
            );
          })}
        </FormControl>
      </Stack>
    </VegaDrawerContent>
  );
}

export default FilterInput;
