import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { TargetSevices } from '../../Apis/TargetSevices';
import NoResults from '../../components/common/NoResults';
import VegaButton from '../../components/common/VegaButton';
import VegaOutlineDatePicker from '../../components/common/VegaOutlineDatePicker';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';
import {
  getTargetList,
  getTargetState,
  setBuckets,
  setIsAddTarget,
  setOpenAddTargetDrawer,
  targetByBuckets,
} from '../../features/targetSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  TargetByBucketsProps,
  TargetListProps,
  TargetsStatus,
} from '../../types/targets';
import { DateUtility } from '../../utils/DateUtlility';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import TargetBucketCard from './modules/TargetBucketCard';
import TargetForm from './modules/TargetForm';
import VegaDrawer from '../../components/common/VegaDrawer';
import { useClientAuth } from '../../providers/ClientProvider';

export interface IBucketInputsProps {
  name: string;
  value: number;
}

export interface IBucketsProps {
  bucket: string;
  bucketInput: IBucketInputsProps[];
}

const TargetAgent = () => {
  const { userId } = useClientAuth();
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();
  const { targets, openAddTargetDrawer, isAddTarget, error } =
    useAppSelector(getTargetState);
  const [dateValue, setDateValue] = useState<string>(
    DateUtility.formatStringToYYYYMMDD(new Date().toDateString())
  );
  const [targetId, setTargetId] = useState<string>('');
  const handleChangeDate = (e: any) => {
    const date = DateUtility.formatStringToYYYYMMDD(e);
    setDateValue(date);
  };

  const onAddAndUpdateTarget = (buckets: TargetByBucketsProps[]) => {
    const newBuckets = buckets.map((bucket: TargetByBucketsProps) => ({
      bucket: bucket.bucket,
      posValue: bucket.posValue,
      noOfCases: bucket.noOfCases,
    }));
    if (isAddTarget) {
      TargetSevices.createTarget({
        agentId: userId,
        targetDate: dateValue,
        targetByBuckets: newBuckets,
      })
        .then(() => {
          setSnackbar('create target successfully!!');
          fetchTargetList();
          dispatch(setOpenAddTargetDrawer(false));
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    } else {
      TargetSevices.updateTarget({
        targetId,
        targetDate: dateValue,
        targetByBuckets: newBuckets,
      })
        .then(() => {
          setSnackbar('update target successfully!!');
          fetchTargetList();
          dispatch(setOpenAddTargetDrawer(false));
        })
        .catch(error => {
          setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        });
    }
  };

  const modifyTarget = (buckets: TargetListProps) => {
    setTargetId(buckets.id);

    const modifyBuckets = targetByBuckets.map(
      (targetedBucket: TargetByBucketsProps) => {
        const dataItem = buckets.targetByBuckets.find(
          (dataItem: TargetByBucketsProps) =>
            dataItem.bucket === targetedBucket.bucket
        );
        return { ...targetedBucket, ...dataItem };
      }
    );
    dispatch(setBuckets(modifyBuckets));
    dispatch(setOpenAddTargetDrawer(true));
  };
  const fetchTargetList = () => {
    dispatch(getTargetList({ agentId: userId, targetDate: dateValue }));
  };

  useEffect(() => {
    fetchTargetList();
  }, [dateValue]);

  useEffect(() => {
    if (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    }
  }, [error]);
  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Targets'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} gap={1}>
              <VegaOutlineDatePicker
                onChange={handleChangeDate}
                value={dateValue}
                maxDate={new Date()}
              />
              <VegaButton
                text="Add Targets"
                disabled={!!targets.length}
                onClick={() => {
                  dispatch(setIsAddTarget(true));
                  dispatch(setOpenAddTargetDrawer(true));
                  dispatch(setBuckets(targetByBuckets));
                }}
              />
            </Stack>
          );
        }}
      />
      {targets.length ? (
        <VegaPageContent sx={{ mt: '1rem' }}>
          <TargetBucketCard
            isBucketStatus
            bucketData={targets}
            renderButtons={(bucket: TargetListProps) => {
              return (
                <Stack
                  direction={'row'}
                  gap={2}
                  justifyContent={'end'}
                  mt={'1rem'}
                >
                  {bucket.targetStatus === TargetsStatus.RAISED && (
                    <VegaButton
                      text="Modify Target"
                      variant="outlined"
                      onClick={() => {
                        dispatch(setIsAddTarget(false));
                        modifyTarget(bucket);
                      }}
                    />
                  )}
                </Stack>
              );
            }}
          />
        </VegaPageContent>
      ) : (
        <NoResults sx={{ height: 'calc(100% - 142px)' }} />
      )}

      <VegaDrawer
        open={openAddTargetDrawer}
        title={isAddTarget ? 'Add Target' : 'Update Target'}
        onClose={() => dispatch(setOpenAddTargetDrawer(false))}
      >
        <TargetForm
          onAddAndUpdateTarget={onAddAndUpdateTarget}
          initialDate={dateValue}
        />
      </VegaDrawer>
    </>
  );
};

export default TargetAgent;
