import { Stack } from '@mui/material';
import LmButton from '../common/LmButton';
import VegaDrawerContent from '../common/VegaDrawerContent';
import { LeaveStatus } from '../../types/leave';
import { VegaSelectOption } from '../common/VegaSelect';
import _ from 'lodash';
import VegaRadioGroup from '../common/VegaRadioGroup';
import { useState } from 'react';

interface props {
  onfilterClick: (selectedStatusFilter: string) => void;
  defaultValue: string;
  onClearAllClick: () => void;
}

const LeaveMangementFilter = ({
  onfilterClick,
  defaultValue,
  onClearAllClick,
}: props) => {
  const [filterStatus, setFilterStatus] = useState<string>(defaultValue);
  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <Stack direction={'row'} spacing="1rem">
            <LmButton
              text="Clear All"
              onClick={() => {
                onClearAllClick();
                setFilterStatus(null);
              }}
              bgColor=""
              variant="text"
              textColor="#1047DC"
            />
            <LmButton
              text="Apply Filter"
              onClick={() => {
                onfilterClick(filterStatus);
              }}
            />
          </Stack>
        );
      }}
    >
      <VegaRadioGroup
        heading="Status"
        options={getStatusFilterOptions()}
        value={filterStatus}
        onChange={e => {
          setFilterStatus(e.target.value);
        }}
      />
    </VegaDrawerContent>
  );
};
const getStatusFilterOptions = () =>
  Object.values(LeaveStatus).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

export default LeaveMangementFilter;
