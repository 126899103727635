import { Grid, Stack } from '@mui/material';
import { fetchLeadsetList } from '../../../actions/TeleAllocationAction';
import {
  defaultLeadsetState,
  getTeleAllocation,
  setCreateLeadsetState,
  setOpenCreateLeadsetDrawer,
} from '../../../features/teleAllocationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { CreateLeadSet } from '../../../types/teleAllocation';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LmTextField from '../../common/LmTextField';
import VegaButton from '../../common/VegaButton';
import VegaDrawerContent from '../../common/VegaDrawerContent';
import VegaFormInputField from '../../common/VegaFormInputField';
import ProcessSelector from '../components/ProcessSelector';
import VegaDrawer from '../../common/VegaDrawer';
import { TeleAllocationService } from '../../../Apis/TeleAllocationServices';

const CreateLeadsetFormDrawer = () => {
  const { createLeadsetState, openCreateLeadsetDrawer } =
    useAppSelector(getTeleAllocation);
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const disabled =
    !createLeadsetState.name.length || !createLeadsetState.processId;

  const onClose = () => {
    dispatch(setOpenCreateLeadsetDrawer(false));
    dispatch(setCreateLeadsetState(defaultLeadsetState));
  };

  const handleChange = (key: keyof CreateLeadSet, value: string) => {
    const leadset = {
      ...createLeadsetState,
      [key]: value,
    };
    dispatch(setCreateLeadsetState(leadset));
  };

  const onCreateLeadset = async () => {
    await TeleAllocationService.createLeadset(createLeadsetState)
      .then(() => {
        setSnackbar('Create Campaign Successfully !!!');
        dispatch(fetchLeadsetList());
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };

  return (
    <VegaDrawer
      title="Create Leadset"
      open={openCreateLeadsetDrawer}
      onClose={onClose}
    >
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text="Create Leadset"
                disabled={disabled}
                onClick={onCreateLeadset}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={'1.25rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'leadset name'}>
              <LmTextField
                placeholder="Leadset Name"
                value={createLeadsetState.name}
                onChange={e => handleChange('name', e.target.value)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Process id'}>
              <ProcessSelector
                selected={createLeadsetState.processId}
                handleChange={e => handleChange('processId', e)}
              />
            </VegaFormInputField>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
};

export default CreateLeadsetFormDrawer;
