import React from 'react';
import { useClientAuth } from '../../providers/ClientProvider';
import AdminHomePage from './modules/AdminHomePage';
import AgentHomePage from './modules/AgentHomePage';

const HomePage = () => {
  const { isAdmin } = useClientAuth();
  return <>{isAdmin ? <AdminHomePage /> : <AgentHomePage />}</>;
};

export default HomePage;
