import React from 'react';
import { Grid, Stack } from '@mui/material';
import { VegaRadio } from '../../../../common/VegaRadio';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { COMMON } from '../../../../../constants/style';
import VegaText from '../../../../common/VegaText';
import { IRoleProps } from '../DataRestriction';
import {
  IRoleObjProps,
  RolesProps,
  useUAM,
} from '../../../../../providers/UAMProvider';
import { useDataResriction } from '../../../../../providers/DataRestrictionProvider';

interface IProps {
  branchRoleId: string;
  role: IRoleProps;
  branchIndex: number;
}

const RoleList = ({ branchRoleId, role, branchIndex }: IProps) => {
  const { selectedBranch, setSelectedBranch } = useUAM();
  const { setSelectedEntityId } = useDataResriction();

  const handleSelecteRole = async (index: number, role: IRoleObjProps) => {
    const updatedBranches = [...selectedBranch];
    const selectedBranchOptions = updatedBranches[index];
    const roleObj = {
      id: role.id,
      name: role.name,
    };
    const newObj = {
      ...selectedBranchOptions,
      roles: roleObj,
    } as RolesProps;
    updatedBranches.splice(index, 1, newObj);
    setSelectedEntityId(null);
    setSelectedBranch(updatedBranches);
  };
  return (
    <>
      <Grid item sm={4}>
        <Stack key={role.id} direction={'row'} alignItems={'center'} gap={1}>
          <VegaRadio
            checked={branchRoleId === role.id}
            onChange={() => handleSelecteRole(branchIndex, role)}
          />
          <VegaText
            text={toLowerCase(role.name)}
            fontWeight={600}
            fontSize={'0.625rem'}
            color={COMMON.balck}
          />
        </Stack>
      </Grid>
    </>
  );
};

export default RoleList;
