/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-key */
import { Box, Grid } from '@mui/material';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useClickOutSide } from '../../../../constants/commonFunction';
import { useDataResriction } from '../../../../providers/DataRestrictionProvider';
import { RolesProps, useUAM } from '../../../../providers/UAMProvider';
import { NESTED_ROUTES } from '../../../../router/routes';
import VegaFormInputField from '../../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../../common/VegaSelect';
import DataRestrictionDropdown from './DataRestrictionDropdown';
import DetailWrapper from './DetailWrapper';

export interface IRoleProps {
  id: string;
  clientId: string;
  name: string;
  entityId: string;
  owner: string | null;
  reporter: string | null;
  createdAt: string;
  updatedAt: string;
}

const DataRestriction = () => {
  const { selectedBranch, setSelectedBranch } = useUAM();
  const { setSelectedRoleId, setSelectedEntityId } = useDataResriction();
  const { params } = useParams();

  const ref = useClickOutSide(() => {
    setSelectedEntityId(null);
    setSelectedRoleId(null);
  });

  const checkValue = (value: any, branches: RolesProps[]) => {
    const isCheck = branches.some(
      (branch: RolesProps) => branch.entityId === value
    );
    return isCheck;
  };

  const handleChangeBranch = async (value: any) => {
    const isAvailble = checkValue(value, selectedBranch);
    if (!isAvailble) {
      setSelectedBranch([
        ...selectedBranch,
        {
          entityId: value,
          roles: { id: '', name: '' },
          managers: { id: '', name: '' },
        },
      ]);
    }
  };
  const isDisable = () => {
    let isEmpty = false;
    selectedBranch.length
      ? selectedBranch.forEach((branch: RolesProps) => {
          const isRole = !branch.roles.id.length;
          const isManager = !branch.managers.id.length;
          if (isRole) {
            isEmpty = true;
          } else if (isManager) {
            isEmpty = true;
          } else {
            isEmpty = false;
          }
        })
      : false;
    return isEmpty;
  };
  return (
    <div>
      <DetailWrapper title="Data Restriction">
        <>
          {params !== NESTED_ROUTES.VIEW_DETAILS && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <VegaFormInputField label="base branch">
                  <VegaSelect
                    options={branchOptions()}
                    onChange={e => handleChangeBranch(e.target.value)}
                    disabled={isDisable()}
                  />
                </VegaFormInputField>
              </Grid>
            </Grid>
          )}
          <Box ref={ref} mt={2}>
            <Grid container spacing={2}>
              {selectedBranch.length
                ? selectedBranch.map((branch: RolesProps, index: number) => (
                    <Grid item xs={12} sm={12} md={6} key={index}>
                      <DataRestrictionDropdown branch={branch} index={index} />
                    </Grid>
                  ))
                : ''}
            </Grid>
          </Box>
        </>
      </DetailWrapper>
    </div>
  );
};

export default DataRestriction;

const branchOptions = () =>
  ['KORAMANGALA', 'PONDI'].map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });
