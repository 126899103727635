import { FormControl, RadioGroup, FormControlLabel } from '@mui/material';
import VegaText from './VegaText';
import { VegaRadio } from './VegaRadio';

export type VegaRadioOption = {
  label: string;
  value: string;
};

type Props = {
  heading?: string;
  options: VegaRadioOption[];
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
  value?: string;
};

function VegaRadioGroup({ heading, options, onChange, value }: Props) {
  return (
    <FormControl>
      {heading && (
        <VegaText
          text={heading}
          marginBottom="1.25rem"
          style={{
            fontSize: '0.625rem',
            fontWeight: 700,
            lineHeight: '124%',
            letterSpacing: '0.04375rem',
            color: '#999DA8',
            textTransform: 'uppercase',
          }}
        />
      )}
      <RadioGroup onChange={onChange} value={value ?? ''}>
        {options.map(item => {
          return (
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={<VegaRadio />}
              label={<VegaText text={item.label} />}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

export default VegaRadioGroup;
