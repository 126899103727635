import { Stack, Box } from '@mui/material';
import VegaText from './VegaText';

type VegaPillProps = {
  text: string;
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  isDot?: boolean;
};

export const VegaPill = ({
  text,
  backgroundColor,
  borderColor,
  textColor,
  isDot = true,
}: VegaPillProps) => {
  const hasBackground = backgroundColor !== undefined;
  const hasBorder = borderColor !== undefined;
  const hasText = textColor !== undefined;

  const pillStyles = {
    border: hasBorder ? `1px solid ${borderColor}` : 'none',
    borderRadius: '60px',
    px: 1,
    py: 0.3,
    bgcolor: hasBackground ? backgroundColor : 'transparent',
  };

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={pillStyles}>
      {hasBorder && isDot && (
        <Box
          sx={{
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            bgcolor: borderColor,
          }}
        />
      )}
      <VegaText
        text={text}
        sx={{
          textTransform: 'uppercase',
          fontWeight: 700,
          fontSize: '0.625rem',
          color: hasText ? textColor : 'inherit',
        }}
      />
    </Stack>
  );
};
