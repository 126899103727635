import { Box, Grid, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentivesGamificationsState,
  setErrorPolicy,
  setPolicyDetailsState,
} from '../../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentiveModeOfCommunicationType,
  IncentivePolicyProps,
  IncentiveVerticalType,
} from '../../../types/incentives';
import { DateUtility } from '../../../utils/DateUtlility';
import { RupeeIcon } from '../../Icons/Icons';
import LmTextField from '../../common/LmTextField';
import VegaDatePicker from '../../common/VegaDatePicker';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaSelectWithCheckbox from '../../common/VegaSelectWithCheckbox';
import VegaButton from '../../common/VegaButton';
import { IncentiveServices } from '../../../Apis/IncentivesService';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { ROUTES } from '../../../router/routes';
import VegaText from '../../common/VegaText';

interface IProps {
  loading: boolean;
}

const TODAY = new Date();

const PolicyDetails = ({ loading }: IProps) => {
  const { policyId } = useParams();

  const navigate = useNavigate();
  const { policyDetailsState, policyEnumValues } = useAppSelector(
    getIncentivesGamificationsState
  );
  const dispatch = useAppDispatch();
  const { setSnackbar } = useSnackbar();

  const disabled =
    !policyDetailsState?.policyName?.length ||
    !policyDetailsState?.policyType?.length ||
    !policyDetailsState?.caseType?.length ||
    !policyDetailsState?.vertical?.length ||
    !policyDetailsState?.channelType?.length ||
    !policyDetailsState?.incentiveType?.length ||
    !policyDetailsState?.policyStartDate?.length ||
    !policyDetailsState?.policyEndDate?.length ||
    !policyDetailsState?.accountType?.length ||
    !policyDetailsState?.payoutFrequency?.length ||
    !policyDetailsState?.maxPayoutPerPerson ||
    !policyDetailsState?.userType?.length ||
    !policyDetailsState?.modeOfCommunication?.length;

  const handlePolicyChange = (
    key: keyof IncentivePolicyProps,
    value: string | number | string[]
  ) => {
    const policyDetails = {
      ...policyDetailsState,
      [key]: value,
    } as IncentivePolicyProps;
    dispatch(setPolicyDetailsState(policyDetails));
  };

  const handleDateChange = (key: keyof IncentivePolicyProps, date: any) => {
    const dateToString =
      DateUtility.parseDateFromDatePicker(date)?.toISOString();
    handlePolicyChange(key, DateUtility.formatStringToYYYYMMDD(dateToString));
  };

  const onUserSelect = (user: string) => {
    const userType = policyDetailsState?.userType ?? [];
    if (userType?.includes(user)) {
      const filteredUser = userType.filter(
        (selectedUser: string) => selectedUser !== user
      );
      handlePolicyChange('userType', filteredUser);
    } else {
      handlePolicyChange('userType', [...userType, user]);
    }
  };
  const onUserDelete = (index: number) => {
    const userType = policyDetailsState?.userType ?? [];
    const users = [...userType];
    users.splice(index);

    handlePolicyChange('userType', users);
  };

  const onCommunicationTypeSelect = (user: string) => {
    const modeOfCommunication = policyDetailsState?.modeOfCommunication ?? [];
    if (modeOfCommunication?.includes(user)) {
      const filteredCommunication = modeOfCommunication.filter(
        (selectedUser: string) => selectedUser !== user
      );

      handlePolicyChange('modeOfCommunication', filteredCommunication);
    } else {
      handlePolicyChange('modeOfCommunication', [...modeOfCommunication, user]);
    }
  };
  const onCommunicationTypeDelete = (index: number) => {
    const modeOfCommunication = policyDetailsState?.modeOfCommunication ?? [];
    const communications = [...modeOfCommunication];
    communications.splice(index);
    handlePolicyChange('modeOfCommunication', communications);
  };

  const onSavePolicyDetails = () => {
    IncentiveServices.addPolicy(policyDetailsState)
      .then(res => {
        setSnackbar('Add policy successfully !!!');
        navigate(`${ROUTES.INCENTIVES_GAMIFICATIONS_ADD}?id=${res.id}`);
        dispatch(setErrorPolicy(''));
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'policy name'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.policyName),
                loading
              )
            ) : (
              <LmTextField
                value={policyDetailsState?.policyName}
                onChange={e => {
                  const value = e.target.value;
                  handlePolicyChange('policyName', value);
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'policy type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.policyType)),
                loading
              )
            ) : (
              <VegaSelect
                options={policyTypeOption(policyEnumValues?.POLICY_TYPE)}
                value={policyDetailsState?.policyType}
                onChange={selected => {
                  handlePolicyChange(
                    'policyType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'vertical'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.vertical),
                loading
              )
            ) : (
              <VegaSelect
                options={verticalOption()}
                value={policyDetailsState?.vertical}
                onChange={selected => {
                  handlePolicyChange(
                    'vertical',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'channel type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.channelType)),
                loading
              )
            ) : (
              <VegaSelect
                options={channelTypeOption(policyEnumValues?.CHANNEL_TYPE)}
                value={policyDetailsState?.channelType}
                onChange={selected => {
                  handlePolicyChange(
                    'channelType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'incentive type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.incentiveType)),
                loading
              )
            ) : (
              <VegaSelect
                options={incentiveTypeOption(policyEnumValues?.INCENTIVE_TYPE)}
                value={policyDetailsState?.incentiveType}
                onChange={selected => {
                  handlePolicyChange(
                    'incentiveType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Start Date'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.policyStartDate),
                loading
              )
            ) : (
              <VegaDatePicker
                value={policyDetailsState.policyStartDate}
                onChange={date => handleDateChange('policyStartDate', date)}
                disabled={!!policyId}
                minDate={TODAY}
                maxDate={DateUtility.previousDays(
                  new Date(policyDetailsState.policyEndDate),
                  1
                )}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'End Date'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState.policyEndDate),
                loading
              )
            ) : (
              <VegaDatePicker
                value={policyDetailsState.policyEndDate}
                onChange={date => handleDateChange('policyEndDate', date)}
                disabled={!!policyId}
                minDate={DateUtility.addDays(
                  new Date(policyDetailsState.policyStartDate),
                  1
                )}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'account type'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.accountType)),
                loading
              )
            ) : (
              <VegaSelect
                options={accountTypeOption(policyEnumValues?.ACCOUNT_TYPES)}
                value={policyDetailsState?.accountType}
                onChange={selected => {
                  handlePolicyChange(
                    'accountType',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Payout Frequency'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(getPolicyValue(policyDetailsState.payoutFrequency)),
                loading
              )
            ) : (
              <VegaSelect
                options={payoutFrequencyOption(
                  policyEnumValues?.PAYOUT_FREQUENCY
                )}
                value={policyDetailsState?.payoutFrequency}
                onChange={selected => {
                  handlePolicyChange(
                    'payoutFrequency',
                    selected.target.value as string
                  );
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Max Payout'}>
            {policyId ? (
              getTextLoadingWrapper(
                `₹ ${getPolicyValue(policyDetailsState.maxPayoutPerPerson)}`,
                loading
              )
            ) : (
              <LmTextField
                type="number"
                startAdornment={<RupeeIcon />}
                value={policyDetailsState?.maxPayoutPerPerson}
                onChange={e => {
                  const value = e.target.value && parseFloat(e.target.value);
                  handlePolicyChange('maxPayoutPerPerson', value);
                }}
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'user type'}>
            {policyId ? (
              getTextLoadingWrapper(
                getPolicyValue(policyDetailsState?.userType.join(', ')),
                loading
              )
            ) : (
              <VegaSelectWithCheckbox
                options={userTypeOption()}
                onSelect={selected => onUserSelect(selected)}
                handleDelete={selectedIndex => onUserDelete(selectedIndex)}
                selected={policyDetailsState.userType}
                placeHolder="Select user type"
              />
            )}
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <VegaFormInputField label={'Mode of Communication'}>
            {policyId ? (
              getTextLoadingWrapper(
                toLowerCase(
                  getPolicyValue(
                    policyDetailsState?.modeOfCommunication.join(', ')
                  )
                ),
                loading
              )
            ) : (
              <VegaSelectWithCheckbox
                options={communicationTypeOption()}
                onSelect={selected => onCommunicationTypeSelect(selected)}
                handleDelete={selectedIndex =>
                  onCommunicationTypeDelete(selectedIndex)
                }
                selected={policyDetailsState.modeOfCommunication}
                placeHolder="Select Communication"
              />
            )}
          </VegaFormInputField>
        </Grid>
      </Grid>
      {!policyId && (
        <Box
          sx={{
            textAlign: 'end',
            mt: '1rem',
          }}
        >
          <VegaButton
            text="Save Policy Details"
            onClick={onSavePolicyDetails}
            disabled={disabled}
          />
        </Box>
      )}
    </>
  );
};

export default PolicyDetails;

const getPolicyValue = (policyValue: string | number) => {
  const value =
    typeof policyValue === 'number' ? policyValue.toString() : policyValue;
  if (!value) return '--';
  if (value.length) {
    return value;
  } else {
    return '--';
  }
};

const getTextLoadingWrapper = (value: string, loading: boolean) => {
  if (loading) {
    return <Skeleton />;
  } else {
    return <VegaText text={value} fontWeight={500} fontSize={'0.875rem'} />;
  }
};

const verticalOption = () => {
  const options: VegaSelectOption[] = Object.keys(IncentiveVerticalType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};
const userTypeOption = () => {
  const options: VegaSelectOption[] = ['RCM', 'CCM', 'ACM', 'CE'].map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};
const communicationTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(
    IncentiveModeOfCommunicationType
  ).map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};
const channelTypeOption = (channelTypeEnums: string[]) => {
  if (channelTypeEnums) {
    const options: VegaSelectOption[] = channelTypeEnums?.map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  }
};
const policyTypeOption = (policyTypeEnums: string[]) => {
  if (policyTypeEnums) {
    const options: VegaSelectOption[] = policyTypeEnums.map((item: string) => ({
      value: item,
      label: toLowerCase(item),
    }));
    return options;
  }
};
const incentiveTypeOption = (incentiveEnums: string[]) => {
  if (incentiveEnums) {
    const options: VegaSelectOption[] = incentiveEnums.map((item: string) => ({
      value: item,
      label: toLowerCase(item),
    }));
    return options;
  }
};
const accountTypeOption = (accountTypeEnums: string[]) => {
  if (accountTypeEnums) {
    const options: VegaSelectOption[] = accountTypeEnums.map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  }
};
const payoutFrequencyOption = (payoutFrequencyEnums: string[]) => {
  if (payoutFrequencyEnums) {
    const options: VegaSelectOption[] = payoutFrequencyEnums.map(
      (item: string) => ({
        value: item,
        label: toLowerCase(item),
      })
    );
    return options;
  }
};
