import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { VisitTrailDto } from '../types/trail';
import { GetTrailDetailRequest } from '../types/request/trail';
import { TrailService } from '../Apis/TrailService';
import { CreateTrailFormData } from '../components/Trail/UpdateTrailDrawer';
import { LoanData } from '../types/loan';

interface TrailRequestActionState {
  page: number;
  totalItems: number;
  pageSize: number;
  loading: boolean;
  trails: VisitTrailDto[];
  error: string | null;
  trailDetail: Partial<LoanData>;
}

export const fetchTrailList = createAsyncThunk(
  'allocations/fetchtrails',
  async (request: Partial<GetTrailDetailRequest>) => {
    return await TrailService.getTrailList(request);
  }
);
export const updateTrail = createAsyncThunk(
  'allocations/updateTrail',
  async (formData: Partial<CreateTrailFormData>) => {
    const response = await TrailService.updateTrail(formData);
    return response;
  }
);

const initialState: TrailRequestActionState = {
  loading: false,
  page: 0,
  totalItems: 0,
  pageSize: 0,
  trails: [],
  error: null,
  trailDetail: {},
};

const trailRequestSlice = createSlice({
  name: 'trailaction',
  initialState,
  reducers: {
    setTrailDetials: (state: TrailRequestActionState, action) => {
      const { payload } = action;
      state.trailDetail = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTrailList.pending, state => {
        state.loading = true;
      })
      .addCase(fetchTrailList.fulfilled, (state, action) => {
        state.loading = false;
        state.trails = action.payload.records;
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchTrailList.rejected, state => {
        state.loading = false;
      })
      .addCase(updateTrail.pending, state => {
        state.loading = true;
      })
      .addCase(updateTrail.fulfilled, state => {
        state.loading = false;
      })
      .addCase(updateTrail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { setTrailDetials } = trailRequestSlice.actions;
export const trailRequestSelector = (state: RootState) =>
  state.trailRequestAction;
export default trailRequestSlice;
