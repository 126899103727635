import { Switch, styled } from '@mui/material';
import _ from 'lodash';
import { useEffect, useRef } from 'react';

export const toLowerCase = (value: string) => {
  if (!value) return '';
  return _.startCase(_.toLower(value));
};

export const useClickOutSide = (handler: any) => {
  const domNode = useRef<any>();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (domNode.current && !domNode.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
  return domNode;
};

export const getIsTrue = (key: any, arr: any) => {
  return arr.some((value: any) => value !== key);
};
export const URLtoFile = async (url: any) => {
  const res = await fetch(url, {
    mode: 'no-cors',
  });
  const blob = await res.blob();
  const mime = blob.type;
  const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);

  const file = new File([blob], `filename.${ext}`, {
    type: mime,
  });

  return file;
};

export const sorting = (arr: any) => {
  const newArr = arr
    .map((val: any) => {
      return { ...val, updatedAt: new Date(val.updatedAt) };
    })
    .sort((a: any, b: any) => b.updatedAt - a.updatedAt);

  return newArr;
};

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#293543',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
