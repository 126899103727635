import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { AUTH_ROUTES } from './routes';
import { useClientAuth } from '../providers/ClientProvider';

interface IProps {
  children: ReactNode;
}

const PrivateRoutes = ({ children }: IProps) => {
  const { isAuth } = useClientAuth();

  if (isAuth) return <>{children}</>;

  return <Navigate to={AUTH_ROUTES.LOGIN} replace />;
};

export default PrivateRoutes;
