import axios from 'axios';
import { MonthlyProgressDto } from '../types/monthlyProgress';
import { MonthlyProgressRequest } from '../types/request/monthlyProgress';

const Endpoint = {
  BASE: '/calendar',
  GET: '/monthly',
};

const BASE_URL = Endpoint.BASE;
export class MonthlyProgressService {
  static async getMonthlyProgress(
    request: Partial<MonthlyProgressRequest>
  ): Promise<MonthlyProgressDto> {
    const endpoint = BASE_URL + Endpoint.GET;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
