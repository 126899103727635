import React, { useState } from 'react';
import LmButton from '../common/LmButton';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import { Grid } from '@mui/material';
import LmTextField from '../common/LmTextField';
import { VegaFileSelectOption } from '../common/VegaFileSelect';
import { VegaSelectOption } from '../common/VegaSelect';

type Props = {
  open: boolean;
  onClose: () => void;
};
export type SendPaymentLinkFormData = {
  amount: string;
  email: string;
  contactNumber: string;
  loanAccountNumber: string;
};
enum InputType {
  Amount = 'amount',
  Email = 'email',
  ContactNumber = 'contactNumber',
  LoanAccountNumber = 'loanAccountNumber',
}
function PaymentLinkDrawer({ onClose, open }: Props) {
  const [formData, setFormData] = useState<Partial<SendPaymentLinkFormData>>(
    {}
  );

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }
  function onSumbitClick() {
    onClose();
  }
  function onAmountChange(value) {
    updateFormData(InputType.Amount, value);
  }
  function onEmailChange(value) {
    updateFormData(InputType.Email, value);
  }
  function onContactNumberChange(value) {
    updateFormData(InputType.ContactNumber, value);
  }
  function onLoanAccountNumberChange(value) {
    updateFormData(InputType.LoanAccountNumber, value);
  }
  return (
    <VegaDrawer open={open} title={'Update Trail'} onClose={onClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return <LmButton text="Send Link" onClick={onSumbitClick} />;
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              header="Amount"
              placeholder="Enter Amount"
              value={formData?.amount}
              onChange={e => onAmountChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              header="Email"
              placeholder="Enter Email Address"
              value={formData?.email}
              onChange={e => onEmailChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              header="Contact number"
              placeholder="Enter Contact Number"
              value={formData?.contactNumber}
              onChange={e => onContactNumberChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              header="Loan Account Number"
              placeholder="Enter Loan Account Number"
              value={formData?.loanAccountNumber}
              onChange={e => onLoanAccountNumberChange(e.target.value)}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default PaymentLinkDrawer;
