import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuctionService } from '../Apis/AuctionService';
import { BidService } from '../Apis/BidService';
import { RootState } from '../store';
import { Auction, Bid } from '../types/auction';
import { GetBidListRequest } from '../types/request/auction';
import { BIDDER_USER_ID } from '../utils/EXPENSE_CONSTANTS';

export interface LiveAuctionDetailsState {
  loading: boolean;
  auction?: Auction;
  latestBid?: Bid;
  page: number;
  totalItems: number;
  pageSize: number;
  error: string | null;
  bids: Bid[];
  isMakeBidDialogOpen: boolean;
}

const initialState: LiveAuctionDetailsState = {
  auction: null,
  loading: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
  bids: [],
  isMakeBidDialogOpen: false,
};

export const fetchBids = createAsyncThunk(
  'bids/fetchBids',
  async (request: Partial<GetBidListRequest>) => {
    const response = await BidService.getBidList(request);
    return response;
  }
);

export const fetchAuctionAndBids = createAsyncThunk(
  'auction/fetchAuctionById',
  async (auctionId: string, { dispatch }) => {
    const response = await AuctionService.getAuctionById(auctionId, true);
    dispatch(fetchBids({ auctionId: auctionId, pageSize: 5 }));
    return response;
  }
);

export const createBid = createAsyncThunk(
  'auction/createBid',
  async (data: { auctionId?: string; bidAmount?: number }) => {
    const response = await BidService.createBid({
      userId: BIDDER_USER_ID,
      auctionId: data.auctionId,
      bidAmount: data.bidAmount,
    });
    return response;
  }
);

const liveAuctionDetailsSlice = createSlice({
  name: 'Live Auction Details',
  initialState,
  reducers: {
    setLatestBid: (state: LiveAuctionDetailsState, action) => {
      state.latestBid = action.payload;
    },
    onBidReceivedFromWebsocket: (state: LiveAuctionDetailsState, action) => {
      state.latestBid = action.payload;
      state.bids = [action.payload, ...state.bids];
    },
    openMakeBidDialog: (state: LiveAuctionDetailsState) => {
      state.isMakeBidDialogOpen = true;
    },
    closeMakeBidDialog: (state: LiveAuctionDetailsState) => {
      state.isMakeBidDialogOpen = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAuctionAndBids.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuctionAndBids.fulfilled, (state, action) => {
        state.loading = false;
        state.auction = action.payload;
      })
      .addCase(fetchAuctionAndBids.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch Auctions';
      })
      .addCase(fetchBids.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBids.fulfilled, (state, action) => {
        state.loading = false;
        state.bids = [...action.payload.records];
        if (
          action.payload.records != null &&
          action.payload.records.length > 0
        ) {
          state.latestBid = action.payload.records[0];
        }

        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchBids.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch Auctions';
      })
      .addCase(createBid.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBid.fulfilled, state => {
        state.loading = false;
        // Optionally, you can update state after creating a bidder if needed.
        // For example, you may want to add the newly created bidder to the list.
      })
      .addCase(createBid.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});

export const {
  setLatestBid,
  onBidReceivedFromWebsocket,
  openMakeBidDialog,
  closeMakeBidDialog,
} = liveAuctionDetailsSlice.actions;

export default liveAuctionDetailsSlice;

export const liveAuctionDetailsState = (state: RootState) =>
  state.liveAuctionDetails;
