/* eslint-disable no-empty */
/* eslint-disable no-prototype-builtins */
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useExpenseApi } from '../../hooks/useExpenseApi';
import { ExpenseClaim, ExpenseDocument } from '../../types/claim';
import LmButton from '../common/LmButton';
import LmTextField from '../common/LmTextField';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFileSelect, {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../common/VegaFileSelect';

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit: (
    claim: ExpenseClaim,
    formData: Partial<ReapplyExpenseForm>
  ) => void;
  claimId?: string;
};

enum FormField {
  Amount = 'amount',
  Description = 'description',
  Documents = 'documents',
}

export type ReapplyExpenseForm = {
  amount: number;
  description: string;
  documents: VegaFileSelectOption[];
  documentsToBeUploaded: VegaFileSelectOption[];
  documentsToBeDeleted: VegaFileSelectOption[];
};

function ReapplyExpenseClaimDrawer({
  show,
  claimId,
  onClose,
  onSubmit,
}: Props) {
  const { fetchExpenseClaim } = useExpenseApi();
  const [formData, setFormData] = useState<Partial<ReapplyExpenseForm>>({});
  const [claim, setClaim] = useState<ExpenseClaim | null>(null);
  const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState<
    VegaFileSelectOption[]
  >([]);
  const [documentsToBeUploaded, setDocumentsToBeUploaded] = useState<
    VegaFileSelectOption[]
  >([]);

  async function fetchClaimById(claimId: string) {
    try {
      const response = await fetchExpenseClaim({
        id: claimId,
        includeDocumentMetaData: true,
      });
      setClaim(response);

      const metaData = response?.metaData;
      let supportingDocuments: ExpenseDocument[] = [];
      if (metaData.hasOwnProperty('supportingDocuments')) {
        supportingDocuments = metaData[
          'supportingDocuments'
        ] as ExpenseDocument[];
      }

      const expenseDocuments = supportingDocuments.map(item => {
        const option: VegaFileSelectOption = {
          id: item.id,
          label: item.name,
        };
        return option;
      });

      setFormData({
        amount: response?.amount,
        documents: expenseDocuments,
      });
    } catch (error) {
    } finally {
    }
  }

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function onFileChange(index: number, file: VegaFileSelectOption) {
    const existingDocuments = formData.documents ?? [];
    if (index >= 0 && index < existingDocuments.length) {
      existingDocuments[index] = file;
    } else {
      existingDocuments.push(file);
    }
    updateFormData(FormField.Documents, existingDocuments);
  }

  function onFileAdd(file: VegaFileSelectOption) {
    const size = (formData.documents ?? []).length;
    onFileChange(size, file);
    if (file.value) {
      setDocumentsToBeUploaded(prev => [...prev, file]);
    }
  }

  function onFileDelete(index: number, file: VegaFileSelectOption) {
    const existingDocuments = formData.documents ?? [];
    if (existingDocuments.length <= 0) return;
    if (index >= 0 && index < existingDocuments.length) {
      existingDocuments.splice(index, 1);
    }
    updateFormData(FormField.Documents, existingDocuments);
    if (!file.value) {
      setDocumentsToBeDeleted(prev => [...prev, file]);
    }
  }

  function onAmountChange(value: string) {
    const parsedNumber = parseFloat(value);
    const isValidNumber = isNaN(parsedNumber) == false;
    updateFormData(FormField.Amount, isValidNumber ? parsedNumber : undefined);
  }

  function onReapplyClick() {
    if (claim == null) return;
    onSubmit(claim, {
      amount: formData.amount,
      description: formData.description,
      documents: formData.documents,
      documentsToBeUploaded: documentsToBeUploaded,
      documentsToBeDeleted: documentsToBeDeleted,
    });
    cleanUp();
  }

  function onCloseClick() {
    onClose();
    cleanUp();
  }

  function cleanUp() {
    setFormData({});
    setClaim(null);
    setDocumentsToBeDeleted([]);
    setDocumentsToBeUploaded([]);
  }

  const isInputValid = () => {
    const isDescriptionValid = (formData.description ?? '').length > 0;
    const isAmountValid = (formData.amount ?? -1) > 0;
    const isDocumentsValid = (formData.documents ?? []).length > 0;
    return isDescriptionValid && isAmountValid && isDocumentsValid;
  };

  useEffect(() => {
    if (show == true && claimId != null) {
      fetchClaimById(claimId);
    }
  }, [claimId, show]);

  return (
    <VegaDrawer open={show} title={'Reapply Expense'} onClose={onCloseClick}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Reapply"
              onClick={onReapplyClick}
              disabled={isInputValid() == false}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <LmTextField
              placeholder="Description"
              header="Description"
              value={formData.description ?? ''}
              onChange={e =>
                updateFormData(FormField.Description, e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <LmTextField
              placeholder="Amount"
              header="Amount"
              value={formData.amount ?? ''}
              onChange={e => onAmountChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <Stack rowGap={'0.75rem'}>
              {formData.documents?.map((item, index) => {
                return (
                  <VegaFileSelect
                    key={index}
                    value={item}
                    acceptedExtension={[
                      AcceptedExtension.JPG,
                      AcceptedExtension.JPEG,
                      AcceptedExtension.PNG,
                      AcceptedExtension.PDF,
                    ]}
                    placeholder={'Upload Document ' + index}
                    onFileSelect={file => {
                      onFileChange(index, file);
                    }}
                    onDeleteClick={() => {
                      onFileDelete(index, item);
                    }}
                  />
                );
              })}
              <VegaFileSelect
                placeholder={'Upload Document '}
                onFileSelect={file => {
                  onFileAdd(file);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ReapplyExpenseClaimDrawer;
