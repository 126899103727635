import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoanService } from '../Apis/LoanServices';
import { RootState } from '../store';
import { LoanData } from '../types/loan';
import { AccountAgentViewModel } from '../types/receiptManagment';
import { LoanListRequest } from '../types/request/loan';
import { DateUtility } from '../utils/DateUtlility';
import { SendPaymentLinkRequest } from '../types/request/receiptManagment';
import { StringUtility } from '../utils/StringUtility';

export interface ReceiptState {
  accounts: AccountAgentViewModel[];
  totalItems: number;

  loading: boolean;
  error: string | null;
  selectedRows: string[];
  sendPaymentLinkState: Partial<SendPaymentLinkRequest> | null;
}

const initialState: ReceiptState = {
  accounts: [],
  selectedRows: [],
  loading: false,

  error: null,
  totalItems: 0,
  sendPaymentLinkState: null,
};

export const getAccountList = createAsyncThunk(
  'accountSlice/loan/list',
  async (data: LoanListRequest) => {
    const response = await LoanService.getLoanList(data);
    return response;
  }
);

const accountsSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    setSendPaymentLinkState: (state: ReceiptState, action) => {
      const { payload } = action;
      state.sendPaymentLinkState = payload;
    },
    setSelectedRows: (state: ReceiptState, action) => {
      const { payload } = action;
      state.selectedRows = payload;
    },
  },

  extraReducers: builder => {
    builder

      .addCase(getAccountList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAccountList.fulfilled, (state, action) => {
        const accountList = [...action.payload.records];
        const accountViewModalList: AccountAgentViewModel[] = accountList.map(
          (loan: LoanData) => {
            const id = loan.loanId;
            const name = loan.customerDetails[0]?.customerName ?? '-';
            const email = loan.customerDetails[0]?.email ?? '-';
            const product = loan.loanMaster.loanProductType;
            const emi = loan.loanMaster?.emi ?? 0;
            const overDue = loan.lmsVariables
              ? loan.lmsVariables?.overdueAmount ?? 0
              : 0;
            const total = loan.lmsVariables
              ? loan.lmsVariables?.chargeDue ?? 0
              : 0;
            const allowedAmount = loan.lmsVariables
              ? loan.lmsVariables?.overdueAmount ?? 0
              : 0;
            const lan = loan.loanId;
            const pos = loan.lmsVariables
              ? loan.lmsVariables?.outstandingAmount ?? 0
              : 0;
            const ptp = 0;
            const bounceCharge = 0;
            const penaltyCharge = 0;
            const legalCharge = 0;
            const other = 0;
            const bucket = loan.lmsVariables
              ? loan.lmsVariables?.bucket ?? '-'
              : '-';
            const contactNumber = loan.customerDetails[0]?.mobileNo ?? '-';
            const allocationDate = loan.metaDataLoanAllocationDto
              ? loan.metaDataLoanAllocationDto?.allocationDate
                ? DateUtility.formatStringToDDMMYYYY(
                    loan.metaDataLoanAllocationDto?.allocationDate
                  )
                : '--'
              : '--';
            const address = loan.customerDetails[0]?.customerAddress ?? '-';
            return {
              id,
              name,
              email,
              product,
              emi,
              overDue,
              total,
              allowedAmount,
              lan,
              pos: StringUtility.formatToINR(pos),
              ptp,
              bounceCharge,
              penaltyCharge,
              legalCharge,
              other,
              bucket,
              contactNumber,
              allocationDate,
              address,
            };
          }
        );
        state.loading = false;
        state.accounts = accountViewModalList;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAccountList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to create bidder';
      });
  },
});
export const {
  setSelectedRows,

  setSendPaymentLinkState,
} = accountsSlice.actions;
export const getAccountState = (state: RootState) => state.accountSlice;
export default accountsSlice;
