import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import LmDialog from '../../../components/common/LmDialog';
import VegaButton from '../../../components/common/VegaButton';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaText from '../../../components/common/VegaText';
import { GREY } from '../../../constants/style';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

function DeallocateLoanAccountsDialog({ open, onClose, onSubmit }: Props) {
  function onSumbitClick() {
    onSubmit();
    onClose();
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={open}
      handleClose={onClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <VegaButton
            text="Cancel"
            fullWidth
            variant="outlined"
            onClick={onClose}
            sx={{ color: GREY.dark, borderColor: GREY.dark }}
            startIcon={<Close sx={{ color: GREY.dark }} />}
          />
          <VegaButton
            fullWidth
            text="Deallocate"
            onClick={() => {
              onSumbitClick();
            }}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E7EDFC'}
        >
          <VegaText text="Are you sure?" fontWeight={600} fontSize={17} />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <Stack direction={'column'} spacing="1rem">
          <VegaText
            color={'#1B1D22'}
            fontSize={'1.0625rem'}
            lineHeight={'120%'}
            fontWeight={600}
            mt={1}
            text={
              ' Are you sure, you want to deallocate the selected accounts?'
            }
          ></VegaText>
        </Stack>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default DeallocateLoanAccountsDialog;
