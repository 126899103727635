import { VegaFileSelectOption } from '../components/common/VegaFileSelect';

export enum ProcessType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}
export enum TeleCallStatus {
  SCHEDULED = 'SCHEDULED',
  DIALED = 'DIALED',
}
export type CreateLeadSet = {
  name: string;
  processId?: string;
};

export type CreateAllocate = {
  file: VegaFileSelectOption | null;
  // expiryDate: string;
  processId: string;
};
export type CreateCampaign = {
  name: string;
  description: string;
};
export type CreateProcess = {
  name: string;
  campaign: number | null;
  callingMode: number;
};

export type CampaignStatusListProps = {
  createdAt: string;
  updatedAt: string;
  id: string;
  slashRtcCampaignId: number;
  slashRtcCampaignName: string;
};

export type CampaignListProps = {
  status: CampaignStatusListProps[];
  success: boolean;
  totalSize: number;
};
export type ProcessListProps = {
  id: string;
  slashRtcProcessId: number;
  name: string;
  campaign: number;
  callingMode: number;
  processTokenId: string;
};
export type LeadsetListProps = {
  id: string;
  leadsetId: string;
  processId: string;
  leadsetName: string;
  createdAt: string;
  updatedAt: string;
};

export type TeleAllocationProcessViewModal = {
  processId: string;
  slashRtcProcessId: number;
  processName: string;
  campaignName: string | number;
  campaignId: string | number;
  processType: string | ProcessType;
  pendingLead: string | number;
};
export type TeleAllocationLeadsetViewModal = {
  id: string;
  leadsetId: string;
  leadsetName: string;
  processId: string;
  pushLead: string;
};
export type TeleAllocationCampaignViewModal = {
  name: string;
  campaignId: string | number;
  description: string;
};
