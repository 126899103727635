import { Stack } from '@mui/material';
import { useState } from 'react';
import { RED } from '../../constants/style';
import { LeaveViewModel } from '../../types/leave';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import LmTextField from '../common/LmTextField';

interface IRejectLeaveRequest {
  show: boolean;
  onClose: () => void;
  onReject: (leave: LeaveViewModel, reason: string) => void;
  leave?: LeaveViewModel;
}

const RejectLeaveRequestDialog = ({
  show,
  leave,
  onClose,
  onReject,
}: IRejectLeaveRequest) => {
  const [reason, setReason] = useState<string>('');

  const isInputValid = () => {
    return reason.length > 0;
  };

  function onSubmit() {
    if (!leave) return;
    onReject(leave, reason);
    handleClose();
  }

  function handleClose() {
    onClose();
    setReason('');
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={handleClose}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <LmButton
            fullWidth
            text="Reject"
            bgColor={'rgba(186, 28, 28, 1)'}
            onClick={onSubmit}
            disabled={isInputValid() === false}
          />
          <LmButton
            text="Cancel"
            fullWidth
            variant="outlined"
            bgColor="#F7F7F7"
            textColor="#1B1D22"
            onClick={handleClose}
            sx={{ borderColor: '#C5C8D0' }}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#F8E8E8'}
        >
          <VegaText
            text="Reject leave request?"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={RED.red}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem">
        <VegaText
          text="What is your reason for rejection?"
          style={{
            color: 'rgba(103, 107, 118, 1)',
            fontSize: '0.625rem',
            fontWeight: 700,
            lineHeight: '124%',
            letterSpacing: '0.04375rem',
            textTransform: 'uppercase',
          }}
        />

        <LmTextField
          placeholder="Enter reason here"
          minRows={4}
          onChange={e => setReason(e.target.value)}
        />
      </Stack>
    </LmDialog>
  );
};

export default RejectLeaveRequestDialog;
