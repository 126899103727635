import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  renderBottomView?: () => ReactNode;
};

function VegaDrawerContent({ children, renderBottomView }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ padding: '2rem' }}>{children}</div>

      {renderBottomView != null && (
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'end'}
          borderTop={`1px solid #E1E4EB`}
          px={4}
          py={2.5}
          gap={2}
        >
          {renderBottomView()}
        </Stack>
      )}
    </div>
  );
}

export default VegaDrawerContent;
