/* eslint-disable no-empty */
import { KeyboardArrowDown } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { fetchManagerByRoleId } from '../../../../../Apis/users';
import { BLUE } from '../../../../../constants/style';
import { useDataResriction } from '../../../../../providers/DataRestrictionProvider';
import VegaText from '../../../../common/VegaText';
import ManagerChip from './ManagerChip';

interface IProps {
  branchRoleId: string;
  branchEntityId: string;
  branchManagerName: string;
  index: number;
}

const ManagerDropdownComponent = ({
  branchEntityId,
  branchRoleId,
  branchManagerName,
  index,
}: IProps) => {
  const { setSelectedRoleId, setManagertList } = useDataResriction();

  const onGetManagerList = async (id: string, entityId: string) => {
    // setSelectedEntityId(null);
    setSelectedRoleId(id);
    try {
      const response = await fetchManagerByRoleId({
        roleId: id,
        entityIds: [entityId],
      });
      if (response) {
        setManagertList(response);
      }
    } catch (error) {}
  };
  return (
    <>
      {branchManagerName ? (
        <ManagerChip branchManagerName={branchManagerName} index={index} />
      ) : (
        <Stack direction={'row'} gap={1}>
          <VegaText
            text={'Assign Manager'}
            fontWeight={600}
            fontSize={'0.625rem'}
            color={BLUE.lighter}
          />
          <KeyboardArrowDown
            sx={{
              color: BLUE.lighter,
              fontSize: '0.9375rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              onGetManagerList(branchRoleId, branchEntityId);
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default ManagerDropdownComponent;
