/* eslint-disable react/prop-types */
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { AllocationService } from '../../../Apis/AllocationService';
import { fetchLeadsetList } from '../../../actions/TeleAllocationAction';
import { PRIMARY } from '../../../constants/style';
import { getTeleAllocation } from '../../../features/teleAllocationSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ProcessType,
  TeleAllocationLeadsetViewModal,
} from '../../../types/teleAllocation';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import LmFilterSection from '../../common/LmFilterSection';
import VegaButton from '../../common/VegaButton';
import VegaDataGrid from '../../common/VegaDataGrid';
import {
  AcceptedExtension,
  VegaFileSelectOption,
} from '../../common/VegaFileSelect';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';

const ListOfLeadsetCreation = () => {
  const { leadsets } = useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();
  const [uploading, setUploading] = useState<boolean>(false);
  const { setSnackbar } = useSnackbar();
  const [leadsetData, setLeadsetData] = useState<
    Partial<TeleAllocationLeadsetViewModal>
  >({});
  const fileInputRef = useRef(null);

  const COL_DEF: GridColumns = [
    {
      field: 'leadsetId',
      headerName: 'Leadset ID',
      flex: 0.5,
    },
    {
      field: 'leadsetName',
      headerName: 'Leadset Name',
      flex: 1,
    },

    {
      field: 'processId',
      headerName: 'Process ID',
      flex: 0.7,
    },

    {
      field: 'pushLead',
      headerName: 'Push Leads',
      flex: 0.7,
    },
    {
      field: 'action',
      headerName: 'Bulk Push Lead',
      flex: 0.7,
      renderCell: props => {
        const leadset = props.row as TeleAllocationLeadsetViewModal;
        return (
          <>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileSelect}
              accept={AcceptedExtension.XLSX}
            />
            <VegaButton
              text={'Upload'}
              sx={{
                bgcolor: '#E7EDFC',
                color: PRIMARY.darkBlue,
                ':hover': {
                  bgcolor: '#E7EDFC',
                  color: PRIMARY.darkBlue,
                },
              }}
              onClick={() => onBulkUpload(leadset)}
            />
          </>
        );
      },
    },
  ];

  const onBulkUpload = (leadsets: TeleAllocationLeadsetViewModal) => {
    fileInputRef.current.click();
    setLeadsetData(leadsets);
  };

  const handleFileSelect = async event => {
    const selectedFile = event.target.files[0];
    const fileObj: VegaFileSelectOption = {
      value: selectedFile,
      label: selectedFile.name,
      id: selectedFile.name,
    };
    try {
      setUploading(true);
      await AllocationService.createTeleAllocations({
        processId: leadsetData.processId,
        file: fileObj,
        leadsetId: leadsetData.leadsetId,
        teleAllocationType: ProcessType.AUTO,
      });
      setSnackbar('Allocate Successfully !!!');
      dispatch(fetchLeadsetList());
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchLeadsetList());
  }, [dispatch]);
  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Leadset '}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return <LmFilterSection />;
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={leadsets}
          idColumn="id"
          columns={COL_DEF}
          paginationMode="client"
          loading={uploading}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfLeadsetCreation;
