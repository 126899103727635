import NoResults from '../../../components/common/NoResults';
import VegaPageContent from '../../../components/common/VegaPageContent';
import { getTargetState } from '../../../features/targetSlice';
import { useAppSelector } from '../../../store';
import { TargetListProps, TargetsStatus } from '../../../types/targets';
import TargetBucketCard from './TargetBucketCard';

const CompletedTargetList = () => {
  const { targets } = useAppSelector(getTargetState);
  return (
    <>
      <VegaPageContent sx={{ mt: '1rem' }}>
        {targets.length ? (
          <TargetBucketCard
            isBucketStatus
            bucketData={targets.filter(
              (target: TargetListProps) =>
                target.targetStatus !== TargetsStatus.RAISED
            )}
          />
        ) : (
          <NoResults sx={{ height: 'calc(100% - 142px)' }} />
        )}
      </VegaPageContent>
    </>
  );
};

export default CompletedTargetList;
