import { Box, Collapse, Grid } from '@mui/material';
import { useDataResriction } from '../../../../../providers/DataRestrictionProvider';
import { IRoleProps } from '../DataRestriction';
import RoleList from './RoleList';

interface IProps {
  branchRoleId: string;
  branchEntityId: string;
  branchIndex: number;
}

const RoleComponent = ({
  branchRoleId,
  branchEntityId,
  branchIndex,
}: IProps) => {
  const { roleList, selectedEntityId } = useDataResriction();
  return (
    <>
      <Collapse in={branchEntityId === selectedEntityId}>
        <Box
          sx={{
            border: `1px solid #C5C8D0`,
            borderRadius: '6px',
            position: 'absolute',
            width: '100%',
            top: 30,
            bgcolor: 'white',
            zIndex: 9999,
            pl: 2,
            pr: 1.5,
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            {roleList.map((role: IRoleProps) => (
              <RoleList
                key={role.id}
                role={role}
                branchIndex={branchIndex}
                branchRoleId={branchRoleId}
              />
            ))}
          </Grid>
        </Box>
      </Collapse>
    </>
  );
};

export default RoleComponent;
