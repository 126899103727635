import { Stack } from '@mui/material';
import TeleAgentDetailsCard from '../components/TeleAgentDetailsCard';
import TeleAgentTextWrapper from '../components/TeleAgentTextWrapper';
import { useAppSelector } from '../../../store';
import { getTeleAgent } from '../../../features/teleAgentSlice';
import { toLowerCase } from '../../../constants/commonFunction';

const AddressDetails = () => {
  const { loanData, loading } = useAppSelector(getTeleAgent);
  const getCustomerDetails = () => {
    if (loanData.length && loanData[0].customerDetails.length) {
      const customerData = loanData[0].customerDetails[0];
      return {
        customerAddress: toLowerCase(customerData?.customerAddress),
      };
    }
    return {
      customerAddress: '--',
    };
  };
  return (
    <TeleAgentDetailsCard text="Address Details" isBorder>
      <Stack gap={2}>
        <TeleAgentTextWrapper
          text="Communication Address"
          value={getCustomerDetails().customerAddress}
          loading={loading}
        />
        <TeleAgentTextWrapper
          text="Permanent Address"
          value={getCustomerDetails().customerAddress}
          loading={loading}
        />
        <TeleAgentTextWrapper
          text="Employment Address"
          value={getCustomerDetails().customerAddress}
          loading={loading}
        />
        <TeleAgentTextWrapper
          text="Employment Address"
          value={getCustomerDetails().customerAddress}
          loading={loading}
        />
      </Stack>
    </TeleAgentDetailsCard>
  );
};

export default AddressDetails;
