/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import {
  DateRangeFilter,
  FilterOption,
} from '../../../components/ExpenseClaim/FilterInput';
import LmFilterSection from '../../../components/common/LmFilterSection';
import VegaButton from '../../../components/common/VegaButton';
import VegaDataGrid from '../../../components/common/VegaDataGrid';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import { VegaPill } from '../../../components/common/VegaPill';
import { PRIMARY } from '../../../constants/style';
import {
  getReceiptsList,
  receiptState,
  setReceiptData,
} from '../../../features/receiptManagmentSlice';
import { useDrawer } from '../../../hooks/useDrawer';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  ReceiptManagmentViewModel,
  getPillColorForStatus,
} from '../../../types/receiptManagment';
import { GetReceiptsListRequest } from '../../../types/request/receiptManagment';
import { DateUtility } from '../../../utils/DateUtlility';
import HistoryRecieptDetailsDialog from './HistoryRecieptDetailsDialog';
import ReceiptFilterDrawer from './ReceiptFilterDrawer';
import ReciptFilterBy from './ReciptFilterBy';

const TODAY = new Date();
const TOMORROW = DateUtility.addDays(TODAY, 1);

const HistoryReceiptManagment = () => {
  const dispatch = useAppDispatch();
  const { receipts, loading, totalItems } = useAppSelector(receiptState);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [openReceipt, setOpenReceipt] = useState<boolean>(false);
  const [dayFilter, setDayFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();
  const [dateFilter, setDateFilter] = useState<DateRangeFilter>({
    startDate: TODAY.toISOString(),
    endDate: TOMORROW.toISOString(),
  });
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const COL_DEF: GridColumns<ReceiptManagmentViewModel[]> = [
    {
      field: 'receiptDate',
      headerName: 'Receipt Date',
      flex: 0.7,
    },
    {
      field: 'receiptId',
      headerName: 'Receipt ID',
      flex: 0.7,
    },
    {
      field: 'agentName',
      headerName: 'Agent name',
      flex: 0.7,
    },
    {
      field: 'lan',
      headerName: 'LAN',
      flex: 0.7,
    },
    {
      field: 'bankNameCode',
      headerName: 'Bank Name & Code',
      flex: 0.7,
    },
    {
      field: 'paymentMode',
      headerName: 'Payment Mode',
      flex: 0.7,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptManagmentViewModel;
        const color = getPillColorForStatus(receipt.status);
        return (
          <VegaPill
            text={receipt.status}
            backgroundColor={color?.LIGHT}
            textColor={color?.DARK}
            borderColor={color?.DARK}
          />
        );
      },
    },
    {
      field: 'proof',
      headerName: 'Details',
      flex: 0.7,
      renderCell: props => {
        const receipt = props.row as unknown as ReceiptManagmentViewModel;
        return (
          <VegaButton
            text="View Details"
            variant="text"
            sx={{
              color: PRIMARY.darkBlue,
              fontSize: '0.875rem',
              fontWeight: 500,
              px: 0,
            }}
            onClick={() => viewDetails(receipt)}
          />
        );
      },
    },
  ];

  const viewDetails = (receipt: ReceiptManagmentViewModel) => {
    toggleDialog();
    dispatch(setReceiptData(receipt));
  };
  const onClearAllClick = () => {
    setDateFilter({ startDate: '', endDate: '' });
    setDayFilter(undefined);
    setStatusFilter('');
    closeFilterDrawer();
    fetchReceiptsList();
  };

  const onSelectFilterClick = (
    statusFilter: string,
    dateRangeFilter: DateRangeFilter,
    dayFilter?: string
  ) => {
    closeFilterDrawer();
    setDayFilter(dayFilter);
    setStatusFilter(statusFilter);
    setDateFilter(dateRangeFilter);

    const filteredReceiptObj = {
      status: statusFilter,
      page,
      pageSize,
    } as GetReceiptsListRequest;

    if (dateRangeFilter.startDate.length && dateRangeFilter.endDate.length) {
      filteredReceiptObj.toDate = dateRangeFilter.endDate;
      filteredReceiptObj.fromDate = dateRangeFilter.startDate;
    }

    dispatch(getReceiptsList(filteredReceiptObj));
  };

  const toggleDialog = () => {
    setOpenReceipt(!openReceipt);
  };

  const fetchReceiptsList = () => {
    dispatch(getReceiptsList({ page, pageSize }));
  };

  useEffect(() => {
    fetchReceiptsList();
  }, [page, pageSize]);
  return (
    <>
      <VegaPageHeader
        title="History"
        renderRightView={() => (
          <LmFilterSection
            setSearch={() => {}}
            onFilter={() => openFilterDrawer()}
          />
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={receipts}
          loading={loading}
          columns={COL_DEF}
          idColumn="id"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
        />
      </VegaPageContent>
      <ReceiptFilterDrawer
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <ReciptFilterBy
          onClearAllClick={onClearAllClick}
          onSelectFilterClick={onSelectFilterClick}
          value={{
            dayFilter: dayFilter,
            statusFilter: statusFilter,
            dateRangeFilter: dateFilter,
          }}
          allowedFilters={[FilterOption.DayFilter, FilterOption.StatusFilter]}
        />
      </ReceiptFilterDrawer>
      <HistoryRecieptDetailsDialog
        open={openReceipt}
        toggleDialog={toggleDialog}
      />
    </>
  );
};

export default HistoryReceiptManagment;
