import { Stack } from '@mui/material';
import React from 'react';
import { CalenderIcon, UserIcon } from '../../../components/Icons/Icons';
import VegaText from '../../../components/common/VegaText';
import { DateUtility } from '../../../utils/DateUtlility';
import { VegaPill } from '../../../components/common/VegaPill';
import { TargetsStatus } from '../../../types/targets';
import { COLOR } from '../../../utils/ColorUtility';

interface IBucketStatusProps {
  date?: string | Date | null;
  user?: string;
  status?: string;
}

const TargetBucketStatusComponent = ({
  date = null,
  user,
  status,
}: IBucketStatusProps) => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      pb={'1rem'}
    >
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          {date ? (
            <>
              <CalenderIcon />
              <VegaText
                fontWeight={500}
                fontSize={'0.875rem'}
                color={'black'}
                text={date ? DateUtility.getDateWithMonthName(date) : ''}
              />
            </>
          ) : (
            ''
          )}
        </Stack>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          {user ? (
            <>
              <UserIcon />
              <VegaText
                fontWeight={500}
                fontSize={'0.875rem'}
                color={'black'}
                text={user}
              />
            </>
          ) : (
            ''
          )}
        </Stack>
      </Stack>
      {status && (
        <VegaPill
          text={status}
          backgroundColor={getColorForStatus(status)?.LIGHT}
          textColor={getColorForStatus(status)?.DARK}
          borderColor={getColorForStatus(status)?.DARK}
        />
      )}
    </Stack>
  );
};

export default TargetBucketStatusComponent;

const getColorForStatus = (status: string) => {
  switch (status) {
    case TargetsStatus.REJECTED:
      return COLOR.RED;
    case TargetsStatus.APPROVED:
      return COLOR.GREEN;
    case TargetsStatus.RAISED:
      return COLOR.ORANGE;
  }
};
