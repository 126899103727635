import {
  CustomerDetail,
  LmsVariable,
  LoadPDCDetails,
  LoanMaster,
  LoanOutstandingDetails,
  MetaDataLoanAllocationDto,
} from './loan';

export enum AllocationTypes {
  FIELD = 'FIELD',
  TELE = 'TELE',
}

export enum AllocationStatus {
  UNALLOCATED = 'UNALLOCATED',
  ALLOCATED = 'ALLOCATED',
  NORMALIZED = 'NORMALIZED',
  STABILIZED = 'STABILIZED',
  ROLLBACK = 'ROLLBACK',
  CANCELLED = 'CANCELLED',
}

export type Allocation = {
  id: string;
  loanAccountId: string;
  region: string;
  branch: string;
  agentCode: string;
  agencyCode: string;
  allocationType: AllocationTypes;
  status: AllocationStatus;
  createdAt: string;
  updatedAt: string;
  expiresAt: string;
};

export type TeleAllocationList = {
  id: string;
  phoneNumber: string;
  processId: string;
  leadsetId: string;
  loanId: string;
  teleAllocatonType: string;
  agentId: string;
  teleCallStatus: string;
  createdAt: string | null;
};

export type TeleAllocateBulk = {
  requestId: string;
  allocations: any[];
  errorFileUrl: string;
};

export type AllocationViewModel = {
  id: string;
  loanAccountNumber: string;
  customerName: string;
  contactNumber: string;
  address: string;
  agentName: string;
  branchName: string;
};

export type AllocationStatusViewModel = {
  [key: string]: number;
};

export type AllocationRule = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  expiresAt: string;
  description: string;
  criteriaList: Criteria[];
  status: string;
  agentId: string;
  createdBy: string;
};

export type AllocationRuleViewModel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  expiresAt: string;
  description: string;
  criteriaList: Criteria[];
  status: string;
  agentId: string;
  createdBy: string;
};

export enum AttributeDataType {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
}

export enum AttributeOperationType {
  GreaterThen = 'GT',
  GreaterThenOrEqual = 'GTE',
  LessThen = 'LT',
  LessThenOrEqual = 'LTE',
  Equal = 'EQ',
  Like = 'LIKE',
}

export type Criteria = {
  attributeName: string;
  dataType: AttributeDataType;
  op: string;
  value: number | string;
  entity: string;
};

export type RuleCriteriaAttribute = {
  name: string;
  dataType: AttributeDataType;
  validOperators: AttributeOperationType[];
  entity: string;
};

export type BulkAllocationMeta = {
  id: number;
  createdBy: string;
  fileName: string;
  totalEntries: number;
  successEntries: number;
  failedEntries: number;
  successfulAllocationIds: string[];
  errorFile: string;
  createdAt: string;
  updatedAt: string;
  allocationType: string;
};

export type AllocationMetaData = {
  loanId: string;
  loanMaster: LoanMaster;
  lmsVariables: LmsVariable;
  loanPdcDetails: LoadPDCDetails;
  loanOutstandingDetails: LoanOutstandingDetails;
  loanTransactions: any | null;
  customerDetails: CustomerDetail[];
  metaDataLoanAllocationDto: MetaDataLoanAllocationDto;
};
export type AllocationMetaDataExcelView = {
  name: string;
  loanAccountNumber: string;
  allocationType: string;
  pos: number;
  bucket: string;
  contactNumber: string;
  allocationDate: string;
};
