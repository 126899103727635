import React from 'react';
import { Stack, Box } from '@mui/material';
import VegaText from '../../common/VegaText';
import { BORDER_COLLECTION, COMMON } from '../../../constants/style';

interface IProps {
  text: string;
  children: JSX.Element;
  isBorder?: boolean;
}

const TeleAgentDetailsCard = ({ text, isBorder, children }: IProps) => {
  return (
    <>
      <Stack gap={2}>
        <VegaText
          text={text}
          fontSize={'0.875rem'}
          fontWeight={500}
          color={COMMON.balck}
        />
        <Box
          sx={{
            pb: '1rem',
            ...(isBorder && { borderBottom: BORDER_COLLECTION }),
          }}
        >
          {children}
        </Box>
      </Stack>
    </>
  );
};

export default TeleAgentDetailsCard;
