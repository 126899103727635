import LmFilterSection from '../../components/common/LmFilterSection';
import { useDrawer } from '../../hooks/useDrawer';
import { AddCircleOutline } from '@mui/icons-material';
import LmButton from '../../components/common/LmButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaPageContent from '../../components/common/VegaPageContent';
import CreateLeaveDrawer from '../../components/Leave/CreateLeaveDrawer';
import { GridColumns } from '@mui/x-data-grid';
import VegaText from '../../components/common/VegaText';
import { VegaPill } from '../../components/common/VegaPill';
import { DateUtility } from '../../utils/DateUtlility';
import { COLOR } from '../../utils/ColorUtility';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import LeaveDetailsDialog from '../../components/Leave/LeaveDetailsDialog';
import { useAppDispatch, useAppSelector } from '../../store';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { LeaveStatus, LeaveViewModel } from '../../types/leave';
import {
  createLeave,
  fetchLeaveList,
  leaveRequestSelector,
} from '../../features/leaveRequestSlice';
import VegaDrawer from '../../components/common/VegaDrawer';
import LeaveMangementFilter from '../../components/Leave/LeaveMangementFilter';
import { useClientAuth } from '../../providers/ClientProvider';

const ApplyLeave = () => {
  const dispatch = useAppDispatch();
  const { userId } = useClientAuth();
  const {
    leaves,
    loading,
    page: currentPage,
    totalItems,
  } = useAppSelector(leaveRequestSelector);

  const [page, setPage] = useState<number>(0);
  const {
    open: openApplyLeaveDrawer,
    close: closeApplyLeaveDrawer,
    isOpen: isApplyLeaveDrawerOpen,
  } = useDrawer(false);
  const {
    open: openDetailDialog,
    close: closeDetailsDialog,
    isOpen: isDetailsDialogOpen,
    props: detailsDialogProps,
  } = useDrawer(false);
  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);
  const [status, setStatus] = useState<string | null>(null);
  function onPageChange(pageNumber: number) {
    if (page == pageNumber) return;
    setPage(pageNumber);
  }

  const onfilterClick = (selectedStatusFilter: string) => {
    closeFilterDrawer();
    setStatus(selectedStatusFilter);
  };

  const onClearAllClick = () => {
    closeFilterDrawer();
    setStatus(null);
  };

  useEffect(() => {
    status
      ? dispatch(
          fetchLeaveList({ userId: userId, status, page: page, size: 10 })
        )
      : dispatch(fetchLeaveList({ userId: userId, page: page, size: 10 }));
  }, [page, status]);

  return (
    <div>
      <VegaPageHeader
        title="Leave Dashboard"
        renderRightView={() => (
          <LmButton
            text="Apply Leave"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              openApplyLeaveDrawer();
            }}
          />
        )}
      />

      <VegaPageHeader
        renderRightView={() => {
          return (
            <LmFilterSection
              onFilter={() => {
                openFilterDrawer();
              }}
            />
          );
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaDrawer
        open={isFilterDrawerOpen}
        title={'Filter By'}
        onClose={function (): void {
          closeFilterDrawer();
        }}
      >
        <LeaveMangementFilter
          onfilterClick={onfilterClick}
          defaultValue={status}
          onClearAllClick={onClearAllClick}
        />
      </VegaDrawer>
      <VegaPageContent>
        <VegaDataGrid
          page={currentPage}
          pageSize={10}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={leaves}
          columns={getColumnDefinition({
            onViewDetailsClick: (leave: LeaveViewModel) => {
              openDetailDialog({ leave: leave });
            },
          })}
          loading={loading}
          idColumn="id"
        />
      </VegaPageContent>
      <CreateLeaveDrawer
        open={isApplyLeaveDrawerOpen}
        onClose={function (): void {
          closeApplyLeaveDrawer();
        }}
        onSubmit={function (formData): void {
          dispatch(createLeave({ formData: formData, userId: userId }));
        }}
      />
      <LeaveDetailsDialog
        open={isDetailsDialogOpen}
        onClose={function (): void {
          closeDetailsDialog();
        }}
        leave={detailsDialogProps.leave}
      />
    </div>
  );
};
export default ApplyLeave;

const getColumnDefinition = (data: {
  onViewDetailsClick: (leave: LeaveViewModel) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'TypeofLeave',
      headerName: 'Type Of Leave',
      flex: 0.7,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={Leave.typesofLeave}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      flex: 1,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={`${DateUtility.formatStringToDDMMYYYY(Leave?.startDate)}`}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.startDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={`${DateUtility.formatStringToDDMMYYYY(Leave?.endDate)}`}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.endDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'Reason',
      headerName: 'Reason/Remark',
      flex: 0.6,
      minWidth: 350,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={Leave.reason}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 0.6,
      minWidth: 130,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaPill
            text={getStatusValue(Leave.status)}
            backgroundColor={getColorForStatus(Leave.status).LIGHT}
            key={Leave.status}
            textColor={getColorForStatus(Leave.status).DARK}
          />
        );
      },
    },
    {
      field: 'ApproversName',
      headerName: 'Approvers Name',
      flex: 0.6,
      minWidth: 149,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={Leave.approversName}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Details',
      headerName: 'Details',
      flex: 0.6,
      minWidth: 119,

      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaButton
            text="View Details"
            onClick={() => data.onViewDetailsClick(Leave)}
            variant="text"
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};

const getColorForStatus = (status: string) => {
  switch (status) {
    case 'REJECTED':
      return COLOR.RED;
    case 'APPROVED':
      return COLOR.GREEN;
    case 'APPLIED':
      return COLOR.ORANGE;
  }
};

const getStatusValue = (status: string) => {
  switch (status) {
    case 'REJECTED':
      return LeaveStatus.REJECTED;
    case 'APPROVED':
      return LeaveStatus.APPROVED;
    case 'APPLIED':
      return 'PENDING';
  }
};
