import { Stack } from '@mui/material';
import { LeaveViewModel } from '../../types/leave';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import {
  AcceptLeaveRequestDialogAcceptIcon,
  RejectLeaveDialogCancelIcon,
} from '../Icons/Icons';

interface IAcceptLeaveRequest {
  show: boolean;
  onAcceptClick: (leave: LeaveViewModel) => void;
  onClose: () => void;
  leave?: LeaveViewModel;
}

const AcceptLeaveRequestDialog = ({
  show,
  onAcceptClick,
  leave,
  onClose,
}: IAcceptLeaveRequest) => {
  function _onAcceptClick() {
    if (!leave) return;
    onAcceptClick(leave);
    _onCloseClick();
  }

  function _onCloseClick() {
    onClose();
  }

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={_onCloseClick}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <LmButton
            fullWidth
            text="Cancel"
            textColor="#1B1D22"
            bgColor={'#F7F7F7'}
            onClick={() => {
              _onCloseClick();
            }}
            startIcon={<RejectLeaveDialogCancelIcon />}
          />
          <LmButton
            text="Approve"
            fullWidth
            variant="contained"
            bgColor="#1047DC"
            textColor="#FFFFFF"
            onClick={() => {
              _onAcceptClick();
            }}
            startIcon={<AcceptLeaveRequestDialogAcceptIcon />}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E7EDFC'}
        >
          <VegaText
            text="Are you sure?"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'#1B1D22'}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem" pt="36px">
        <VegaText
          text={`You are approving the leave Request of Agent ${
            leave?.agentName ?? ''
          }`}
          style={{
            minWidth: '328px',
            color: '#1B1D22',
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: '120%',
            fontStyle: 'normal',
            letterSpacing: '0.04375rem',
            textTransform: 'uppercase',
            fontFamily: 'Aspekta',
          }}
        />
      </Stack>
    </LmDialog>
  );
};
export default AcceptLeaveRequestDialog;
