import axios from 'axios';
import { ApiService } from '../types/api';
import { AreaListProps, RescheduleVisit } from '../types/minVisitsType';
import {
  AreaListRequest,
  CreateMinVisitRuleRequest,
  CreateVisitBulkRequest,
  MinVisitListProps,
  RescheduleVisitRequest,
} from '../types/request/minVisitsRequest';
import { PaginatedResponse } from '../utils/api';

const Endpoint = {
  BASE: '/master',
  VISIT: '/visit',
  ALLOCATION: '/allocation',
  MIN_VISIT: '/minVisitRule',
  AREA: '/area',
  LIST: '/list',
  BULK: '/bulk',
  RESCHEDULE: '/reschedule',
};

const AREA_BASE_URL = Endpoint.BASE + Endpoint.AREA;
const MIN_VISIT_URL = Endpoint.VISIT + Endpoint.MIN_VISIT;
const BULK_VISIT_URL = Endpoint.VISIT + Endpoint.BULK;
const RESCHEDULE_VISIT_URL = Endpoint.VISIT + Endpoint.RESCHEDULE;

export class MinVisitsSevices extends ApiService {
  static async getAreaList(
    request: Partial<AreaListRequest>
  ): Promise<PaginatedResponse<AreaListProps>> {
    const endpoint = AREA_BASE_URL + Endpoint.LIST;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async getMinVisitRuleList(
    request: Partial<CreateMinVisitRuleRequest>
  ): Promise<PaginatedResponse<MinVisitListProps>> {
    const endpoint = MIN_VISIT_URL + Endpoint.LIST;
    const response = await axios.get(endpoint, { params: request });
    return response.data;
  }
  static async createMinVisitRule(
    request: Partial<CreateMinVisitRuleRequest>
  ): Promise<MinVisitListProps> {
    const endpoint = MIN_VISIT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createMinVisitBulk(
    request: Partial<CreateVisitBulkRequest>
  ): Promise<MinVisitListProps> {
    const endpoint = BULK_VISIT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }

  static async updateMinVisitRule(
    request: Partial<CreateMinVisitRuleRequest>
  ): Promise<MinVisitListProps> {
    const endpoint = MIN_VISIT_URL;
    const response = await axios.put(endpoint, request);
    return response.data;
  }

  static async rescheduleVisit(
    request: Partial<RescheduleVisitRequest>
  ): Promise<RescheduleVisit> {
    const endpoint = RESCHEDULE_VISIT_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
