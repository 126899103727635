import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import VegaText from './VegaText';

type VegaCrumbProps<T> = {
  label: string;
  link?: T;
  color?: string;
};

interface VegaBreadCrumbProps<T> {
  items: Array<VegaCrumbProps<T>>;
  separator?: string;
}

function VegaBreadCrumb<T>({ items, separator = '>' }: VegaBreadCrumbProps<T>) {
  const navigate = useNavigate();
  return (
    <Breadcrumbs separator={<VegaText text={separator} />}>
      {items.map((item, index) => {
        let color = 'rgba(27, 29, 34, 1)';

        if (item.color) {
          color = item.color;
        } else {
          if (item.link == null) {
            color = 'rgba(27, 29, 34, 1)';
          } else {
            color = '#1047DC';
          }
        }

        return (
          <div
            key={index}
            style={{
              textDecoration: 'none',
              color: 'inherit',
              cursor: item.link != null ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (item.link != null) {
                navigate(item.link, { relative: 'path' });
              }
            }}
          >
            <VegaText
              text={item.label}
              color={color}
              fontSize="0.75rem"
              fontWeight={500}
              lineHeight="0.975rem"
            />
          </div>
        );
      })}
    </Breadcrumbs>
  );
}

export default VegaBreadCrumb;
