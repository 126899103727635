import React from 'react';
import { NESTED_ROUTES } from '../../../../../router/routes';
import { useParams } from 'react-router-dom';
import { RolesProps, useUAM } from '../../../../../providers/UAMProvider';
import { Stack } from '@mui/material';
import { toLowerCase } from '../../../../../constants/commonFunction';
import { Close } from '@mui/icons-material';
interface IProps {
  branchManagerName: string;
  index: number;
}

const ManagerChip = ({ branchManagerName, index }: IProps) => {
  const { params } = useParams();
  const { selectedBranch, setSelectedBranch } = useUAM();

  const handleDeleteManager = async (index: number) => {
    const updatedBranches = [...selectedBranch];
    const selectedBranchOptions = updatedBranches[index];
    const managerObj = {
      id: '',
      name: '',
    };
    const newObj = {
      ...selectedBranchOptions,
      managers: managerObj,
    } as RolesProps;
    updatedBranches.splice(index, 1, newObj);
    setSelectedBranch(updatedBranches);
  };
  return (
    <div>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        key={branchManagerName}
        sx={{
          bgcolor: 'white',
          color: '#3966E2',
          borderRadius: '12px',
          border: '1px solid #3966E2',
          px: 1.5,
          py: 0.5,
          fontFamily: `var(--font-Aspekta-600) !important`,
          fontSize: '0.625rem',
        }}
      >
        {toLowerCase(branchManagerName)}
        {params !== NESTED_ROUTES.VIEW_DETAILS && (
          <Close
            sx={{
              fontSize: '0.75rem',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleDeleteManager(index);
            }}
          />
        )}
      </Stack>
    </div>
  );
};

export default ManagerChip;
