import { AddCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import AddHolidayType from '../components/HolidayType/AddHolidayType';
import ListOfHolidayType from '../components/HolidayType/ListOfHolidayType';
import LmButton from '../components/common/LmButton';
import LmFilterSection from '../components/common/LmFilterSection';
import VegaDrawer from '../components/common/VegaDrawer';
import VegaDrawerContent from '../components/common/VegaDrawerContent';
import VegaPageHeader from '../components/common/VegaPageHeader';
import { useHolidayType } from '../providers/HolidayTypeProvider';
import { ICreateHolidayTypeProps } from '../types/holiday';

const LmHolidayType = () => {
  const {
    isAdd,
    selectedHolidayType,
    holidayTypeState,
    setHolidayTypeState,
    toggleAddHolidayTypeDrawer,
    onAddHolidayTypeSubmit,
    onUpdateHolidayTypeSubmit,
    setSelectedHolidayType,
  } = useHolidayType();
  const isEmptyValue = holidayTypeState
    ? Object.values(holidayTypeState).some((type: any) => type === '')
    : true;

  const checkCondition = (selectedHolidayType: string) => {
    if (selectedHolidayType) {
      return {
        title: 'Update Holiday Type',
        buttonText: 'Update Details',
        buttonClick: () => {
          const newHolidayTypeObj = {
            ...holidayTypeState,
            id: selectedHolidayType,
          } as ICreateHolidayTypeProps;

          onUpdateHolidayTypeSubmit(newHolidayTypeObj);
        },
      };
    } else {
      return {
        title: 'Add Holiday Type',
        buttonText: 'Add Details',
        buttonClick: onAddHolidayTypeSubmit,
      };
    }
  };

  return (
    <>
      <VegaPageHeader
        title="Holiday Type"
        renderRightView={() => (
          <LmButton
            text="Add New Holiday Type"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setSelectedHolidayType(null);
              setHolidayTypeState(null);
              toggleAddHolidayTypeDrawer();
            }}
          />
        )}
      />
      <Box p={3}>
        <LmFilterSection setSearch={() => {}} />
        <Box pt={3}>
          {' '}
          <ListOfHolidayType />{' '}
        </Box>
      </Box>
      <VegaDrawer
        title={checkCondition(selectedHolidayType?.id).title}
        open={isAdd}
        onClose={toggleAddHolidayTypeDrawer}
      >
        <VegaDrawerContent
          renderBottomView={() => (
            <LmButton
              text={checkCondition(selectedHolidayType?.id).buttonText}
              disabled={isEmptyValue}
              fontWeight={500}
              onClick={checkCondition(selectedHolidayType?.id).buttonClick}
            />
          )}
        >
          <AddHolidayType />
        </VegaDrawerContent>
      </VegaDrawer>
    </>
  );
};

export default LmHolidayType;
