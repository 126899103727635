import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { RED, GREY } from '../../constants/style';
import { ExpenseClaim } from '../../types/claim';
import LmButton from '../common/LmButton';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import LmTextField from '../common/LmTextField';

type Props = {
  show: boolean;
  claim?: ExpenseClaim;
  onRejectClick: (claim: ExpenseClaim, reason: string) => void;
  onCancelClick: () => void;
};

function RejectConfirmationDialog({
  show,
  onCancelClick,
  onRejectClick,
  claim,
}: Props) {
  const [reason, setReason] = useState<string>('');

  function onReject() {
    if (!claim) return;
    onRejectClick(claim, reason);
  }

  function onCancel() {
    setReason('');
    onCancelClick();
  }

  const isInputValid = () => {
    return reason.length > 0;
  };

  return (
    <LmDialog
      fullWidth
      maxWidth="xs"
      open={show}
      handleClose={onCancelClick}
      buttonComponent={() => (
        <Stack
          width={'100%'}
          direction={'row'}
          alignItems={'center'}
          px={4}
          pb={4}
          gap={1}
        >
          <LmButton
            fullWidth
            text="Reject"
            bgColor={'rgba(186, 28, 28, 1)'}
            onClick={() => {
              onReject();
            }}
            disabled={isInputValid() == false}
          />
          <LmButton
            text="Cancel"
            fullWidth
            variant="outlined"
            bgColor=""
            textColor={GREY.dark}
            onClick={onCancel}
            startIcon={<Close sx={{ color: GREY.dark }} />}
          />
        </Stack>
      )}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#F8E8E8'}
        >
          <VegaText
            text="Reject expense claim?"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={RED.red}
          />
        </Stack>
      )}
    >
      <Stack spacing={'0.5rem'} padding="2rem">
        <VegaText
          text="What is your reason for rejection?"
          style={{
            color: 'rgba(103, 107, 118, 1)',
            fontSize: '0.625rem',
            fontWeight: 700,
            lineHeight: '124%',
            letterSpacing: '0.04375rem',
            textTransform: 'uppercase',
          }}
        />

        <LmTextField
          placeholder="Enter reason here"
          minRows={4}
          onChange={e => setReason(e.target.value)}
        />
      </Stack>
    </LmDialog>
  );
}

export default RejectConfirmationDialog;
