import axios from 'axios';
import { ICalenderProps } from '../types/CalenderType';
import { ApiService } from '../types/api';
import { ICalenderRequest } from '../types/request/CalenderRequest';

const Endpoint = {
  BASE: '/calendar',
};

export class CalenderService extends ApiService {
  static async getAccountListForCalender(
    request: Partial<ICalenderRequest>
  ): Promise<ICalenderProps> {
    const endpoint = Endpoint.BASE;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
