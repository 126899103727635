import { Box } from '@mui/material';
import React from 'react';
import ManagerList from './ManagerList';
import { ICreateUserProps } from '../../../../../types/UAM';
import { useDataResriction } from '../../../../../providers/DataRestrictionProvider';

interface IProps {
  branchRoleId: string;
}

const ManagerComponent = ({ branchRoleId }: IProps) => {
  const { managerList, selectedRoleId } = useDataResriction();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {branchRoleId === selectedRoleId && (
          <Box
            sx={{
              border: `1px solid #C5C8D0`,
              borderRadius: '6px',
              position: 'absolute',
              top: 30,
              bgcolor: 'white',
              py: 1,
              width: '50%',
            }}
          >
            {managerList.length
              ? managerList.map((manager: ICreateUserProps, inx: number) => (
                  <ManagerList key={inx} manager={manager} index={inx} />
                ))
              : ''}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ManagerComponent;
