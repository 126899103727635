import { Radio, RadioProps, styled } from '@mui/material';

const Icon = styled('span')(() => ({
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  boxShadow:
    'inset 0 0 0 1px rgba(16, 71, 220, 1), inset 0 -1px 0 rgba(16, 71, 220, 1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto rgba(16, 71, 220, 1)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(Icon)({
  backgroundColor: 'rgba(16, 71, 220, 1)',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '1rem',
    height: '1rem',
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: 'rgba(16, 71, 220, 1)',
  },
});

export function VegaRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<Icon />}
      {...props}
    />
  );
}
