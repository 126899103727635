import { AddCircleOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ListOfUAM from '../components/UAM/ListOfUAM';
import LmButton from '../components/common/LmButton';
import LmFilterSection from '../components/common/LmFilterSection';
import { useUAM } from '../providers/UAMProvider';
import { NESTED_ROUTES, ROUTES } from '../router/routes';
import VegaPageHeader from '../components/common/VegaPageHeader';

const LmUAM = () => {
  const navigate = useNavigate();
  const { setUserState, setIsSave, setSelectedBranch } = useUAM();

  return (
    <>
      <VegaPageHeader
        title="User Access Management"
        renderRightView={() => (
          <LmButton
            text="Create New User"
            fontWeight={500}
            startIcon={<AddCircleOutline />}
            onClick={() => {
              setUserState(null);
              setIsSave(false);
              setSelectedBranch([]);
              navigate(
                `${ROUTES.UAM_PARAMS.replace(':params', NESTED_ROUTES.CREATE)}`
              );
            }}
          />
        )}
      />
      <Box p={3}>
        <LmFilterSection setSearch={() => {}} />
        <Box pt={3}>
          {' '}
          <ListOfUAM />{' '}
        </Box>
      </Box>
    </>
  );
};

export default LmUAM;
