import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchHolidayById } from '../../Apis/holiday';
import { useHolidayName } from '../../providers/HolidayNameProvider';
import { useHolidayType } from '../../providers/HolidayTypeProvider';
import { IHolidayTypeListProps } from '../../types/holiday';
import { DateUtility } from '../../utils/DateUtlility';
import { getTimeString } from '../../utils/dayjsUtils';
import VegaKeyValueDisplay from '../common/VegaKeyValueDisplay';
import VegaText from '../common/VegaText';

const ViewHolidayDetails = () => {
  const { selectedHoliday, setHolidayState, holidayState } = useHolidayName();
  const { holidayTypeListState } = useHolidayType();
  const [loading, setLoading] = useState<boolean>(false);
  const [holiayTypeName, setHoliayTypeName] = useState<string>('');

  const getHolidayDetailsById = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetchHolidayById(id);
      const filteredHolidayType = holidayTypeListState.records.find(
        (holidayType: IHolidayTypeListProps) =>
          response.holidayType === holidayType.id
      );
      setHoliayTypeName(filteredHolidayType?.name);
      setHolidayState(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHoliday.id) {
      getHolidayDetailsById(selectedHoliday.id);
    }
  }, [selectedHoliday]);
  return (
    <div>
      <VegaText
        text="Details"
        fontWeight={600}
        fontSize={'0.875rem'}
        color={'#222222'}
        mb={2.5}
      />
      <Stack gap={1.5}>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading={'Name'}
              value={holidayState.name}
              loading={loading}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Holiday Type"
              value={holiayTypeName}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Stack>
      <VegaText
        text="Timeline"
        fontWeight={600}
        fontSize={'0.875rem'}
        color={'#222222'}
        mb={2.5}
        mt={4}
      />
      <Stack gap={1}>
        <Grid container rowGap={'0.75rem'}>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Start Date"
              value={DateUtility.formatStringToDDMMYYYY(
                holidayState.startDateTime
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="Start Time"
              value={getTimeString(holidayState?.startDateTime)}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="End Date"
              value={DateUtility.formatStringToDDMMYYYY(
                holidayState.endDateTime
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <VegaKeyValueDisplay
              heading="End Time"
              value={getTimeString(holidayState?.endDateTime)}
            />
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default ViewHolidayDetails;
