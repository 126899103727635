/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import { PRIMARY } from '../../constants/style';
import {
  getAccountList,
  getAccountState,
  setSelectedRows,
} from '../../features/accountsSlice';
import { useDrawer } from '../../hooks/useDrawer';
import { ROUTES } from '../../router/routes';
import { useAppDispatch, useAppSelector } from '../../store';
import { AccountAgentViewModel } from '../../types/receiptManagment';
import { LoanListRequest } from '../../types/request/loan';
import { LmsVariable } from '../../types/loan';
import { StringUtility } from '../../utils/StringUtility';
import VegaText from '../common/VegaText';

const ListOfAccounts = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accounts, loading, totalItems, selectedRows } =
    useAppSelector(getAccountState);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const {
    isOpen: isFilterDrawerOpen,
    open: openFilterDrawer,
    close: closeFilterDrawer,
  } = useDrawer(false);

  const handleSelectionModelChange = (selectionModel: string[]) => {
    dispatch(setSelectedRows(selectionModel));
  };

  const fetchAccountsList = async () => {
    try {
      const body = {
        includeLmsVariables: true,
        includeCustomerDetails: true,
        page,
        size: pageSize,
      } as LoanListRequest;
      dispatch(getAccountList(body));
    } catch (error) {}
  };

  useEffect(() => {
    fetchAccountsList();
  }, [page, pageSize]);

  return (
    <>
      <VegaPageHeader
        title="Pending"
        renderRightView={() => (
          <LmFilterSection
            setSearch={() => {}}
            onFilter={() => openFilterDrawer()}
          />
        )}
        sx={{ borderBottom: 'none', paddingBottom: '1rem' }}
      />

      <VegaPageContent>
        <VegaDataGrid
          data={accounts}
          loading={loading}
          columns={getColumnDefinition({
            onViewDetails: account => {
              navigate(
                ROUTES.ACCOUNT_BY_LOAN_ACCOUNT_ID.replace(
                  ':loanAccountId',
                  account.lan
                )
              );
            },
          })}
          idColumn="id"
          page={page}
          pageSize={pageSize}
          rowCount={totalItems}
          paginationMode="server"
          onPageChange={page => setPage(page)}
          onPageSizeChange={size => setPageSize(size)}
          checkboxSelection
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfAccounts;

const getColumnDefinition = (data: {
  onViewDetails: (account: AccountAgentViewModel) => void;
}) => {
  const COL_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
    },

    {
      field: 'lan',
      headerName: 'Loan Account Number',
      flex: 0.7,
    },
    {
      field: 'pos',
      headerName: 'POS',
      flex: 0.5,
    },
    {
      field: 'bucket',
      headerName: 'Bucket',
      flex: 0.5,
    },
    {
      field: 'contactNumber',
      headerName: 'Contact number',
      flex: 0.7,
    },
    {
      field: 'allocationDate',
      headerName: 'Allocation Date',
      flex: 0.7,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 0.6,
      renderCell: props => {
        const account = props.row as unknown as AccountAgentViewModel;
        return (
          <Stack direction={'row'} gap={1}>
            <VegaButton
              text={'View Details'}
              variant="text"
              onClick={() => data.onViewDetails(account)}
            />
          </Stack>
        );
      },
    },
  ];
  return COL_DEF;
};
