import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Box,
  DrawerProps,
} from '@mui/material';

import VegaText from './VegaText';

interface IProps extends DrawerProps {
  open: boolean;
  title: string;
  onClose: () => void;
}

const VegaDrawer = ({ children, onClose, title, ...rest }: IProps) => {
  return (
    <Drawer
      {...rest}
      PaperProps={{
        sx: {
          width: '40%',
          minWidth: 360,
        },
      }}
      onClose={onClose}
      anchor="right"
    >
      <AppBar
        position="static"
        sx={{
          bgcolor: '#E5F7FC',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            '&.MuiToolbar-root': { minHeight: '75px !important' },
          }}
        >
          <VegaText
            text={title}
            color={'black'}
            fontWeight={600}
            fontSize={'1.0625rem'}
          />

          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            onClick={onClose}
            sx={{
              color: '#222222',
              mr: 2,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          bgcolor: 'white',
          height: '100%',
          overflow: 'auto',
          '::-webkit-scrollbar': {
            width: 0,
          },
          '::-moz-scrollbar': {
            width: 0,
          },
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
};

export default VegaDrawer;
