export const COLOR = {
  RED: {
    LIGHT: 'rgba(248, 232, 232, 1)',
    DARK: 'rgba(169, 25, 25, 1)',
  },
  GREEN: {
    LIGHT: 'rgba(235, 244, 236, 1)',
    DARK: 'rgba(49, 132, 57, 1)',
  },
  ORANGE: {
    LIGHT: 'rgba(255, 242, 230, 1)',
    DARK: 'rgba(232, 112, 1, 1)',
  },
};
