/* eslint-disable no-unused-vars */
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { VegaClientInterface } from '../types/client';
import { useSnackbar } from './SnackbarProvider';

export type ClientContextType = {
  clientId: string | null;
  userId: string | null;
  isAuth: boolean;
  isAdmin: boolean;
  email: string | null;
  userName: string | null;
  lastLoginTime: Date | null;
  login: (event: any, email: string, password: string) => void;
  logout: () => void;
  client: VegaClientInterface | null;
  completeNewPassword: (newPassword: string) => void;
};

type Props = {
  children: React.ReactNode;
};

const ClientContext = React.createContext<ClientContextType | null>(null);
export const useClientAuth = () =>
  React.useContext(ClientContext) as ClientContextType;

const ClientAuthProvider = ({ children }: Props) => {
  const existingId = sessionStorage.getItem('clientId');
  const existingUserId = sessionStorage.getItem('userId');
  const { setSnackbar } = useSnackbar();
  const [isAuth, setIsAuth] = useState(!!existingId);
  const [clientId, setClientId] = useState<string | null>(existingId || '');
  const [userId, setUserId] = useState<string | null>(existingUserId || '');
  const [client, setClient] = useState<VegaClientInterface | null>(null);
  const [loginChallange, setLoginChallange] = useState<any>(null);
  const [email, setEmail] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [lastLoginTime, setLastLoginTime] = useState<Date | null>(null);
  const tokenRefreshJob = React.useRef<NodeJS.Timeout | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const stopTokenRefreshJob = () => {
    // eslint-disable-next-line no-undef
    clearInterval(tokenRefreshJob.current as NodeJS.Timeout);
    tokenRefreshJob.current = null;
  };

  const logout = () => {
    Auth.signOut();
    stopTokenRefreshJob();
    setClientId(null);
    setClient(null);
    setIsAuth(false);
    sessionStorage.clear();
    localStorage.clear();
  };

  const refreshAccessToken = () => {
    Auth.currentSession()
      .then(() => {})
      .catch(() => {
        logout();
      });
  };

  const startTokenRefreshJob = () => {
    if (!tokenRefreshJob.current) {
      const tokenInterval = setInterval(refreshAccessToken, 1000 * 60 * 60 * 1);
      tokenRefreshJob.current = tokenInterval;
    }
  };

  const setUserParams = async () => {
    await Auth.currentAuthenticatedUser()
      .then(async user => {
        if (user) {
          const clientId = user.attributes['custom:clientId'];
          const userId = user.attributes['custom:userId'];
          const email = user.attributes.email;
          const loggedInTime = new Date(
            user.signInUserSession.idToken.payload['auth_time'] * 1000
          );
          const userName = user.attributes.preferred_username;
          setLastLoginTime(loggedInTime);
          setEmail(email);
          setUserName(userName);
          // const idToken = user.signInUserSession.idToken.jwtToken;
          // const accessToken = user.signInUserSession.accessToken.jwtToken;
          // const refreshToken = user.signInUserSession.refreshToken.jwtToken;
          setClientId(clientId);
          setUserId(userId);
          setIsAuth(true);
          setLoginChallange(null);
          sessionStorage.setItem('clientId', clientId);
          sessionStorage.setItem('userId', userId);
          startTokenRefreshJob();
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('failed to fetch current user', err);
      })
      .finally(() => {});
  };

  const login = (event: any, email: string, password: string) => {
    if (event != null) {
      event.preventDefault();
    }

    Auth.signIn(email, password)
      .then(user => {
        if (window.location.pathname != '/onboarding') {
          setSnackbar(`Login successfull`);
        }

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setLoginChallange({ forceChange: true, user: user });
          //   navigate(ROUTES.SET_NEW_PASSWORD);
        } else {
          setUserParams();
        }
      })
      .catch(error => {
        setSnackbar(`${error.message}`, 'error');
      });
  };

  const completeNewPassword = async (newPassword: string) => {
    if (loginChallange?.forceChange) {
      try {
        let user = loginChallange.user;
        try {
          const { requiredAttributes } = user.challengeParam;
          user = await Auth.completeNewPassword(
            loginChallange.user as CognitoUser,
            newPassword,
            requiredAttributes
          );

          setUserParams();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Failed to update', e);
      }
    }
  };

  useEffect(() => {
    setUserParams();
    return () => {
      stopTokenRefreshJob();
    };
  }, []);

  // useEffect(() => {
  //   if (!client || !loggedInUserDetails) return;
  //   const updatedLoggedInDetails: ILoggedInUser = {
  //     email: loggedInUserDetails.email,
  //     mobileNumber: client.clientMob,
  //     loginTime: loggedInUserDetails.loginTime,
  //     idToken: loggedInUserDetails.idToken,
  //     accessToken: loggedInUserDetails.idToken,
  //     refreshToken: loggedInUserDetails.idToken,
  //     name: loggedInUserDetails.name,
  //     user: loggedInUserDetails?.user,
  //   };
  //   setLoggedInUserDetails(updatedLoggedInDetails);
  // }, [client]);

  return (
    <ClientContext.Provider
      value={{
        clientId: clientId,
        userId,
        isAuth,
        isAdmin,
        login,
        logout,
        client,
        completeNewPassword,
        email,
        userName,
        lastLoginTime,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientAuthProvider;
