import { InfoOutlined } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchHolidayById } from '../../Apis/holiday';
import { toLowerCase } from '../../constants/commonFunction';
import { useHolidayName } from '../../providers/HolidayNameProvider';
import { useHolidayType } from '../../providers/HolidayTypeProvider';
import { IHolidayTypeListProps } from '../../types/holiday';
import { DateUtility } from '../../utils/DateUtlility';
import LmTextField from '../common/LmTextField';
import VegaDateTimePicker from '../common/VegaDateTimePicker';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

const TODAY = new Date();

const AddNewHoliday = () => {
  const {
    holidayState,
    handleAddHolidayChange,
    selectedHoliday,
    setHolidayState,
  } = useHolidayName();
  const { holidayTypeListState } = useHolidayType();
  // const today = new Date().toISOString().split('T')[0];

  const [loading, setLoading] = useState<boolean>(false);

  const getHolidayTypeById = async (id: string) => {
    try {
      const response = await fetchHolidayById(id);
      setHolidayState(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function onStartDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);

    var endDateTime = DateUtility.parseStringToDate(holidayState.endDateTime);
    if (endDateTime && parsedDate) {
      if (DateUtility.isBefore(endDateTime, parsedDate)) {
        endDateTime = DateUtility.addDays(parsedDate, 1);
      }
    }

    setHolidayState(prev => {
      return {
        ...prev,
        startDateTime: parsedDate?.toISOString(),
        endDateTime: endDateTime?.toISOString(),
      };
    });
  }

  function onEndDateChange(e: any) {
    const parsedDate = DateUtility.parseDateFromDatePicker(e);
    setHolidayState(prev => {
      return {
        ...prev,
        endDateTime: parsedDate?.toISOString(),
      };
    });
  }

  useEffect(() => {
    if (selectedHoliday?.id) {
      setLoading(true);
      getHolidayTypeById(selectedHoliday.id);
    }
  }, [selectedHoliday]);
  return (
    <>
      <Box mb={2}>
        <LmTextField
          loading={loading}
          placeholder="Enter Holiday Name"
          header="name"
          value={holidayState.name}
          onChange={e => {
            const value = e.target.value;
            handleAddHolidayChange('name', value);
          }}
        />
      </Box>

      <Box mb={2}>
        <VegaFormInputField
          label="Select Holiday Type"
          endAdornment={
            <InfoOutlined
              sx={{
                fontSize: '0.75rem',
                color: '#676B76',
                cursor: 'pointer',
              }}
            />
          }
        >
          <VegaSelect
            loading={loading}
            options={holidayTypeOption(holidayTypeListState.records)}
            value={holidayState.holidayType}
            onChange={e => {
              const value = e.target.value;
              handleAddHolidayChange('holidayType', value);
            }}
          />
        </VegaFormInputField>
      </Box>

      <Box mb={2}>
        <VegaFormInputField label={'add new holiday'}>
          <Grid container columnGap={'1rem'} rowGap="1.5rem" columns={12}>
            <Grid item xs={12}>
              <VegaDateTimePicker
                onChange={onStartDateChange}
                value={holidayState.startDateTime}
                minDate={TODAY}
              />
            </Grid>
            <Grid item xs={12}>
              <VegaDateTimePicker
                onChange={onEndDateChange}
                value={holidayState.endDateTime}
                minDate={holidayState.startDateTime}
              />
            </Grid>
          </Grid>
        </VegaFormInputField>
      </Box>
    </>
  );
};

export default AddNewHoliday;

const holidayTypeOption = (holidayType: IHolidayTypeListProps[]) =>
  holidayType.map((item: IHolidayTypeListProps) => {
    const option: VegaSelectOption = {
      value: item.id,
      label: toLowerCase(item.name),
    };
    return option;
  });
