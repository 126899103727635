import { Box, Collapse, Stack } from '@mui/material';
import { useState } from 'react';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import {
  fetchMinVisitList,
  getMinVisitsState,
  setOpenMinVisitsFilterDrawer,
} from '../../../features/minVisitsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import BranchSelector from './BranchSelector';
import RegionSelector from './RegionSelector';

interface IFormDataProps {
  region: string;
  branch: string;
}

const MinVisitsFilterDrawer = () => {
  const { openMinVisitsFilterDrawer } = useAppSelector(getMinVisitsState);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<IFormDataProps>({
    region: '',
    branch: '',
  });
  const onClose = () => {
    dispatch(setOpenMinVisitsFilterDrawer(false));
  };
  const onClear = () => {
    setFormData({ branch: '', region: '' });
    dispatch(
      fetchMinVisitList({
        page: 0,
        pageSize: 10,
      })
    );
    onClose();
  };
  const handleChange = (key: keyof IFormDataProps, value: string) => {
    setFormData({ ...formData, [key]: value });
  };

  const disabled = !formData.region.length || !formData.branch.length;

  const onFilterRule = () => {
    dispatch(
      fetchMinVisitList({
        page: 0,
        pageSize: 10,
        region: formData.region,
        branch: formData.branch,
      })
    );
    onClose();
  };
  return (
    <div>
      <VegaDrawer
        title="Filter By"
        open={openMinVisitsFilterDrawer}
        onClose={onClose}
      >
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Clear All"
                  sx={{
                    color: '#1047DC',
                  }}
                  variant="text"
                  onClick={onClear}
                />
                <VegaButton
                  text="Apply Filter"
                  disabled={disabled}
                  onClick={onFilterRule}
                />
              </Stack>
            );
          }}
        >
          <Stack gap={2}>
            <Box>
              <VegaFormInputField label={'Region'}>
                <RegionSelector
                  selected={formData.region}
                  handleChange={selected => handleChange('region', selected)}
                />
              </VegaFormInputField>
            </Box>
            <Box>
              <Collapse in={formData?.region?.length > 0}>
                <VegaFormInputField label={'branch'}>
                  <BranchSelector
                    selected={formData?.branch}
                    handleChange={selected => handleChange('branch', selected)}
                  />
                </VegaFormInputField>
              </Collapse>
            </Box>
          </Stack>
          {/* <Box sx={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label={'Bucket'}>
              <VegaRadioGroup
                heading="Day"
                options={renderBuketOption()}
                value={formData.bucket}
                onChange={e => {
                  handleChange('bucket', e.target.value);
                }}
              />
            </VegaFormInputField>
          </Box> */}
        </VegaDrawerContent>
      </VegaDrawer>
    </div>
  );
};

export default MinVisitsFilterDrawer;
