import { Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import ExpenseCard from '../../components/Calender/ExpenseCard';
import ListOfAccountCalender from '../../components/Calender/ListOfAccountCalender';
import { VegaDateCalender } from '../../components/common/VegaDateCalender';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaText from '../../components/common/VegaText';

const TODAY = new Date();

const Calender = () => {
  const [date, setDate] = useState<Dayjs | null>(dayjs(TODAY));
  return (
    <div>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <VegaText
              text={'Calender'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
      />
      <VegaPageContent>
        <Stack
          direction={'row'}
          sx={{ width: '100%', mt: '1rem' }}
          gap={2}
          alignItems={'start'}
        >
          <ListOfAccountCalender date={date} />
          <Stack sx={{ maxWidth: '320px' }}>
            <VegaDateCalender
              date={date}
              onChange={(newDate: Dayjs) => setDate(newDate)}
            />
            <ExpenseCard date={date} />
          </Stack>
        </Stack>
      </VegaPageContent>
    </div>
  );
};

export default Calender;
