import { Box, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import OverviewChart from '../../../components/Home/AdminHome/OverviewChart';
import QuickAccess from '../../../components/Home/AdminHome/QuickAccess';
import HomeSummariesCard from '../../../components/Home/components/HomeSummariesCard';
import VegaOutlineDatePicker from '../../../components/common/VegaOutlineDatePicker';
import VegaPageContent from '../../../components/common/VegaPageContent';
import VegaPageHeader from '../../../components/common/VegaPageHeader';
import VegaText from '../../../components/common/VegaText';
import { DateUtility } from '../../../utils/DateUtlility';
import AdminNotifications from '../../../components/Home/AdminHome/AdminNotifications';
import { VisitService } from '../../../Apis/VisitService';
import { useClientAuth } from '../../../providers/ClientProvider';

const DUMMY = [
  {
    label: 'Incentive',
    value: '24',
    sameDayValue: '65',
    growth: 'UP',
  },
  {
    label: 'No. of accounts',
    value: '12',
    sameDayValue: '65',
    growth: 'UP',
  },
  {
    label: 'My reportees',
    value: '7',
    sameDayValue: '65',
    growth: 'UP',
  },
  {
    label: 'No. of Escalation',
    value: '32',
    sameDayValue: '65',
    growth: 'DOWN',
  },
];

const AdminHomePage = () => {
  const [dateValue, setDateValue] = useState<string>(
    DateUtility.formatStringToYYYYMMDD(new Date().toDateString())
  );

  const handleChangeDate = (e: any) => {
    const date = DateUtility.formatStringToYYYYMMDD(e);
    setDateValue(date);
  };

  return (
    <Box height={'100%'}>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Hi, Akash'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} gap={1}>
              <VegaOutlineDatePicker
                onChange={handleChangeDate}
                value={dateValue}
                maxDate={new Date()}
              />
            </Stack>
          );
        }}
      />
      <Box height={'calc(100% - 95px)'} sx={{ overflow: 'scroll' }}>
        <VegaPageContent mt={'1rem'}>
          <Grid container spacing={2}>
            {DUMMY.map((card, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <HomeSummariesCard
                  label={card.label}
                  value={card.value}
                  sameDayValue={card.sameDayValue}
                  growth={card.growth}
                />
              </Grid>
            ))}
          </Grid>
        </VegaPageContent>
        <VegaPageContent mt={'1rem'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Stack gap={2}>
                <OverviewChart />
                <QuickAccess />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} height={'auto'}>
              <AdminNotifications />
            </Grid>
          </Grid>
        </VegaPageContent>
      </Box>
    </Box>
  );
};

export default AdminHomePage;
