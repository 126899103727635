import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import React from 'react';
import { useDataResriction } from '../../../../providers/DataRestrictionProvider';
import { fetchRolesByClientId } from '../../../../Apis/users';
import { BLUE } from '../../../../constants/style';
import { useClientAuth } from '../../../../providers/ClientProvider';

interface IProps {
  entityId: string;
}

const ArrowComponent = ({ entityId }: IProps) => {
  const { clientId } = useClientAuth();
  const { selectedEntityId, setSelectedEntityId, setRoleList } =
    useDataResriction();

  const toggleCollapse = async (rowId: string) => {
    const response = await fetchRolesByClientId(clientId);
    setRoleList(response);
    setSelectedEntityId(rowId);
  };
  return (
    <>
      {entityId === selectedEntityId ? (
        <KeyboardArrowUp
          sx={{
            color: BLUE.lighter,
            fontSize: '0.9375rem',

            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedEntityId(null);
          }}
        />
      ) : (
        <KeyboardArrowDown
          sx={{
            color: BLUE.lighter,
            fontSize: '0.9375rem',

            cursor: 'pointer',
          }}
          onClick={() => {
            toggleCollapse(entityId);
          }}
        />
      )}
    </>
  );
};

export default ArrowComponent;
