import { Grid } from '@mui/material';
import LmButton from '../common/LmButton';
import LmDateField from '../common/LmDateField';
import LmTextField from '../common/LmTextField';
import VegaDrawerContent from '../common/VegaDrawerContent';
import _ from 'lodash';
import { useState } from 'react';
import dayjs from 'dayjs';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';
import VegaFormInputField from '../common/VegaFormInputField';
import {
  CreateApplyLeaveFormData,
  HalfOfDay,
  TypeOfLeave,
} from '../../types/leave';
import { DateUtility } from '../../utils/DateUtlility';

enum FormField {
  TYPE_OF_LEAVE = 'typeOfLeave',
  START_DATE = 'startDate',
  START_DATE_HALF = 'startDateHalf',
  END_DATE = 'endDate',
  END_DATE_HALF = 'endDateHalf',
  REASON = 'reason',
}

type Props = {
  onSubmit: (formData: Partial<CreateApplyLeaveFormData>) => void;
};
const CreateLeaveForm = ({ onSubmit }: Props) => {
  const [formData, setFormData] = useState<Partial<CreateApplyLeaveFormData>>({
    startDate: new Date(),
    endDate: new Date(),
  });

  function updateFormData(field: FormField, value: any) {
    setFormData(prev => {
      return {
        ...prev,
        [field]: value,
      };
    });
  }

  function onCreateClick() {
    onSubmit(formData);
  }

  const setTypeOfLeaveField = (value: string) => {
    updateFormData(FormField.TYPE_OF_LEAVE, value);
  };
  const setStartDateField = (value: Date) => {
    updateFormData(FormField.START_DATE, dayjs(value));
  };
  const setStartDateHalfField = (value: string) => {
    updateFormData(FormField.START_DATE_HALF, value);
  };
  const setEndDateField = (value: Date) => {
    updateFormData(FormField.END_DATE, dayjs(value));
  };
  const setEndDateHalfField = (value: string) => {
    updateFormData(FormField.END_DATE_HALF, value);
  };
  const setReasonField = (value: string) => {
    updateFormData(FormField.REASON, value);
  };

  const isInputValid = (formData: Partial<CreateApplyLeaveFormData>) => {
    if (
      !formData.typeOfLeave ||
      !formData.startDate ||
      !formData.startDateHalf ||
      !formData.endDate ||
      !formData.endDateHalf ||
      !formData.reason
    ) {
      return false;
    }
    return true;
  };

  return (
    <VegaDrawerContent
      renderBottomView={() => {
        return (
          <LmButton
            text="APPLY"
            disabled={isInputValid(formData) == false}
            onClick={() => {
              onCreateClick();
            }}
          />
        );
      }}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Type of leave">
            <VegaSelect
              options={typeOfLeaveOptions()}
              value={formData.typeOfLeave}
              onChange={e => setTypeOfLeaveField(e.target.value as string)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <LmDateField
            header="START DATE"
            value={formData.startDate}
            onChange={e =>
              setStartDateField(DateUtility.parseDateFromDatePicker(e))
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Select Half">
            <VegaSelect
              options={StartDateHalfOfDay()}
              value={formData.startDateHalf}
              onChange={e => setStartDateHalfField(e.target.value as string)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <LmDateField
            minDate={formData.startDate}
            header="END DATE"
            value={formData.endDate}
            onChange={e =>
              setEndDateField(DateUtility.parseDateFromDatePicker(e))
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <VegaFormInputField label="Select Half">
            <VegaSelect
              options={EndDateHalfOfDay(formData)}
              value={formData.typeOfLeave}
              onChange={e => setEndDateHalfField(e.target.value as string)}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
          <LmTextField
            placeholder="Enter Reason"
            header="Reason"
            value={formData.reason}
            onChange={e => {
              setReasonField(e.target.value as string);
            }}
          />
        </Grid>
      </Grid>
    </VegaDrawerContent>
  );
};

export default CreateLeaveForm;

const typeOfLeaveOptions = () =>
  Object.values(TypeOfLeave).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const StartDateHalfOfDay = () =>
  Object.values(HalfOfDay).map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: _.startCase(_.toLower(item)),
    };
    return option;
  });

const EndDateHalfOfDay = (formData: Partial<CreateApplyLeaveFormData>) => {
  const isStartDateSecondHalf =
    formData.startDateHalf === HalfOfDay.SECOND_HALF;
  const areStartAndEndDateSame = dayjs(formData.startDate).isSame(
    formData.endDate,
    'day'
  );

  return Object.values(HalfOfDay)
    .map(item => {
      if (
        isStartDateSecondHalf &&
        areStartAndEndDateSame &&
        item !== HalfOfDay.SECOND_HALF
      ) {
        return null;
      }

      const option: VegaSelectOption = {
        value: item,
        label: _.startCase(_.toLower(item)),
      };
      return option;
    })
    .filter(Boolean);
};

// const endDateHandler = formData => {
//   return formData.startDateHalf === HalfOfDay.SECOND_HALF
//     ? DateUtility.addDays(formData.startDate, 1)
//     : formData.startDate;
// };
