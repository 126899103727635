import { Stack } from '@mui/system';
import { liveAuctionDetailsState } from '../../features/liveAuctionDetailsSlice';
import { useAppSelector } from '../../store';
import { Possession } from '../../types/auction';

function PossessionImagePreview() {
  const { auction } = useAppSelector(liveAuctionDetailsState);
  const getPossession = () => {
    if (!auction?.metaData) return;
    if (Object.hasOwn(auction.metaData, 'possessionItem')) {
      return auction.metaData['possessionItem'] as Possession;
    }
  };

  const getFirstImage = () => {
    const possession = getPossession();
    const images = possession?.images ?? [];
    if (images.length > 0) return images[0];
  };

  return (
    <div>
      <Stack spacing={'1rem'}>
        <div
          style={{
            height: '17rem',
            minHeight: '9rem',
            minWidth: '15rem',
            width: '26rem',
            borderRadius: '0.25rem',
          }}
        >
          <img
            src={getFirstImage()}
            height={'100%'}
            width={'100%'}
            style={{ objectFit: 'cover', borderRadius: '0.25rem' }}
          ></img>
        </div>
        <Stack direction={'row'} spacing="0.5rem">
          {getPossession()?.images.map(item => {
            return <SecondaryImagePreview url={item} key={item} />;
          })}
        </Stack>
      </Stack>
    </div>
  );
}

export default PossessionImagePreview;

const SecondaryImagePreview = (data: { url: string }) => {
  return (
    <div
      style={{
        height: '4.15rem',
        width: '6.25rem',
        borderRadius: '0.25rem',
      }}
    >
      <img
        src={data.url}
        height={'100%'}
        width={'100%'}
        style={{ objectFit: 'cover', borderRadius: '0.25rem' }}
      ></img>
    </div>
  );
};
