import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Stack,
  TextField,
  TextFieldProps,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { COMMON } from '../../constants/style';
import LmDialog from './LmDialog';
import VegaText from './VegaText';

export type ILmInputProps = {
  header?: string;
  isTextArea?: boolean;
  isInfo?: boolean;
  loading?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
} & TextFieldProps;

const LmTextField = ({
  header,
  isTextArea,
  isInfo = false,
  loading = false,
  startAdornment,
  endAdornment,
  ...rest
}: ILmInputProps) => {
  const { type, sx } = rest;
  const [show, setShow] = useState<boolean>(false);

  const getEndAdornment = () => {
    if (loading) {
      return (
        <InputAdornment position="end">
          <CircularProgress size={'30px'} />
        </InputAdornment>
      );
    }
    if (endAdornment) {
      return <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }
  };

  return (
    <>
      {header && (
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'relative'}
        >
          <VegaText
            fontWeight={700}
            sx={{
              textTransform: 'uppercase',
              fontSize: '0.625rem',
              letterSpacing: '0.07em',
              color: '#676B76',
              mb: 0.5,
            }}
            text={header}
          />
          {isInfo && (
            <Box onClick={() => setShow(true)}>
              <InfoOutlined
                sx={{
                  fontSize: '0.75rem',
                  color: '#676B76',
                  cursor: 'pointer',
                }}
              />
            </Box>
          )}
          <ShowInfoModal show={show} title={header} setShow={setShow} />
        </Stack>
      )}
      <Stack>
        <TextField
          fullWidth
          onWheel={(e: any) => e.target.blur()}
          autoComplete={'off'}
          type={type}
          sx={{
            '& .MuiInputBase-input': {
              fontFamily: `var(--font-Aspekta-450) !important`,
              fontSize: '13px',
              color: '#1B1D22',
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '6px',
              '&.Mui-focused fieldset': {
                borderColor: '#C5C8D0',
                borderWidth: '1px',
                borderRadius: '6px',
              },
              ':hover fieldset': {
                borderColor: '#C5C8D0',
              },
            },
            ...sx,
          }}
          InputProps={{
            style: {
              borderRadius: '9px',
              height: isTextArea ? '' : '35px',
            },
            // endAdornment: endAdornment ? (
            //   endAdornment
            // ) : rest.error ? (
            //   <InputAdornment position="end">
            //     <CancelOutlined />
            //   </InputAdornment>
            // ) : (
            //   rest.error !== undefined && (
            //     <InputAdornment position="end">
            //       <ApprovalOutlined />
            //     </InputAdornment>
            //   )
            // ),
            endAdornment: getEndAdornment(),
            startAdornment: startAdornment,
          }}
          {...rest}
        />
      </Stack>
    </>
  );
};

export default LmTextField;

interface IProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const ShowInfoModal = ({ title, show, setShow }: IProps) => {
  return (
    <LmDialog
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'end',
        },
      }}
      maxWidth="xs"
      open={show}
      isCloseIcon
      handleClose={() => setShow(false)}
      buttonComponent={() => <></>}
      headerComponent={() => <></>}
    >
      <Box px={4} pt={3} pb={4}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Box>
            <InfoOutlined
              sx={{
                cursor: 'pointer',
                fontSize: '1rem',
              }}
            />
          </Box>
          <VegaText
            fontWeight={700}
            sx={{
              textTransform: 'uppercase',
              fontSize: '0.8125rem',
              color: COMMON.balck,
              mb: 0.5,
            }}
            text={title}
          />
        </Stack>
      </Box>
    </LmDialog>
  );
};
