import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import { ReactNode } from 'react';

function addAlpha(color: string, alpha: string) {
  const alphaColor = color.replace(/[^,]+(?=\))/, alpha);
  return alphaColor;
}

export const BUTTON_COLOR = {
  PRIMARY: 'rgba(16, 71, 220, 1)',
  SUCCESS: 'rgba(18, 178, 140, 1)',
};

interface VegaPrimaryButtonProps extends StyledButtonProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  text: string;
  loading?: boolean;
}

interface StyledLoaderProps extends CircularProgressProps {
  buttonVariant?: string;
}

interface StyledButtonProps extends ButtonProps {
  isSuccess?: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ variant = 'contained', isSuccess }) => {
    const commonStyles = {
      borderRadius: '0.25rem',

      '&:hover': {
        transform: 'scale(1.02)',
      },
      '&.Mui-disabled': {
        backgroundColor: '#293543',
        color: '#FFFFFF',
      },
    };

    const containedStyles = {
      transition: 'all 0.3s ease',
      textTransform: 'none',
      cursor: 'pointer',
      backgroundColor: isSuccess ? BUTTON_COLOR.SUCCESS : BUTTON_COLOR.PRIMARY,
      color: 'white',
      '&:hover': {
        backgroundColor: isSuccess
          ? BUTTON_COLOR.SUCCESS
          : BUTTON_COLOR.PRIMARY,
      },
      '&.Mui-disabled': {
        opacity: 0.5,
        color: 'white',
      },
    };

    const outlinedStyles = {
      backgroundColor: 'transparent',
      textTransform: 'none',
      border: `1px solid ${
        isSuccess ? BUTTON_COLOR.SUCCESS : BUTTON_COLOR.PRIMARY
      }`,
      color: isSuccess ? BUTTON_COLOR.SUCCESS : BUTTON_COLOR.PRIMARY,
      '&:hover': {
        border: `1px solid ${
          isSuccess ? BUTTON_COLOR.SUCCESS : BUTTON_COLOR.PRIMARY
        }`,
        backgroundColor: addAlpha(
          isSuccess ? BUTTON_COLOR.SUCCESS : BUTTON_COLOR.PRIMARY,
          '1'
        ),
        color: 'white',
        '& .MuiButton-startIcon svg path': {
          fill: 'white',
        },
      },
      '&.Mui-disabled': {
        color: '#293543',
        border: '1px solid #293543',
      },
    };

    const variantStyles =
      variant === 'contained'
        ? containedStyles
        : variant === 'outlined'
        ? outlinedStyles
        : {};

    return {
      ...commonStyles,
      ...variantStyles,
    };
  }
);

const StyledLoader = styled(CircularProgress)<StyledLoaderProps>(
  ({ buttonVariant = 'contained', size }) => {
    if (buttonVariant == 'contained') {
      return {
        size: size,
        color: 'white',
      };
    }
    return {
      size: size,
      color: '#001aa1',
    };
  }
);

function VegaButton({
  onClick,
  startIcon,
  endIcon,
  text,
  loading = false,
  isSuccess,
  ...rest
}: VegaPrimaryButtonProps) {
  return (
    <StyledButton
      {...rest}
      isSuccess={isSuccess}
      disabled={rest.disabled || loading}
      startIcon={
        loading ? (
          <StyledLoader size={24} buttonVariant={rest.variant} />
        ) : (
          startIcon
        )
      }
      endIcon={endIcon}
      onClick={onClick}
      sx={{
        ...rest.sx,

        ...(!text.length && {
          '& .MuiButton-startIcon': {
            margin: '0px',
          },
          '&.MuiButton-root': {
            padding: '6px',
            minWidth: '30px',
          },
        }),
      }}
    >
      {text}
      {/* {_.startCase(_.toLower(text))} */}
    </StyledButton>
  );
}

export default VegaButton;
