import { Box } from '@mui/material';
import { BLUE } from '../../../../constants/style';
import VegaText from '../../../common/VegaText';

interface IProps {
  title: string;
  children: JSX.Element;
}

const DetailWrapper = ({ title, children }: IProps) => {
  return (
    <Box
      sx={{
        border: `1px solid #E4E4E4`,
        borderRadius: '0.5rem',
      }}
    >
      <Box
        sx={{
          py: 2,
          px: 3,
          borderBottom: `1px solid #E4E4E4`,
          bgcolor: BLUE.lightest,
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <VegaText
          text={title}
          color={'#202020'}
          fontWeight={600}
          fontSize={'0.875rem'}
        />
      </Box>
      <Box
        p={3}
        bgcolor={'white'}
        sx={{
          borderBottomLeftRadius: '0.5rem',
          borderBottomRightRadius: '0.5rem',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DetailWrapper;
