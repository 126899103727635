import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuctionService } from '../Apis/AuctionService';
import { RootState } from '../store';
import { Auction } from '../types/auction';
import { GetAuctionListRequest } from '../types/request/auction';
import { PaginatedResponse } from '../utils/api';

export interface AuctionState {
  loading: boolean;
  auctions: Auction[];
  page: number;
  totalItems: number;
  pageSize: number;
  error: string | null;
}

const initialState: AuctionState = {
  auctions: [],
  loading: false,
  error: null,
  page: 0,
  pageSize: 0,
  totalItems: 0,
};

export const fetchAuctions = createAsyncThunk(
  'auction/fetchAuctions',
  async (
    request: Partial<GetAuctionListRequest>
  ): Promise<PaginatedResponse<Auction>> => {
    const response = await AuctionService.getAuctionList(request);
    return response;
  }
);

const auctionSlice = createSlice({
  name: 'auction',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAuctions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuctions.fulfilled, (state, action) => {
        state.loading = false;
        state.auctions = [...action.payload.records];
        state.page = action.payload.pageNumber;
        state.pageSize = action.payload.numberOfItems;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(fetchAuctions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch Auctions';
      });
  },
});

export default auctionSlice;

export const auctionState = (state: RootState) => state.auction;
