import { GridColumns } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { fetchCampaignList } from '../../../actions/TeleAllocationAction';
import { getTeleAllocation } from '../../../features/teleAllocationSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import LmFilterSection from '../../common/LmFilterSection';
import VegaDataGrid from '../../common/VegaDataGrid';
import VegaPageContent from '../../common/VegaPageContent';
import VegaPageHeader from '../../common/VegaPageHeader';
import VegaText from '../../common/VegaText';

const ListOfCampaignCreation = () => {
  const { campaigns } = useAppSelector(getTeleAllocation);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCampaignList());
  }, [dispatch]);

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Campaign '}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          return <LmFilterSection />;
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          data={campaigns}
          idColumn="campaignId"
          columns={getColumnDefinition()}
          paginationMode="client"
        />
      </VegaPageContent>
    </>
  );
};

export default ListOfCampaignCreation;

const getColumnDefinition = () => {
  const COL_DEF: GridColumns = [
    {
      field: 'name',
      headerName: 'Campaign Name',
      flex: 0.5,
    },
    {
      field: 'campaignId',
      headerName: 'Campaign ID',
      flex: 0.5,
    },
    {
      field: 'description',
      headerName: 'Campaign Description',
      flex: 2,
    },
  ];
  return COL_DEF;
};
