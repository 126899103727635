/* eslint-disable no-unused-vars */
import { useState } from 'react';

interface DrawerOptions {
  [key: string]: any;
}

interface DrawerHookResult {
  isOpen: boolean;
  open: (options?: DrawerOptions) => void;
  close: () => void;
  props: DrawerOptions;
}

export function useDrawer(initialState = false): DrawerHookResult {
  const [isOpen, setIsOpen] = useState<boolean>(initialState);
  const [props, setProps] = useState<DrawerOptions>({});

  const open = (options: DrawerOptions = {}) => {
    setIsOpen(true);
    setProps(options);
  };

  const close = () => {
    setIsOpen(false);
    setProps({});
  };

  return {
    isOpen,
    open,
    close,
    props,
  };
}
