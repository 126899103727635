import { getTargetState } from '../../features/targetSlice';
import { useAppSelector } from '../../store';
import TargetAdmin from './TargetAdmin';
import TargetAgent from './TargetAgent';

const Targets = () => {
  const { isAdmin } = useAppSelector(getTargetState);

  return <>{isAdmin ? <TargetAdmin /> : <TargetAgent />}</>;
};

export default Targets;
