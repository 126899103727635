/* eslint-disable no-empty */
import { useEffect, useState } from 'react';
import { VegaSelectOption } from '../../../components/common/VegaSelect';
import VegaSelectWithCheckbox from '../../../components/common/VegaSelectWithCheckbox';

interface IProps {
  selected: any;
  onSelecte: (select: string) => void;
  onDelete: (select: number) => void;
}

const PolicySelector = ({ onSelecte, selected, onDelete }: IProps) => {
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [policies, setPolicies] = useState<VegaSelectOption[]>([
    {
      label: 'RCM',
      value: 'RCM',
    },
    {
      label: 'CCM',
      value: 'CCM',
    },
    {
      label: 'ACM',
      value: 'ACM',
    },
  ]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== policies.length) {
        setPage(page + 1);
      }
    }
  };

  const getUserList = async () => {};

  useEffect(() => {
    getUserList();
  }, [page]);
  return (
    <VegaSelectWithCheckbox
      options={policies}
      onSelect={selected => onSelecte(selected)}
      handleDelete={onDelete}
      selected={selected}
      placeHolder="Select policy"
      onScroll={loadMoreItems}
    />
  );
};

export default PolicySelector;
