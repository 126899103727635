import { Grid } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import LmButton from '../../../components/common/LmButton';
import LmDateField from '../../../components/common/LmDateField';
import VegaDrawer from '../../../components/common/VegaDrawer';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import { VegaFileSelectOption } from '../../../components/common/VegaFileSelect';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { getUsers, UserActions } from '../../../features/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { VegaUser } from '../../../types/claim';
import { DateUtility } from '../../../utils/DateUtlility';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Partial<ReallocateLoanAccountsFormData>) => void;
};

export type ReallocateLoanAccountsFormData = {
  agentId: string;
  allocationType: string;
  expiryDate: string;
};

enum InputType {
  AgentId = 'agentId',
  AllocationType = 'allocationType',
  ExpiryDate = 'expiryDate',
}

function ReallocateLoanAccountsDrawer({ open, onClose, onSubmit }: Props) {
  const [formData, setFormData] = useState<
    Partial<ReallocateLoanAccountsFormData>
  >({});
  const dispatch = useAppDispatch();
  const { agents, loading } = useAppSelector(getUsers);

  function updateFormData(
    type: InputType,
    value?: string | number | VegaSelectOption | Date | VegaFileSelectOption
  ) {
    setFormData(prev => {
      return {
        ...prev,
        [type]: value,
      };
    });
  }
  function onSumbitClick() {
    onSubmit(formData);
    onClose();
  }

  const isInputValid = () => {
    const isAgentIdValid = (formData.agentId ?? '').length > 0;
    const isAllocationTypeValid = (formData.allocationType ?? '').length > 0;
    return isAgentIdValid && isAllocationTypeValid;
  };

  function onAgentIdChange(value: string) {
    updateFormData(InputType.AgentId, value);
  }
  function onAllocationTypeChange(value: string) {
    updateFormData(InputType.AllocationType, value);
  }
  function updateExpiryDate(value: any) {
    updateFormData(
      InputType.ExpiryDate,
      DateUtility.parseDateFromDatePicker(value)
    );
  }

  useEffect(() => {
    if (open) {
      dispatch(UserActions.fetchAgents());
    }
  }, [open]);

  return (
    <VegaDrawer open={open} title={'ReAllocate'} onClose={onClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <LmButton
              text="Sumbit"
              onClick={onSumbitClick}
              disabled={isInputValid() == false}
            />
          );
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent'}>
              <VegaSelect
                options={getAgentsOptions(agents)}
                loading={loading}
                value={formData.agentId ?? ''}
                onChange={e => onAgentIdChange(e.target.value as string)}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.25rem' }}>
            <VegaFormInputField label="Allocation Type">
              <VegaSelect
                options={allocationTypeOptions()}
                value={formData.allocationType}
                onChange={e => {
                  onAllocationTypeChange(e.target.value as string);
                }}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1.5rem' }}>
            <LmDateField
              header="Expiry Date"
              value={formData.expiryDate}
              onChange={e => {
                updateExpiryDate(e);
              }}
            />
          </Grid>
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ReallocateLoanAccountsDrawer;
enum AllocationType {
  FIELD = 'FIELD',
  TELE = 'TELE',
}
const allocationTypeOptions = () =>
  [AllocationType.TELE, AllocationType.FIELD].map(item => {
    const option: VegaSelectOption = {
      label: _.startCase(_.toLower(item)),
      value: item,
    };
    return option;
  });

const getAgentsOptions = (agents: VegaUser[]) => {
  const options: VegaSelectOption[] = agents.map(agent => {
    const option: VegaSelectOption = {
      value: agent.id,
      label: StringUtility.concatenateStrings(
        agent.firstName,
        agent.middleName,
        agent.lastName
      ),
    };
    return option;
  });
  return options;
};
