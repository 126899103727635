import { ReactNode, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AUTHENTICATION_ROUTES } from '.';
import { useClientAuth } from '../providers/ClientProvider';
import { AUTH_ROUTES, ROUTES } from './routes';

interface IProps {
  children: ReactNode;
}

const PublicRoutes = ({ children }: IProps) => {
  const { isAuth } = useClientAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      isAuth &&
      AUTHENTICATION_ROUTES.map(route => route.path).includes(pathname)
    ) {
      if (pathname === AUTH_ROUTES.LOGIN) {
        navigate(ROUTES.ACCOUNTS);
      } else {
        navigate(pathname);
      }
    }
  }, [isAuth]);

  if (!isAuth) return <>{children}</>;
  return <Outlet />;
};

export default PublicRoutes;
