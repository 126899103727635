import './App.css';
import Router from './router';
import { configureAxiosDefaults } from './Apis/axisConfig';
import { Amplify } from 'aws-amplify';
import { DEV_COGNITO } from './aws';

function App() {
  configureAxiosDefaults();

  Amplify.configure({
    aws_cognito_region: DEV_COGNITO.REGION,
    aws_user_pools_id: DEV_COGNITO.USER_POOL_ID,
    aws_user_pools_web_client_id: DEV_COGNITO.APP_CLIENT_ID,
  });

  return (
    // <Provider store={store}>
    <Router />
    // </Provider>
  );
}

export default App;
