import { Box } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { BLUE, GREEN, PRIMARY } from '../../constants/style';
import { UploadIcon } from '../Icons/Icons';
import LmButton from './LmButton';
import VegaText from './VegaText';

interface IProps {
  selectedFile: File | null;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error: string;
  uploadProgress: number;
  loading?: boolean;
}

const LmBulkUpload = ({
  selectedFile,
  handleFileChange,
  error,
  uploadProgress,
  loading,
}: IProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const sizeMb = selectedFile && uploadProgress * selectedFile?.size;
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <Box my={2} sx={{}}>
        <label
          style={{
            border:
              !loading && selectedFile
                ? `2px dashed ${GREEN.dark}`
                : `2px dashed ${BLUE.lighter}`,
            borderRadius: '8px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            height: '60px',
          }}
          htmlFor="bulkUpload"
        >
          <Box>
            {loading && uploadProgress && uploadProgress > 0 ? (
              <Box>
                <VegaText
                  text="File is being uploaded..."
                  color={PRIMARY.darkBlue}
                  sx={{ textAlign: 'center' }}
                />
                <Box
                  sx={{
                    width: '200px',
                    height: '8px',
                    borderRadius: '20px',
                    backgroundColor: BLUE.light, // Use your desired color here.
                    marginTop: '5px',
                    overflow: 'hidden',
                    mx: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: `${uploadProgress}%`,
                      height: '100%',
                      borderRadius: '20px',
                      backgroundColor: PRIMARY.darkBlue, // Use your desired color here.
                    }}
                  />
                </Box>
              </Box>
            ) : (
              ''
            )}
            {!loading && selectedFile ? (
              <Box>
                <VegaText
                  text={`${selectedFile.name} Upload Successfully`}
                  sx={{ color: GREEN.dark, textAlign: 'center' }}
                />
                <VegaText
                  text={`file size : ${
                    sizeMb && (sizeMb / 1024 / 1024).toFixed(2)
                  } mb`}
                  sx={{ color: GREEN.dark, textAlign: 'center' }}
                />
              </Box>
            ) : (
              !loading && (
                <LmButton
                  variant="text"
                  text="Upload File"
                  bgColor=""
                  textColor={PRIMARY.darkBlue}
                  onClick={handleButtonClick}
                  startIcon={<UploadIcon />}
                />
              )
            )}
          </Box>
        </label>
        <input
          type="file"
          id="bulkUpload"
          hidden
          ref={fileInputRef}
          accept=".csv, .xlsx"
          onChange={handleFileChange}
        />
      </Box>
      <Box>
        {error.length ? (
          <VegaText
            text={error}
            fontWeight={600}
            fontSize={10}
            color={'red'}
            textAlign={'center'}
          />
        ) : (
          ''
        )}

        <VegaText
          text="You can upload files in xlsx and csv format"
          textAlign={'center'}
          fontWeight={600}
          fontSize={10}
          color={'#7B8694'}
        />
      </Box>
    </>
  );
};

export default LmBulkUpload;
