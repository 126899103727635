/* eslint-disable no-undef */
import axios from 'axios';
import {
  GetClickToCallRequest,
  GetLeadsetListApiRequest,
  GetProcessListApiRequest,
} from '../types/request/teleAllocation';
import {
  CampaignStatusListProps,
  CreateCampaign,
  LeadsetListProps,
  ProcessListProps,
} from '../types/teleAllocation';
import { CreateTeleAllocateRequest } from '../types/request/allocation';

const Endpoint = {
  BASE: '/master/capri',
  CAMPAIGN: '/campaign',
  PROCESS: '/process',
  PROCESS_BY_PROCESS_ID: '/:processId',
  LEAD: '/lead',
  LEADSET: '/leadset',
  LIST: '/list',
  CLICK_TO_CALL: '/clickToCall',
  BULK: '/mapAgentToProcess/bulk',
};

const CAMPAIGN_URL = Endpoint.BASE + Endpoint.CAMPAIGN;
const PROCESS_URL = Endpoint.BASE + Endpoint.PROCESS;
const LEAD_URL = Endpoint.BASE + Endpoint.LEAD;
const LEADSET_URL = Endpoint.BASE + Endpoint.LEADSET;
const CLICK_TO_CALL_URL = Endpoint.BASE + Endpoint.CLICK_TO_CALL;

export class TeleAllocationService {
  static async getCampaignList(): Promise<CampaignStatusListProps[]> {
    const endpoint = CAMPAIGN_URL + Endpoint.LIST;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getProcessList(): Promise<ProcessListProps[]> {
    const endpoint = PROCESS_URL + Endpoint.LIST;
    const response = await axios.get(endpoint);
    return response.data;
  }

  static async createBulkProcess(request: Partial<CreateTeleAllocateRequest>) {
    const endpoint = PROCESS_URL + Endpoint.BULK;
    const processId =
      typeof request.processId === 'number'
        ? request.processId.toString()
        : request.processId;

    const formData = new FormData();
    formData.append('file', request.file.value);
    formData.append('processId', processId);

    const response = await axios.post(endpoint, formData);
    return response.data;
  }
  static async getProcessByProcessId(
    processId: string | number
  ): Promise<ProcessListProps> {
    const convertProcessId =
      typeof processId === 'number' ? processId.toString() : processId;
    const endpoint =
      PROCESS_URL +
      Endpoint.PROCESS_BY_PROCESS_ID.replace(':processId', convertProcessId);
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async getClickToCall(requrst: Partial<GetClickToCallRequest>) {
    const endpoint = CLICK_TO_CALL_URL;
    const response = await axios.post(endpoint, requrst);
    return response.data;
  }
  static async getLeadsetList(): Promise<LeadsetListProps[]> {
    const endpoint = LEAD_URL + Endpoint.LIST;
    const response = await axios.get(endpoint);
    return response.data;
  }
  static async createCampaign(request: Partial<CreateCampaign>) {
    const endpoint = CAMPAIGN_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createLeadset(request: Partial<GetLeadsetListApiRequest>) {
    const endpoint = LEADSET_URL;

    const response = await axios.post(endpoint, request);
    return response.data;
  }
  static async createProcess(request: Partial<GetProcessListApiRequest>) {
    const endpoint = PROCESS_URL;
    const response = await axios.post(endpoint, request);
    return response.data;
  }
}
