/** @format */

import { Avatar, Box, Grid, IconButton, Popover, Stack } from '@mui/material';
import { useRef, useState } from 'react';

import LmButton from '../components/common/LmButton';
import VegaText from '../components/common/VegaText';
import { ChevronDown, NotificationsIdle } from '../components/Icons/Icons';
import { useClientAuth } from '../providers/ClientProvider';
import { DateUtility } from '../utils/DateUtlility';

const LmMainHeader = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const { logout, lastLoginTime, userName, email } = useClientAuth();

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLastLoginTime = () => {
    if (!lastLoginTime) {
      return '';
    }
    return (
      'Last Login: ' +
      DateUtility.formatStringToDDMMYYYYWithTime(lastLoginTime.toISOString())
    );
  };

  const userPreferredName = () => {
    return userName ?? 'User';
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      ref={headerRef}
      sx={{
        top: 0,
        bgcolor: '#FFF',
        py: '0.75rem',
        px: '1.5rem',
        height: '4rem',
        borderBottom: '1px solid #DBDBDB',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Grid container justifyContent={'space-between'}>
        <Grid item></Grid>
        <Grid item>
          <Stack direction={'row'} spacing="0.5rem" alignItems={'center'}>
            <IconButton>
              <NotificationsIdle />
            </IconButton>
            <Avatar sx={{ width: '2rem', height: '2rem' }} />
            <VegaText text={userPreferredName()} />
            <IconButton onClick={handleClick}>
              <ChevronDown />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ marginTop: 22 }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: 9999,
          }}
        >
          <VegaText text={userPreferredName()}></VegaText>
          <VegaText
            text={email}
            sx={{ fontSize: 14, marginTop: 1, marginBottom: 1 }}
          ></VegaText>
          <Stack spacing={1}>
            <LmButton
              sx={{ minWidth: 220 }}
              text={'Logout'}
              onClick={logout}
            ></LmButton>
            {/* <LmButton
              text="Reset Password"
              variant="outlined"
              bgColor=""
              textColor="#1047DC"
            /> */}
            <VegaText text={getLastLoginTime()} />
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
};

export default LmMainHeader;
