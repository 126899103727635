import { Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FilePlusIcon, FileUploadIcon } from '../../components/Icons/Icons';
import ListOfPolicies from '../../components/IncentivesGamifications/ListOfPolicies';
import NewPolicyFormDrawer from '../../components/IncentivesGamifications/components/NewPolicyFormDrawer';
import VegaButton, { BUTTON_COLOR } from '../../components/common/VegaButton';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { toLowerCase } from '../../constants/commonFunction';
import { ROUTES } from '../../router/routes';
import {
  DEFAULT_POLICY_DETAILS,
  DEFAULT_RULE,
  getIncentivesGamificationsState,
  setPolicyDetailsState,
  setPolicyRuleState,
  setSelectedPolicyTab,
} from '../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../store';

export enum IncentivesTabType {
  LIVE = 'LIVE',
  FUTURE = 'FUTURE',
  PENDING = 'PENDING',
  HISTORY = 'HISTORY',
  ALL_POLICY = 'ALL',
}

const IncentivesGamifications = () => {
  const { selectedPolicyTab } = useAppSelector(getIncentivesGamificationsState);
  const [openNewPolicy, setOpenNewPolicy] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onOpen = () => {
    setOpenNewPolicy(!openNewPolicy);
  };

  return (
    <>
      <VegaPageHeader
        sx={{ borderBottom: 'none' }}
        renderLeftView={() => {
          return (
            <VegaText
              text={'Incentives & Gamifications'}
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          );
        }}
        renderRightView={() => {
          if (selectedPolicyTab === 0) {
            return (
              <Stack direction={'row'} gap={1}>
                <VegaButton
                  variant="outlined"
                  text={'Add-on Policy'}
                  startIcon={<FilePlusIcon />}
                  sx={{
                    ':hover': {
                      color: BUTTON_COLOR.PRIMARY,
                      bgcolor: 'inherit',
                    },
                  }}
                  onClick={() => {
                    dispatch(setPolicyDetailsState(DEFAULT_POLICY_DETAILS));
                    dispatch(setPolicyRuleState(DEFAULT_RULE));
                    navigate(ROUTES.INCENTIVES_GAMIFICATIONS_ADD);
                  }}
                />
                <VegaButton
                  text={'New Policy'}
                  startIcon={<FileUploadIcon strokeColor="white" />}
                  onClick={onOpen}
                />
              </Stack>
            );
          }
        }}
      />
      <VegaPageHeader
        sx={{ paddingTop: 0 }}
        renderLeftView={() => {
          return (
            <VegaTabBar
              value={selectedPolicyTab}
              onChange={(e, selected) => {
                dispatch(setSelectedPolicyTab(selected));
              }}
            >
              {Object.keys(IncentivesTabType).map((tab: string) => (
                <VegaTabBarItem key={tab} label={`${toLowerCase(tab)}`} />
              ))}{' '}
            </VegaTabBar>
          );
        }}
      />

      <ListOfPolicies selectedPolicyTab={selectedPolicyTab} />

      <NewPolicyFormDrawer open={openNewPolicy} setOpen={setOpenNewPolicy} />
    </>
  );
};

export default IncentivesGamifications;

export const getHeaderText = (tab: number) => {
  if (tab === 1) {
    return IncentivesTabType.FUTURE;
  } else if (tab === 2) {
    return IncentivesTabType.PENDING;
  } else if (tab === 3) {
    return IncentivesTabType.HISTORY;
  } else if (tab === 4) {
    return IncentivesTabType.ALL_POLICY;
  } else {
    return IncentivesTabType.LIVE;
  }
};
