import { Grid, Stack, IconButton } from '@mui/material';
import { toLowerCase } from '../../../constants/commonFunction';
import {
  getIncentivesGamificationsState,
  setPolicyRuleState,
} from '../../../features/IncentivesGamificationsSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  IncentiveCollectionType,
  IncentivePayoutMetrixType,
} from '../../../types/incentives';
import {
  IncentivePolicyRulesRequest,
  PolicyRangeProps,
} from '../../../types/request/incentives';
import LmTextField from '../../common/LmTextField';
import VegaFormInputField from '../../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../../common/VegaSelect';
import VegaText from '../../common/VegaText';
import { DeleteIcon } from '../../Icons/Icons';
import { BORDER_COLLECTION } from '../../../constants/style';
import { useParams } from 'react-router-dom';

interface IBumperProps {
  penaltyIncentive: PolicyRangeProps;
  index: number;
  onDelete: (index: number) => void;
}

const AddPenalty = ({ penaltyIncentive, index, onDelete }: IBumperProps) => {
  const { policyRuleState } = useAppSelector(getIncentivesGamificationsState);
  const dispatch = useAppDispatch();
  const { policyId } = useParams();

  const handlePenltyIncentiveChange = (
    key: keyof PolicyRangeProps,
    value: string | number
  ) => {
    const spreadPenaltyIncentivePolicy = [...policyRuleState.penaltyRange];
    const penaltyIncentiveObj = {
      ...penaltyIncentive,
      [key]: value,
    };
    spreadPenaltyIncentivePolicy.splice(index, 1, penaltyIncentiveObj);
    const penaltyPoliciyRule = {
      ...policyRuleState,
      penaltyRange: spreadPenaltyIncentivePolicy,
    } as IncentivePolicyRulesRequest;
    dispatch(setPolicyRuleState(penaltyPoliciyRule));
  };
  return (
    <Stack sx={{ borderBottom: BORDER_COLLECTION, pb: '1rem' }} gap={2}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <VegaText
          text={`Parameter ${index + 1}`}
          fontWeight={500}
          fontSize={13}
          color={'#202020'}
        />
        {!policyId && (
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Collection parameter'}>
            <VegaSelect
              options={collectionTypeOption()}
              value={penaltyIncentive.parameter}
              onChange={e => {
                const value = e.target.value as string;
                handlePenltyIncentiveChange('parameter', value);
              }}
              disabled={!!policyId}
            />
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'Parameter range '}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '33.33%' }}>
                <LmTextField
                  type="number"
                  placeholder="Min"
                  value={penaltyIncentive.parameterMin}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handlePenltyIncentiveChange('parameterMin', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '33.33%' }}>
                <LmTextField
                  type="number"
                  placeholder="Max"
                  value={penaltyIncentive.parameterMax}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handlePenltyIncentiveChange('parameterMax', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '33.33%' }}>
                <VegaSelect
                  options={metricOption()}
                  value={penaltyIncentive.parameterMetric}
                  onChange={e => {
                    const value = e.target.value as string;
                    handlePenltyIncentiveChange('parameterMetric', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'penalty Metric'}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '50%' }}>
                <LmTextField
                  type="number"
                  value={penaltyIncentive.payoutValue}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handlePenltyIncentiveChange('payoutValue', value);
                  }}
                  disabled={!!policyId}
                />
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <VegaSelect
                  options={maxPayoutTypeOption()}
                  value={penaltyIncentive.payoutMetric}
                  onChange={selected => {
                    handlePenltyIncentiveChange(
                      'payoutMetric',
                      selected.target.value as string
                    );
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <VegaFormInputField label={'penalty capping (Max)'}>
            <Stack direction={'row'} gap={1}>
              <Stack sx={{ width: '50%' }}>
                <LmTextField
                  type="number"
                  disabled={!!policyId}
                  value={penaltyIncentive.payoutCapMax}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    handlePenltyIncentiveChange('payoutCapMax', value);
                  }}
                />
              </Stack>
              <Stack sx={{ width: '50%' }}>
                <VegaSelect
                  options={maxPayoutTypeOption()}
                  value={penaltyIncentive.payoutCapMetric}
                  onChange={selected => {
                    handlePenltyIncentiveChange(
                      'payoutCapMetric',
                      selected.target.value as string
                    );
                  }}
                  disabled={!!policyId}
                />
              </Stack>
            </Stack>
          </VegaFormInputField>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AddPenalty;

const metricOption = () => {
  const options: VegaSelectOption[] = ['PERCENTAGE'].map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};

const collectionTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(IncentiveCollectionType).map(
    (item: string) => ({
      value: item,
      label: toLowerCase(item),
    })
  );
  return options;
};

const maxPayoutTypeOption = () => {
  const options: VegaSelectOption[] = Object.keys(
    IncentivePayoutMetrixType
  ).map((item: string) => ({
    value: item,
    label: toLowerCase(item),
  }));
  return options;
};
