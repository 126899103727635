import { VegaFileSelectOption } from '../components/common/VegaFileSelect';
import { COLOR } from '../utils/ColorUtility';

export enum ReceiptStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_DEPOSIT = 'PENDING_DEPOSIT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}
export enum Relationship {
  SELF = 'SELF',
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  SON = 'SON',
}
export enum PaymentMode {
  CASH = 'CASH',
  DD = 'DD',
  NEFT = 'NEFT',
  CHEQUE = 'CHEQUE',
  ELECTRONIC_FUND_TRANSFER = 'ELECTRONIC_FUND_TRANSFER',
}
export enum PaymentType {
  EMI = 'EMI',
}

export type ReceiptManagmentViewModel = {
  id: string;
  depositId?: string;
  status: ReceiptStatus;
  receiptDate: string;
  receiptId?: string | null;
  agentName: string;
  lan: string;
  bankNameCode: string;
  paymentMode: string | null;
  proof: string[];
};
export type AccountAgentViewModel = {
  id: string;
  name: string;
  email: string;
  product: string;
  emi: number;
  overDue: number;
  total: number;
  allowedAmount: number;
  lan: string;
  pos: number | string;
  ptp: number;
  bounceCharge: number;
  penaltyCharge: number;
  legalCharge: number;
  other: number;
  bucket: string;
  contactNumber: string;
  allocationDate: string;
  address: string;
};
export type ReceiptsListProps = {
  createdAt: string;
  updatedAt: string;
  id: string;
  agentId: string;
  loanId: string;
  locationImageUrl: string;
  documentImageUrl: string;
  payeeName: string;
  relationshipWithCustomer: string;
  totalAmount: number;
  paymentMeta: {
    paymentMode: string;
    refNumber: string;
    refDate: string;
  };
  panCard: string;
  remarks: string;
  status: ReceiptStatus;
  depositId: string | null;
  dateOfDeposit: string | null;
  dateOfApproval: string | null;
  dateOfReceipt: string;
};

export type DepositReceiptProps = {
  id: string;
  receiptId: string;
  depositCentre: string;
  depositRefId: string;
  depositDate: string;
  depositProofUrl: string;
  status: string;
  remarks: string;
};

export type ElectronicPayment = {
  paymentMode: string;
  refNumber: string;
  refDate: string;
};
export type CashPaymentMeta = {
  denomination: number;
  count: number;
};
export type CashPayment = {
  cashPaymentMeta: CashPaymentMeta[];
  paymentMode: string;
};
export type ChequePayment = {
  chequeNumber: string;
  chequeDate: string;
  ifscCode: string;
  bankName: string;
  branchName: string;
  paymentMode: string;
};
export type DDPayment = {
  ddNumber: string;
  micr: string;
  ddDate: string;
  paymentMode: string;
};

export type AnyPaymentType =
  | ElectronicPayment
  | CashPayment
  | ChequePayment
  | DDPayment;

export type IssueReceiptProps = {
  agentId: string;
  loanId: string;
  mobileNumber?: string;
  email?: string;
  paymentType?: string;
  documentImage: VegaFileSelectOption | null;
  payeeName: string;
  relationshipWithCustomer: string;
  totalAmount: number;
  paymentMeta: any;
  panCard: string;
  remarks: string;
};

export const getPillColorForStatus = (status: ReceiptStatus) => {
  switch (status) {
    case ReceiptStatus.APPROVED:
      return COLOR.GREEN;
    case ReceiptStatus.PENDING_APPROVAL:
    case ReceiptStatus.PENDING_DEPOSIT:
      return COLOR.ORANGE;
    case ReceiptStatus.REJECTED:
    case ReceiptStatus.CANCELLED:
      return COLOR.RED;
  }
};
