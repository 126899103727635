import { TrailService } from '../../Apis/TrailService';
import { getTeleAgent } from '../../features/teleAgentSlice';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppSelector } from '../../store';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import UpdateTrailDrawer, {
  CreateTrailFormData,
} from '../Trail/UpdateTrailDrawer';
import { IActionProps } from './AccountActionContainer';

const UpdateTrailFormDrawer = ({ open, setOpen }: IActionProps) => {
  const { loanData } = useAppSelector(getTeleAgent);
  const { setSnackbar } = useSnackbar();

  const onClose = () => {
    setOpen(false);
  };

  const onUpdateTrailClick = (formData: Partial<CreateTrailFormData>) => {
    TrailService.updateTrail(formData)
      .then(() => {
        setSnackbar('Update Trail Successfully !!!');
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
    onClose();
  };
  return (
    <UpdateTrailDrawer
      onUpdateTrailClick={onUpdateTrailClick}
      loanDetail={loanData[0]}
      open={open}
      onClose={onClose}
    />
  );
};

export default UpdateTrailFormDrawer;
