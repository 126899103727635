import { Box, IconButton, Stack } from '@mui/material';
import { DeleteIcon } from '../Icons/Icons';
import VegaText from './VegaText';

interface IVegaTitleCardProps {
  children: JSX.Element;
  title: string;
  onDelete?: () => void;
  isDelete?: boolean;
}

const VegaTitleCard = ({
  children,
  title,
  onDelete,
  isDelete,
}: IVegaTitleCardProps) => {
  return (
    <Box
      sx={{
        border: '1px solid #E4E4E4',
        borderRadius: '8px',
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          px: '1.5rem',
          py: '1rem',
          bgcolor: '#F3F7FF',
          borderBottom: '1px solid #E4E4E4',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <VegaText text={title} fontWeight={600} fontSize={'0.875rem'} />
        {isDelete && (
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Box
        sx={{
          p: '1.5rem',
          bgcolor: 'white',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default VegaTitleCard;
