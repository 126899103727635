import { Grid, Stack } from '@mui/material';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDrawerContent from '../../../components/common/VegaDrawerContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { getTargetState, setBuckets } from '../../../features/targetSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { TargetByBucketsProps } from '../../../types/targets';

interface ITargetFormProps {
  initialDate?: string;
  onAddAndUpdateTarget: (buckets: TargetByBucketsProps[]) => void;
}

const TargetForm = ({
  onAddAndUpdateTarget,
  initialDate,
}: ITargetFormProps) => {
  const dispatch = useAppDispatch();
  const { buckets, isAddTarget } = useAppSelector(getTargetState);

  const handleBucketValue = (index: number, field: string, value: number) => {
    const newBuckets = [...buckets];
    const updatedBucket = { ...newBuckets[index] };
    updatedBucket[field] = value;
    newBuckets[index] = updatedBucket;
    dispatch(setBuckets(newBuckets));
  };

  const isDisabled = () => {
    for (const bucket of buckets) {
      if (bucket.posValue <= 0 || bucket.noOfCases <= 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <Stack direction={'row'} spacing="1rem">
              <VegaButton
                text={isAddTarget ? 'Save Details' : 'Update Details'}
                onClick={() => onAddAndUpdateTarget(buckets)}
                disabled={isDisabled()}
              />
            </Stack>
          );
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <VegaFormInputField label={'Date'}>
              <LmTextField disabled value={initialDate} />
            </VegaFormInputField>
          </Grid>
          {buckets.map((bucket: TargetByBucketsProps, index: number) => (
            <>
              <Grid key={bucket.bucket} item xs={12} sm={6}>
                <VegaFormInputField label={bucket.posName}>
                  <LmTextField
                    type="number"
                    value={bucket.posValue}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBucketValue(index, 'posValue', value);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
              <Grid key={bucket.bucket} item xs={12} sm={6}>
                <VegaFormInputField label={bucket.caseName}>
                  <LmTextField
                    type="number"
                    value={bucket.noOfCases}
                    onChange={e => {
                      const value =
                        e.target.value && parseFloat(e.target.value);
                      handleBucketValue(index, 'noOfCases', value);
                    }}
                  />
                </VegaFormInputField>
              </Grid>
            </>
          ))}
        </Grid>
      </VegaDrawerContent>
    </>
  );
};

export default TargetForm;
