import React from 'react';
import { Stack } from '@mui/material';
import VegaText from '../../common/VegaText';
import VegaPageContent from '../../common/VegaPageContent';
import VegaButton from '../../common/VegaButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../router/routes';

type Props = { loanAccountId?: string };
const TeleAgentAction = ({ loanAccountId }: Props) => {
  const navigate = useNavigate();
  return (
    <VegaPageContent sx={{ pt: '1.5rem' }}>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <VegaText
          fontWeight={700}
          sx={{
            textTransform: 'uppercase',
            fontSize: '0.625rem',
            letterSpacing: '0.07em',
            color: '#676B76',
          }}
          text={'action'}
        />
        {/* <VegaButton variant="outlined" text="Send Payment Link" /> */}
        <VegaButton
          variant="outlined"
          text="Create Receipt"
          onClick={() => {
            navigate(
              ROUTES.RECEIPT_AGENT_ISSUE_RECEIPT.replace(
                ':loanId',
                loanAccountId
              )
            );
          }}
        />
        <VegaButton
          variant="outlined"
          text="View Trail"
          onClick={() => {
            if (loanAccountId) {
              navigate(
                ROUTES.Trail_Detail_Page.replace(
                  ':loanAccountId',
                  loanAccountId
                )
              );
            }
          }}
        />
      </Stack>
    </VegaPageContent>
  );
};

export default TeleAgentAction;
