/* eslint-disable react/prop-types */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { PRIMARY, RED } from '../../constants/style';
import { useUAM } from '../../providers/UAMProvider';
import { NESTED_ROUTES, ROUTES } from '../../router/routes';
import { EditIcon, TrashIcon } from '../Icons/Icons';
import LmButton from '../common/LmButton';
import VegaDataGrid from '../common/VegaDataGrid';

const ListOfUAM = () => {
  const { uamRecords, loading, rowCount, page, handleChangePage } = useUAM();
  const navigate = useNavigate();

  const COLUMN_DEF: GridColumns = [
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 0.7,
      minWidth: 100,
      cellClassName: 'padding',
      headerClassName: 'padding',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'designation',
      headerName: 'Designation',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'employeeCode',
      headerName: 'Employee Code',
      flex: 0.6,
      minWidth: 150,
    },
    {
      field: 'baseBranch',
      headerName: 'Base Branch',
      flex: 0.6,
      minWidth: 100,
    },

    {
      field: 'userDetails',
      headerName: 'User Details',
      flex: 0.6,
      minWidth: 80,
      renderCell: props => {
        return (
          <LmButton
            text="View Details"
            variant="text"
            textColor={PRIMARY.darkBlue}
            fontWeight={500}
            sx={{
              px: 0,
            }}
            fontSize={'0.875rem'}
            bgColor="transparant"
            onClick={() => {
              navigate(
                `${ROUTES.UAM_PARAMS_ID.replace(
                  ':params',
                  NESTED_ROUTES.VIEW_DETAILS
                ).replace(':paramsId', props.row.id)}`
              );
            }}
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 80,
      renderCell: () => {
        return (
          <Stack direction={'row'} gap={1}>
            <LmButton
              text=""
              startIcon={<EditIcon />}
              bgColor="#E7EDFC"
              textColor={PRIMARY.darkBlue}
            />
            <LmButton
              text=""
              startIcon={<TrashIcon />}
              onClick={() => {
                // setSelectedId(holidayType.id);
                // toggleFailureModal();
              }}
              bgColor="#F8E8E8"
              textColor={RED.red}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <VegaDataGrid
        data={uamRecords}
        columns={COLUMN_DEF}
        idColumn="id"
        loading={loading}
        page={page}
        rowCount={rowCount}
        paginationMode="server"
        onPageChange={page => handleChangePage(page)}
      />
    </>
  );
};

export default ListOfUAM;
