import { Close } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { ReceiptManagmentService } from '../../../Apis/ReceiptManagmentService';
import { ApproveIcon } from '../../../components/Icons/Icons';
import LmDialog from '../../../components/common/LmDialog';
import VegaButton from '../../../components/common/VegaButton';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaText from '../../../components/common/VegaText';
import { GREY } from '../../../constants/style';
import {
  receiptState,
  setOpenApproval,
} from '../../../features/receiptManagmentSlice';
import { useSnackbar } from '../../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ReceiptStatus } from '../../../types/receiptManagment';
import { UpdateReceiptParams } from '../../../types/request/receiptManagment';
import { getErrorMessageFromErrorObj } from '../../../utils/api';

interface IProps {
  onFetchList: () => void;
}

const ReceiptApproveDialog = ({ onFetchList }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const { openApproval, receiptData } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();

  const onApprove = async () => {
    const depositStatusObj = {
      depositId: receiptData.depositId,
      status: ReceiptStatus.APPROVED,
    } as UpdateReceiptParams;
    await ReceiptManagmentService.updateStatusForReceipt(depositStatusObj)
      .then(() => {
        setSnackbar('Approve successfully !!');
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
    onFetchList();
    dispatch(setOpenApproval(false));
  };

  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={openApproval}
        handleClose={() => dispatch(setOpenApproval(false))}
        buttonComponent={() => (
          <>
            <Stack
              width={'100%'}
              direction={'row'}
              alignItems={'center'}
              px={4}
              pb={4}
              gap={1}
            >
              <VegaButton
                text="Cancel"
                fullWidth
                variant="outlined"
                sx={{
                  color: GREY.dark,
                }}
                onClick={() => dispatch(setOpenApproval(false))}
                startIcon={<Close sx={{ color: GREY.dark }} />}
              />
              <VegaButton
                fullWidth
                text="Appove"
                startIcon={<ApproveIcon strokeColor="white" />}
                onClick={onApprove}
              />
            </Stack>
          </>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#E5F7FC'}
          >
            <VegaText
              text="Are you sure?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={'black'}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaText
            text={`You are approving the Receipt ID #${receiptData.receiptId} `}
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default ReceiptApproveDialog;
