import axios from 'axios';

import { ICreateDepositBankProps } from '../types/depositBank';

const DEPOSIT_BANK = {
  BANK: '/master/bank',
  BANK_BY_ID: '/master/bank/{depositBankId}',
  BANK_LIST: '/master/bank/list',
};

/////////// Deposit Bank APIs \\\\\\\\\\

export const fetchDepositBanksList = async () => {
  const response = await axios.get(DEPOSIT_BANK.BANK_LIST);
  return response.data;
};

export const fetchDepositBankById = async (depositBankId: string) => {
  const response = await axios.get(
    DEPOSIT_BANK.BANK_BY_ID.replace('{depositBankId}', depositBankId)
  );
  return response.data;
};

export const createDepositBank = async (
  depositeBankState: ICreateDepositBankProps
) => {
  const response = await axios.post(DEPOSIT_BANK.BANK, depositeBankState);
  return response.data;
};

export const updateDepositBank = async (
  depositeBankState: ICreateDepositBankProps
) => {
  const response = await axios.put(DEPOSIT_BANK.BANK, depositeBankState);
  return response.data;
};

export const deleteDepositBank = async (depositBankId: string) => {
  const response = await axios.delete(
    DEPOSIT_BANK.BANK_BY_ID.replace('{depositBankId}', depositBankId)
  );
  return response.data;
};
