import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { ReceiptManagmentService } from '../../Apis/ReceiptManagmentService';
import { setSendPaymentLinkState } from '../../features/accountsSlice';
import { getTeleAgent } from '../../features/teleAgentSlice';
import { useClientAuth } from '../../providers/ClientProvider';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { useAppDispatch, useAppSelector } from '../../store';
import { SendPaymentLinkRequest } from '../../types/request/receiptManagment';
import { getErrorMessageFromErrorObj } from '../../utils/api';
import { RupeeIcon } from '../Icons/Icons';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import { IActionProps } from './AccountActionContainer';

const SendPaymentLinkFormDrawer = ({ open, setOpen }: IActionProps) => {
  const { loanData } = useAppSelector(getTeleAgent);
  const { setSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<number>(0);

  const { userId } = useClientAuth();

  const onClose = () => {
    setOpen(false);
    dispatch(setSendPaymentLinkState(null));
  };

  const loanId = loanData[0]?.loanId;
  const mobileNumber = loanData[0]?.customerDetails[0]?.mobileNo ?? '-';
  const email = loanData[0]?.customerDetails[0]?.email ?? '';
  const payeeName = loanData[0]?.customerDetails[0]?.customerName ?? '-';
  const relationshipWithCustomer = 'SELF';

  const onSendLink = () => {
    const sendPaymentLinkState = {
      agentId: userId,
      amount,
      loanId,
      mobileNumber,
      email,
      payeeName,
      relationshipWithCustomer,
    } as SendPaymentLinkRequest;
    ReceiptManagmentService.createSendPaymentLink(sendPaymentLinkState)
      .then(() => {
        setSnackbar('Send payment link successfully !!!');
        onClose();
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
        onClose();
      });
  };
  return (
    <>
      <VegaDrawer open={open} title={'Send Payment Link'} onClose={onClose}>
        <VegaDrawerContent
          renderBottomView={() => {
            return (
              <Stack direction={'row'} spacing="1rem">
                <VegaButton
                  text="Send Link"
                  disabled={!amount}
                  onClick={onSendLink}
                />
              </Stack>
            );
          }}
        >
          <Grid container spacing={'1.25rem'}>
            <Grid item xs={12}>
              <VegaFormInputField label={'Amount'}>
                <LmTextField
                  type="number"
                  placeholder="Enter Amount"
                  value={amount}
                  startAdornment={<RupeeIcon />}
                  onChange={e => {
                    const value = e.target.value && parseFloat(e.target.value);
                    setAmount(value);
                  }}
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'email'}>
                <LmTextField placeholder="Enter Email" value={email} disabled />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'contact number'}>
                <LmTextField
                  placeholder="Enter Contact Number"
                  value={mobileNumber}
                  disabled
                />
              </VegaFormInputField>
            </Grid>
            <Grid item xs={12}>
              <VegaFormInputField label={'loan account number'}>
                <LmTextField
                  placeholder="Enter Contact Number"
                  value={loanId}
                  disabled
                />
              </VegaFormInputField>
            </Grid>
          </Grid>
        </VegaDrawerContent>
      </VegaDrawer>
    </>
  );
};

export default SendPaymentLinkFormDrawer;
