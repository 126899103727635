import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  CampaignStatusListProps,
  CreateAllocate,
  CreateCampaign,
  CreateLeadSet,
  CreateProcess,
  LeadsetListProps,
  ProcessListProps,
  ProcessType,
  TeleAllocationCampaignViewModal,
  TeleAllocationLeadsetViewModal,
  TeleAllocationProcessViewModal,
} from '../types/teleAllocation';
import {
  fetchCampaignList,
  fetchLeadsetList,
  fetchProcessList,
} from '../actions/TeleAllocationAction';

export const defaultAllocateState = {
  file: null,
  processId: '',
} as CreateAllocate;

export const defaultCampaignState = {
  name: '',
  description: '',
} as CreateCampaign;

export const defaultProcessState = {
  name: '',
  campaign: null,
} as CreateProcess;

export const defaultLeadsetState = {
  processId: '',
  name: '',
} as CreateLeadSet;

export interface TeleAllocationSliceState {
  openCreateLeadsetDrawer: boolean;
  openCreateProcessDrawer: boolean;
  openCreateCampaignDrawer: boolean;
  openCreateAllocateDrawer: boolean;
  openViewProcessDialog: boolean;
  loading: boolean;
  error: string;
  createAllocateState: CreateAllocate;
  createCampaignState: CreateCampaign;
  createProcessState: CreateProcess;
  createLeadsetState: CreateLeadSet;
  campaigns: TeleAllocationCampaignViewModal[];
  processes: TeleAllocationProcessViewModal[];
  leadsets: TeleAllocationLeadsetViewModal[];
  processDetials: Partial<TeleAllocationProcessViewModal | null>;
}

const initialState: TeleAllocationSliceState = {
  loading: false,
  error: '',
  openCreateLeadsetDrawer: false,
  openCreateProcessDrawer: false,
  openCreateCampaignDrawer: false,
  openCreateAllocateDrawer: false,
  openViewProcessDialog: false,
  createAllocateState: defaultAllocateState,
  createCampaignState: defaultCampaignState,
  createProcessState: defaultProcessState,
  createLeadsetState: defaultLeadsetState,
  campaigns: [],
  processes: [],
  leadsets: [],
  processDetials: null,
};

const teleAllocationSlice = createSlice({
  name: 'teleAllocation',
  initialState,
  reducers: {
    setOpenCreateCampaignDrawer: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.openCreateCampaignDrawer = payload;
    },
    setOpenCreateLeadsetDrawer: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.openCreateLeadsetDrawer = payload;
    },
    setOpenCreateProcessDrawer: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.openCreateProcessDrawer = payload;
    },
    setOpenCreateAllocateDrawer: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.openCreateAllocateDrawer = payload;
    },
    setOpenViewProcessDialog: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.openViewProcessDialog = payload;
    },
    setProcessDetials: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.processDetials = payload;
    },
    setCreateAllocateState: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.createAllocateState = payload;
    },
    setCreateCampaignState: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.createCampaignState = payload;
    },
    setCreateProcessState: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.createProcessState = payload;
    },
    setCreateLeadsetState: (state: TeleAllocationSliceState, action) => {
      const { payload } = action;
      state.createLeadsetState = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCampaignList.pending, state => {
        state.campaigns = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCampaignList.fulfilled, (state, action) => {
        const response = [...action.payload];
        const viewModal = response.map(
          (campaign: CampaignStatusListProps) =>
            ({
              name: campaign.slashRtcCampaignName,
              campaignId: campaign.slashRtcCampaignId,
              description: campaign.slashRtcCampaignName,
            } as TeleAllocationCampaignViewModal)
        );
        state.loading = false;
        state.campaigns = viewModal;
      })
      .addCase(fetchCampaignList.rejected, (state, action) => {
        state.loading = false;
        state.campaigns = [];
        state.error = (action.payload as string) || 'Failed to fetch Campaign';
      })
      .addCase(fetchProcessList.pending, state => {
        state.processes = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProcessList.fulfilled, (state, action) => {
        const response = [...action.payload];
        const viewModal = response.map(
          (process: ProcessListProps) =>
            ({
              slashRtcProcessId: process.slashRtcProcessId,
              processId: process.id,
              processName: process.name,
              campaignName: process.campaign,
              processType:
                process.callingMode === 3
                  ? ProcessType.AUTO
                  : ProcessType.MANUAL,
              campaignId: process.campaign,
              pendingLead: process.campaign,
            } as TeleAllocationProcessViewModal)
        );
        state.loading = false;
        state.processes = viewModal;
      })
      .addCase(fetchProcessList.rejected, (state, action) => {
        state.loading = false;
        state.processes = [];
        state.error = (action.payload as string) || 'Failed to fetch Process';
      })
      .addCase(fetchLeadsetList.pending, state => {
        state.leadsets = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeadsetList.fulfilled, (state, action) => {
        const response = [...action.payload];
        const viewModal = response.map(
          (leadset: LeadsetListProps) =>
            ({
              id: leadset.id,
              leadsetId: leadset.leadsetId,
              leadsetName: leadset.leadsetName,
              processId: leadset.processId,
              pushLead: leadset.leadsetId,
            } as TeleAllocationLeadsetViewModal)
        );
        state.loading = false;
        state.leadsets = viewModal;
      })
      .addCase(fetchLeadsetList.rejected, (state, action) => {
        state.loading = false;
        state.processes = [];
        state.error = (action.payload as string) || 'Failed to fetch Process';
      });
  },
});
export const {
  setOpenCreateLeadsetDrawer,
  setOpenCreateProcessDrawer,
  setOpenCreateCampaignDrawer,
  setOpenCreateAllocateDrawer,
  setOpenViewProcessDialog,
  setCreateAllocateState,
  setCreateCampaignState,
  setCreateProcessState,
  setCreateLeadsetState,
  setProcessDetials,
} = teleAllocationSlice.actions;
export const getTeleAllocation = (state: RootState) => state.teleAllocation;
export default teleAllocationSlice;
