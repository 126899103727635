import { Stack } from '@mui/material';
import { useState } from 'react';
import { ReceiptManagmentService } from '../../../Apis/ReceiptManagmentService';
import LmDialog from '../../../components/common/LmDialog';
import LmTextField from '../../../components/common/LmTextField';
import VegaButton from '../../../components/common/VegaButton';
import VegaDialogContent from '../../../components/common/VegaDialogContent';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import VegaText from '../../../components/common/VegaText';
import { GREY, RED } from '../../../constants/style';
import {
  receiptState,
  setOpenReject,
} from '../../../features/receiptManagmentSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ReceiptStatus } from '../../../types/receiptManagment';
import { UpdateReceiptParams } from '../../../types/request/receiptManagment';
import { getErrorMessageFromErrorObj } from '../../../utils/api';
import { useSnackbar } from '../../../providers/SnackbarProvider';

interface IProps {
  onFetchList: () => void;
}

const ReceiptRejectDialog = ({ onFetchList }: IProps) => {
  const { setSnackbar } = useSnackbar();
  const { openReject, receiptData } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();
  const [remarks, setRemarks] = useState<string>('');

  const onReject = async () => {
    const depositStatusObj = {
      depositId: receiptData.depositId,
      status: ReceiptStatus.REJECTED,
      remarks,
    } as UpdateReceiptParams;
    await ReceiptManagmentService.updateStatusForReceipt(depositStatusObj)
      .then(() => {
        setSnackbar('Reject successfully !!');
      })
      .catch(error => {
        setSnackbar(getErrorMessageFromErrorObj(error), 'error');
      });
    onFetchList();
    dispatch(setOpenReject(false));
  };
  return (
    <div>
      <LmDialog
        fullWidth
        maxWidth="xs"
        open={openReject}
        handleClose={() => dispatch(setOpenReject(false))}
        buttonComponent={() => (
          <Stack
            width={'100%'}
            direction={'row'}
            alignItems={'center'}
            px={4}
            pb={4}
            gap={1}
          >
            <VegaButton
              fullWidth
              text="Reject"
              sx={{
                bgcolor: RED.red,
              }}
              onClick={onReject}
            />
            <VegaButton
              text="Cancel"
              fullWidth
              variant="outlined"
              sx={{
                color: GREY.dark,
              }}
              onClick={() => dispatch(setOpenReject(false))}
            />
          </Stack>
        )}
        headerComponent={() => (
          <Stack
            direction={'row'}
            alignItems={'center'}
            px={4}
            py={2.5}
            gap={2}
            bgcolor={'#F8E8E8'}
          >
            <VegaText
              text="Reject Receipt?"
              fontWeight={600}
              fontSize={'1.0625rem'}
              color={RED.red}
            />
          </Stack>
        )}
      >
        <VegaDialogContent>
          <VegaFormInputField label="What is your reason for rejection?">
            <LmTextField
              isTextArea
              rows={2}
              multiline
              value={remarks}
              onChange={e => setRemarks(e.target.value)}
            />
          </VegaFormInputField>
        </VegaDialogContent>
      </LmDialog>
    </div>
  );
};

export default ReceiptRejectDialog;
