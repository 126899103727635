import { Box } from '@mui/material';
import VegaDataGrid from '../common/VegaDataGrid';
import VegaText from '../common/VegaText';
import { GridColumns } from '@mui/x-data-grid';
import { useDrawer } from '../../hooks/useDrawer';
import VegaPageContent from '../common/VegaPageContent';
import { useEffect, useState } from 'react';
import VegaButton from '../common/VegaButton';
import { COLOR } from '../../utils/ColorUtility';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppSelector } from '../../store';
import _ from 'lodash';
import { CheckBrokerIcon, CrossIcon } from '../Icons/Icons';
import RejectLeaveRequestDialog from './RejectLeaveRequestDialog';
import AcceptLeaveRequestDialog from './AcceptLeaveRequestDialog';

import { DateUtility } from '../../utils/DateUtlility';
import {
  fetchLeaveList,
  leaveRequestSelector,
  updateLeaveStatus,
} from '../../features/leaveRequestSlice';
import { LeaveStatus, LeaveViewModel } from '../../types/leave';
import { APPROVER_ID } from '../../utils/EXPENSE_CONSTANTS';
import LmFilterSection from '../common/LmFilterSection';
import VegaPageHeader from '../common/VegaPageHeader';

type props = {
  tabHeading: string;
};
const PendingLeaveRequest = ({ tabHeading }: props) => {
  const { page: currentPage, totalItems } =
    useAppSelector(leaveRequestSelector);

  const [page, setPage] = useState<number>(0);

  const dispatch = useDispatch<AppDispatch>();
  const leaveData = useSelector<RootState, LeaveViewModel[]>(
    state => state.leaveRequestAction.leaves
  );

  function onPageChange(pageNumber: number) {
    setPage(pageNumber);
  }

  const {
    open: openRejectLeaveDialog,
    close: closeRejectLeaveDialog,
    isOpen: isRejectLeaveDialogOpen,
    props: rejectLeaveDialogProps,
  } = useDrawer(false);
  const {
    open: openAcceptLeaveDialog,
    close: closeAcceptLeaveDialog,
    isOpen: isAcceptLeaveDialogOpen,
    props: acceptLeaveDialogProps,
  } = useDrawer(false);

  useEffect(() => {
    dispatch(
      fetchLeaveList({
        approverId: APPROVER_ID,
        status: LeaveStatus.APPLIED,
        page: page,
        size: 10,
      })
    );
  }, [page]);

  return (
    <>
      <VegaPageHeader
        title={tabHeading}
        renderRightView={() => {
          return <LmFilterSection />;
        }}
        sx={{ borderBottom: 'none' }}
      ></VegaPageHeader>
      <VegaPageContent>
        <VegaDataGrid
          page={currentPage}
          pageSize={10}
          rowCount={totalItems}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={leaveData}
          columns={getColumnDefinition({
            onAcceptLeaveClick: (leave: LeaveViewModel) => {
              openAcceptLeaveDialog({ leave: leave });
            },
            onRejectLeaveClick: (leave: LeaveViewModel) => {
              openRejectLeaveDialog({ leave: leave });
            },
          })}
          idColumn="id"
        />
      </VegaPageContent>
      <RejectLeaveRequestDialog
        show={isRejectLeaveDialogOpen}
        leave={rejectLeaveDialogProps.leave}
        onClose={function (): void {
          closeRejectLeaveDialog();
        }}
        onReject={function (leave: LeaveViewModel, reason: string): void {
          dispatch(
            updateLeaveStatus({
              request: {
                leaveId: leave?.id,
                leaveStatus: LeaveStatus.REJECTED,
                rejectionReason: reason,
              },
              fetchList: true,
              fetchListParams: {
                approverId: APPROVER_ID,
                status: LeaveStatus.APPLIED,
              },
            })
          );
        }}
      />
      <AcceptLeaveRequestDialog
        leave={acceptLeaveDialogProps.leave}
        show={isAcceptLeaveDialogOpen}
        onAcceptClick={leave => {
          dispatch(
            updateLeaveStatus({
              request: {
                leaveId: leave?.id,
                leaveStatus: LeaveStatus.APPROVED,
              },
              fetchList: true,
              fetchListParams: {
                approverId: APPROVER_ID,
                status: LeaveStatus.APPLIED,
              },
            })
          );
        }}
        onClose={function (): void {
          closeAcceptLeaveDialog();
        }}
      />
    </>
  );
};

const getColumnDefinition = (data: {
  onAcceptLeaveClick: (leave: LeaveViewModel) => void;
  onRejectLeaveClick: (leave: LeaveViewModel) => void;
}) => {
  const COLUMN_DEF: GridColumns = [
    {
      field: 'AgentName',
      headerName: 'Agent Name',
      flex: 0.2,
      minWidth: 130,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={_.startCase(_.toLower(Leave.agentName))}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'TypeofLeave',
      headerName: 'Type Of Leave',
      flex: 0.3,
      minWidth: 128,
      cellClassName: 'padding',
      headerClassName: 'padding',
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={_.startCase(_.toLower(Leave.typesofLeave))}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'StartDate',
      headerName: 'Start Date',
      flex: 0.3,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={DateUtility.formatStringToDDMMYYYY(Leave.startDate)}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.startDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'EndDate',
      headerName: 'End Date',
      flex: 0.3,
      minWidth: 129,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <div style={{ display: 'block' }}>
            <VegaText
              text={DateUtility.formatStringToDDMMYYYY(Leave.endDate)}
              fontWeight={500}
              fontSize={'0.875rem'}
            />

            <VegaText
              text={_.startCase(_.toLower(Leave.endDateHalf))}
              fontWeight={500}
              fontSize={'0.875rem'}
            />
          </div>
        );
      },
    },
    {
      field: 'Reason',
      headerName: 'Reason/Remark',
      flex: 0.6,
      minWidth: 411,
      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <VegaText
            text={_.startCase(_.toLower(Leave.reason))}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },

    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 207,

      renderCell: props => {
        const Leave = props.row as LeaveViewModel;
        return (
          <>
            <Box pr={2}>
              <VegaButton
                startIcon={<CheckBrokerIcon strokeColor="#318439" />}
                text="Approve"
                onClick={() => {
                  data.onAcceptLeaveClick(Leave);
                }}
                style={{
                  backgroundColor: COLOR.GREEN.LIGHT,
                  color: COLOR.GREEN.DARK,
                }}
              />
            </Box>
            <VegaButton
              startIcon={<CrossIcon />}
              text="Reject"
              onClick={() => {
                data.onRejectLeaveClick(Leave);
              }}
              style={{
                backgroundColor: COLOR.RED.LIGHT,
                color: COLOR.RED.DARK,
              }}
            />
          </>
        );
      },
    },
  ];
  return COLUMN_DEF;
};

export default PendingLeaveRequest;
