import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TargetSevices } from '../Apis/TargetSevices';
import { RootState } from '../store';
import { TargetListRequest } from '../types/request/target';
import { TargetByBucketsProps, TargetListProps } from '../types/targets';

export const targetByBuckets: TargetByBucketsProps[] = [
  {
    bucket: 'BUCKETX',
    posName: 'Bucket X :pos',
    caseName: 'Bucket X :cases',
    posValue: 0,
    noOfCases: 0,
  },
  {
    bucket: 'BUCKET1',
    posName: 'Bucket 1-30 :pos',
    caseName: 'Bucket 1-30 :cases',
    posValue: 0,
    noOfCases: 0,
  },
  {
    bucket: 'BUCKET2',
    posName: 'Bucket 31-60 :pos',
    caseName: 'Bucket 31-60 :cases',
    posValue: 0,
    noOfCases: 0,
  },
  {
    bucket: 'BUCKET3',
    posName: 'Bucket 61-90 :pos',
    caseName: 'Bucket 61-90 :cases',
    posValue: 0,
    noOfCases: 0,
  },
  {
    bucket: 'NPA',
    posName: 'NPA :pos',
    caseName: 'NPA :cases',
    posValue: 0,
    noOfCases: 0,
  },
];

export interface TargetSliceProps {
  targets: TargetListProps[];
  buckets: TargetByBucketsProps[];
  isAdmin: boolean;
  isAddTarget: boolean;
  loading: boolean;
  openAddTargetDrawer: boolean;
  openRejectTargetDialog: boolean;
  openApproveTargetDialog: boolean;
  error: string;
  totalItems: number;
  bucketDetails: Partial<TargetListProps>;
}

const initialState: TargetSliceProps = {
  targets: [],
  bucketDetails: {},
  buckets: targetByBuckets,
  isAdmin: false,
  isAddTarget: true,
  loading: false,
  openAddTargetDrawer: false,
  openRejectTargetDialog: false,
  openApproveTargetDialog: false,
  error: '',
  totalItems: 0,
};

export const getTargetList = createAsyncThunk(
  'target/list',
  async (data: Partial<TargetListRequest>) => {
    const response = await TargetSevices.getTargetList(data);
    return response;
  }
);

const targetSlice = createSlice({
  name: 'target',
  initialState,
  reducers: {
    setIsAddTarget: (state: TargetSliceProps, action) => {
      const { payload } = action;
      state.isAddTarget = payload;
    },
    setOpenAddTargetDrawer: (state: TargetSliceProps, action) => {
      const { payload } = action;
      state.openAddTargetDrawer = payload;
    },
    setOpenApproveTargetDialog: (state: TargetSliceProps, action) => {
      const { payload } = action;
      state.openApproveTargetDialog = payload;
    },
    setOpenRejectTargetDialog: (state: TargetSliceProps, action) => {
      const { payload } = action;
      state.openRejectTargetDialog = payload;
    },
    setBuckets: (state: TargetSliceProps, action) => {
      const { payload } = action;
      state.buckets = payload;
    },
    setBucketDetails: (state: TargetSliceProps, action) => {
      const { payload } = action;
      state.bucketDetails = payload;
    },
  },

  extraReducers: builder => {
    builder

      .addCase(getTargetList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTargetList.fulfilled, (state, action) => {
        state.loading = false;
        state.targets = action.payload.records;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getTargetList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch data';
      });
  },
});
export const {
  setOpenAddTargetDrawer,
  setBuckets,
  setIsAddTarget,
  setBucketDetails,
  setOpenRejectTargetDialog,
  setOpenApproveTargetDialog,
} = targetSlice.actions;
export const getTargetState = (state: RootState) => state.target;
export default targetSlice;
