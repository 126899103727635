import { Grid, Stack } from '@mui/material';
import { COLOR } from '../../utils/ColorUtility';
import LmDialog from '../common/LmDialog';
import VegaText from '../common/VegaText';
import VegaDialogContent from '../common/VegaDialogContent';
import VegaKeyValueDisplay from '../common/VegaKeyValueDisplay';
import { DateUtility } from '../../utils/DateUtlility';
import _ from 'lodash';
import { LeaveStatus, LeaveViewModel } from '../../types/leave';

type Props = { open: boolean; onClose: () => void; leave?: LeaveViewModel };
function LeaveDetailsDialog({ open, onClose, leave }: Props) {
  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      handleClose={() => onClose()}
      isCloseIcon
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Leave Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <VegaDialogContent>
        <div>
          <Grid container gap="115px">
            <Grid item display={'flex'} flexDirection={'column'} gap="13px">
              <VegaKeyValueDisplay
                heading={'Type Of leave'}
                value={_.startCase(_.toLower(leave?.typesofLeave))}
              />

              <VegaKeyValueDisplay
                heading="Start Date"
                value={DateUtility.formatStringToDDMMYYYY(leave?.startDate)}
              />
              <VegaKeyValueDisplay
                heading="End Date"
                value={DateUtility.formatStringToDDMMYYYY(leave?.endDate)}
              />
              <VegaKeyValueDisplay
                heading="leave Reason"
                value={leave?.reason}
              />
            </Grid>
            <Grid item display={'flex'} flexDirection={'column'} gap="13px">
              <VegaKeyValueDisplay
                heading="Status"
                renderValue={() => {
                  return (
                    <VegaText
                      text={getStatusValue(leave?.status)}
                      fontWeight={500}
                      fontSize={'0.8125rem'}
                      color={getColorForStatus(leave?.status)}
                    />
                  );
                }}
              ></VegaKeyValueDisplay>
              <VegaKeyValueDisplay
                heading="Start Time"
                value={_.startCase(_.toLower(leave?.startDateHalf))}
              />
              <VegaKeyValueDisplay
                heading="End Time"
                value={_.startCase(_.toLower(leave?.endDateHalf))}
              />
              {leave?.rejectionReason && (
                <VegaKeyValueDisplay
                  heading="Reject Reason"
                  value={leave?.rejectionReason}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </VegaDialogContent>
    </LmDialog>
  );
}

export default LeaveDetailsDialog;

const getColorForStatus = (status: string) => {
  switch (status) {
    case LeaveStatus.REJECTED:
      return COLOR.RED.DARK;
    case LeaveStatus.APPROVED:
      return COLOR.GREEN.DARK;
    case LeaveStatus.APPLIED:
      return COLOR.ORANGE.DARK;
  }
};

const getStatusValue = (status?: string) => {
  if (!status) return '';
  switch (status) {
    case LeaveStatus.REJECTED:
      return _.startCase(_.toLower(LeaveStatus.REJECTED));
    case LeaveStatus.APPROVED:
      return _.startCase(_.toLower(LeaveStatus.APPROVED));
    case LeaveStatus.APPLIED:
      return 'Pending';
  }
};
