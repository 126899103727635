/* eslint-disable no-empty */
import { useEffect, useState } from 'react';

import { MinVisitsSevices } from '../../../Apis/MinVisitsServices';
import VegaSelect, {
  VegaSelectOption,
} from '../../../components/common/VegaSelect';
import { AreaListProps } from '../../../types/minVisitsType';
import { useAppDispatch } from '../../../store';
import { setSelectedRegion } from '../../../features/minVisitsSlice';

interface IProps {
  selected: string;
  handleChange: (value: string) => void;
  label?: string;
  disabled?: boolean;
}

const RegionSelector = ({
  selected,
  handleChange,
  label,
  disabled,
}: IProps) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [regionList, setRegionList] = useState<VegaSelectOption[]>([]);

  const loadMoreItems = (event: any) => {
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      if (totalItem !== regionList.length) {
        setPage(page + 1);
      }
    }
  };

  const getAreaRegionList = async () => {
    try {
      const response = await MinVisitsSevices.getAreaList({
        page,
        pageSize: 10,
      });

      const branchListOption = await response.records.map(
        (area: AreaListProps) => {
          return {
            value: area.region,
            label: area.region,
          };
        }
      );
      if (page === 0) {
        setRegionList(branchListOption);
      } else {
        setRegionList([...regionList, ...branchListOption]);
      }
      setTotalItem(response.totalItems);
    } catch (error) {}
  };

  const onChange = (value: string) => {
    const filterdRegion = regionList.find(
      (region: VegaSelectOption) => region.value === value
    );
    dispatch(setSelectedRegion(filterdRegion.label));
    handleChange(value);
  };

  useEffect(() => {
    getAreaRegionList();
  }, [page]);
  return (
    <VegaSelect
      placeholder={label ?? 'Select Region'}
      options={regionList}
      onChange={e => onChange(e.target.value)}
      value={selected}
      onScroll={loadMoreItems}
      disabled={disabled}
    />
  );
};

export default RegionSelector;
