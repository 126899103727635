import React from 'react';
import LmDialog from '../../../components/common/LmDialog';
import ReceiptDetails from './ReceiptDetails';
import { Stack } from '@mui/material';
import VegaText from '../../../components/common/VegaText';

interface IProps {
  open: boolean;
  toggleDialog: () => void;
}

const HistoryRecieptDetailsDialog = ({ open, toggleDialog }: IProps) => {
  return (
    <LmDialog
      fullWidth
      maxWidth="sm"
      open={open}
      isCloseIcon
      handleClose={toggleDialog}
      buttonComponent={() => <></>}
      headerComponent={() => (
        <Stack
          direction={'row'}
          alignItems={'center'}
          px={4}
          py={2.5}
          gap={2}
          bgcolor={'#E5F7FC'}
        >
          <VegaText
            text="Receipt Details"
            fontWeight={600}
            fontSize={'1.0625rem'}
            color={'black'}
          />
        </Stack>
      )}
    >
      <ReceiptDetails isAgent />
    </LmDialog>
  );
};

export default HistoryRecieptDetailsDialog;
