export enum TargetsStatus {
  RAISED = 'RAISED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum BucketEnum {
  BUCKETX = 'BUCKETX',
  BUCKET1 = 'BUCKET1',
  BUCKET2 = 'BUCKET2',
  BUCKET3 = 'BUCKET3',
  NPA = 'NPA',
}

export interface TargetByBucketsProps {
  id?: string;
  caseName?: string;
  posName?: string;
  bucket: string;
  bucketStart?: number;
  bucketEnd?: number;
  posValue: number;
  noOfCases: number;
}
export interface TargetListProps {
  id: string;
  agentId: string;
  targetByBuckets: TargetByBucketsProps[];
  approverId: string;
  targetStatus: string;
  targetDate: string;
}
export interface BucketDetailsProps {
  id: string;
  agentId: string;
  targetByBuckets: TargetByBucketsProps[];
  approverId: string;
  targetStatus: string;
  targetDate: string;
}
