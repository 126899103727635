import { Stack } from '@mui/material';
import React from 'react';
import { BORDER_COLLECTION } from '../../../constants/style';
import AreaChart from '../components/AreaChart';
import VegaText from '../../common/VegaText';

const series = [
  {
    name: 'Team Target',
    data: [13, 24, 36, 42, 56, 47, 39, 26, 14, 37, 54, 39],
  },
  {
    name: 'Target Achieved',
    data: [23, 12, 54, 61, 32, 56, 41, 19, 23, 34, 46, 67],
  },
];

const MONTH = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const OverviewChart = () => {
  return (
    <div>
      <Stack
        gap={2}
        sx={{
          border: BORDER_COLLECTION,
          borderRadius: '0.75rem',
          px: 2,
          py: 1.5,
          bgcolor: 'white',
        }}
      >
        <VegaText
          text={'Overview'}
          fontWeight={600}
          fontSize={17}
          color={'black'}
        />
        <AreaChart
          colors={['#1D97DD', '#78B57E']}
          series={series}
          categories={MONTH}
        />
      </Stack>
    </div>
  );
};

export default OverviewChart;
