import React from 'react';
import { Grid } from '@mui/material';
import LmTextField from '../../../components/common/LmTextField';
import VegaFormInputField from '../../../components/common/VegaFormInputField';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  receiptState,
  setIssueReceipt,
} from '../../../features/receiptManagmentSlice';
import {
  ChequePayment,
  IssueReceiptProps,
} from '../../../types/receiptManagment';
import VegaDatePicker from '../../../components/common/VegaDatePicker';

const ChequePaymentDetails = () => {
  const { issueReceipt } = useAppSelector(receiptState);
  const dispatch = useAppDispatch();
  const chequeDetails = issueReceipt?.paymentMeta as ChequePayment;
  const onReceiptChange = (
    key: keyof IssueReceiptProps,
    value: ChequePayment
  ) => {
    const paymentDetailsObj = {
      ...issueReceipt,
      [key]: value,
    } as IssueReceiptProps;
    dispatch(setIssueReceipt(paymentDetailsObj));
  };

  const onChequePaymentChange = (key: keyof ChequePayment, value: string) => {
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const chequePaymentDetailsObj = {
      ...paymentSpread,
      [key]: value,
    } as ChequePayment;
    onReceiptChange('paymentMeta', chequePaymentDetailsObj);
  };
  const onChequeDateChange = (key: keyof ChequePayment, value: Date) => {
    const dateToIsoString = value.toISOString();
    const paymentSpread = { ...issueReceipt?.paymentMeta };
    const chequePaymentDetailsObj = {
      ...paymentSpread,
      [key]: dateToIsoString,
    } as ChequePayment;
    onReceiptChange('paymentMeta', chequePaymentDetailsObj);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Cheque number'}>
          <LmTextField
            type="text"
            value={chequeDetails?.chequeNumber}
            onChange={e => {
              const value = e.target.value;
              onChequePaymentChange('chequeNumber', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Cheque date'}>
          <VegaDatePicker
            type="text"
            value={chequeDetails?.chequeDate}
            onChange={e => {
              const value = e;
              onChequeDateChange('chequeDate', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'IFSC CODE'}>
          <LmTextField
            type="text"
            value={chequeDetails?.ifscCode}
            onChange={e => {
              const value = e.target.value;
              onChequePaymentChange('ifscCode', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'bANK nAME'}>
          <LmTextField
            type="text"
            value={chequeDetails?.bankName}
            onChange={e => {
              const value = e.target.value;
              onChequePaymentChange('bankName', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <VegaFormInputField label={'Branch nAME'}>
          <LmTextField
            type="text"
            value={chequeDetails?.branchName}
            onChange={e => {
              const value = e.target.value;
              onChequePaymentChange('branchName', value);
            }}
          />
        </VegaFormInputField>
      </Grid>
    </Grid>
  );
};

export default ChequePaymentDetails;
