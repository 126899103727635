/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { Grid, Stack } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UserActions, getUsers } from '../../features/userSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  AllocationRuleViewModel,
  AttributeDataType,
  AttributeOperationType,
  Criteria,
} from '../../types/allocations';
import { VegaUser } from '../../types/claim';
import { DateUtility } from '../../utils/DateUtlility';
import { StringUtility } from '../../utils/StringUtility';
import LmTextField from '../common/LmTextField';
import VegaButton from '../common/VegaButton';
import VegaDatePicker from '../common/VegaDatePicker';
import VegaDrawer from '../common/VegaDrawer';
import VegaDrawerContent from '../common/VegaDrawerContent';
import VegaFormInputField from '../common/VegaFormInputField';
import VegaSelect, { VegaSelectOption } from '../common/VegaSelect';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  rule?: AllocationRuleViewModel;
};

type FormData = {
  name: string;
  agentId: string;
  expiry: string;
  criterias: Partial<Criteria>[];
};

function ViewRuleDrawer({ open, onClose, onSubmit, rule }: Props) {
  const dispatch = useAppDispatch();
  const { agents, loading } = useAppSelector(getUsers);
  const [attributes, setAttributes] = useState<Partial<Criteria>[]>([]);
  const [formData, setFormData] = useState<Partial<FormData>>({});

  function handleClose() {
    onClose();
  }

  const isInputValid = () => {
    const isNameValid = (formData.name ?? '').length > 0;
    const isAgentValid = (formData.agentId ?? '').length > 0;
    const isExpirySelected = (formData.expiry ?? '').length > 0;
    const isExpiryValid =
      DateUtility.parseStringToDate(formData.expiry) != null;
    const hasRules = attributes.length > 0;
    return (
      isNameValid &&
      isAgentValid &&
      hasRules &&
      isExpiryValid &&
      isAgentValid &&
      isExpirySelected
    );
  };

  function onCreateClick() {
    onSubmit();
    handleClose();
  }

  function cleanup() {
    setAttributes([]);
    setFormData({});
  }

  useEffect(() => {
    if (open) {
      dispatch(UserActions.fetchAgents());
    }
    if (rule) {
      setFormData(() => {
        setAttributes(rule.criteriaList);
        return {
          name: rule.name,
          agentId: rule.agentId,
          expiry: rule.expiresAt,
        };
      });
    }
  }, [open, rule]);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <VegaDrawer open={open} title={'Add Rule'} onClose={handleClose}>
      <VegaDrawerContent
        renderBottomView={() => {
          return (
            <VegaButton
              text={'Create Rule'}
              disabled={isInputValid() == false}
              onClick={onCreateClick}
            />
          );
        }}
      >
        <Grid container rowGap={'1.5rem'}>
          <Grid item xs={12}>
            <VegaFormInputField label={'Rule Name'}>
              <LmTextField value={formData.name} />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Agent/Agency'}>
              <VegaSelect
                options={getAgentsOptions(agents)}
                loading={loading}
                value={formData.agentId ?? ''}
              />
            </VegaFormInputField>
          </Grid>
          <Grid item xs={12}>
            <VegaFormInputField label={'Expiry'}>
              <VegaDatePicker
                onChange={() => {}}
                value={formData.expiry}
                minDate={new Date()}
              />
            </VegaFormInputField>
          </Grid>
          {attributes.map(item => {
            return (
              <Grid container key={item.attributeName}>
                <Grid item xs={12}>
                  <VegaFormInputField label={_.startCase(item.attributeName)}>
                    <Grid container alignItems={'center'} columnGap="0.5rem">
                      <Grid item xs={7}>
                        <LmTextField value={item.value} />
                      </Grid>
                      <Grid item xs={3}>
                        <Stack>
                          <VegaSelect
                            fullWidth
                            options={getAttributeOperationTypes(item)}
                            value={item.op ?? ''}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </VegaFormInputField>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </VegaDrawerContent>
    </VegaDrawer>
  );
}

export default ViewRuleDrawer;

const getAgentsOptions = (agents: VegaUser[]) => {
  const options: VegaSelectOption[] = agents.map(agent => {
    const option: VegaSelectOption = {
      value: agent.id,
      label: StringUtility.concatenateStrings(
        agent.firstName,
        agent.middleName,
        agent.lastName
      ),
    };
    return option;
  });
  return options;
};

const getAttributeOptions = (attribute: Partial<Criteria>) => {
  if (attribute.dataType == AttributeDataType.NUMBER) {
    return [
      AttributeOperationType.GreaterThen,
      AttributeOperationType.GreaterThenOrEqual,
      AttributeOperationType.Equal,
      AttributeOperationType.LessThen,
      AttributeOperationType.LessThenOrEqual,
    ];
  } else if (attribute.dataType == AttributeDataType.STRING) {
    return [AttributeOperationType.Equal, AttributeOperationType.Like];
  }
  return [];
};

const getAttributeOperationTypes = (attribute: Partial<Criteria>) => {
  const availableOptions = getAttributeOptions(attribute);
  const options: VegaSelectOption[] = availableOptions.map(item => {
    const option: VegaSelectOption = {
      value: item,
      label: item,
    };
    return option;
  });
  return options;
};
