import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { setIsOpenIframe } from '../features/teleAgentSlice';
import { LmSidebarListItemType } from '../router';
import { APP_ROUTES } from '../router/AppRoutes';
import { useAppDispatch } from '../store';
import LmMainHeader from './LmMainHeader';
import LmSubMenu from './LmSubMenu';

interface IProps {
  children: ReactNode;
}

const LmLayout = ({ children }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: 'auto',

      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="main-container">
      <motion.div
        animate={{
          width: isOpen ? '250px' : '45px',
          transition: {
            duration: 0.3,
          },
        }}
        style={{
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
        className={`sidebar`}
        onMouseLeave={() => setIsOpen(false)}
        onMouseEnter={() => setIsOpen(true)}
      >
        <div className="top_section">
          <AnimatePresence>
            {isOpen && (
              <motion.h1
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="logo"
              ></motion.h1>
            )}
          </AnimatePresence>
        </div>

        <section className="routes">
          {APP_ROUTES.map((route: LmSidebarListItemType, index) => {
            if (route.children) {
              return (
                <LmSubMenu
                  key={index}
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                >
                  {route.children}
                </LmSubMenu>
              );
            }

            if (route.label.length) {
              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link "
                  onClick={() => dispatch(setIsOpenIframe(false))}
                  //   activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="font-aspekta-500 link_text"
                        style={{ fontSize: 13 }}
                      >
                        {route.label}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            }
          })}
        </section>
      </motion.div>
      {/* <motion.div
        animate={{
          width: isOpen ? `calc(100vw - 250px)` : 'calc(100vw - 45px)',
          transition: {
            duration: 0.3,
          },
        }}
      > */}
      <Box
        component={'main'}
        sx={{
          bgcolor: '#F7F7F7',
          height: '100%',
          width: '100%',
        }}
      >
        <LmMainHeader />
        <Box
          sx={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: 'calc(100% - 64px)',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
      {/* </motion.div> */}
      {/* <motion.div
        animate={{
          width: isOpenIframe ? '450px' : '0px',
          transition: {
            duration: 0.3,
          },
        }}
        className={`sidebar`}
      >
        <section style={{ height: '100%' }}>
          <SlashRtcView />
        </section>
      </motion.div> */}
    </div>
  );
};

export default LmLayout;
