import { Stack } from '@mui/material';
import React from 'react';
import VegaText from './VegaText';

interface LosFormInputFieldProps {
  label?: string | number;
  children: React.ReactNode;
  endAdornment?: React.ReactNode;
}

function VegaFormInputField({
  label,
  children,
  endAdornment,
}: LosFormInputFieldProps) {
  return (
    <Stack spacing={'0.5rem'}>
      <Stack
        direction={'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems={'center'}
        maxHeight="0.75rem"
      >
        {label && (
          <VegaText
            text={label}
            fontWeight={700}
            style={{
              fontSize: '0.625rem',
              lineHeight: '124%',
              letterSpacing: '0.04375rem',
              textTransform: 'uppercase',
              color: '#676B76',
            }}
          />
        )}
        {endAdornment}
      </Stack>
      {children}
    </Stack>
  );
}

export default VegaFormInputField;
