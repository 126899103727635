import { receiptState } from '../../../features/receiptManagmentSlice';
import { useAppSelector } from '../../../store';
import { PaymentMode } from '../../../types/receiptManagment';
import CashPaymentDetails from './CashPaymentDetails';
import ChequePaymentDetails from './ChequePaymentDetails';
import DDPaymentDetails from './DDPaymentDetails';
import ElectronicPaymentDetails from './ElectronicPaymentDetails';

const PaymentDetails = () => {
  const { issueReceipt } = useAppSelector(receiptState);

  const getComponents = (paymentMode: string) => {
    switch (paymentMode) {
      case PaymentMode.CASH:
        return <CashPaymentDetails />;
      case PaymentMode.CHEQUE:
        return <ChequePaymentDetails />;
      case PaymentMode.DD:
        return <DDPaymentDetails />;
      case PaymentMode.ELECTRONIC_FUND_TRANSFER:
      case PaymentMode.NEFT:
        return <ElectronicPaymentDetails />;

      default:
        return <></>;
    }
  };
  return getComponents(issueReceipt.paymentMeta?.paymentMode);
};

export default PaymentDetails;
