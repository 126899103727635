/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AllocationService } from '../../Apis/AllocationService';
import { reallocateAllocations } from '../../actions/AllocationActions';
import ReallocateAllocationsDrawer, {
  ReallocateAllocationFormData,
} from '../../components/Allocation/ReallocateAllocationsDrawer';
import LmFilterSection from '../../components/common/LmFilterSection';
import VegaButton from '../../components/common/VegaButton';
import VegaDataGrid from '../../components/common/VegaDataGrid';
import VegaPageContent from '../../components/common/VegaPageContent';
import VegaPageHeader from '../../components/common/VegaPageHeader';
import VegaTabBar from '../../components/common/VegaTabBar';
import VegaTabBarItem from '../../components/common/VegaTabItem';
import VegaText from '../../components/common/VegaText';
import { useDrawer } from '../../hooks/useDrawer';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { AppDispatch } from '../../store';
import { AllocationMetaData } from '../../types/allocations';
import { LmsVariable } from '../../types/loan';
import { DateUtility } from '../../utils/DateUtlility';
import { EnumUtility } from '../../utils/EnumUtility';
import { StringUtility } from '../../utils/StringUtility';
import {
  PaginatedResponse,
  getErrorMessageFromErrorObj,
} from '../../utils/api';

enum AllocationTabType {
  Allocated,
  Unallocated,
  Resolved,
  Normalized,
  Stabilized,
  Rollback,
  Cancelled,
}

function AssignAllocation() {
  const { setSnackbar } = useSnackbar();
  const dispatch = useDispatch<AppDispatch>();
  const [allocationsData, setAllocationsData] = useState<
    PaginatedResponse<AllocationMetaData>
  >({
    records: [],
    pageNumber: 0,
    totalPages: 0,
    totalItems: 0,
    numberOfItems: 0,
  });
  const [unAllocationsData, setUnAllocationsData] = useState<
    PaginatedResponse<AllocationMetaData>
  >({
    records: [],
    pageNumber: 0,
    totalPages: 0,
    totalItems: 0,
    numberOfItems: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(
    AllocationTabType.Allocated
  );

  const {
    open: openReallocateDrawer,
    close: closeReallocateDrawer,
    isOpen: isReallocatedDrawerOpen,
  } = useDrawer();

  const handleSelectionModelChange = (selectionModel: string[]) => {
    setSelectedRows(selectionModel);
  };

  function _onTabChange(value: number): void {
    setSelectedTab(value);
  }

  function onPageChange(pageNumber: number) {
    if (selectedTab == 0) {
      setAllocationsData(prev => {
        return {
          ...prev,
          pageNumber: pageNumber,
        };
      });
    } else {
      setUnAllocationsData(prev => {
        return {
          ...prev,
          pageNumber: pageNumber,
        };
      });
    }
  }

  function _reallocateAllocations(
    formData: Partial<ReallocateAllocationFormData>
  ) {
    const loanIds = selectedRows ?? [];
    if (loanIds.length <= 0) return;
    dispatch(
      reallocateAllocations({
        loanIds: loanIds,
        formData: formData,
      })
    );
    setSelectedRows([]);
  }

  async function fetchData() {
    try {
      setSelectedRows([]);
      if (selectedTab != 0 && selectedTab != 1) return;
      setLoading(true);
      const pageNumber =
        selectedTab == 0
          ? allocationsData.pageNumber
          : unAllocationsData.pageNumber;
      const response = await AllocationService.getAllocationMetaData({
        status: getAllocationStatusFromTab(selectedTab),
        page: pageNumber,
      });
      if (selectedTab == 0) {
        setAllocationsData(response);
      } else if (selectedTab == 1) {
        setUnAllocationsData(response);
      }
    } catch (error) {
      setSnackbar(getErrorMessageFromErrorObj(error), 'error');
    } finally {
      setLoading(false);
    }
  }

  const getPageNumber = () => {
    if (selectedTab == 0) return allocationsData.pageNumber;
    else if (selectedTab == 1) return unAllocationsData.pageNumber;
    return 0;
  };

  const getRecords = () => {
    if (selectedTab == 0) return allocationsData.records;
    else if (selectedTab == 1) return unAllocationsData.records;
    return [];
  };

  const getRowCount = () => {
    if (selectedTab == 0) return allocationsData.totalItems;
    else if (selectedTab == 1) return unAllocationsData.totalItems;
    return 0;
  };

  useEffect(() => {
    fetchData();
  }, [allocationsData.pageNumber, unAllocationsData.pageNumber, selectedTab]);

  return (
    <>
      <VegaPageHeader
        renderLeftView={() => {
          return (
            <Stack spacing={'2rem'}>
              <VegaText
                text={'Allocation Status'}
                fontWeight={600}
                fontSize={'1.0625rem'}
                color={'black'}
              />
              <div
                style={{
                  flex: 1,
                }}
              >
                <VegaTabBar
                  value={selectedTab}
                  variant="scrollable"
                  scrollButtons={false}
                  onChange={(e, selected) => {
                    _onTabChange(selected);
                  }}
                >
                  <VegaTabBarItem label="Allocated" />
                  <VegaTabBarItem label="Unallocated" />
                  <VegaTabBarItem label="Resolved" />
                  <VegaTabBarItem label="Normalised" />
                  <VegaTabBarItem label="Stablised" />
                  <VegaTabBarItem label="Rollback" />
                  <VegaTabBarItem label="Cancelled" />
                </VegaTabBar>
              </div>
            </Stack>
          );
        }}
        renderRightView={() => {
          return (
            <Stack direction={'row'} spacing="0.5rem">
              <VegaButton
                variant="outlined"
                text="Reallocate"
                disabled={selectedRows.length <= 0}
                onClick={() => {
                  openReallocateDrawer();
                }}
              />
            </Stack>
          );
        }}
      />
      <VegaPageHeader
        sx={{ border: 'none', marginBottom: '2rem' }}
        title={EnumUtility.getEnumValueName(AllocationTabType, selectedTab)}
        renderRightView={() => {
          return <LmFilterSection />;
        }}
      />
      <VegaPageContent>
        <VegaDataGrid
          page={getPageNumber()}
          pageSize={10}
          checkboxSelection
          rowCount={getRowCount()}
          onPageChange={page => {
            onPageChange(page);
          }}
          data={getRecords()}
          columns={getColumnDefinition()}
          loading={loading}
          idColumn="loanId"
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </VegaPageContent>

      <ReallocateAllocationsDrawer
        open={isReallocatedDrawerOpen}
        onClose={function (): void {
          closeReallocateDrawer();
        }}
        onSubmit={_reallocateAllocations}
      />
    </>
  );
}

export default AssignAllocation;

const getColumnDefinition = () => {
  const COLUMN_DEF: GridColumns<AllocationMetaData> = [
    {
      field: 'Name',
      headerName: 'Name',
      flex: 0.7,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerName = getFirstItemIfExists(loan.customerDetails);
          if (getCustomerName.customerName) {
            displayText = getCustomerName.customerName;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'LoanAccountNumber',
      headerName: 'Loan Account Number',
      flex: 1,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan.loanId ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'POS',
      headerName: 'POS',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        var displayText = '-';
        if (loan.lmsVariables) {
          const outstandingDetails = loan.lmsVariables as LmsVariable;
          if (outstandingDetails.outstandingAmount) {
            displayText = StringUtility.formatToINR(
              outstandingDetails.outstandingAmount
            );
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'Bucket',
      headerName: 'Bucket',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        return (
          <VegaText
            text={loan.lmsVariables.bucket ?? '-'}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'ContactNumber',
      headerName: 'Contact Number',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;

        let displayText = '';
        if (loan.customerDetails) {
          const getCustomerContactNumber = getFirstItemIfExists(
            loan.customerDetails
          );
          if (getCustomerContactNumber.mobileNo) {
            displayText = getCustomerContactNumber.mobileNo;
          }
        }
        return (
          <VegaText text={displayText} fontWeight={500} fontSize={'0.875rem'} />
        );
      },
    },
    {
      field: 'AllocationDate',
      headerName: 'Allocation Date',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        return (
          <VegaText
            text={DateUtility.formatStringToDDMMYYYYWithTime(
              loan.metaDataLoanAllocationDto?.allocationDate
            )}
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
    {
      field: 'Channel',
      headerName: 'Channel',
      flex: 0.6,
      minWidth: 129,
      renderCell: props => {
        const loan = props.row as AllocationMetaData;
        return (
          <VegaText
            text={
              loan?.metaDataLoanAllocationDto?.allocationTypeFromModelParser ??
              '-'
            }
            fontWeight={500}
            fontSize={'0.875rem'}
          />
        );
      },
    },
  ];
  return COLUMN_DEF;
};
function getFirstItemIfExists<T>(arr: T[]): T | undefined {
  return arr?.[0];
}
const getAllocationStatusFromTab = (tab: AllocationTabType) => {
  switch (tab) {
    case AllocationTabType.Allocated:
      return 'ALLOCATED';
    case AllocationTabType.Unallocated:
      return 'UNALLOCATED';
  }
};
